/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { deplymentServiceAPIv2 } from 'services';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { Notification } from 'components/common/Notification';
import { Icon } from 'components/common/Icon';
import './ProjectOriginDeploymentDetails.scss';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Loader } from 'components/common/Loader';
import { timeAgo } from 'helpers/utils';
import { size } from 'lodash';
import moment from 'moment';
import { Hostname } from 'models';
import { IDeployment } from 'models/deployments';

export const ProjectOriginDeploymentDetails = () => {
  const { directoryName, deploymentId } = useParams<{
    directoryName?: string;
    deploymentId?: string;
  }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { dns_domain_name, status } = useAppSelector(({ property }) => property);

  const { data: deployments } = deplymentServiceAPIv2.useFetchAllDeploymentsQuery(
    String(directoryName),
    {
      skip: !token,
    }
  );

  const { data: deploymentDetails } = deplymentServiceAPIv2.useFetchDeploymentDetailsQuery(
    String(deploymentId),
    {
      skip: !token,
      pollingInterval: status === 'deploying' ? 100 : 0,
    }
  );

  const outerDetails = deployments?.data?.deployments?.find(
    (i: any) => i.id === deploymentId
  ) as IDeployment;

  const getURL = useMemo(() => {
    if (outerDetails && outerDetails?.configuration_data) {
      const configData = JSON.parse(outerDetails.configuration_data);
      const { hostnames } = configData;
      return size(hostnames) > 0 ? (
        hostnames.map((host: Hostname) => (
          <Col span={24} key={host.hostname}>
            <a href={`https://${host.hostname ?? ''}`} target="_blank" rel="noreferrer">
              <span>https://{host.hostname ?? ''}</span>
              <span style={{ position: 'relative', fontSize: 13 }}>
                <Icon name="external-link" />
              </span>
            </a>
          </Col>
        ))
      ) : (
        <a href={`https://${dns_domain_name ?? ''}`} target="_blank" rel="noreferrer">
          <span>https://{dns_domain_name ?? ''}</span>
          <span style={{ position: 'relative', fontSize: 13 }}>
            <Icon name="external-link" />
          </span>
        </a>
      );
    }
    return null;
  }, [dns_domain_name, outerDetails]);

  const getDeploymentTime = (logs: { timestamp: string }[]): string => {
    if (logs.length === 0) return '0s';

    const startTimestamp = logs[0].timestamp;
    const endTimestamp = logs[logs.length - 1].timestamp;

    const startTime = moment(startTimestamp);
    const endTime = moment(endTimestamp);

    return `${endTime.diff(startTime, 'seconds')}s`;
  };

  const curColor = (statusDeployment: string) => {
    if (statusDeployment === 'completed') {
      return 'green';
    }
    if (statusDeployment === 'failed') {
      return 'red';
    }
    return 'yellow';
  };

  const getStatus = useCallback((color: string) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <span
          style={{
            display: 'block',
            position: 'relative',
            borderRadius: '50%',
            backgroundColor: color,
            width: '12px',
            height: '12px',
            marginRight: '5px',
          }}
        />
      </div>
    );
  }, []);

  return (
    <>
      {!deploymentDetails?.success && (
        <div
          className="ProjectOriginDeploymentDetails"
          style={{
            minHeight: 490,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Loader />
        </div>
      )}

      {deploymentDetails?.success && (
        <div className="ProjectOriginDeploymentDetails">
          <Row>
            <span className="title">Deployment</span>
          </Row>

          <Row className="ProjectOriginDeploymentDetails-header" justify="start">
            <Col span={3}>Deployment ID:</Col>
            <Col span={21}>{outerDetails?.id}</Col>
          </Row>

          <Row className="ProjectOriginDeploymentDetails-header" justify="start">
            <Col span={3}>Status:</Col>
            <Col span={21}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}>
                <div>{getStatus(curColor(outerDetails?.status))}</div>
                <div>{outerDetails?.status}</div>
              </div>
            </Col>
          </Row>

          <Row className="ProjectOriginDeploymentDetails-header" justify="start">
            <Col span={3}>Deployed at:</Col>
            <Col span={21}>
              <Tooltip title={moment(outerDetails?.created_at).format('MMMM Do YYYY, HH:mm:ss')}>
                {timeAgo(outerDetails?.created_at)}
              </Tooltip>
            </Col>
          </Row>

          <Row className="ProjectOriginDeploymentDetails-header" justify="start">
            <Col span={3}>Deployed time:</Col>
            <Col span={21}>{getDeploymentTime(deploymentDetails?.data)}</Col>
          </Row>

          <Row className="ProjectOriginDeploymentDetails-header" justify="start">
            <Col span={3}>URL:</Col>
            <Col span={21}>{getURL}</Col>
          </Row>

          <Row className="ProjectOriginDeploymentDetails-logs">
            <span className="title">Logs</span>
            <Col span={24}>Output from the deployment process:</Col>
            <Col
              span={24}
              style={{
                backgroundColor: 'black',
                position: 'relative',
                color: '#fff',
                padding: 10,
                borderRadius: 8,
                minHeight: 350,
                margin: '5px 0px',
              }}>
              {deploymentDetails?.data?.map((item: any) => (
                <Col
                  key={item.timestamp}
                  span={24}
                  style={{
                    fontSize: 12,
                    fontFamily: 'Menlo, Monaco, "Courier New", monospace',
                    paddingLeft: 5,
                  }}>
                  <span>{item.timestamp} - </span>
                  <span style={{ color: '#55FFFF' }}>{item.level}</span>
                  <span> - {item.message}</span>
                </Col>
              ))}
              <div style={{ position: 'absolute', top: 10, right: 10 }}>
                <Tooltip placement="top" title="Copy to clipboard">
                  <CopyToClipboard
                    text={String(
                      deploymentDetails?.data
                        ?.map(
                          (item: any) => `${item.timestamp} - ${item.level} - ${item.message}\n`
                        )
                        .join('')
                    )}
                    onCopy={() => Notification({ title: 'Successfuly copied to clipboard' })}>
                    <Icon className="copyIcon" name="copy" style={{ fontSize: 20 }} />
                  </CopyToClipboard>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ProjectOriginDeploymentDetails;
