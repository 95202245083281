import './Dropdown.scss';
import React, { FC, ReactNode } from 'react';
import { Tooltip as AntdDropdown } from 'antd';
import cn from 'classnames';

interface Props {
  trigger?: ('click' | 'hover' | 'contextMenu')[];
  triggerElement: ReactNode;
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
  onVisibleChange?: (e: boolean) => void;
  isSmall?: boolean;
  visible?: boolean;
  withArrow?: boolean;
  align?: {
    targetOffset?: number[];
  };
  children?: ReactNode;
  className?: string;
  mouseEnterDelay?: number;
}

export const Dropdown: FC<Props> = ({
  children,
  trigger = ['click'],
  triggerElement,
  placement = 'bottomRight',
  onVisibleChange = () => {},
  isSmall,
  visible,
  withArrow,
  align,
  className,
  mouseEnterDelay = 0.1,
}) => {
  return (
    <AntdDropdown
      overlayClassName={cn('Dropdown', className, { _small: isSmall, _arrow: withArrow })}
      placement={placement}
      title={children}
      trigger={trigger}
      open={visible}
      align={align}
      mouseEnterDelay={mouseEnterDelay}
      onOpenChange={(e) => onVisibleChange(e)}>
      {triggerElement}
    </AntdDropdown>
  );
};

export default Dropdown;
