import './Button.scss';
import React, { DOMAttributes, forwardRef } from 'react';
import classNames from 'classnames';
import { Icon, IconType } from '../Icon';

export const buttonTestId = 'button';

export type ButtonType = 'primary' | 'secondary' | 'subtle' | 'danger';
export interface Props {
  children?: string | JSX.Element;
  submit?: boolean;
  type?: ButtonType;
  fluid?: boolean;
  loading?: boolean;
  icon?: IconType | false;
  iconPosition?: 'left' | 'right';
  className?: string;
  borderColor?: string;
  disabled?: boolean;
  size?: 'small' | 'big' | 'default' | 'smaller';
  onClick?: DOMAttributes<HTMLButtonElement>['onClick'];
  active?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      type = 'primary',
      fluid = false,
      loading = false,
      disabled = false,
      submit = false,
      iconPosition = 'left',
      size = 'default',
      className,
      icon,
      children,
      onClick,
      active,
      borderColor,
    },
    ref
  ) => {
    const handleClick: DOMAttributes<HTMLButtonElement>['onClick'] = (e) => {
      if (!disabled && onClick && !loading) {
        onClick(e);
      }
    };

    return (
      <button
        ref={ref}
        type={submit ? 'submit' : 'button'}
        data-testid={buttonTestId}
        className={classNames('Button', `_${type}`, `_${size}`, className, {
          _loading: loading,
          _icon: !!icon,
          _fluid: fluid,
          _active: active,
          '_only-child': !children,
        })}
        disabled={disabled}
        onClick={handleClick}
        style={{ borderColor: borderColor || undefined }}>
        {!!icon && (
          <Icon
            name={icon}
            className={classNames('Button__icon', `_${iconPosition}`, {
              '_only-child': !children,
            })}
          />
        )}
        <span className="Button__text">{children}</span>
        <svg
          data-testid="button-svg-loading"
          className="Button__loading"
          focusable="false"
          viewBox="0 0 20 20">
          <circle cx="10" cy="10" r="9" />
        </svg>
      </button>
    );
  }
);

export default Button;
