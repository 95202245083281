export const apacOptions = [
  { value: 'Bypass', name: 'Bypass' },
  {
    value: 'BLR',
    name: 'BLR - Bangalore, India',
  },
  {
    value: 'TIR',
    name: 'TIR - Chennai, India',
  },
  {
    value: 'NDL',
    name: 'NDL - Delhi, India',
  },
  {
    value: 'FOR',
    name: 'FOR - Fortaleza',
  },
  {
    value: 'HKC',
    name: 'HKC - Hong Kong',
  },
  {
    value: 'KHH',
    name: 'KHH - Kaohsiung',
  },
  {
    value: 'MBW',
    name: 'MBW - Melbourne',
  },
  {
    value: 'MTY',
    name: 'MTY - Monterrey, Mexico - PCI',
  },
  {
    value: 'OSA',
    name: 'OSA - Osaka - PCI',
  },
  {
    value: 'RIB',
    name: 'RIB - Rio de Janeiro - PCI',
  },
  {
    value: 'SPB',
    name: 'SPB - Sao Paulo, Brazil - PCI',
  },
  {
    value: 'SGC',
    name: 'SGC - Singapore - PCI',
  },
  {
    value: 'NWA',
    name: 'NWA - Sydney - PCI',
  },
  { value: 'TKB', name: 'TKB - Tokyo' },
  { value: 'TKA', name: 'TKA - Tokyo' },
  {
    value: 'TKC',
    name: 'TKC - Tokyo - PCI',
  },
];
export const emeaOptions = [
  { value: 'Bypass', name: 'Bypass' },
  {
    value: 'AMB',
    name: 'AMB - Amsterdam - PCI',
  },
  {
    value: 'FRB',
    name: 'FRB - Frankfurt',
  },
  {
    value: 'FRC',
    name: 'FRC - Frankfurt - PCI',
  },
  {
    value: 'HYV',
    name: 'HYV - Helsinki',
  },
  {
    value: 'JNB',
    name: 'JNB - Johannesburg, South Africa - PCI',
  },
  {
    value: 'LHC',
    name: 'LHC - London - PCI',
  },
  {
    value: 'LHD',
    name: 'LHD - London - PCI',
  },
  {
    value: 'MDR',
    name: 'MDR - Madrid - PCI',
  },
  {
    value: 'LPL',
    name: 'LPL - Manchester - PCI',
  },
  { value: 'MIL', name: 'MIL - Milan' },
  { value: 'PAB', name: 'PAB - Paris' },
  {
    value: 'PAA',
    name: 'PAA - Paris - PCI',
  },
  {
    value: 'RIX',
    name: 'RIX - Riga, Latvia',
  },
  {
    value: 'STO',
    name: 'STO - Stockholm',
  },
  {
    value: 'SKA',
    name: 'STO - Stockholm',
  },
  {
    value: 'VIA',
    name: 'VIA - Vienna - PCI',
  },
  { value: 'WMI', name: 'WMI - Warsaw' },
];
export const usWestOptions = [
  { value: 'Bypass', name: 'Bypass' },
  {
    value: 'DAC',
    name: 'DAC - Dallas - PCI',
  },
  {
    value: 'DAD',
    name: 'DAD - Dallas - PCI',
  },
  { value: 'DNA', name: 'DNA - Denver' },
  {
    value: 'LAA',
    name: 'LAA - Los Angeles',
  },
  {
    value: 'LAC',
    name: 'LAC - Los Angeles - PCI',
  },
  {
    value: 'SAC',
    name: 'SAC - San Jose - PCI',
  },
  {
    value: 'SED',
    name: 'SED - Seattle - PCI',
  },
];
export const usEastOptions = [
  { value: 'Bypass', name: 'Bypass' },
  {
    value: 'DCD',
    name: 'DCD - Ashburn - PCI',
  },
  {
    value: 'DCE',
    name: 'DCE - Ashburn - PCI',
  },
  { value: 'AGB', name: 'AGB - Atlanta' },
  {
    value: 'AGC',
    name: 'AGC - Atlanta - PCI',
  },
  { value: 'BSB', name: 'BSB - Boston' },
  { value: 'CHA', name: 'CHA - Chicago' },
  {
    value: 'CHD',
    name: 'CHD - Chicago - PCI',
  },
  { value: 'MIC ', name: 'MIC - Miami' },
  {
    value: 'MID',
    name: 'MID - Miami - PCI',
  },
  {
    value: 'NYD',
    name: 'NYD - New York - PCI',
  },
  {
    value: 'PHD',
    name: 'PHD - Philadelphia',
  },
];
