/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { LogsAggregateChartData, LogsChartData } from 'models';
import { isEmpty } from 'lodash';
import { Loader } from 'components/common/Loader';
import styles from './ProjectLogsViewList.module.scss';
import { ProjectLogsViewListBarChart } from './ProjectLogsViewListBarChart';
import { ProjectLogsViewListStackedBarChart } from './ProjectLogsViewListStackedBarChart';

interface IProjectLogsViewListProps {
  data: LogsChartData[];
  aggregateData: LogsAggregateChartData[];
  interval: string;
  dataType?: string;
  isGroupedByStatus?: boolean;
  isChartDataLoading: boolean;
  isLoading: boolean;
  handleChartHistory: (preInterval: [number, number]) => void;
  updateHttpStatusCodes: (code: string) => void;
  httpCodes: string[];
}

export const ProjectLogsViewList: FC<IProjectLogsViewListProps> = ({
  data,
  aggregateData,
  interval,
  dataType,
  isGroupedByStatus,
  isLoading,
  handleChartHistory,
  updateHttpStatusCodes,
  httpCodes,
  isChartDataLoading,
}) => {
  const [isNoData, setIsNoData] = useState(false);
  const [isStackedChart, setIsStackedChart] = useState(false);
  useEffect(() => {
    if (dataType && dataType === 'ADN' && isGroupedByStatus && isChartDataLoading === false) {
      setIsStackedChart(true);
    } else if (isChartDataLoading === false) setIsStackedChart(false);
  }, [dataType, isChartDataLoading, isGroupedByStatus]);

  useEffect(() => {
    if (dataType && dataType === 'ADN' && isEmpty(aggregateData)) {
      setIsNoData(true);
    }
    if (dataType && dataType !== 'ADN' && isEmpty(data)) {
      setIsNoData(true);
    }

    return () => setIsNoData(false);
  }, [aggregateData, data, dataType]);

  const renderChart = useMemo(() => {
    if (isNoData) {
      return <div className={styles.empty}>No logs data for chart</div>;
    }
    return isStackedChart
      ? !isEmpty(aggregateData) && (
          <ProjectLogsViewListStackedBarChart
            data={aggregateData}
            interval={interval}
            handleChartHistory={handleChartHistory}
            updateHttpStatusCodes={updateHttpStatusCodes}
            httpCodes={httpCodes}
            isLoading={isLoading}
          />
        )
      : !isEmpty(data) && (
          <ProjectLogsViewListBarChart
            data={data}
            interval={interval}
            handleChartHistory={handleChartHistory}
            isLoading={isLoading}
          />
        );
  }, [
    isNoData,
    isStackedChart,
    aggregateData,
    interval,
    handleChartHistory,
    updateHttpStatusCodes,
    httpCodes,
    isLoading,
    data,
  ]);

  return (
    <div className={styles.page}>
      <div className={styles.chart}>
        {isLoading && (
          <div className={styles.chartLoaderAbsolute}>
            <Loader />
          </div>
        )}
        {renderChart}
      </div>
    </div>
  );
};

export default ProjectLogsViewList;
