import './ProjectOriginAlerts.scss';
import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { Input } from 'components/common/Input';
import Button from 'components/common/Button';
import { Icon } from 'components/common/Icon';

interface Props {
  onAddAlert?: () => void;
}

export const ProjectOriginAlerts: FC<Props> = ({ onAddAlert }) => {
  return (
    <div className="ProjectOriginAlerts">
      <div className="ProjectOriginAlerts__head">
        <Row justify="space-between" align="middle">
          <Col>
            <Row gutter={32} align="middle">
              <Col>
                <h4 className="ProjectOriginAlerts__title">Real-time alerts</h4>
              </Col>
              <Col>
                <Input icon="search" placeholder="Search" width={260} />
              </Col>
            </Row>
          </Col>
          <Col>
            <Button icon="plus" type="secondary" onClick={onAddAlert}>
              Add New Alert
            </Button>
          </Col>
        </Row>
      </div>

      <Row className="ProjectOriginAlerts__table-head">
        <Col span={5} className="ProjectOriginAlerts__head-col">
          <span className="ProjectOriginAlerts__head-label">ALERT</span>
        </Col>
        <Col span={5} className="ProjectOriginAlerts__head-col">
          <span className="ProjectOriginAlerts__head-label">Expression</span>
        </Col>
        <Col span={5} className="ProjectOriginAlerts__head-col">
          <span className="ProjectOriginAlerts__head-label">Notify On</span>
        </Col>
        <Col span={4} className="ProjectOriginAlerts__head-col">
          <span className="ProjectOriginAlerts__head-label">Interval</span>
        </Col>
        <Col span={4} className="ProjectOriginAlerts__head-col">
          <span className="ProjectOriginAlerts__head-label">Enabled</span>
        </Col>
      </Row>

      <Row className="ProjectOriginAlerts__table-item">
        <Col span={5} className="ProjectOriginAlerts__table-col">
          <span className="ProjectOriginAlerts__table-label">Lorem Ipsum</span>
        </Col>
        <Col span={5} className="ProjectOriginAlerts__table-col">
          <span className="ProjectOriginAlerts__table-label">Lorem Ipsum</span>
        </Col>
        <Col span={5} className="ProjectOriginAlerts__table-col">
          <span className="ProjectOriginAlerts__table-label">Lorem Ipsum</span>
        </Col>
        <Col span={4} className="ProjectOriginAlerts__table-col">
          <span className="ProjectOriginAlerts__table-label">Lorem Ipsum</span>
        </Col>
        <Col span={4} className="ProjectOriginAlerts__table-col">
          <span className="ProjectOriginAlerts__table-label">Lorem Ipsum</span>
        </Col>
        <Col flex={1} className="ProjectOriginAlerts__table-col _ta-right">
          <Icon name="edit" className="ProjectOriginAlerts__link-icon _margin-less" />
          <Icon name="remove" className="ProjectOriginAlerts__link-icon" />
        </Col>
      </Row>
      <Row className="ProjectOriginAlerts__table-item _last">
        <Col span={5} className="ProjectOriginAlerts__table-col">
          <span className="ProjectOriginAlerts__table-label">Lorem Ipsum</span>
        </Col>
        <Col span={5} className="ProjectOriginAlerts__table-col">
          <span className="ProjectOriginAlerts__table-label">Lorem Ipsum</span>
        </Col>
        <Col span={5} className="ProjectOriginAlerts__table-col">
          <span className="ProjectOriginAlerts__table-label">Lorem Ipsum</span>
        </Col>
        <Col span={4} className="ProjectOriginAlerts__table-col">
          <span className="ProjectOriginAlerts__table-label">Lorem Ipsum</span>
        </Col>
        <Col span={4} className="ProjectOriginAlerts__table-col">
          <span className="ProjectOriginAlerts__table-label">Lorem Ipsum</span>
        </Col>
        <Col flex={1} className="ProjectOriginAlerts__table-col _ta-right">
          <Icon name="edit" className="ProjectOriginAlerts__link-icon _margin-less" />
          <Icon name="remove" className="ProjectOriginAlerts__link-icon" />
        </Col>
      </Row>
    </div>
  );
};

export default ProjectOriginAlerts;
