import { Col, Row, DatePicker } from 'antd';
import classNames from 'classnames';
import Button from 'components/common/Button';
import { Icon } from 'components/common/Icon';
import StatusDot from 'components/common/StatusDot/StatusDot';
import { find, isArray, map, some } from 'lodash';
import React, { FC, useCallback, useState } from 'react';
import moment, { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from 'store';
import { ActivePeriodType, changeSelectedPeriod } from 'store/slices/project';
import { RangePickerProps } from 'antd/lib/date-picker';
import { propertyAPI } from 'services';
import { useParams } from 'react-router-dom';

const { RangePicker } = DatePicker;

export const dropdownValues: { name: string; value: ActivePeriodType }[] = [
  { name: 'Live', value: 'live' },
  {
    name: 'Today',
    value: [moment().startOf('D'), moment().endOf('D')],
  },
  {
    name: 'Yesterday',
    value: [
      moment().startOf('D').subtract(1, 'd'),
      moment().startOf('D').subtract(1, 'd').endOf('D'),
    ],
  },
  {
    name: 'This Week',
    value: [moment().startOf('W'), moment()],
  },
  {
    name: 'Last Week',
    value: [
      moment().startOf('W').subtract(1, 'w'),
      moment().startOf('W').subtract(1, 'w').endOf('W'),
    ],
  },
  { name: 'This Month', value: [moment().startOf('M'), moment()] },
  {
    name: 'Last Month',
    value: [
      moment().subtract(1, 'months').startOf('M'),

      moment().subtract(1, 'months').endOf('month'),
    ],
  },
  {
    name: 'Last Three Months',
    value: [
      moment().subtract(3, 'months').startOf('M'),
      moment().subtract(1, 'months').endOf('month'),
    ],
  },
  {
    name: 'Last Six Months',
    value: [
      moment().subtract(6, 'months').startOf('M'),
      moment().subtract(1, 'months').endOf('month'),
    ],
  },
];

export const ProjectHeadTimeDropdown: FC = () => {
  const [isDatesOpen, setIsDatesOpen] = useState(false);

  const { selectedPeriod, disabledTimePeriods } = useAppSelector(({ project }) => project);
  const { token } = useAppSelector(({ auth }) => auth);
  const { directoryName } = useParams<{ directoryName?: string }>();
  const dispatch = useAppDispatch();
  const { data: projectDetails } = propertyAPI.useFetchPropertyQuery(String(directoryName), {
    skip: !token,
  });

  const disabledDate: RangePickerProps['disabledDate'] = useCallback(
    (current: Moment) => {
      return (
        current &&
        (current > moment().endOf('day') || current < moment(projectDetails?.data?.created_at))
      );
    },
    [projectDetails?.data?.created_at]
  );

  return (
    <div className="ProjectHead__live-dropdown">
      <Row align="middle" style={{ position: 'relative', cursor: 'pointer' }}>
        {selectedPeriod === 'live' && (
          <Button
            onClick={() => setIsDatesOpen(!isDatesOpen)}
            type="subtle"
            className="ProjectHead__live-button">
            <div className={classNames('ProjectHead__purge-text')}>
              {selectedPeriod === 'live' ? (
                <>
                  <StatusDot type="success" className="ProjectHead__status" />
                  Live
                </>
              ) : (
                find(dropdownValues, (item) => item.value === selectedPeriod)?.name
              )}
              <Icon
                onClick={() => setIsDatesOpen(!isDatesOpen)}
                className="ProjectHead__small-arrow"
                name="expand-down"
              />
            </div>
          </Button>
        )}
        <Col style={{ zIndex: 0 }}>
          <Icon name="calendar" className="ProjectHead__dropdown-icon" />
        </Col>
        <Col
          style={{
            zIndex: 0,
            //  display: isDatesOpen ? 'block' : 'none'
          }}>
          <RangePicker
            // onOpenChange={(v) => setIsDatesOpen(v)}
            className="ProjectHead__calendar"
            bordered={false}
            format="DD/MM/YYYY"
            disabledDate={disabledDate}
            value={
              isArray(selectedPeriod)
                ? selectedPeriod
                : [moment().startOf('D'), moment().endOf('D')]
            }
            onChange={(v) => {
              dispatch(changeSelectedPeriod(v as [Moment, Moment]));
            }}
            popupClassName="ProjectHead__calendar-dropdown"
            renderExtraFooter={() => {
              return (
                <div className="ProjectHead__extraFooter">
                  {map(dropdownValues, ({ name, value }, idx) => (
                    <Button
                      key={idx}
                      type="secondary"
                      fluid
                      active={
                        selectedPeriod === 'live'
                          ? idx === 0
                          : isArray(value) &&
                            isArray(selectedPeriod) &&
                            value[0].isSame(selectedPeriod[0]) &&
                            value[1].isSame(selectedPeriod[1])
                      }
                      disabled={some(disabledTimePeriods, (v) => v === idx)}
                      onClick={() => dispatch(changeSelectedPeriod(value))}>
                      {name}
                    </Button>
                  ))}
                </div>
              );
            }}
            suffixIcon={
              <Icon
                className={classNames('ProjectHead__dropdown-icon', {
                  _active: isDatesOpen,
                })}
                name="expand-down"
              />
            }
            separator={<span className="ProjectHead__separator" />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ProjectHeadTimeDropdown;
