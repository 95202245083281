/* eslint-disable no-underscore-dangle */
import { Col, Row, Skeleton, Tooltip } from 'antd';
import classNames from 'classnames';
import { BlockWrapper } from 'components/common/BlockWrapper';
import { map } from 'lodash';
import React, { FC } from 'react';
import styles from './ProjectSecuritySmallTable.module.scss';

interface ProjectSecuritySmallTableProps {
  data?: {
    [key: string]: string | number;
  }[];
  title?: string;
  loading?: boolean;
}

export const ProjectSecuritySmallTable: FC<ProjectSecuritySmallTableProps> = ({
  data,
  title,
  loading,
}) => {
  return (
    <BlockWrapper title={title} headerPadding="16px 24px 0" minHeight="initial">
      <Row className={styles.tableHead}>
        <Col span={18} className={styles.headCol}>
          <span className={styles.headLabel}>Type</span>
        </Col>
        {/* <Col span={4} className={styles.headCol}>
          <span className={styles.headLabel}>Percentage</span>
        </Col> */}
        <Col span={6} className={classNames(styles.headCol, styles.rightAlign)}>
          <span className={styles.headLabel}>Events</span>
        </Col>
      </Row>

      {loading ? (
        <Row className={styles.item}>
          <Skeleton
            active
            paragraph={{ rows: 1 }}
            title={{ width: 0, style: { display: 'none' } }}
          />
        </Row>
      ) : (
        map(data, (item) => (
          <Row className={styles.item} key={item._id}>
            <Col span={21} className={styles.col}>
              <Tooltip placement="topLeft" title={item._id}>
                <span className={styles.tableLabel}>{item._id}</span>
              </Tooltip>
            </Col>
            {/* <Col span={4} className={styles.col}>
            <span className={styles.tableLabel}> </span>
          </Col> */}
            <Col span={3} className={classNames(styles.col, styles.rightAlign)}>
              <span className={styles.tableLabel}>{item.count}</span>
            </Col>
          </Row>
        ))
      )}
    </BlockWrapper>
  );
};

export default ProjectSecuritySmallTable;
