import { createApi } from '@reduxjs/toolkit/query/react';
import { IDataType } from 'components/views/Project/ProjectCharts/configs';
import { map } from 'lodash';
import { ISecurityEventsLogResponse } from 'models';
import moment from 'moment';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: baseQuery(),
  tagTypes: ['Analytics'],
  endpoints: (build) => ({
    fetchHistoricalStristicsEvents: build.query<
      IDataType[],
      {
        directoryName: string;
        cnameId: string;
        duration: 'second' | 'minute' | 'hour' | 'day';
        startDate: string | number;
        endDate: string | number;
      }
    >({
      query: ({ directoryName, cnameId, duration, startDate, endDate }) => ({
        url: endpoints.analytics.eventsLogs(directoryName, cnameId),
        params: {
          start_date: startDate,
          end_date: endDate,
          duration,
        },
      }),
      transformResponse: (response: ISecurityEventsLogResponse) => {
        return map(response?.data?.aggregate?.buckets, ({ key_as_string, doc_count }) => ({
          value: Number(doc_count),
          label: moment(key_as_string).format('MMM DD YYYY HH:mm:ss'),
          // label: moment(timestamp).unix(),
        }));
      },
    }),

    fetchHistoricalStristicsRateLimit: build.query<
      IDataType[],
      {
        directoryName: string;
        cnameId: string;
        duration: 'second' | 'minute' | 'hour' | 'day';
        startDate: string | number;
        endDate: string | number;
      }
    >({
      query: ({ directoryName, cnameId, duration, startDate, endDate }) => ({
        url: endpoints.analytics.rateLimiting(directoryName, cnameId),
        params: {
          start_date: startDate,
          end_date: endDate,
          duration,
        },
      }),
      transformResponse: (response: ISecurityEventsLogResponse) => {
        return map(response?.data?.aggregate?.buckets, ({ key_as_string, doc_count }) => ({
          value: Number(doc_count),
          label: moment(key_as_string).format('MMM DD YYYY HH:mm:ss'),
          // label: moment(timestamp).unix(),
        }));
      },
    }),

    fetchHistoricalStristicsBotManagement: build.query<
      IDataType[],
      {
        directoryName: string;
        cnameId: string;
        duration: 'second' | 'minute' | 'hour' | 'day';
        startDate: string | number;
        endDate: string | number;
      }
    >({
      query: ({ directoryName, cnameId, duration, startDate, endDate }) => ({
        url: endpoints.analytics.botMagementLogs(directoryName, cnameId),
        params: {
          start_date: startDate,
          end_date: endDate,
          duration,
        },
      }),
      transformResponse: (response: ISecurityEventsLogResponse) => {
        return map(response?.data?.aggregate?.buckets, ({ key_as_string, doc_count }) => ({
          value: Number(doc_count),
          label: moment(key_as_string).format('MMM DD YYYY HH:mm:ss'),
          // label: moment(timestamp).unix(),
        }));
      },
    }),
  }),
});

export default analyticsApi;
