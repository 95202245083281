import { createApi } from '@reduxjs/toolkit/query/react';
import { map } from 'lodash';
import { IAdnCnamesResponce } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const cdnCnamesAPI = createApi({
  reducerPath: 'cdnCnamesAPI',
  baseQuery: baseQuery(),
  tagTypes: ['CdnCnames'],
  endpoints: (build) => ({
    fetchCdnCnames: build.query<IAdnCnamesResponce[], string>({
      query: (directoryName) => ({
        url: endpoints.cdnCnames.cnames(directoryName),
      }),
      providesTags: (result) =>
        result
          ? [...map(result, ({ id }) => ({ type: 'CdnCnames' as const, id })), 'CdnCnames']
          : ['CdnCnames'],
    }),
    fetchdnCname: build.query<IAdnCnamesResponce[], { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: `${endpoints.cdnCnames.cnames(directoryName)}/${id}`,
      }),
    }),
    createCdnCname: build.mutation<IAdnCnamesResponce, { directoryName: string; hostname: string }>(
      {
        query: ({ directoryName, hostname }) => ({
          url: endpoints.cdnCnames.cnames(directoryName),
          method: 'POST',
          body: { hostname },
        }),
        invalidatesTags: ['CdnCnames'],
      }
    ),
    removeCdnCname: build.mutation<IAdnCnamesResponce, { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: `${endpoints.cdnCnames.cnames(directoryName)}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CdnCnames'],
    }),
    editCdnCname: build.mutation<
      IAdnCnamesResponce,
      { directoryName: string; id: string; hostname: string }
    >({
      query: ({ directoryName, id, hostname }) => ({
        url: `${endpoints.cdnCnames.cnames(directoryName)}/${id}`,
        method: 'PUT',
        body: { hostname },
      }),
      invalidatesTags: ['CdnCnames'],
    }),
  }),
});

export default cdnCnamesAPI;
