import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeCardNumberElementOptions } from '@stripe/stripe-js';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import Button from 'components/common/Button';
import { Loader } from 'components/common/Loader';
import React, { FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Notification } from 'components/common/Notification';
import { paymentMethodsAPI } from 'services';
import { ReactComponent as Image } from 'assets/svg/PaymentModalImage.svg';
import { ReactComponent as Stripe } from 'assets/svg/Stripe.svg';
import { useAppSelector } from 'store';

import styles from './UserSettingsPaymentModal.module.scss';

interface IUserSettingsPaymentModalProps {
  onCancel: () => void;
}

export const UserSettingsPaymentModal: FC<IUserSettingsPaymentModalProps> = ({ onCancel }) => {
  const { token } = useAppSelector(({ auth }) => auth);
  const {
    data: intentData,
    isLoading,
    isError,
  } = paymentMethodsAPI.useFetchIntentQuery('', {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });
  const [createPaymentMethod] = paymentMethodsAPI.useCreatePaymentMethodsMutation();
  const stripe = useStripe();
  const elements = useElements();
  // const options = useOptions();
  const [loading, setLoading] = useState(false);

  const options: StripeCardNumberElementOptions = useMemo(
    () => ({
      classes: {
        base: styles.input,
        invalid: classNames(styles.input, styles.invalid),
        focus: classNames(styles.input, styles.focus),
      },
      style: {
        base: {
          backgroundColor: 'inherit',
          fontSize: '13px',
          fontWeight: '400',
          color: '#192a3e',
          fontFamily: 'inherit',
          '::placeholder': {
            color: '#90a0b7',
          },
        },
        invalid: {
          color: '#db5960',
        },
      },
      showIcon: true,
    }),
    []
  );

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const date = elements.getElement(CardExpiryElement);
      const cv = elements.getElement(CardCvcElement);

      if (cardElement) {
        setLoading(true);
        const { error, setupIntent } = await stripe.confirmCardSetup(
          String(intentData?.client_secret),
          {
            payment_method: {
              card: cardElement,
            },
          }
        );
        if (error) {
          Notification({ type: 'cross', title: 'Stripe error', message: error?.message });
          setLoading(false);
        }
        if (setupIntent) {
          createPaymentMethod(setupIntent.payment_method)
            .unwrap()
            .then((v) => {
              Notification({ type: 'check', title: 'Success!', message: v.message });
              setLoading(false);
              cardElement.clear();
              date?.clear();
              cv?.clear();
              onCancel();
            })
            .finally(() => setLoading(false));
        }
      }
    },
    [createPaymentMethod, elements, intentData?.client_secret, onCancel, stripe]
  );

  useEffect(() => {
    if (isError) {
      Notification({ type: 'check', message: 'Something wrong with intent get' });
    }
  }, [isError]);
  return (
    <>
      {isLoading ? (
        <Row justify="center" style={{ height: 100 }} align="middle">
          <Loader />
        </Row>
      ) : (
        <form onSubmit={handleSubmit} className={styles.modal}>
          <Row>
            <Col span={12} className={styles.content}>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <h2 className={styles.title}>Add New Card</h2>
                  <label className={styles.label}>
                    <span className={styles.inputName}>Card Number</span>
                    <CardNumberElement
                      options={{ ...options, placeholder: 'XXXX XXXX XXXX XXXX' }}
                    />
                  </label>
                </Col>
                <Col span={24}>
                  <Row gutter={20}>
                    <Col span={12}>
                      <label className={styles.label}>
                        <span className={styles.inputName}>Expiration Date</span>
                        <CardExpiryElement options={options} />
                      </label>
                    </Col>
                    <Col span={12}>
                      <label className={styles.label}>
                        <span className={styles.inputName}>CVV</span>
                        <CardCvcElement options={{ ...options, placeholder: '***' }} />
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className={styles.footer}>
                <Row justify="space-between">
                  <Col>
                    <Stripe />
                  </Col>
                  <Col>
                    <Button loading={loading} submit disabled={!stripe && isError}>
                      Add Card
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12} className={styles.rightBlock}>
              <Image />
            </Col>
          </Row>
        </form>
      )}
    </>
  );
};

export default UserSettingsPaymentModal;
