import React, { CSSProperties, FC } from 'react';
import { Divider as AntdDivider } from 'antd';
import { DividerProps } from 'antd/lib/divider';
import './Divider.scss';
import cn from 'classnames';

interface Props {
  dividerProps?: DividerProps;
  style?: CSSProperties;
  type?: 'horizontal' | 'vertical';
  className?: string;
}

export const Divider: FC<Props> = ({ dividerProps, style, type = 'horizontal', className }) => {
  return (
    <AntdDivider type={type} {...dividerProps} className={cn('Divider', className)} style={style} />
  );
};

export default Divider;
