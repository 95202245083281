import './ProjectEngineHistory.scss';
import { Col, Row } from 'antd';
import { Icon } from 'components/common/Icon';
import React, { FC, useState } from 'react';
import cn from 'classnames';
import Label from 'components/common/Label/Label';
import Dropdown from 'components/common/Dropdown';
// import { rulesAPI } from 'services';
// import { useAppSelector } from 'store';
// import { useParams } from 'react-router-dom';

interface ActiveFilterType {
  name: 'date' | 'version' | 'status' | 'note';
  orderAsc: boolean;
}

export const ProjectEngineHistory: FC = () => {
  const [activeFilter, setActiveFilter] = useState<ActiveFilterType>({
    name: 'date',
    orderAsc: false,
  });

  // const { directoryName } = useParams<{ directoryName?: string }>();
  // const { token } = useAppSelector(({ auth }) => auth);
  // const { selectedCdn } = useAppSelector(({ project }) => project);
  // const { data: rules, isLoading: isRulesLoading } = rulesAPI.useGetRulesQuery(
  //   { cnameId: String(selectedCdn), directoryName: String(directoryName), trashed: true },
  //   { skip: !token || !directoryName || !selectedCdn }
  // );

  const handleSortClick = (name: ActiveFilterType['name']) => {
    if (activeFilter.name !== name) {
      setActiveFilter({
        name,
        orderAsc: true,
      });
    } else {
      setActiveFilter((prevState) => ({ ...prevState, orderAsc: !prevState.orderAsc }));
    }
  };

  return (
    <div className="ProjectEngineHistory">
      <div className="ProjectEngineHistory">
        <Row className="ProjectEngineHistory__table-head">
          <Col
            span={4}
            className="ProjectEngineHistory__head-col"
            onClick={() => handleSortClick('date')}>
            <span className="ProjectEngineHistory__head-label">DATE & TIME</span>
            <Icon
              name="line-arrow-down"
              className={cn('ProjectEngineHistory__head-icon', {
                _active: activeFilter.name === 'date',
                _rotate: activeFilter.name === 'date' && activeFilter.orderAsc,
              })}
            />
          </Col>
          <Col
            style={{ paddingRight: 80 }}
            span={4}
            className="ProjectEngineHistory__head-col _ta-right"
            onClick={() => handleSortClick('version')}>
            <span className="ProjectEngineHistory__head-label">EVENT</span>
            <Icon
              name="line-arrow-down"
              className={cn('ProjectEngineHistory__head-icon', {
                _active: activeFilter.name === 'version',
                _rotate: activeFilter.name === 'version' && activeFilter.orderAsc,
              })}
            />
          </Col>
          <Col
            span={4}
            className="ProjectEngineHistory__head-col"
            onClick={() => handleSortClick('status')}>
            <span className="ProjectEngineHistory__head-label">STATUS</span>
            <Icon
              name="line-arrow-down"
              className={cn('ProjectEngineHistory__head-icon', {
                _active: activeFilter.name === 'status',
                _rotate: activeFilter.name === 'status' && activeFilter.orderAsc,
              })}
            />
          </Col>
          <Col
            span={10}
            className="ProjectEngineHistory__head-col"
            onClick={() => handleSortClick('note')}>
            <span className="ProjectEngineHistory__head-label">NOTE</span>
            <Icon
              name="line-arrow-down"
              className={cn('ProjectEngineHistory__head-icon', {
                _active: activeFilter.name === 'note',
                _rotate: activeFilter.name === 'note' && activeFilter.orderAsc,
              })}
            />
          </Col>
        </Row>

        <Row className="ProjectEngineHistory__table-item">
          <Col span={4} className="ProjectEngineHistory__table-col">
            <span className="ProjectEngineHistory__table-label">1 Jan 2020, 12:05:05</span>
          </Col>
          <Col
            span={4}
            style={{ paddingRight: 80 }}
            className="ProjectEngineHistory__table-col _ta-right">
            <span className="ProjectEngineHistory__table-label">1</span>
          </Col>
          <Col span={4} className="ProjectEngineHistory__table-col">
            <Label type="warning">Type</Label>
          </Col>
          <Col span={10} className="ProjectEngineHistory__table-col">
            <span className="ProjectEngineHistory__table-label">
              Lorem ipsum dolor sit amet, consectetu dolor sit amet....
            </span>
          </Col>
          <Col span={2} className="ProjectEngineHistory__table-col _ta-right">
            <Icon name="lock" className="ProjectEngineHistory__lock" />
            <Dropdown
              triggerElement={
                <Icon name="horizontal" className="ProjectEngineHistory__dropdown-trigger" />
              }>
              <div className="ProjectEngineHistory__dropdown">
                <button type="button" className="ProjectEngineHistory__dropdown-btn">
                  Restore
                </button>
                <button type="button" className="ProjectEngineHistory__dropdown-btn">
                  Deploy
                </button>
                <button type="button" className="ProjectEngineHistory__dropdown-btn">
                  Make a copy
                </button>
              </div>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProjectEngineHistory;
