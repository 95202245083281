import { createApi } from '@reduxjs/toolkit/query/react';
import { IEventLogsResponse } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const eventLogsApi = createApi({
  reducerPath: 'eventLogsApi',
  baseQuery: baseQuery(),
  tagTypes: ['EventLogs'],
  endpoints: (build) => ({
    fetchEventLogs: build.query<IEventLogsResponse, string>({
      query: (directoryName) => ({
        url: endpoints.eventLogs(directoryName),
      }),
      providesTags: (result, error, arg) => [{ type: 'EventLogs', id: arg }],
    }),
  }),
});

export default eventLogsApi;
