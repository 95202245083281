export interface IDataset {
  seriesName?: string;
  legendIconBgColor?: string;
  data: {
    value: string | number;
  }[];
}

export interface IDataType {
  label: string | number;
  value: string | number;
}

export interface ICategory {
  label: string;
}
interface IConfig {
  id: string;
  type?: 'realtimearea' | 'realtimeline';
  isMultiline?: boolean;
  numberSuffix?: string;
  dataset: IDataset[];
  height?: string | number;
  rows?: number;
  cols?: number;
  numdisplaysets?: string;
  palettecolors?: string;
  plotToolText?: string;
}

interface IHistoricalConfig {
  type?: string;
  plottooltext?: string;
  height?: string | number;
  rows?: number;
  cols?: number;
  dataset: IDataset[];
  category: ICategory[];
  xAxisValuesStep?: string;
}

interface IHistoricalConfigBW {
  type?: string;
  plottooltext?: string;
  height?: string | number;
  rows?: number;
  cols?: number;
  data: IDataType[];
  xAxisValuesStep?: string;
}
export const chartConfigs = (config: IConfig) => {
  const {
    dataset,
    id,
    numberSuffix,
    isMultiline,
    palettecolors,
    type,
    height = '240',
    rows = 3,
    cols = 5,
    numdisplaysets = '60',
    plotToolText = '$dataValue',
  } = config;
  return {
    id,
    type,
    width: '100%',
    height,
    dataFormat: 'json',
    dataSource: {
      chart: {
        palettecolors: isMultiline ? palettecolors : '0079D3',
        usePlotGradientColor: '1',
        plotGradientColor: '#ffffff',
        plotFillAngle: '90',
        plotFillAlpha: '10',
        plotFillRatio: '10,0',
        showPlotBorder: '1',
        drawFullAreaBorder: '0',
        plotBorderThickness: '3',
        plotBorderAlpha: '100',
        plotBorderColor: '#0079D3',
        baseFont: 'Assistant',
        baseFontColor: '#90A0B7',
        baseFontSize: '10',
        theme: 'fusion',
        refreshinterval: '5',
        numdisplaysets,
        showRealTimeValue: '0',
        showToolTip: '1',
        plotToolText,
        toolTipBGColor: '#fff',
        toolTipBorderColor: '#fff',
        toolTipBorderRadius: '2',
        toolTipColor: '#445A84',
        showToolTipShadow: '1',
        plotHoverEffect: '1',
        plotBorderHoverColor: '#000',
        drawAnchors: '0',
        numberSuffix,
        legendItemFont: 'Assistant',
        legendItemFontSize: 12,
        legendItemFontColor: '#445A84',
        legendNumRows: rows,
        legendNumColumns: cols,
        legendPosition: 'bottom-left',
        drawCustomLegend: '1',
        legendIconBgAlpha: '100',
        showXAxisValues: 0,
        plotHighlightEffect:
          'fadeout|alpha=60, valueBgColor=#ff0000, valueBorderColor=#3a4660, valueFontColor=#000000',
      },
      categories: [
        {
          category: [
            {
              label: 'start',
            },
          ],
        },
      ],
      dataset,
    },
  };
};

export const historicalChartConfigs = (config: IHistoricalConfig) => {
  const {
    type = 'msline',
    height = '240',
    plottooltext = '<b style="font-size: 14px;">$dataValue</b> <span style="font-size: 14px;">$seriesName</span>',
    rows = 3,
    cols = 5,
    category,
    dataset,
    xAxisValuesStep = '40',
  } = config;
  return {
    type,
    width: '100%',
    height,
    dataFormatL: 'JSON',
    dataSource: {
      chart: {
        palettecolors: '1a59d9, 9ac6a9, ab61c5, fbc447, d8a430, 549568, 97b716',
        baseFont: 'Assistant',
        baseFontColor: '#90A0B7',
        baseFontSize: '10',
        theme: 'fusion',
        showToolTip: '1',
        plottooltext,
        toolTipBGColor: '#fff',
        toolTipBorderColor: '#fff',
        toolTipBorderRadius: '2',
        toolTipColor: '#445A84',
        showToolTipShadow: '1',
        plotHoverEffect: '1',
        plotBorderHoverColor: '#000',
        drawAnchors: '0',
        legendItemFont: 'Assistant',
        legendItemFontSize: 12,
        legendItemFontColor: '#445A84',
        legendNumRows: rows,
        legendNumColumns: cols,
        legendPosition: 'bottom-left',
        drawCustomLegend: '1',
        legendIconBgAlpha: '100',
        xAxisValuesStep,
        plotHighlightEffect:
          'fadeout|alpha=60, valueBgColor=#ff0000, valueBorderColor=#3a4660, valueFontColor=#000000',
      },
      categories: [
        {
          category,
        },
      ],
      dataset,
    },
  };
};

export const historicalChartMapConfigs = () => {
  const createNumberArrayFromRangesWithStep = (...ranges: number[][]): number[] => {
    const result: number[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [start, end, step] of ranges) {
      for (let num = start; num <= end; num += step) {
        result.push(num);
      }
    }
    return result;
  };

  const generateGradient = (startColor: string, endColor: string, steps: number): string[] => {
    const hexToRGB = (hex: string): number[] | null => {
      const matches = hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
        .substring(1)
        .match(/.{2}/g);

      if (matches === null) {
        return null;
      }

      return matches.map((x: string) => parseInt(x, 16));
    };

    const rgbToHex = (r: number, g: number, b: number): string =>
      `#${[r, g, b]
        .map((x) => {
          const hex = x.toString(16);
          return hex.length === 1 ? `0${hex}` : hex;
        })
        .join('')}`;

    const [startR, startG, startB] = hexToRGB(startColor) || [0, 0, 0];
    const [endR, endG, endB] = hexToRGB(endColor) || [0, 0, 0];

    const stepR = (endR - startR) / (steps - 1);
    const stepG = (endG - startG) / (steps - 1);
    const stepB = (endB - startB) / (steps - 1);

    const colors: string[] = [startColor];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < steps - 1; i++) {
      const r = Math.round(startR + i * stepR);
      const g = Math.round(startG + i * stepG);
      const b = Math.round(startB + i * stepB);
      colors.push(rgbToHex(r, g, b));
    }
    colors.push(endColor);

    return colors;
  };

  const numbers: number[] = createNumberArrayFromRangesWithStep(
    [0, 1048576, 524288],
    [3145728, 52428800, 49283072],
    [209715200, 1048576000, 419430400],
    [2147483648, 1072668082176, 133815074816],
    [2199023255552, 10995116277760, 4398046511104],
    [109951162777600, 1125899906842624, 253987186016256],
    [3377699720527872, 11258999068426240, 7881299347898368],
    [1152921504606847000, 115292150460684700000, 114139228956077850000]
  );

  const lightBlue = '#8bd7f7';
  const darkBlue = '#000052';
  const gradientColors: string[] = generateGradient(lightBlue, darkBlue, numbers.length);

  const mergeArraysIntoObjects = (
    arr1: number[],
    arr2: string[]
  ): { maxvalue: number; code: string }[] => {
    if (arr1.length !== arr2.length) {
      throw new Error('Arrays must have the same length.');
    }

    const result: { maxvalue: number; code: string }[] = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr1.length; i++) {
      const obj = { maxvalue: arr1[i], code: arr2[i] };
      result.push(obj);
    }

    return result;
  };

  return {
    chart: {
      theme: 'fusion',
      showLegend: 0,
      nullentityfillcolor: '#c4cfde',
      showHoverEffect: 0,
      entityFillHoverColor: '#b6cfb6',
    },
    colorrange: {
      gradient: '0',
      minvalue: '0',
      code: '#e44a00',
      color: mergeArraysIntoObjects(numbers, gradientColors),
    },
  };
};

export const historicalChartConfigBW = (config: IHistoricalConfigBW) => {
  const { type = 'area', height = '240', data, xAxisValuesStep, plottooltext } = config;
  return {
    type,
    plottooltext:
      '<b style="font-size: 14px;">$dataValue</b> <span style="font-size: 14px;">$seriesName</span>',
    width: '100%',
    height,
    dataFormatL: 'JSON',
    dataSource: {
      chart: {
        palettecolors: '1a59d9, 9ac6a9, ab61c5, fbc447, d8a430, 549568, 97b716',
        usePlotGradientColor: '1',
        plotGradientColor: '#ffffff',
        plotFillAngle: '90',
        plotFillAlpha: '10',
        plotFillRatio: '10,0',
        showPlotBorder: '1',
        drawFullAreaBorder: '0',
        plotBorderThickness: '2',
        plotBorderAlpha: '100',
        plotBorderColor: '#0079D3',
        baseFont: 'Assistant',
        baseFontColor: '#90A0B7',
        baseFontSize: '10',
        theme: 'fusion',
        refreshinterval: '5',
        numdisplaysets: '20',
        showRealTimeValue: '0',
        showToolTip: '1',
        plottooltext,
        toolTipBGColor: '#fff',
        toolTipBorderColor: '#fff',
        toolTipBorderRadius: '2',
        toolTipColor: '#445A84',
        showToolTipShadow: '1',
        plotHoverEffect: '1',
        plotBorderHoverColor: '#000',
        drawAnchors: '0',
        xAxisValuesStep,
        // numberSuffix,
        plotHighlightEffect:
          'fadeout|alpha=60, valueBgColor=#ff0000, valueBorderColor=#3a4660, valueFontColor=#000000',
      },
      data,
    },
  };
};

export const historicalChartConfigCard = (config: IHistoricalConfigBW) => {
  const { type = 'area', height = '80', data, xAxisValuesStep } = config;
  return {
    type,
    width: '100%',
    height,
    dataFormatL: 'JSON',
    dataSource: {
      chart: {
        bgColor: 'ffffff',
        showXAxisLine: false,
        showXAxisValues: false,
        showYAxisValues: false,
        palettecolors: '1a59d9, 9ac6a9, ab61c5, fbc447, d8a430, 549568, 97b716',
        usePlotGradientColor: '1',
        plotGradientColor: '#ffffff',
        plotFillAngle: '90',
        plotFillAlpha: '10',
        plotFillRatio: '10,0',
        showPlotBorder: '1',
        drawFullAreaBorder: '0',
        plotBorderThickness: '2',
        plotBorderAlpha: '100',
        plotBorderColor: '#0079D3',
        baseFont: 'Assistant',
        baseFontColor: '#90A0B7',
        baseFontSize: '10',
        theme: 'fusion',
        refreshinterval: '5',
        numdisplaysets: '20',
        showRealTimeValue: '0',
        showToolTip: '1',
        plotToolText: '$dataValue',
        toolTipBGColor: '#fff',
        toolTipBorderColor: '#fff',
        toolTipBorderRadius: '2',
        toolTipColor: '#445A84',
        showToolTipShadow: '1',
        plotHoverEffect: '1',
        plotBorderHoverColor: '#000',
        drawAnchors: '0',
        xAxisValuesStep,
        divLineColor: '#fff',
        // numberSuffix,
        plotHighlightEffect:
          'fadeout|alpha=60, valueBgColor=#ff0000, valueBorderColor=#3a4660, valueFontColor=#000000',
      },
      data,
    },
  };
};

export const historicalChartConfigCardEmpty = (config: IHistoricalConfigBW) => {
  const { type = 'area', height = '80', data, xAxisValuesStep } = config;
  return {
    type,
    width: '100%',
    height,
    dataFormatL: 'JSON',
    dataSource: {
      chart: {
        showXAxisLine: false,
        showXAxisValues: false,
        showYAxisValues: false,
        palettecolors: '90a0b7',
        usePlotGradientColor: '1',
        plotGradientColor: '#ffffff',
        plotFillAngle: '90',
        plotFillAlpha: '10',
        plotFillRatio: '10,0',
        showPlotBorder: '1',
        drawFullAreaBorder: '0',
        plotBorderThickness: '2',
        plotBorderAlpha: '100',
        plotBorderColor: '#90a0b7',
        baseFont: 'Assistant',
        baseFontColor: '#90A0B7',
        baseFontSize: '10',
        theme: 'fusion',
        refreshinterval: '5',
        numdisplaysets: '20',
        showRealTimeValue: '0',
        showToolTip: '0',
        plotHoverEffect: '1',
        plotBorderHoverColor: '#000',
        drawAnchors: '0',
        xAxisValuesStep,
        divLineColor: '#fff',
        // numberSuffix,
        plotHighlightEffect:
          'fadeout|alpha=60, valueBgColor=#ff0000, valueBorderColor=#3a4660, valueFontColor=#000000',
      },
      data,
    },
  };
};

export const historicalChartConfigSecurity = (config: IHistoricalConfigBW) => {
  const { type = 'area', height = '240', data, xAxisValuesStep } = config;
  return {
    type,
    width: '100%',
    height,
    dataFormatL: 'JSON',
    dataSource: {
      chart: {
        palettecolors: '1a59d9, 9ac6a9, ab61c5, fbc447, d8a430, 549568, 97b716',
        usePlotGradientColor: '1',
        plotGradientColor: '#ffffff',
        plotFillAngle: '90',
        plotFillAlpha: '10',
        plotFillRatio: '10,0',
        showPlotBorder: '1',
        drawFullAreaBorder: '0',
        plotBorderThickness: '2',
        plotBorderAlpha: '100',
        plotBorderColor: '#0079D3',
        baseFont: 'Assistant',
        baseFontColor: '#90A0B7',
        baseFontSize: '10',
        theme: 'fusion',
        refreshinterval: '5',
        numdisplaysets: '20',
        showRealTimeValue: '0',
        showToolTip: '1',
        plotToolText: `<div class="TooltipCustom">
        <span class="TooltipCustom__name">$label</span>
        <div class="TooltipCustom__row">
          <div class="TooltipCustom__col">
            <span class="TooltipCustom__dot"></span>
            <span>Events</span>
          </div>
          <span class="TooltipCustom__value">$dataValue</span>
        </div>
      </div>`,
        toolTipBGColor: '#fff',
        toolTipBorderColor: '#fff',
        toolTipBorderRadius: '2',
        toolTipColor: '#445A84',
        showToolTipShadow: '1',
        plotHoverEffect: '1',
        plotBorderHoverColor: '#000',
        drawAnchors: '0',
        xAxisValuesStep,
        // numberSuffix,
        plotHighlightEffect:
          'fadeout|alpha=60, valueBgColor=#ff0000, valueBorderColor=#3a4660, valueFontColor=#000000',
      },
      data,
    },
  };
};

export const historicalTimeseriesChartConfigSecurity = () => {
  return {
    xAxis: {
      binning: {},
    },
    yAxis: {
      title: '',
      plot: {
        value: 'Events',
        type: 'line',
        aggregation: 'Last',
      },
    },
    navigator: {
      enabled: 0,
      scrollbar: {},
      window: {},
      label: {},
      'label-background': {},
      height: Number,
    },
    extensions: {
      standardRangeSelector: {
        enabled: '0',
      },
      customRangeSelector: {
        enabled: '0',
      },
    },
    palettecolors: '1a59d9, 9ac6a9, ab61c5, fbc447, d8a430, 549568, 97b716',
    showPrintMenuItem: '1',
    legend: {
      enabled: '0',
    },
    chart: {
      palettecolors: '1a59d9, 9ac6a9, ab61c5, fbc447, d8a430, 549568, 97b716',
      usePlotGradientColor: '1',
      plotGradientColor: '#ffffff',
      plotFillAngle: '90',
      plotFillAlpha: '10',
      plotFillRatio: '10,0',
      showPlotBorder: '1',
      drawFullAreaBorder: '0',
      plotBorderThickness: '2',
      plotBorderAlpha: '100',
      plotBorderColor: '#0079D3',
      baseFont: 'Assistant',
      baseFontColor: '#90A0B7',
      baseFontSize: '10',
      theme: 'fusion',
      refreshinterval: '5',
      numdisplaysets: '20',
      showRealTimeValue: '0',
      showToolTip: '1',
      xaxisname: ' ',
      yaxisname: ' ',
      toolTipBGColor: '#fff',
      toolTipBorderColor: '#fff',
      toolTipBorderRadius: '2',
      toolTipColor: '#445A84',
      showToolTipShadow: '1',
      plotHoverEffect: '1',
      plotBorderHoverColor: '#000',
      drawAnchors: '0',
      plotHighlightEffect:
        'fadeout|alpha=60, valueBgColor=#ff0000, valueBorderColor=#3a4660, valueFontColor=#000000',
    },
  };
};

export const binningConfig = (type: string) => {
  if (type === 'sec')
    return {
      year: [],
      month: [],
      day: [],
      hour: [],
      minute: [],
      second: [1],
      millisecond: [],
    };
  if (type === 'hour')
    return {
      year: [],
      month: [],
      day: [],
      hour: [1],
      minute: [],
      second: [],
      millisecond: [],
    };
  if (type === 'min')
    return {
      year: [],
      month: [],
      day: [],
      hour: [],
      minute: [1],
      second: [],
      millisecond: [],
    };
  return {
    year: [],
    month: [],
    day: [1],
    hour: [],
    minute: [],
    second: [],
    millisecond: [],
  };
};

export default chartConfigs;
