import './SectorButton.scss';
import React, { FC, ReactNode } from 'react';
import { indexOf, map } from 'lodash';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { Icon, IconType } from '../Icon';
import SectorButtonDropdown from './SectorButtonDropdown';

export interface ButtonType {
  label?: string;
  toolTipTitle?: string;
  icon?: IconType;
  isDropdown?: boolean;
  dropdownContent?: ReactNode;
}

interface Props {
  title?: string;
  data: ButtonType[];
  onClick?: (idx: number) => void;
  disabled?: number[];
  size?: 'small' | 'normal';
  checked?: number;
  isPill?: boolean;
  whiteSpace?: boolean;
  withConnector?: boolean;
}

export const SectorButton: FC<Props> = ({
  withConnector,
  title,
  data,
  onClick,
  disabled,
  size,
  checked,
  isPill,
  whiteSpace,
}) => {
  return (
    <div className="SectorButton">
      {withConnector && <span className="SectorButton__divider" />}

      {title && (
        <div
          className={cn('SectorButton__sector-btn', 'SectorButton__title', {
            _disabled: true,
            _title: true,
          })}>
          {title}
        </div>
      )}
      {map(data, ({ label, icon, isDropdown, dropdownContent, toolTipTitle }, idx) => {
        return isDropdown ? (
          <SectorButtonDropdown key={`${label}${idx}`} name={label}>
            {dropdownContent}
          </SectorButtonDropdown>
        ) : (
          <Tooltip title={toolTipTitle} key={`${label}${idx}`}>
            <button
              key={`${label}${idx}`}
              onClick={() => {
                if (onClick && indexOf(disabled, idx) === -1) onClick(idx);
              }}
              type="button"
              className={cn('SectorButton__sector-btn', {
                _disabled: indexOf(disabled, idx) > -1,
                _active: idx === checked,
                _size: size,
                _isPill: isPill,
                _whiteSpace: whiteSpace,
              })}>
              {icon && (
                <Icon
                  name={icon}
                  className={cn('SectorButton__sector-icon', {
                    '_with-text': icon,
                  })}
                />
              )}
              {label}
            </button>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default SectorButton;
