import './UserSettingsInvoices.scss';
import React, { FC, useMemo } from 'react';
import { Col, Empty, Row } from 'antd';
import { Icon } from 'components/common/Icon';
import { groupBy, isEmpty, map } from 'lodash';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { invoicesAPIv2 } from 'services';
import { useAppSelector } from 'store';
import { UserSettingsInvoicesItem } from 'components';
import { Loader } from 'components/common/Loader';
import moment from 'moment';
import EmptyState from '../../../assets/svg/Empty.svg';

// import invoicesAPIv2 from 'services/InvoicesServicev2';

export const UserSettingsInvoices: FC = withAuthenticationRequired(() => {
  const { token } = useAppSelector(({ auth }) => auth);
  const { data, isFetching } = invoicesAPIv2.useGetInvoicesQuery(
    {
      end_date: moment().format('DD/MM/YYYY'),
      start_date: moment().startOf('y').format('DD/MM/YYYY'),
    },
    { skip: !token }
  );

  const groupedData = useMemo(() => {
    return groupBy(data?.data, 'created');
  }, [data?.data]);

  const renderContent = useMemo(() => {
    if (isFetching) {
      return (
        <Row justify="center" align="bottom" style={{ minHeight: 136 }}>
          <Col>
            <Loader />
          </Col>
        </Row>
      );
    }

    if (!isFetching && isEmpty(data)) {
      return (
        <div className="UserSettingsInvoices__empty-state">
          <Row justify="center" align="bottom" style={{ minHeight: 166 }}>
            <Col span={24} className="HomeTable__empty-state">
              <Empty
                image={EmptyState}
                imageStyle={{ height: 90 }}
                description="You don't have any invoices yet."
              />
            </Col>
          </Row>
        </div>
      );
    }
    return (
      <div className="UserSettingsInvoices__wrapper">
        <Row style={{ overflow: 'scroll', maxHeight: '87vh' }}>
          <Col span={24}>
            <Row className="UserSettingsInvoices__table-head">
              <Col span={7} className="UserSettingsInvoices__table-head-item _start">
                <button type="button" className="UserSettingsInvoices__filter">
                  INVOICE
                </button>
              </Col>
              <Col span={2} className="UserSettingsInvoices__table-head-item">
                <button type="button" className="UserSettingsInvoices__filter">
                  UNITS
                  <Icon name="line-arrow-down" className="UserSettingsInvoices__arrow" />
                </button>
              </Col>
              <Col span={6} className="UserSettingsInvoices__table-head-item">
                <button type="button" className="UserSettingsInvoices__filter">
                  SERVICE COST
                  <Icon name="line-arrow-down" className="UserSettingsInvoices__arrow" />
                </button>
              </Col>
              <Col span={4} className="UserSettingsInvoices__table-head-item">
                <button type="button" className="UserSettingsInvoices__filter">
                  TOTAL COST
                  <Icon name="line-arrow-down" className="UserSettingsInvoices__arrow" />
                </button>
              </Col>
            </Row>
          </Col>
          {data &&
            map(groupedData, (items, k) => (
              <UserSettingsInvoicesItem key={k} items={items} offset={0} name={k} />
            ))}
        </Row>
      </div>
    );
  }, [data, groupedData, isFetching]);

  return <div className="UserSettingsInvoices">{renderContent}</div>;
});

export default UserSettingsInvoices;
