import { Col, Row } from 'antd';
import Button from 'components/common/Button';
import { Input } from 'components/common/Input';
import { isEqual } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import styles from './ProjectOriginCollaborationDeleteModal.module.scss';

interface IProjectOriginCollaborationDeleteModalProps {
  onFinish: () => void;
  onCancel: () => void;
  loading?: boolean;
  isSuccess?: boolean;
  projectName?: string;
  type?: 'changeOwner' | 'deleteCollaborator';
  email?: string;
}

export const ProjectOriginCollaborationDeleteModal: FC<
  IProjectOriginCollaborationDeleteModalProps
> = ({ onFinish, type, onCancel, loading, isSuccess, projectName, email }) => {
  const [value, setValue] = useState('');
  const isChangeModal = type === 'changeOwner';
  useEffect(() => {
    if (isSuccess) setValue('');
  }, [isSuccess]);
  return (
    <div>
      <Row gutter={[20, 20]} style={{ paddingBottom: 24 }}>
        <Col span={24} style={{ textAlign: 'left' }}>
          <span className={styles.title}>
            Are you sure you want to {isChangeModal ? 'change ownership' : 'delete collaborator'} of
            the current property?
            <span>
              Please be aware that once the {isChangeModal ? 'transfer' : 'delete'} is complete, it
              cannot be undone
            </span>
          </span>
          <span className={styles.text}>
            To confirm the property&apos;s{' '}
            {isChangeModal ? 'transfer to a new owner' : 'delete collaborator'}, please enter{' '}
            <strong>{`${isChangeModal ? projectName : email} `}</strong>
            in the input field and click the {isChangeModal ? 'save' : 'delete'} button.
          </span>
        </Col>
        <Col span={24}>
          <Input
            label={isChangeModal ? 'Project Name' : 'Email'}
            value={value}
            onChange={(v: React.FormEvent<HTMLInputElement>) => setValue(v.currentTarget.value)}
          />
        </Col>
      </Row>
      <div className={styles.footer}>
        <Button className={styles.footerBtn} type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!isEqual(isChangeModal ? projectName : email, value)}
          submit
          // type=''
          type={isChangeModal ? 'primary' : 'danger'}
          loading={loading}
          onClick={onFinish}>
          {isChangeModal ? 'Save' : 'Delete'}
        </Button>
      </div>
    </div>
  );
};

export default ProjectOriginCollaborationDeleteModal;
