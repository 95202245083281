import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
import { Moment } from 'moment';
import { IChartDataFields } from '../../models/service';

export const dateFormat = 'YYYY/MM/DD HH:mm:ss';

export type ActivePeriodType = 'live' | [Moment, Moment];

export type HistoricalFilterType = 0 | 1 | 2 | 3;

export interface IChartData {
  date: string | number | Date;
  data: IChartDataFields;
}

export type TTimeDuration = 5 | 10 | 15 | 30;

export enum CustomPeriodInLogsPage {
  // used in logs section
  M15 = '15m',
  M30 = '30m',
  M60 = '60m',
  H3 = '3h',
  H6 = '6h',
  H24 = '24h',
  D7 = '7d',
  D30 = '30d',
  D90 = '90d',
  NULL = 'null',
}

export enum CustomPeriodInSecurityPage {
  // used in security section
  MIN5 = '5 minutes',
  MIN15 = '15 minutes',
  HOU1 = '1 hour',
  HOU6 = '6 hours',
  HOU12 = '12 hours',
  DAY1 = '1 day',
  DAY2 = '2 days',
  DAY7 = '7 days',
  DAY30 = '30 days',
}

export type CustomPeriod = CustomPeriodInLogsPage | CustomPeriodInSecurityPage;

export interface IProjectState {
  bw: IChartData[];
  http: IChartData[];
  cache: IChartData[];
  conn: IChartData[];
  timeDuration: TTimeDuration;
  selectedPeriod: ActivePeriodType;
  historicalTimeSelected: HistoricalFilterType;
  selectedCustomPeriodInLogsPage: CustomPeriodInLogsPage;
  selectedCustomPeriod: CustomPeriod;
  isUsingCustomRange: boolean;
  selectedCdn: string | number;
  disabledTimePeriods: number[];
}
const initialState: IProjectState = {
  bw: [],
  http: [],
  cache: [],
  conn: [],
  timeDuration: 5,
  selectedPeriod: 'live',
  historicalTimeSelected: 0,
  selectedCustomPeriodInLogsPage: CustomPeriodInLogsPage.M15,
  selectedCustomPeriod: CustomPeriodInSecurityPage.DAY1,
  isUsingCustomRange: false,
  selectedCdn: '',
  disabledTimePeriods: [],
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    clearRealtimeData: (state) => {
      state.bw = [];
      state.http = [];
      state.cache = [];
      state.conn = [];
    },
    updateTimeDuration: (state, action: PayloadAction<TTimeDuration>) => {
      state.timeDuration = action.payload;
    },
    changeSelectedPeriod: (state, action: PayloadAction<ActivePeriodType>) => {
      if (action.payload !== 'live' && isArray(action.payload)) {
        const diff = action.payload[1].diff(action.payload[0], 'd');

        switch (true) {
          case diff < 14 && diff > 1:
            state.historicalTimeSelected = 1;
            break;
          case diff >= 14:
            state.historicalTimeSelected = 2;
            break;
          case diff <= 1:
            state.historicalTimeSelected = 0;
            break;
          default:
            state.historicalTimeSelected = 0;
            break;
        }
      }
      state.selectedPeriod = action.payload;
    },
    changeHistoricalTimeSelected: (state, action: PayloadAction<HistoricalFilterType>) => {
      state.historicalTimeSelected = action.payload;
    },
    changeSelectedCustomPeriod: (state, action: PayloadAction<CustomPeriod>) => {
      state.selectedCustomPeriod = action.payload;
    },
    changeSelectedCustomPeriodInLogsPage: (
      state,
      action: PayloadAction<CustomPeriodInLogsPage>
    ) => {
      state.selectedCustomPeriodInLogsPage = action.payload;
    },
    setIsUsingCustomRange: (state, action) => {
      state.isUsingCustomRange = action.payload;
    },
    updateSelectedCdn: (state, action: PayloadAction<string | number>) => {
      state.selectedCdn = action.payload;
    },
    updateDisabledTimePeriods: (state, action: PayloadAction<number[]>) => {
      state.disabledTimePeriods = action.payload;
    },
  },
});

export const {
  // updateBw,
  // updateCache,
  // updateConn,
  // updateHttp,
  updateTimeDuration,
  clearRealtimeData,
  changeSelectedPeriod,
  changeHistoricalTimeSelected,
  changeSelectedCustomPeriod,
  changeSelectedCustomPeriodInLogsPage,
  updateSelectedCdn,
  updateDisabledTimePeriods,
  setIsUsingCustomRange,
} = projectSlice.actions;

export default projectSlice.reducer;
