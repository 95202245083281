/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import {
  IField,
  selectModeObject,
  selectTreatmentObject,
  selectUnitsObject,
  switchEnabledObject,
} from './fieldsObjects';

export const bandwidthParameters: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const bandwidthThrottling: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'prebuf_seconds',
    label: 'Prebuf Seconds',
  },
  {
    id: 1,
    type: 'input',
    fieldName: 'kbytes_per_second',
    label: 'Kbytes Per Seconds',
  },
];

export const bypassCache: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const cacheControlHeaderTreatment: IField[] = [
  {
    id: 0,
    ...selectTreatmentObject,
  },
];

export const cacheKeyQueryString: IField[] = [
  {
    id: 0,
    ...selectModeObject,
  },
  {
    id: 1,
    type: 'input',
    fieldName: 'parameters',
    label: 'Parameters',
    regexp: /[-_a-zA-Z0-9\~%:;=!,\.\+\*'\\\s]*/,
    tooltip: 'Regex Pattern: ',
  },
];

export const cacheKeyRewrite: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'source',
    label: 'Source',
    regexp: /.+/,
    tooltip: 'Regex Pattern: ',
  },
  {
    id: 1,
    type: 'input',
    fieldName: 'destination',
    label: 'Destination',
  },
];

export const compressFileTypes: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'media_types',
    label: 'Media Types',
    regexp: /[-\+a-zA-Z0-9]+\[-\+\.a-zA-Z0-9]+/,
    tooltip: 'Regex Pattern: ',
  },
];

export const defaultInternalMaxAge: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'status',
    label: 'Status',
  },
];

export const expiresHeaderTreatment: IField[] = [
  {
    id: 0,
    ...selectTreatmentObject,
  },
];

export const externalMaxAge: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'value',
    label: 'Value',
  },
  {
    id: 1,
    ...selectUnitsObject,
  },
];

export const forceInternalMaxAge: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'status',
    label: 'Status',
  },
];

export const hSupport: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'extensions',
    label: 'Extensions',
    regexp: /(\.[^. ]+\s*)+/,
    tooltip: 'Regex Pattern: ',
  },
];

export const hSupportVideo: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'seek_start',
    label: 'Seek Start',
    regexp: /[-_\.a-zA-Z0-9]+/,
    tooltip: 'Regex Pattern: ',
  },
  {
    id: 1,
    type: 'input',
    fieldName: 'seek_end',
    label: 'Seek End',
    regexp: /[-_\.a-zA-Z0-9]+/,
    tooltip: 'Regex Pattern: ',
  },
];

export const honorNoCacheRequest: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const ignoreOriginNoCache: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'codes',
    label: 'Codes',
    regexp:
      /200|203|300|301|302|305|307|400|401|402|403|404|405|406|407|408|409|410|411|412|413|414|415|416|417|429|451|500|501|502|503|504|505/,
    tooltip: 'Regex Pattern: ',
  },
];

export const ignoreUnsatisfiedRanges: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const internalMaxStale: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'status',
    label: 'Status',
  },
];

export const partialCacheSharing: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const prevalidateCachedContent: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'value',
    label: 'Value',
  },
  {
    id: 1,
    ...selectUnitsObject,
  },
];

export const refreshZeroBytesCacheFiles: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const setCachebleStatusCodes: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'codes',
    label: 'Codes',
    regexp:
      /200|203|300|301|302|305|307|400|401|402|403|404|405|406|407|408|409|410|411|412|413|414|415|416|417|429|451|500|501|502|503|504|505/,
    tooltip: 'Regex Pattern: ',
  },
];

export const staleContentDeliveryOnError: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const staleWhileRevalidate: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'value',
    label: 'Value',
  },
  {
    id: 1,
    ...selectUnitsObject,
  },
];
