/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import './UserSettingsBilling.scss';
import { Col, Form, Row } from 'antd';
import Button from 'components/common/Button';
import { Input } from 'components/common/Input';
import { Icon } from 'components/common/Icon';
import Radio from 'components/common/Radio';
import cn from 'classnames';
import { CheckCircleFilled } from '@ant-design/icons';
import { Notification } from 'components/common/Notification';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { paymentMethodsAPI, userDetailsApi } from 'services';
import { useAppSelector } from 'store';
import Modal from 'components/common/Modal';
import { UserSettingsBillingEmptyState, UserSettingsPaymentModal } from 'components';
import { map, isNil, isEqual, isEmpty } from 'lodash';
import { Loader } from 'components/common/Loader';
import { PaymentMethodsResponse, UserPaymentsDetailsPayload } from 'models';
import countriesBilling from 'libs/countriesWithFlags.json';
import Select from 'components/common/Select';
import { ReactComponent as Stripe } from 'assets/svg/Stripe.svg';

export const UserSettingsBilling: FC = withAuthenticationRequired(() => {
  const [visible, setVisible] = useState(false);
  const [cards, setCards] = useState([] as PaymentMethodsResponse[]);
  const [isLoadingCards, setIsLoadingCards] = useState(false);
  const [settingsForm] = Form.useForm<UserPaymentsDetailsPayload>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { data, isLoading } = paymentMethodsAPI.useFetchPaymentMethodsQuery('', { skip: !token });
  const [updateDefault, { isLoading: isUpdateLoading }] =
    paymentMethodsAPI.useUpdateDefaultPaymentMethodsMutation();
  const [deletePaymentMethod, { isLoading: isDeleteLoading }] =
    paymentMethodsAPI.useDeletePaymentMethodMutation();
  const { data: userPaymentDetails, isLoading: isUserPaymentsDetailsLoading } =
    userDetailsApi.useFetchUserBillingDetailsQuery('', {
      skip: !token,
    });
  const [updateUserBillingDetails, { isLoading: isUpdateUserBillingDetailsLoading }] =
    userDetailsApi.useUpdateUserBillingDetailsMutation();

  useEffect(() => {
    setCards([...(data || [])]);
    setIsLoadingCards(false);
  }, [data]);

  const onFinish = useCallback(
    (values: UserPaymentsDetailsPayload) => {
      updateUserBillingDetails(values)
        .unwrap()
        .then(() => Notification({ type: 'check', title: 'Success' }))
        .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
    },
    [updateUserBillingDetails]
  );

  const updateDefaultMethod = useCallback(
    (id: string) => {
      setIsLoadingCards(true);
      updateDefault(id)
        .unwrap()
        .then(() =>
          Notification({ title: 'Success', message: 'Default method changed sucessfuly' })
        )
        .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
    },
    [updateDefault]
  );

  const deletePayment = useCallback(
    (id: string) => {
      setIsLoadingCards(true);
      deletePaymentMethod(id)
        .unwrap()
        .then(() => Notification({ title: 'Success', message: 'Deleted successfully' }))
        .catch((err: { data: { message: string } }) =>
          Notification({ type: 'cross', title: 'Error', message: err.data.message })
        );
    },
    [deletePaymentMethod]
  );

  const renderModal = useMemo(() => {
    if (visible) {
      return <UserSettingsPaymentModal onCancel={() => setVisible(false)} />;
    }
    return null;
  }, [visible]);

  useEffect(() => {
    if (userPaymentDetails && !isNil(userPaymentDetails.data)) {
      const { address, country, payer, zip_code, address_2, city, phone, tax } =
        userPaymentDetails.data;
      settingsForm.setFieldsValue({
        payer,
        country,
        address,
        zip_code,
        address_2,
        city,
        phone,
        tax,
      });
    }
  }, [settingsForm, userPaymentDetails]);

  const renderPageContent = useMemo(() => {
    const extendedData = [...cards].map((item) => ({
      ...item,
      defaultPaymentMethod: false,
    }));

    const currentExtendedData = [
      ...extendedData.map((item, idx) => {
        if (idx === 0) item.defaultPaymentMethod = true;
        return item;
      }),
    ].sort((a: PaymentMethodsResponse, b: PaymentMethodsResponse) => a.created - b.created);

    if (!data) {
      return (
        <Row justify="center" align="middle">
          <Loader />
        </Row>
      );
    }
    if (data && isEmpty(data)) {
      return <UserSettingsBillingEmptyState />;
    }
    return (
      <Row gutter={[20, 20]} className="UserSettingsBilling__wrapper">
        <Modal
          destroyOnClose
          visible={visible}
          // title="Add New Card"

          onCancel={() => {
            setVisible(false);
          }}
          width="630px">
          {renderModal}
        </Modal>
        <Col span={24}>
          <div>
            <Row gutter={16} align="middle">
              <Col>
                <h2 className="UserSettingsBilling__title">Payment Details</h2>
              </Col>
              <Col style={{ position: 'relative' }}>
                {(isUpdateLoading || isDeleteLoading || isLoading) && (
                  <div className="UserSettingsBilling__loader">
                    <Loader />
                  </div>
                )}
              </Col>
            </Row>
            <Row
              gutter={[16, 16]}
              className={cn('UserSettingsBilling__paymentDetails', {
                _loading: isLoadingCards,
              })}>
              {map(
                currentExtendedData,
                ({ card: { exp_month, exp_year, last4, brand }, id, defaultPaymentMethod }) => (
                  <Col key={id} span={6}>
                    <div
                      className={cn('UserSettingsBilling__card', {
                        _selected: defaultPaymentMethod,
                      })}>
                      <Row justify="space-between" className="UserSettingsBilling__card-head">
                        <Col>
                          <Icon
                            name={brand === 'visa' ? 'visa' : 'master-card'}
                            className="UserSettingsBilling__card-icon"
                          />
                        </Col>
                        <Col>
                          {defaultPaymentMethod ? (
                            <CheckCircleFilled style={{ color: '#9ac6a9', fontSize: '18px' }} />
                          ) : (
                            <Radio
                              value={id}
                              onChange={() => updateDefaultMethod(id)}
                              checked={defaultPaymentMethod}
                            />
                          )}
                        </Col>
                      </Row>
                      <p className="UserSettingsBilling__card-number">**** **** **** {last4}</p>
                      <Row justify="space-between" align="middle">
                        <span className="UserSettingsBilling__card-date">
                          {exp_month}/{exp_year}
                        </span>
                        {!defaultPaymentMethod && (
                          <Icon
                            name="remove"
                            className="UserSettingsBilling__remove-icon"
                            onClick={() => deletePayment(id)}
                          />
                        )}
                      </Row>
                    </div>
                  </Col>
                )
              )}
              <Col span={6}>
                <button
                  className="UserSettingsBilling__add-btn"
                  type="button"
                  onClick={() => setVisible(true)}>
                  <Icon name="plus" className="UserSettingsBilling__add-icon" />
                </button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={24}>
          <div>
            <h2 className="UserSettingsBilling__title">Invoice Details</h2>
            <Form
              form={settingsForm}
              name="signup-form"
              onFinish={onFinish}
              className="UserSettingsBilling__form">
              <div className="UserSettingsBilling__form-content">
                <Row gutter={[24, 16]} align="bottom" style={{ marginBottom: 22 }}>
                  <Col span={24}>
                    <Form.Item name="payer" noStyle>
                      <Input label="Card Owner" />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item name="country" noStyle>
                      <Select
                        showSearch
                        label="Country"
                        data={map(countriesBilling, ({ code, name, image }) => ({
                          name,
                          value: code,
                          image,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item name="city" noStyle>
                      <Input label="City" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="tax" noStyle>
                      <Input label="TAX / VAT" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} align="bottom" style={{ marginBottom: 22 }}>
                  <Col span={10}>
                    <Form.Item name="address" noStyle>
                      <Input label="Address 1" />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item name="address_2" noStyle>
                      <Input label="Address 2" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="zip_code" noStyle>
                      <Input label="Zip Code" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Row style={{ width: '600px' }} justify="end">
                <Col>
                  <Form.Item shouldUpdate noStyle>
                    {() => (
                      <Button
                        submit
                        fluid
                        loading={isUpdateUserBillingDetailsLoading || isUserPaymentsDetailsLoading}
                        disabled={
                          !!settingsForm.getFieldsError().filter(({ errors }) => errors.length)
                            .length ||
                          isEqual(userPaymentDetails?.data, settingsForm.getFieldsValue())
                        }>
                        Save Updates
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col>
          <div className="UserSettingsBilling__stripe">
            <Stripe />
          </div>
        </Col>
      </Row>
    );
  }, [
    cards,
    data,
    deletePayment,
    isDeleteLoading,
    isLoading,
    isLoadingCards,
    isUpdateLoading,
    isUpdateUserBillingDetailsLoading,
    isUserPaymentsDetailsLoading,
    onFinish,
    renderModal,
    settingsForm,
    updateDefaultMethod,
    userPaymentDetails?.data,
    visible,
  ]);

  return <div className="UserSettingsBilling">{renderPageContent}</div>;
});

export default UserSettingsBilling;
