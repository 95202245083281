import React, { FC, useCallback, useState } from 'react';
import { Col, Row } from 'antd';
import { BlockWrapper } from 'components/common/BlockWrapper';
import Button from 'components/common/Button';
import { Notification } from 'components/common/Notification';
import { Typography } from 'components/common/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { propertyAPI } from 'services';
import { useAppSelector } from 'store';
import { ProjectOriginKey } from 'components/views/Project/ProjectOrigin';
import ProjectOriginPciDetails from 'components/views/Project/ProjectOrigin/ProjectOriginPciDetails/ProjectOriginPciDetails';
import { ProjectOriginEnvIdDetails } from 'components/views/Project/ProjectOrigin/ProjectOriginEnvIdDetails';
import { ProjectOriginDeleteModal } from 'components/views/Project/ProjectOrigin/ProjectOriginDeleteModal';
import Modal from 'components/common/Modal';
// import { ProjectOriginRenameDetails } from 'components/views/Project/ProjectOrigin/ProjectOriginRenameDetails';

export const ProjectOriginServiceView: FC = () => {
  const [deleteModalVisibleProperty, setDeleteModalVisibleProperty] = useState(false);

  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);

  const { data: propertyDetails, isLoading: isPropertyLoading } = propertyAPI.useFetchPropertyQuery(
    String(directoryName),
    {
      skip: !token,
    }
  );

  const [deleteProperty, { isLoading: isLoadingProperty, isSuccess: isSuccessProperty }] =
    propertyAPI.useDeletePropertyMutation();
  const navigate = useNavigate();

  const onDeleteProperty = useCallback(() => {
    deleteProperty(String(directoryName))
      .unwrap()
      .then(() => {
        navigate('/');
        Notification({ title: 'Deleted Successfuly', type: 'check' });
      })
      .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
  }, [deleteProperty, directoryName, navigate]);

  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <BlockWrapper minHeight="initial" isSmall padding="16px 24px" title="Environment ID">
          <ProjectOriginEnvIdDetails />
        </BlockWrapper>
      </Col>

      <Col span={24}>
        <BlockWrapper
          rows={3}
          minHeight="220px"
          padding="16px 24px"
          title="API Key"
          isSmall
          isSkeleton={isPropertyLoading || !propertyDetails?.success}>
          <ProjectOriginKey />
        </BlockWrapper>
      </Col>

      <Col span={24}>
        <BlockWrapper minHeight="initial" isSmall padding="16px 24px" title="PCI Status">
          <ProjectOriginPciDetails />
        </BlockWrapper>
      </Col>

      {/* <Col span={24}>
        <BlockWrapper minHeight="initial" isSmall padding="16px 24px" title="Rename environment">
          <ProjectOriginRenameDetails />
        </BlockWrapper>
      </Col> */}

      <Col span={24}>
        <BlockWrapper
          minHeight="initial"
          padding="16px 24px"
          title="Delete Property"
          isSmall
          isSkeleton={isPropertyLoading || !propertyDetails?.success}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Typography>Once you delete property, there is no going back.</Typography>
            </Col>
            <Col>
              <Button
                onClick={() => setDeleteModalVisibleProperty(true)}
                loading={isLoadingProperty}
                type="danger">
                Delete Property
              </Button>
              <Modal
                visible={deleteModalVisibleProperty}
                title="Delete Property"
                width="400px"
                onCancel={() => setDeleteModalVisibleProperty(false)}>
                <ProjectOriginDeleteModal
                  domain={propertyDetails?.data?.name || ''}
                  onCancel={() => setDeleteModalVisibleProperty(false)}
                  onFinish={onDeleteProperty}
                  loading={isLoadingProperty}
                  isSuccess={isSuccessProperty}
                />
              </Modal>
            </Col>
          </Row>
        </BlockWrapper>
      </Col>
    </Row>
  );
};

export default ProjectOriginServiceView;
