enum RouteTypes {
  home = '/',
  create = '/create-property',
  project = '/property/:directoryName',
  details = '/property/:directoryName',
  settings = '/property/:directoryName/settings',
  settingsSecurity = '/property/:directoryName/settings/security',
  settingsDeployments = '/property/:directoryName/settings/deployments',
  settingsDeploymentsDetails = '/property/:directoryName/settings/deployments/:deploymentId',
  settingsHostnames = '/property/:directoryName/settings/hostnames',
  settingsAlerts = '/property/:directoryName/settings/alerts',
  settingsApi = '/property/:directoryName/settings/api',
  settingsService = '/property/:directoryName/settings/service',
  // settingsRename = '/property/:directoryName/settings/rename',
  // settingsEnvId = '/property/:directoryName/settings/envirenment-id',
  // settingsPciStatus = '/property/:directoryName/settings/pci-status',
  settingsCollaboration = '/property/:directoryName/settings/collaboration',
  settingsRules = '/property/:directoryName/settings/rules',
  settingsCache = '/property/:directoryName/settings/cache',
  settingsTLS = '/property/:directoryName/settings/tls',
  settingsAccess = '/property/:directoryName/settings/access-control',
  security = '/property/:directoryName/security',
  routing = '/property/:directoryName/routing',
  engine = '/property/:directoryName/rules',
  engineHistory = '/property/:directoryName/rules/history',
  engineDraft = '/property/:directoryName/rules/draft',
  engineDraftDetails = '/property/:directoryName/rules/draft/:id',
  logs = '/property/:directoryName/logs',
  error401 = '/401',
  error403 = '/403',
  error404 = '/404',
  error500 = '/500',
  userSettings = '/user-settings',
  settingsUser = '/user-settings/settings',
  billing = '/user-settings/billing',
  invoices = '/user-settings/invoices',
  billingReports = '/user-settings/billing-reports',
}

export { RouteTypes };
export default RouteTypes;
