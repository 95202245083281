/* eslint-disable array-callback-return */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import FusionCharts from 'fusioncharts';
import cn from 'classnames';
import { Col, Row, Tooltip } from 'antd';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import ReactFusioncharts from 'react-fusioncharts';
import { Bucket, DataModelPieLogsItem, DynamicKeyData } from 'models';
import styles from './ProjectLogsPieChart.module.scss';

PowerCharts(FusionCharts);

interface ResultDataType {
  id: string;
  name: string;
  label?: string;
  parent: string | null;
  value: number;
  color?: string;
  showLabel?: boolean;
}

export const ProjectLogsPieChart: FC<{
  data: DataModelPieLogsItem[];
  groupByParams: string[];
  isLoading: boolean;
}> = ({ data, groupByParams, isLoading }) => {
  const [hoveredData, setHoveredData] = useState<string | null>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hovered = (eventObj: any, eventArgs: any) => {
      if (eventArgs.nodeId) {
        setHoveredData(eventArgs.nodeId);
      }
    };
    const hoveredOut = () => {
      setHoveredData(null);
    };
    FusionCharts.addEventListener('dataPlotRollOver', hovered);
    FusionCharts.addEventListener('dataPlotRollOut', hoveredOut);

    return () => {
      FusionCharts.removeEventListener('dataPlotRollOver', hovered);
      FusionCharts.removeEventListener('dataPlotRollOut', hoveredOut);
    };
  });

  const aggregateSunburstData = useCallback(
    (dataW: DataModelPieLogsItem | Bucket, keys: string[], parentKey = '', index = 0) => {
      let result: ResultDataType[] = [];

      if (index < keys.length && dataW[keys[index]]) {
        const currentKeyPart = keys[index];
        const nextIndex = index + 1;

        const currentData = dataW[currentKeyPart] as DynamicKeyData;

        currentData?.buckets?.forEach((bucket: Bucket) => {
          const bucketKey = bucket.key || 'Undefined';
          const fullKey = parentKey ? `${parentKey} > ${bucketKey}` : bucketKey;
          // const fullKey = parentKey ? `${parentKey} > ${bucketKey}` : bucketKey;

          result.push({
            id: fullKey,
            name: bucketKey,
            // name: bucket.key || fullKey,
            // name: `${currentKeyPart}: ${bucket.key || fullKey}`,
            // label: `${bucketKey} - ${bucket.aggregate_doc_count.value}`,
            value: bucket.aggregate_doc_count.value,
            parent: parentKey || null,
          });

          if (nextIndex < keys.length && bucket[keys[nextIndex]]) {
            const childData = aggregateSunburstData(bucket, keys, fullKey, nextIndex);
            result = result.concat(childData);
          }
        });
      } else if (index === keys.length) {
        result.push({
          id: 'Total',
          name: 'Total',
          label: 'Total',
          value: dataW.aggregate_doc_count.value,
          parent: parentKey || null,
        });
      }

      return result;
    },
    []
  );

  const curData = useMemo(() => {
    return data.length > 0 ? aggregateSunburstData(data[0], groupByParams) : [];
  }, [data, aggregateSunburstData, groupByParams]);

  const totalCount = useMemo(() => {
    return data.length > 0
      ? curData.reduce((acc, curr) => acc + curr.value, 0)
      : data[0].aggregate_doc_count.value;
  }, [curData, data]);

  const handleMouseEnter = useCallback(
    (item: ResultDataType) => {
      curData.map((el) => {
        if (el.id === item.id) {
          // el.color = '#ff0000';
          el.showLabel = true;
        } else {
          // el.color = '';
          el.showLabel = false;
        }
      });
    },
    [curData]
  );

  const handleMouseLeave = useCallback(() => {
    if (hoveredData) {
      setHoveredData(null);
    }
    curData.map((el) => {
      // el.color = '';
      el.showLabel = false;
    });
  }, [curData, hoveredData]);

  // eslint-disable-next-line consistent-return
  const chartConfig = useMemo(() => {
    if (curData) {
      return {
        type: 'sunburst',
        renderAt: 'chart-container',
        id: 'sunburst-container',
        width: '500',
        height: '400',
        dataFormat: 'json',
        dataSource: {
          chart: {
            showToolBarButtonTooltext: '1',
            // plotHighlightEffect: 'fadeout',
            toolbarButtonColor: '#ffffff',
            pieborderthickness: '2',
            toolTipSepChar: ' - ',
            showToolTip: '1',
            valueFontColor: '#ffffff',
            textOutline: '0',
            valueFontSize: '12',
            defaultCenterLabel: String(totalCount),
            centerLabelFontSize: 13,
            centerLabelBold: '1',
            manageLabelOverflow: '1',
            theme: 'fusion',
            plotToolText: `<div style="padding: 4px">$label - <strong>$value</strong> <br/> </div>`,
            showplotborder: '1',
            showlabels: '0',
            labelDisplay: 'stagger',
            useEllipsesWhenOverflow: '1',
            labelStep: '3',
            labelFontSize: '9',
            maxLabelHeight: '10',
            showLegend: '1',
            innerRadius: '50',
          },
          data: curData,
        },
      };
    }
  }, [curData, totalCount]);

  return (
    <div
      className={cn(styles.wrapperChart, {
        [styles.origin]: !isLoading,
        [styles.grayed]: isLoading,
      })}>
      <Row gutter={[16, 8]} className={styles.pieChartContainer} wrap={false}>
        <Col flex="none">{chartConfig && <ReactFusioncharts {...chartConfig} />}</Col>
        <Col flex="auto">
          <div className={styles.legendContainer}>
            <div className={styles.legendDivider} />
            <div className={styles.legendTitle}>
              <Row wrap={false}>
                <Col flex="auto" className={styles.titleName}>
                  {groupByParams.length > 0 ? (
                    <>{groupByParams.filter((e) => !!e && e !== 'select parameter').join(' > ')}</>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col flex="none">
                  <div className={styles.titleColumn}>Count</div>
                </Col>
                <Col flex="none" style={{ width: 65 }}>
                  <div className={styles.titleColumn}>Share</div>
                </Col>
              </Row>
            </div>
            <ul>
              {curData.map((item) => (
                <Tooltip
                  key={item.id}
                  title={` ${item.id} | ${item.value} | ${(
                    (+item.value / totalCount) *
                    100
                  ).toFixed(2)}%`}
                  placement="top">
                  <li
                    className={cn(styles.legend, {
                      [styles.hoveredItem]: hoveredData && hoveredData === item.id,
                    })}
                    key={item.id}
                    data-value={item.id}
                    onMouseEnter={(e) => {
                      setHoveredData(e.currentTarget.getAttribute('data-value'));
                      handleMouseEnter(item);
                    }}
                    onMouseLeave={handleMouseLeave}>
                    <Row wrap={false}>
                      <Col flex="auto" className={styles.titleName}>
                        {item.id}
                      </Col>
                      <Col flex="none">
                        <div className={styles.titleColumn}>{item.value}</div>
                      </Col>
                      <Col flex="none" style={{ width: 65 }}>
                        <div className={styles.titleColumn}>
                          {((+item.value / totalCount) * 100).toFixed(2)}%
                        </div>
                      </Col>
                    </Row>
                  </li>
                </Tooltip>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectLogsPieChart;
