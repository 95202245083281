import { Col, Row } from 'antd';
// import Button from 'components/common/Button';
// import Divider from 'components/common/Divider';
import React, { FC, useCallback } from 'react';
import { cloneDeep, remove } from 'lodash';
import { InputWithTags } from 'components/common/InputWithTags';
import { Icon } from 'components/common/Icon';
import { useAppDispatch, useAppSelector } from 'store';
import {
  clearInputValue,
  clearTagsValue,
  updateInputValue,
  updateTagsValue,
} from 'store/slices/logs';
import styles from './ProjectLogsViewControls.module.scss';

// const actionButtonData: ButtonType[] = [
//   {
//     icon: 'zoom-out',
//   },
//   {
//     icon: 'auto-renew',
//   },
// ];
export interface ProjectLogsViewControlsProps {
  errorIcon: boolean;
  setErrorIcon: (b: boolean) => void;
  dataType: string;
  withAdditionalParameters?: boolean;
}

export const ProjectLogsViewControls: FC<ProjectLogsViewControlsProps> = ({
  errorIcon,
  setErrorIcon,
  dataType,
  withAdditionalParameters,
}) => {
  const dispatch = useAppDispatch();
  const { inputValue, tags } = useAppSelector(({ logs }) => logs);

  const onTagChange = useCallback(
    (val: string, idx: number) => {
      const shallowCoppy = cloneDeep(tags);
      shallowCoppy[idx] = val;
      dispatch(updateTagsValue(shallowCoppy));
    },
    [dispatch, tags]
  );

  const onRemoveClick = useCallback(
    (index: number) => {
      const shallowCoppy = cloneDeep(tags);
      remove(shallowCoppy, (_, idx) => idx === index);
      dispatch(updateTagsValue(shallowCoppy));
    },
    [dispatch, tags]
  );

  const onEnterPress = useCallback(() => {
    const operatorsNormalizer = (valueString: string) => {
      const currentVal = valueString.split(' ');

      if (currentVal.length > 1) {
        return currentVal;
      }

      if (valueString.toLowerCase().trim() === 'or' || valueString.toLowerCase().trim() === '@or') {
        return [`OR`];
      }
      if (
        valueString.toLowerCase().trim() === 'and' ||
        valueString.toLowerCase().trim() === '@and'
      ) {
        return [`AND`];
      }
      return [valueString];
    };

    dispatch(updateTagsValue([...tags, ...operatorsNormalizer(inputValue)]));
    dispatch(clearInputValue());
  }, [dispatch, inputValue, tags]);

  const onClearClick = useCallback(() => {
    dispatch(clearTagsValue());
  }, [dispatch]);

  return (
    <>
      {/* <Row className={styles.head} align="middle" justify="space-between">
        <Col>
          <Row gutter={16} align="middle">
            <Col>
              <SectorButton data={actionButtonData} />
            </Col>
            <Divider style={{ margin: 0 }} type="vertical" />
            <Col>
              <Button type="secondary">Save As</Button>
            </Col>
          </Row>
        </Col>
      </Row> */}
      <Row gutter={16} align="middle" style={{ marginBottom: 10 }}>
        <Col flex={1}>
          <InputWithTags
            value={inputValue}
            onChange={(v) => dispatch(updateInputValue(v))}
            tags={tags}
            onTagChange={onTagChange}
            onRemoveClick={onRemoveClick}
            onEnterPress={onEnterPress}
            onClearClick={onClearClick}
            errorIcon={errorIcon}
            setErrorIcon={setErrorIcon}
            dataType={dataType}
            withAdditionalParameters={withAdditionalParameters}
          />
        </Col>
        <Col>
          <Icon name="code" className={styles.code} />
        </Col>
      </Row>
    </>
  );
};

export default ProjectLogsViewControls;
