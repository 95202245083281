import React, { FC } from 'react';
import { Icon } from 'components/common/Icon';
import Button from 'components/common/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Notification } from 'components/common/Notification';
import { apikeyAPIv2 } from 'services';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { Popconfirm, Skeleton } from 'antd';
import './ProjectOriginKey.scss';

export const ProjectOriginKey: FC = () => {
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { data, refetch } = apikeyAPIv2.useFetchApikeyQuery(String(directoryName), {
    skip: !token,
  });
  const [regenerateApikey, { isLoading }] = apikeyAPIv2.useRegenerateApikeyMutation();

  const confirm = () => {
    regenerateApikey(String(directoryName));
    refetch();
  };

  return (
    <div className="ProjectOriginKey">
      <label className="ProjectOriginKey__label">Your personal API token</label>
      <div className="ProjectOriginKey__coppy-block">
        {data?.data.apikey ? (
          <>
            <span className="ProjectOriginKey__coppy-label">{data?.data.apikey}</span>
            <CopyToClipboard
              text={String(data?.data.apikey)}
              onCopy={() =>
                Notification({
                  title: 'Copied successfuly',
                  message: `Value ${data?.data.apikey} successfuly copied to clipboard`,
                })
              }>
              <Icon name="copy" className="ProjectOriginKey__edit-icon" onClick={() => {}} />
            </CopyToClipboard>
          </>
        ) : (
          <Skeleton.Input style={{ width: 250 }} active size="small" />
        )}
      </div>
      <Popconfirm
        icon={<br />}
        placement="bottom"
        title={
          <div className="ProjectHead__popconfirm">
            <p style={{ paddingBottom: 5 }}>
              <strong>Are you certain you want to proceed with this action?</strong>
            </p>
            <p> Please be aware that the preview API key will cease to function.</p>
          </div>
        }
        onConfirm={confirm}
        okText="Yes"
        cancelText="No">
        <Button type="secondary" className="ProjectOriginKey__btn" loading={isLoading}>
          Generate New Token
        </Button>
      </Popconfirm>

      <p className="ProjectOriginKey__text _last">
        Important: keep your API token secure, as it can be used to access your data
      </p>
    </div>
  );
};

export default ProjectOriginKey;
