import { Col, Row } from 'antd';
import { BlockWrapper } from 'components/common/BlockWrapper';
import {
  ProjectOriginCollaboration,
  ProjectOriginInvitations,
} from 'components/views/Project/ProjectOrigin';
import React, { FC, useState } from 'react';

export const ProjectOriginCollaborationView: FC = () => {
  const [pollingIntervalTime, setPollingIntervalTime] = useState(0);

  const getPollingInterval = (time: number) => setPollingIntervalTime(time);

  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <BlockWrapper minHeight="initial" isSmall>
          <ProjectOriginCollaboration pollingIntervalTime={pollingIntervalTime} />
        </BlockWrapper>
      </Col>
      <Col span={24}>
        <BlockWrapper minHeight="initial" isSmall>
          <ProjectOriginInvitations
            getPollingInterval={getPollingInterval}
            pollingIntervalTime={pollingIntervalTime}
          />
        </BlockWrapper>
      </Col>
    </Row>
  );
};

export default ProjectOriginCollaborationView;
