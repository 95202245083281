/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Radio, RadioChangeEvent } from 'antd';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import { Notification } from 'components/common/Notification';
import { Select as SelectCommon, SelectItemType } from 'components/common/Select';
import { ClearOutlined } from '@ant-design/icons';
import { IPropertyResponse } from 'models';
import { useParams } from 'react-router-dom';
import { propertyAPI } from 'services';

interface Props {
  property: IPropertyResponse | undefined;
  isFetching: boolean;
  isVisible: boolean;
}

export const ProjectHeadPurgeDropdown: FC<Props> = ({ property, isFetching, isVisible }) => {
  const [purgeType, setPurgeType] = useState('all');
  const [isPurgeModalVisible, setPurgeModalVisible] = useState(false);
  const hostnamesData = useMemo(() => {
    return Array.isArray(property?.data)
      ? []
      : property?.data?.hostnames?.map((i) => i.hostname) || [];
  }, [property?.data]);
  const { directoryName } = useParams<{ directoryName?: string }>();

  const [urlVals, setUrlVals] = useState<string[]>([]);

  const [purgeAll, { isLoading: isAllLoading }] = propertyAPI.usePurgeAllMutation();
  const [purgeUrl, { isLoading: isUrlLoading }] = propertyAPI.usePurgeCustomMutation();

  useEffect(() => {
    if (isFetching) {
      setUrlVals(['...Loading']);
    }
    setUrlVals([purgeType === 'all' ? '' : hostnamesData.join(',')]);
  }, [hostnamesData, isFetching, purgeType]);

  const onCancelClick = useCallback(() => {
    setPurgeModalVisible(false);
    setPurgeType('all');
  }, []);

  const onPurgeUrlClick = useCallback(() => {
    purgeUrl({
      propertyId: String(directoryName),
      url: [...(urlVals ?? [])],
    })
      .unwrap()
      .then((resp) => Notification({ type: 'check', title: resp?.message }))
      .then(() => onCancelClick())
      .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data?.message }));
  }, [purgeUrl, directoryName, urlVals, onCancelClick]);

  const onPurgeAllClick = useCallback(() => {
    purgeAll({
      propertyId: String(directoryName),
      url: [...(urlVals ?? [])],
    })
      .unwrap()
      .then((resp) => Notification({ type: 'check', title: resp?.message }))
      .then(() => onCancelClick())
      .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data?.message }));
  }, [directoryName, onCancelClick, purgeAll, urlVals]);

  const onChangeType = (e: RadioChangeEvent) => {
    setUrlVals([purgeType === 'all' ? '' : hostnamesData.join(',')]);
    setPurgeType(e.target.value);
  };

  const options = useMemo(
    () => [
      {
        value: purgeType === 'all' ? '' : hostnamesData.join(','),
        label: 'all',
        name: 'all',
      },
      ...hostnamesData?.map((i: string) => ({
        value: i,
        label: i,
        name: i,
      })),
    ],
    [hostnamesData, purgeType]
  );

  const handleSelectChange = (v: string) => {
    return setUrlVals([v]);
  };
  const handleMultiSelectChange = (v: string) => {
    if (v.includes(',')) {
      return setUrlVals(v.split(','));
    }
    return setUrlVals([v]);
  };

  const renderSelect = useCallback(
    (data: SelectItemType[], onChangeData: (val: string) => void, idKey: number | string) => {
      return (
        <SelectCommon
          disabled={isFetching}
          key={idKey}
          placeholder={isFetching ? 'Loading...' : 'Select option'}
          onChange={(v) => onChangeData(v as string)}
          data={data}
          defaultValue={String(data[0].value)}
        />
      );
    },
    [isFetching]
  );

  return (
    <>
      <Modal
        visible={isPurgeModalVisible}
        onCancel={() => onCancelClick()}
        width={600}
        destroyOnClose
        title="Purge Cache"
        okButtonLabel="Purge">
        <Row>
          <Col span={24}>
            <div>
              {`Your property's cache is automatically purged each time you deploy. You can manually
              purge the cache here.`}
            </div>
          </Col>
          <Radio.Group name="radiogroup" defaultValue="all" onChange={onChangeType}>
            <Col span={24}>
              <div style={{ marginTop: 20 }}>
                <Radio value="all">Purge All</Radio>
              </div>
            </Col>
            <Col span={24}>
              <div style={{ marginTop: 10 }}>
                <Radio value="custom">Purge Custom</Radio>
              </div>
            </Col>
          </Radio.Group>

          <Col span={24}>
            <div style={{ marginTop: 20 }}>
              <span style={{ color: 'grey', fontSize: '14px' }}>Hostname</span>

              {/* <SelectCommon
                placeholder="Select option"
                onChange={(v) => handleSelectChange(v as string)}
                data={options as SelectItemType[]}
              /> */}

              {purgeType === 'all'
                ? renderSelect(options, handleSelectChange, purgeType)
                : renderSelect(options, handleMultiSelectChange, purgeType)}
            </div>
          </Col>
          <Col span={24}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>
              <Button
                type="secondary"
                loading={isAllLoading || isUrlLoading}
                onClick={() => onCancelClick()}>
                <Row align="middle">Cancel </Row>
              </Button>
              <div style={{ marginLeft: 20 }}>
                <Button
                  type="primary"
                  disabled={isFetching}
                  onClick={() => {
                    purgeType === 'all' ? onPurgeAllClick() : onPurgeUrlClick();
                  }}>
                  <Row align="middle">Purge Cache </Row>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
      {isVisible ? (
        <Button
          type="subtle"
          loading={isAllLoading || isUrlLoading}
          onClick={() => setPurgeModalVisible(true)}>
          <Row align="middle" style={{ marginRight: '28px', fontSize: '14px' }}>
            <span style={{ paddingRight: 2 }}>
              <ClearOutlined />
            </span>
            Purge
          </Row>
        </Button>
      ) : (
        <Button
          type="subtle"
          loading={isAllLoading || isUrlLoading}
          onClick={() => setPurgeModalVisible(true)}>
          <Row align="middle">Purge</Row>
        </Button>
      )}
    </>
  );
};

export default ProjectHeadPurgeDropdown;
