import './UserSettings.scss';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

export const UserSettings: FC = withAuthenticationRequired(() => {
  return (
    <>
      <div className="UserSettings__page">
        <Outlet />
      </div>
    </>
  );
});

export default UserSettings;
