import { useCallback, useEffect, useMemo, useState } from 'react';
import httpResponseCodesCheckboxes, {
  cacheStatusCheckboxes,
  cacheStatusesCheckboxesHistorical,
  httpResponseCodesCheckboxesHistorical,
} from 'assets/data/checkboxes';
import { isArray, map } from 'lodash';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { analyticsApiv2, propertyAPI } from 'services';
import {
  changeHistoricalTimeSelected,
  clearRealtimeData,
  HistoricalFilterType,
  updateDisabledTimePeriods,
  updateTimeDuration,
} from 'store/slices/project';
import { getHistoricalFrequency } from 'helpers';
import moment from 'moment';

export const useProjectDetails = () => {
  const [httpChecked, setHttpChecked] = useState<string[]>(
    map(httpResponseCodesCheckboxes, (item) => item.value)
  );
  const [cacheChecked, setCacheChecked] = useState<string[]>(
    map(cacheStatusCheckboxes, (item) => item.value)
  );

  const [realtime, setRealtime] = useState(true);
  const [realtimeFilter, setRealtimeFilter] = useState(0);
  const [disabledFilters, setDisabledFilters] = useState<number[]>([]);
  const [skiped, setSkiped] = useState(false);
  const [pollingIntervalTime] = useState(5000);
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { hostnames } = useAppSelector(({ property }) => property);

  const hosts = useMemo(() => {
    return hostnames.map((i) => i.hostname).join(',');
  }, [hostnames]);

  const { timeDuration, selectedPeriod, historicalTimeSelected, selectedCdn } = useAppSelector(
    ({ project }) => project
  );
  // useQuery
  const { data: projectDetails } = propertyAPI.useFetchPropertyQuery(String(directoryName), {
    skip: !token,
    // pollingInterval:
    //   projectConditions?.propagation && projectConditions?.propagation < 100 ? 15000 : undefined,
  });

  const {
    data: historicalData,
    isLoading: isHistoricalDataLoading,
    isFetching,
  } = analyticsApiv2.useFetchHistoricalDataByCountryQuery(
    {
      type: getHistoricalFrequency(historicalTimeSelected),
      propertyId: String(directoryName),
      hosts,

      endDate: isArray(selectedPeriod) ? selectedPeriod[1].format('YYYY-MM-DDTHH:mm:ss') : '',
      startDate: isArray(selectedPeriod) ? selectedPeriod[0].format('YYYY-MM-DDTHH:mm:ss') : '',
    },
    { skip: !token || selectedPeriod === 'live' || !hosts }
  );

  const {
    data: historicalDataMap,
    isLoading: isHistoricalDataMapLoading,
    status: historicalDataMapStatus,
  } = analyticsApiv2.useFetchHistoricalDataByCountryQuery(
    {
      type: 1440,
      propertyId: String(directoryName),
      country: 'country',
      hosts,
      endDate: isArray(selectedPeriod) ? selectedPeriod[1].format('YYYY-MM-DDTHH:mm:ss') : '',
      startDate: isArray(selectedPeriod) ? selectedPeriod[0].format('YYYY-MM-DDTHH:mm:ss') : '',
    },
    { skip: !token || selectedPeriod === 'live' || !hosts }
  );

  const { data: allRealtimeStatistics, fulfilledTimeStamp: allRealtimeStatisticsUpdate } =
    analyticsApiv2.useFetchRealTimeStatsQuery(
      {
        propertyId: String(directoryName),
        hosts,
      },
      { skip: !token || skiped || !hosts, pollingInterval: pollingIntervalTime }
    );
  const { data: popsData, fulfilledTimeStamp: popsUpdate } =
    analyticsApiv2.useFetchRealTimePopStatsQuery(
      {
        propertyId: String(directoryName),

        hosts,
      },
      { skip: !token || skiped || !hosts, pollingInterval: pollingIntervalTime }
    );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projectDetails) {
      const today = moment();
      const dateCreation = moment(projectDetails?.data?.created_at);
      const diff = today.diff(dateCreation, 'd');

      const startOfWeek = today.diff(moment().startOf('w'), 'd');
      switch (true) {
        case diff < 1:
          dispatch(updateDisabledTimePeriods([2, 3, 4, 5, 6, 7, 8]));
          break;
        case diff < startOfWeek:
          dispatch(updateDisabledTimePeriods([3, 4, 5, 6, 7, 8]));
          break;
        case diff < startOfWeek + 7:
          dispatch(updateDisabledTimePeriods([4, 5, 6, 7, 8]));
          break;
        case diff < 30:
          dispatch(updateDisabledTimePeriods([5, 6, 7, 8]));
          break;
        case diff < 60:
          dispatch(updateDisabledTimePeriods([6, 7, 8]));
          break;
        case diff < 90:
          dispatch(updateDisabledTimePeriods([7, 8]));
          break;
        case diff < 180:
          dispatch(updateDisabledTimePeriods([8]));
          break;
        default:
          dispatch(updateDisabledTimePeriods([]));
          break;
      }
    }
    return () => {
      dispatch(updateDisabledTimePeriods([]));
    };
  }, [dispatch, projectDetails]);

  const onRealtimeButtonClick = (idx: number) => {
    setRealtimeFilter(idx);
    dispatch(clearRealtimeData());
    setSkiped(false);
    switch (idx) {
      case 1:
        dispatch(updateTimeDuration(10));
        return;
      case 2:
        dispatch(updateTimeDuration(15));
        return;
      case 3:
        dispatch(updateTimeDuration(30));
        return;

      default:
        dispatch(updateTimeDuration(5));
    }
  };

  const onFilterButtonClick = useCallback(
    (idx: HistoricalFilterType) => {
      dispatch(changeHistoricalTimeSelected(idx));
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedPeriod !== 'live') {
      const [start, end] = selectedPeriod;
      const diff = end.diff(start, 'd');

      switch (true) {
        case diff < 14 && diff > 3:
          setDisabledFilters([0]);
          break;
        case diff >= 14:
          setDisabledFilters([0, 1]);
          break;
        case diff <= 3:
          setDisabledFilters([3]);
          break;
        default:
          setDisabledFilters([]);
          break;
      }
    }
    if (selectedPeriod === 'live') setDisabledFilters([]);
  }, [dispatch, selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod === 'live') {
      setRealtime(true);
      setSkiped(false);
    } else {
      setSkiped(true);
      setRealtime(false);
    }
  }, [selectedPeriod]);

  return {
    realtime,
    setRealtime,
    realtimeFilter,
    historicalTimeSelected,
    disabledFilters,
    onRealtimeButtonClick,
    onFilterButtonClick,
    httpChecked,
    setHttpChecked,
    cacheChecked,
    setCacheChecked,
    httpResponseCodesCheckboxesHistorical,
    cacheStatusCheckboxes,
    cacheStatusesCheckboxesHistorical,
    historicalData,
    isHistoricalDataLoading,
    isFetching,
    popsData,
    popsUpdate,
    selectedCdn,
    timeDuration,
    historicalDataMap,
    isHistoricalDataMapLoading,
    historicalDataMapStatus,
    projectDetails,
    allRealtimeStatistics,
    allRealtimeStatisticsUpdate,
    selectedPeriod,
  };
};

export default useProjectDetails;
