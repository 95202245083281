/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import {
  IField,
  inputNameObject,
  inputValueObject,
  selectActionObject,
  switchEnabledObject,
} from './fieldsObjects';

export const ageResponseHeader: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const debugCacheResponseHeader: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const modifyClientRequestHeader: IField[] = [
  {
    id: 0,
    ...selectActionObject,
  },
  {
    id: 1,
    ...inputNameObject,
    regexp: /^[+-]?[-_a-zA-Z0-9]+$/,
  },
  {
    id: 2,
    ...inputValueObject,
    regexp: /[ -~]*/,
  },
];

export const modifyClientResponseHeader: IField[] = [
  {
    id: 0,
    ...selectActionObject,
  },
  {
    id: 1,
    ...inputNameObject,
    regexp: /^[+-]?[-_a-zA-Z0-9]+$/,
  },
  {
    id: 2,
    ...inputValueObject,
    regexp: /[ -~]*/,
  },
];

export const setClientIpCustomHeader: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
  {
    id: 1,
    type: 'input',
    fieldName: 'name',
    label: 'True-Client-IP',
    regexp: /[-_\.a-zA-Z0-9]+/,
    tooltip: 'Regex Pattern: ',
  },
];
