/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { isEmpty, map } from 'lodash';
import { Divider, Row } from 'antd';
import { Icon } from '../Icon';
import styles from './InputWithTags.module.scss';
import { InputWithTagsItem } from './InputWithTagsItem';
import { InputWithTagsItemOperators } from './InputWithTagsItemOperators';
import {
  ListItemType,
  adnListOptions,
  botListOptions,
  rlListOptions,
  wafListOptions,
} from './options';

export interface IInputWithTagsProps {
  className?: string;
  onChange: (value: string) => void;
  value?: string;
  tags?: string[];
  onTagChange?: (value: string, idx: number) => void;
  onRemoveClick: (idx: number) => void;
  onEnterPress?: () => void;
  onClearClick?: () => void;
  errorIcon: boolean;
  setErrorIcon: (b: boolean) => void;
  dataType: string;
  withAdditionalParameters?: boolean;
}

export const InputWithTags: FC<IInputWithTagsProps> = ({
  onChange,
  className,
  value,
  tags,
  onTagChange,
  onRemoveClick,
  onEnterPress,
  onClearClick,
  errorIcon,
  setErrorIcon,
  dataType,
  withAdditionalParameters,
}) => {
  const getListOptions = useMemo(() => {
    switch (dataType) {
      case 'ADN':
        return adnListOptions;
      case 'WAF':
        return wafListOptions;
      case 'RL':
        return rlListOptions;
      case 'BOT':
        return botListOptions;

      default:
        return adnListOptions;
    }
  }, [dataType]);

  const inputRef = useRef<HTMLInputElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const dropDownListRef = useRef<HTMLUListElement>(null);

  const [listData, setListData] = useState<ListItemType[]>([]);
  const [isHiddenDropDown, setIsHiddenDropDown] = useState(true);
  const [isBackSpace, setIsBackSpace] = useState(false);

  useEffect(() => {
    setListData(getListOptions);
  }, [getListOptions]);

  function filterList(input: string, array: ListItemType[]) {
    const searchTerm = input.toLowerCase().trim().replace('@', '');
    if (searchTerm.includes(':')) {
      return array.filter((item) => item.label.toLowerCase().includes(searchTerm.split(':')[0]));
    }

    return array.filter((item) => item.label.toLowerCase().includes(searchTerm));
  }

  const handleOnChange = useCallback(
    (valueString: string) => {
      const filteredList = filterList(valueString, getListOptions);
      setListData(filteredList);
      onChange(valueString);
    },
    [getListOptions, onChange]
  );

  const handleRemoveClick = (index: number) => {
    onRemoveClick(index);
    const filteredList = filterList('', getListOptions);
    setListData(filteredList);
  };

  const handleOnClick = useCallback(
    (v: string) => {
      handleOnChange(v);
      inputRef.current?.focus();
    },
    [handleOnChange]
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      setIsBackSpace(false);
      if (e.code === 'Backspace' && isEmpty(value)) {
        setIsBackSpace(true);
      }

      if ((e.key === 'Enter' || e.code === 'Space') && onEnterPress) {
        onEnterPress();
        setListData(getListOptions);
        if (e.key === 'Enter') setIsHiddenDropDown(true);
        if (e.code === 'Space') setIsHiddenDropDown(false);
      }

      if (e.code === 'Escape') {
        setListData(getListOptions);
        setIsHiddenDropDown(true);
      }

      if (e.code === 'Tab') {
        e.preventDefault();
        if (
          [...(dropDownListRef.current?.children || [])].filter((item) =>
            item.classList.contains(styles.active)
          ).length > 0
        ) {
          handleOnClick(
            [...(dropDownListRef.current?.children || [])]
              .filter((item) => item.classList.contains(styles.active))[0]
              .getAttribute('value') || ''
          );
        }
      }
      if (listData.length === 1) {
        dropDownListRef.current?.children[0].classList.add(styles.active);
      }

      if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
        e.preventDefault();

        const dropdownList = dropDownListRef.current?.children;
        if (dropdownList && dropdownList.length > 0) {
          let currentIndex = 0;

          for (let i = 0; i < dropdownList.length; i += 1) {
            if (dropdownList[i].classList.contains(styles.active)) {
              currentIndex = i;
              break;
            }
          }

          if (e.code === 'ArrowUp') {
            if (currentIndex > 0) {
              dropdownList[currentIndex].classList.remove(styles.active);
              dropdownList[currentIndex - 1].classList.add(styles.active);

              const selectedElement = dropdownList[currentIndex - 1];
              const dropdownContainer = dropDownListRef.current;
              const selectedElementRect = selectedElement.getBoundingClientRect();
              const containerRect = dropdownContainer.getBoundingClientRect();

              if (selectedElementRect.top < containerRect.top) {
                dropdownContainer.scrollTop -= containerRect.top - selectedElementRect.top;
              }
            }
          }

          if (e.code === 'ArrowDown') {
            if (currentIndex < dropdownList.length - 1) {
              dropdownList[currentIndex].classList.remove(styles.active);
              dropdownList[currentIndex + 1].classList.add(styles.active);

              const selectedElement = dropdownList[currentIndex + 1];
              const dropdownContainer = dropDownListRef.current;
              const selectedElementRect = selectedElement.getBoundingClientRect();
              const containerRect = dropdownContainer.getBoundingClientRect();

              if (selectedElementRect.bottom > containerRect.bottom) {
                dropdownContainer.scrollTop += selectedElementRect.bottom - containerRect.bottom;
              }
            }
          }
        }
      }
    },

    [value, onEnterPress, listData.length, getListOptions, handleOnClick]
  );

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        inputRef.current &&
        !dropDownRef.current?.contains(e.target as Node) &&
        !inputRef.current?.contains(e.target as Node)
      ) {
        setIsHiddenDropDown(true);
      }

      if (typeof (e.target as HTMLElement)?.className === typeof '') {
        const isDropDownAreaClick = (e.target as HTMLElement)?.className.includes('dropDown');

        if (!isHiddenDropDown && !isDropDownAreaClick && onEnterPress && !isEmpty(tags)) {
          setListData(getListOptions);
          onEnterPress();
        }
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isHiddenDropDown, getListOptions, onEnterPress, tags]);

  // const handleOnBlur = (e: any) => {
  //   if (e.target.value && onEnterPress) {
  //     setListData(getListOptions);
  //     onEnterPress();
  //   }
  // };

  const handleClearAll = () => {
    if (onClearClick) onClearClick();
    setListData(getListOptions);
  };

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
        <div className={styles.lableBlock}>
          Search for
          <span
            className={styles.groupBy}
            style={{
              height: withAdditionalParameters ? '69px' : '27px',
              bottom: withAdditionalParameters ? '-70px' : '-28px',
            }}
          />
        </div>
        <div
          className={classNames(styles.block, className, errorIcon && styles.errorBlock)}
          style={{ width: '100%' }}>
          <Icon name="search" className={styles.searchIcon} />
          <div className={styles.tags}>
            {map(tags, (item, idx) => {
              const key = `${item}${idx}`;
              if (
                item.toLocaleLowerCase().trim() === 'and' ||
                item.toLocaleLowerCase().trim() === '@and' ||
                item.toLocaleLowerCase().trim() === 'or' ||
                item.toLocaleLowerCase().trim() === '@or'
              ) {
                const currentValue = item.replace(/(?!and|or)[^a-zA-Z]/g, '').toUpperCase();
                return (
                  <InputWithTagsItemOperators
                    value={currentValue}
                    key={key}
                    onChange={(v) => onTagChange && onTagChange(v, idx)}
                    onRemoveClick={() => handleRemoveClick(idx)}
                    isBackSpace={[...(tags || [])].length - 1 === idx ? isBackSpace : false}
                    setIsBackSpace={setIsBackSpace}
                    setErrorIcon={setErrorIcon}
                    isHiddenDropDown={isHiddenDropDown}
                    errorIcon={
                      [...(tags || [])].length - 1 === idx ||
                      [...(tags || [])][0].toLowerCase() === 'or' ||
                      [...(tags || [])][0].toLowerCase() === '@or' ||
                      [...(tags || [])][0].toLowerCase() === 'and' ||
                      [...(tags || [])][0].toLowerCase() === '@and'
                        ? errorIcon
                        : false
                    }
                  />
                );
              }

              return (
                <InputWithTagsItem
                  value={item}
                  key={key}
                  onChange={(v) => onTagChange && onTagChange(v, idx)}
                  onRemoveClick={() => handleRemoveClick(idx)}
                  isBackSpace={[...(tags || [])].length - 1 === idx ? isBackSpace : false}
                  setIsBackSpace={setIsBackSpace}
                  isHiddenDropDown={isHiddenDropDown}
                  dropDownRef={dropDownRef}
                />
              );
            })}
          </div>
          <input
            ref={inputRef}
            value={value}
            onClick={() => setIsHiddenDropDown(false)}
            onChange={(e) => {
              if (e.target.value) {
                setErrorIcon(false);
              }
              handleOnChange(e.target.value);
            }}
            className={styles.input}
            onKeyDown={onKeyDown}
            // onBlur={(e) => handleOnBlur(e)}
          />
          {!isEmpty(tags) && (
            <Icon name="cross-in-circle" className={styles.clearIcon} onClick={handleClearAll} />
          )}
        </div>
        <>
          <div
            ref={dropDownRef}
            className={classNames(styles.dropDown, isHiddenDropDown && styles.isHidden)}>
            <ul ref={dropDownListRef} className={classNames(styles.dropDownList)}>
              {isEmpty(listData) ? (
                <li className={classNames(styles.dropDownEmptyItem)} key={0}>
                  <span>No suggestion found</span>
                </li>
              ) : (
                map(listData, (item) => {
                  return (
                    <li
                      key={item.name}
                      value={item.label}
                      className={classNames(styles.dropDownItem)}
                      onClick={() => handleOnClick(item.label)}>
                      <span>
                        <Icon name="search" className={styles.searchIconDropDown} />
                        {item.label}
                      </span>
                      <span className={styles.dropDownItemTooltip}>{item.tooltip}</span>
                    </li>
                  );
                })
              )}
            </ul>
            <div className={classNames(styles.dropDownFooter)}>
              <Row justify="space-between" className={classNames(styles.dropDownFooter1)}>
                <div className={styles.dropDownFooterItem}>
                  <span className={classNames(styles.footer1Label)}>Field search: </span>
                  <span className={styles.footer2Border}>
                    <span className={classNames(styles.footer1Service)}>@service</span>
                    <span className={classNames(styles.footer1Colon)}>:</span>
                    <span className={classNames(styles.footer1LabelData)}>service_a</span>
                  </span>
                </div>
                <div className={styles.dropDownFooterItem}>
                  <span className={classNames(styles.footer1Label)}>Union: </span>
                  <span className={styles.footer2Border}>
                    <span className={classNames(styles.footer1Service)}>@service</span>
                    <span className={classNames(styles.footer1Colon)}>(</span>
                    <span className={classNames(styles.footer1LabelData)}>
                      service_a
                      <span className={classNames(styles.footer1Colon)}>;</span>service_b
                    </span>
                    <span className={classNames(styles.footer1Colon)}>)</span>
                  </span>
                </div>
                <div className={styles.dropDownFooterItem}>
                  <span className={classNames(styles.footer1Label)}>Exclusion: </span>
                  <span className={styles.footer2Border}>
                    <span className={classNames(styles.footer1Colon)}>-</span>
                    <span className={classNames(styles.footer1Service)}>@service</span>
                    <span className={classNames(styles.footer1Colon)}>:</span>
                    <span className={classNames(styles.footer1LabelData)}>service_a</span>
                  </span>
                </div>
              </Row>
              <Divider style={{ margin: 0 }} />
              <Row justify="space-between" className={classNames(styles.dropDownFooter2)}>
                <div className={styles.dropDownFooterItem}>
                  {'Use '}
                  <span className={styles.footer2Border}>&#8593;</span>
                  <span className={styles.footer2Border}>&#8595;</span>
                  {' to navigate'}
                </div>
                <div className={styles.dropDownFooterItem}>
                  <span className={styles.footer2Border}>TAB</span> to choose service
                </div>
                <div className={styles.dropDownFooterItem}>
                  <span className={styles.footer2Border}>ENTER</span>
                  {' or '}
                  <span className={styles.footer2Border}>SPACE</span> to update query
                </div>
                <div className={styles.dropDownFooterItem}>
                  <span className={styles.footer2Border}>ESC</span> to close
                </div>
              </Row>
            </div>
          </div>
        </>
      </div>
    </>
  );
};
