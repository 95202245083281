/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { capitalize } from 'lodash';
import { localStorageAsync } from './localStorageAsync';

/* eslint-disable no-useless-escape */
export const validEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
  return re.test(String(email).toLowerCase()) && email.length < 40;
};

export const validMessage = (message: string) => {
  const re = /[a-zA-Z0-9]/;
  return re.test(String(message).toLowerCase());
};
export const validNumber = (number: string) => {
  const re = /([0-9]|[\-+#])+/;
  return re.test(String(number).toLowerCase()) && number.length < 18 && number.length > 5;
};

export const calculateDiscountedPrice = (oldPrice: number, discountPercentage: number) => {
  if (oldPrice <= 0 || discountPercentage < 0 || discountPercentage > 100) {
    return null;
  }
  const discountAmount = (oldPrice * discountPercentage) / 100;
  const discountedPrice = oldPrice - discountAmount;

  return Math.round(discountedPrice);
};

export const extractDigitsFromString = (str: string) => {
  const digits = str.match(/\d+/);

  if (digits) {
    return digits[0];
  }
  return 0;
};

export const calculateRemainingDiscountMonths = (discountEndDate: string) => {
  const endDate = new Date(discountEndDate);
  endDate.setFullYear(endDate.getFullYear() + 1);
  endDate.setHours(23, 59, 59, 999);

  const currentDate = new Date();

  const monthsRemaining =
    (endDate.getFullYear() - currentDate.getFullYear()) * 12 +
    (endDate.getMonth() - currentDate.getMonth());

  return monthsRemaining;
};

export const urlRegexValidation =
  /^(?:(?:http|https|ftp):\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]+$/;

export const getFieldName = (value: string) => {
  const formatString = (input: string) => {
    if (input.includes('location')) {
      return 'Origins > Origin Hostname';
    }

    return input
      .split('.')
      .map((part: string) => {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(Number(part.charAt(0)))) {
          const word = capitalize(part);

          if (word.includes('_')) {
            return word
              .split('_')
              .map((item) => capitalize(item))
              .join(' ');
          }

          return word;
        }
        return ' > ';
        // return part;
      })
      .join(' ');
  };

  switch (value) {
    case 'name':
      return 'Property Name';
    // case 'origins.0.name':
    //   return 'Origin > Name';
    // case 'propagated':
    //   return 'success';
    // case 'Creating':
    //   return 'warning';
    default:
      return formatString(value);
  }
};
export const arraysAreEqual = (a: any, b: any) => {
  if (a.length !== b.length) {
    return false;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < a.length; i++) {
    if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) {
      return false;
    }
  }

  return true;
};

export const updateSkeletonsSize = (name: string, dirName: string, val: number) => {
  const dataString = localStorageAsync.getItemSync(name);
  let data = dataString ? JSON.parse(dataString) : {};

  // Ensure data is an object
  if (typeof data !== 'object' || Array.isArray(data)) {
    data = {};
  }

  data[dirName] = val.toString();

  localStorageAsync.setItem(name, JSON.stringify(data));
};

export const getSkeletonsSize = (name: string, dirName: string) => {
  const dataString = localStorageAsync.getItemSync(name);
  let data = dataString ? JSON.parse(dataString) : {};

  // Ensure data is an object
  if (typeof data !== 'object' || Array.isArray(data)) {
    data = {};
  }

  return data[dirName] || 1;
};

export const timeAgo = (dateStr: string) => {
  const date = moment(dateStr);
  const now = moment();

  const yearsDifference = now.diff(date, 'years');
  if (yearsDifference >= 1) {
    return `${yearsDifference} year${yearsDifference > 1 ? 's' : ''} ago`;
  }

  const monthsDifference = now.diff(date, 'months');
  if (monthsDifference >= 1) {
    return `${monthsDifference} month${monthsDifference > 1 ? 's' : ''} ago`;
  }

  const daysDifference = now.diff(date, 'days');
  if (daysDifference >= 1) {
    return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
  }

  const hoursDifference = now.diff(date, 'hours');
  if (hoursDifference >= 1) {
    return `about ${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
  }

  const minutesDifference = now.diff(date, 'minutes');
  return `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} ago`;
};

export const expiresTime = (expirationStr: string) => {
  const now = moment();
  const expiration = moment(expirationStr);

  const yearsDifference = expiration.diff(now, 'years');
  expiration.subtract(yearsDifference, 'years');

  const monthsDifference = expiration.diff(now, 'months');
  expiration.subtract(monthsDifference, 'months');

  const daysDifference = expiration.diff(now, 'days');
  expiration.subtract(daysDifference, 'days');

  const hoursDifference = expiration.diff(now, 'hours');
  expiration.subtract(hoursDifference, 'hours');

  const minutesDifference = expiration.diff(now, 'minutes');

  let result = 'expires ';

  if (yearsDifference > 0) {
    result += `${yearsDifference} year${yearsDifference > 1 ? 's' : ''} `;
  }

  if (monthsDifference > 0) {
    result += `${monthsDifference} month${monthsDifference > 1 ? 's' : ''} `;
  }

  if (yearsDifference === 0 && monthsDifference > 0) {
    if (daysDifference > 0) {
      result += `${daysDifference} day${daysDifference > 1 ? 's' : ''} `;
    }
  }

  if (yearsDifference === 0 && monthsDifference === 0) {
    if (daysDifference > 0) {
      result += `${daysDifference} day${daysDifference > 1 ? 's' : ''} `;
    }
    if (hoursDifference > 0) {
      result += `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} `;
    }
  }

  if (yearsDifference === 0 && monthsDifference === 0 && daysDifference === 0) {
    result += `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} `;
  }

  return `(${result.trim()}, automatically renewed)`;
};
