import React, { FC, useState } from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { Icon } from 'components/common/Icon';
import { capitalize, map, size } from 'lodash';
import { InvoicesResponseItem } from 'models';
import Button from 'components/common/Button';
import { Label } from 'components/common/Label';

interface ItemType {
  item: InvoicesResponseItem;
  className?: string;
}

export const UserSettingsInvoicesItemSubitem: FC<ItemType> = ({ item, className }) => {
  const [childrensVisibility, setChildrensVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRowClick = () => {
    setChildrensVisibility((prevState) => !prevState);
  };

  const replaceFreeTrial = (str: string, isLabel: boolean) => {
    const regex = /\(Free trial\)/;
    const parts = str.split(regex);

    if (str.match(regex)) {
      return isLabel ? (
        <span>
          <Label type="info">Free trial</Label>
        </span>
      ) : (
        <>
          {parts[0]}
          {parts[1]}
        </>
      );
    }

    return !isLabel && str;
  };

  const replaceCurrencyToIcon = (str: string): React.ReactNode => {
    if (str.toLowerCase() === 'eur') return <Icon name="eur" style={{ fontSize: 16 }} />;
    if (str.toLowerCase() === 'usd') return <Icon name="usd" style={{ fontSize: 16 }} />;
    return str;
  };

  const handleDownloadPdf = (invUrl: string) => {
    setIsLoading(true);
    const link = document.createElement('a');
    link.href = invUrl;
    link.rel = 'noopener noreferrer';
    link.download = `invoice.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <>
      <Col span={24} className={classNames('UserSettingsInvoices__table-item', className)}>
        <Row>
          <Col onClick={() => handleRowClick()} style={{ paddingLeft: 30 }} span={5}>
            <div
              className={classNames('UserSettingsInvoices__table-item-first _primary', className)}>
              <Icon
                className={classNames('UserSettingsInvoices__expand-icon', {
                  _active: childrensVisibility,
                })}
                name="expand-down"
              />
              <span className="UserSettingsInvoices__text">{capitalize(item.billing_reason)}</span>
            </div>
          </Col>
          <Col span={2} className={classNames('UserSettingsInvoices__col _primary', className)}>
            <span className="UserSettingsInvoices__text"> </span>
          </Col>
          <Col span={6} className={classNames('UserSettingsInvoices__col _primary', className)}>
            <span className="UserSettingsInvoices__text"> </span>
          </Col>
          <Col span={4} className={classNames('UserSettingsInvoices__col _primary', className)}>
            <span className="UserSettingsInvoices__text"> </span>
          </Col>
          <Col span={7} className={classNames('UserSettingsInvoices__col _primary', className)}>
            <Button
              type="subtle"
              className="UserSettingsInvoices__filter"
              loading={isLoading}
              onClick={() => handleDownloadPdf(item.invoice_pdf)}>
              <Icon name="export" className="UserSettingsInvoices__upload-icon" />
            </Button>
          </Col>
        </Row>
      </Col>
      {childrensVisibility &&
        map(item?.lines?.data, (subitem, idx) => {
          return (
            <Col
              key={subitem.id}
              span={24}
              className={classNames('UserSettingsInvoices__table-item')}>
              <Row>
                <Col style={{ paddingLeft: 90 }} span={7}>
                  <div
                    className={classNames('UserSettingsInvoices__table-item-first', {
                      _last: idx + 1 === size(item?.lines?.data),
                    })}>
                    <span className="UserSettingsInvoices__text">
                      {replaceFreeTrial(subitem.description, false)}
                    </span>
                  </div>
                </Col>
                <Col
                  span={2}
                  className={classNames('UserSettingsInvoices__col', {
                    _last: idx + 1 === size(item?.lines?.data),
                  })}>
                  <span className="UserSettingsInvoices__text" />
                </Col>
                <Col
                  span={6}
                  className={classNames('UserSettingsInvoices__col', {
                    _last: idx + 1 === size(item?.lines?.data),
                  })}>
                  <span className="UserSettingsInvoices__text">
                    {subitem.amount}
                    <span className="UserSettingsInvoices__currency">
                      {replaceCurrencyToIcon(subitem.currency)}
                    </span>
                  </span>
                </Col>
                <Col
                  span={4}
                  className={classNames('UserSettingsInvoices__col', {
                    _last: idx + 1 === size(item?.lines?.data),
                  })}>
                  <span className="UserSettingsInvoices__text"> </span>
                </Col>
                <Col
                  span={5}
                  className={classNames('UserSettingsInvoices__col', {
                    _last: idx + 1 === size(item?.lines?.data),
                  })}>
                  {replaceFreeTrial(subitem.description, true)}
                </Col>
              </Row>
            </Col>
          );
        })}
    </>
  );
};

export default UserSettingsInvoicesItemSubitem;
