import { createApi } from '@reduxjs/toolkit/query/react';
import { InvitationsResponse, UsersCollaborationResponse } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './baseV2';

export const collaborationApiv2 = createApi({
  reducerPath: 'collaborationApiv2',
  baseQuery: baseQuery(),
  tagTypes: ['CollaborationUsersV2', 'Invitationsv2'],
  endpoints: (build) => ({
    fetchInvitations: build.query<InvitationsResponse, string>({
      query: (propertyId) => ({
        url: endpoints.v2.invitations.getInvitations(propertyId),
      }),
      providesTags: () => ['Invitationsv2'],
    }),

    fetchCollaborationUsers: build.query<UsersCollaborationResponse, string>({
      query: (propertyId) => ({
        url: endpoints.v2.properties.users(propertyId),
      }),
      providesTags: () => ['CollaborationUsersV2'],
    }),

    deleteInvitation: build.mutation<InvitationsResponse, { propertyInvitationId: string }>({
      query: ({ propertyInvitationId }) => ({
        url: endpoints.v2.invitations.deleteInvitation(propertyInvitationId),
        method: 'DELETE',
      }),
      invalidatesTags: ['Invitationsv2'],
    }),

    inviteCollaborator: build.mutation<unknown, { propertyId: string; emails: string[] }>({
      query: ({ propertyId, emails }) => ({
        url: endpoints.v2.invitations.invitations,
        method: 'POST',
        body: {
          propertyId,
          users: emails,
        },
      }),
      invalidatesTags: ['CollaborationUsersV2', 'Invitationsv2'],
    }),

    deleteCollaborator: build.mutation<unknown, { propertyId: string; userId: string }>({
      query: ({ propertyId, userId }) => ({
        url: endpoints.v2.properties.usersDelete(propertyId, userId),
        method: 'POST',
      }),
      invalidatesTags: ['CollaborationUsersV2'],
    }),

    resendInvitation: build.mutation<InvitationsResponse, { propertyInvitationId: string }>({
      query: ({ propertyInvitationId }) => ({
        url: endpoints.v2.invitations.invitationsResend(propertyInvitationId),
        method: 'POST',
      }),
      invalidatesTags: ['Invitationsv2'],
    }),

    changeOwner: build.mutation<InvitationsResponse, { propertyId: string }>({
      query: ({ propertyId }) => ({
        url: endpoints.v2.properties.changeOwner(propertyId),
        method: 'POST',
      }),
      invalidatesTags: ['CollaborationUsersV2'],
    }),
  }),
});

export default collaborationApiv2;
