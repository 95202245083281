import { Row } from 'antd';
import React, { FC, ReactNode, useState } from 'react';
import { Dropdown } from '../Dropdown';
import { Icon } from '../Icon';

interface Props {
  name?: string;
  children?: ReactNode;
}

const SectorButtonDropdown: FC<Props> = ({ name, children }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Dropdown
      onVisibleChange={(v) => setVisible(v)}
      triggerElement={
        <Row gutter={8} align="middle">
          <button className="SectorButton__sector-btn" type="button">
            {name}
            <Icon
              name="expand-down"
              className="SectorButton__sector-icon _black"
              style={{
                transform: visible ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </button>
        </Row>
      }>
      {children}
    </Dropdown>
  );
};

export default SectorButtonDropdown;
