/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-case-declarations */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Col, Row, DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import classNames from 'classnames';
import { BlockWrapper } from 'components/common/BlockWrapper';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { Icon } from 'components/common/Icon';
import { Label } from 'components/common/Label';
import { SelectButton } from 'components/common/SelectButton';
import { SectorButton } from 'components/common/SectorButton';
import Switch from 'components/common/Switch';
import { Typography } from 'components/common/Typography';
import { ProjectLogsViewControls, ProjectLogsViewList } from 'components/views/ProjectLogsView';
import { ProjectLogsViewListTable } from 'components/views/ProjectLogsView/ProjectLogsViewList/ProjectLogsViewListTable';
import { isEmpty, isNil, join, map } from 'lodash';
import moment, { DurationInputArg2 } from 'moment';
import { useParams } from 'react-router-dom';
import { analyticsApiv2, propertyAPI } from 'services';
import { useAppDispatch, useAppSelector } from 'store';
import { updateSelectedCustomLogsPeriod } from 'store/slices/logs';
import { httpStatusCodes as initialHttpStatusCodes } from 'helpers/httpStatusCodes';
import { changeSelectedCustomPeriodInLogsPage, setIsUsingCustomRange } from 'store/slices/project';
import {
  DataModelHistogramLogsItem,
  DataModelLogsItem,
  DataModelPieLogsItem,
  Hostname,
  LogsAggregateChartData,
  LogsChartData,
} from 'models';
import { convertToReadableTime, getInterval, getUnitAndQuantity } from './utils';
import {
  HISTOGRAM,
  LIST,
  PIE,
  bindingTags,
  customDropdownValues,
  customRangeBtns,
  eventsTypeButtonData,
  fieldOptions,
  groupByOptions,
  playButtonData,
  topOptions,
  vievButtonData,
} from './constants';
import styles from './ProjectLogsView.module.scss';
import './ProjectLogsViewRangePicker.scss';

const { RangePicker } = DatePicker;

type ChartHistoryType = Record<number, [number, number]>;

export const ProjectLogsView: FC = withAuthenticationRequired(() => {
  const dispatch = useAppDispatch();
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { tags, selectedCustomLogsPeriod } = useAppSelector(({ logs: logsStore }) => logsStore);
  const { selectedCustomPeriodInLogsPage: selectedCustomPeriod, isUsingCustomRange } =
    useAppSelector(({ project }) => project);
  const [getData, { isLoading }] = analyticsApiv2.useSearchLogsMutation();
  const [getAggregateData, { isLoading: isLoadingChartData }] =
    analyticsApiv2.useAggregateLogsMutation();

  const lastRequestIdRef = useRef(0);
  const [index, setIndex] = useState(0);
  const [httpCodes, setHttpCodes] = useState(initialHttpStatusCodes);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [isChartDataLoading, setIsChartDataLoading] = useState(false);
  const [fieldParam, setFieldParam] = useState<string>('client_ip');
  const [groupByParams, setGroupByParams] = useState<string[]>(['client_country_code']);
  const [limiToTop, setLimitToTop] = useState<string>('top 10');
  const [errorIcon, setErrorIcon] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const [checkedChartType, setCheckedChartType] = useState(0);
  const [checkedDataType, setCheckedDataType] = useState(0);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [logs, setLogs] = useState<DataModelLogsItem[]>([]);
  const [chartData, setChartData] = useState<LogsChartData[]>([]);
  const [chartHistogramData, setHistogramData] = useState<DataModelHistogramLogsItem[]>([]);
  const [chartPieData, setChartPieData] = useState<DataModelPieLogsItem[]>([]);
  const [aggregateData, setAggregateData] = useState<LogsAggregateChartData[]>([]);
  const [isChartVisible, setIsChartVisible] = useState(true);
  const [isGroupedByStatus, setIsGroupedByStatus] = useState(true);
  const [disabledBackBtn, setDisabledBackBtn] = useState([0]);
  const [isDatesOpen, setIsDatesOpen] = useState(false);
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [mainChartHistory, setMainChartHistory] = useState<number[] | null>(
    selectedCustomLogsPeriod
  );
  const [chartHistory, setChartHistory] = useState<ChartHistoryType>({ 0: [0, 0] });

  const [isSkip, setIsSkip] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSkip(false);
    }, 500);

    return () => {
      clearTimeout(timer);
      setIsSkip(true);
    };
  }, []);

  const updateHttpStatusCodes = (code: string) =>
    setHttpCodes((prevState) =>
      prevState.includes(code)
        ? [...prevState.filter((item) => item !== code)]
        : [...prevState, code]
    );

  const clearStateChartHistory = () => {
    setChartHistory({ 0: [0, 0] });
    setIndex(0);
  };

  const { data: propertyDetails } = propertyAPI.useFetchPropertyQuery(String(directoryName), {
    skip: isSkip || !token,
  });

  const hosts: string | string[] = useMemo(() => {
    return propertyDetails?.data?.hostnames.map((i: Hostname) => i.hostname) || '';
  }, [propertyDetails?.data?.hostnames]);

  useEffect(() => {
    const [periodUnit, periodQuantity] = getUnitAndQuantity(selectedCustomPeriod);
    const endTime = moment();
    const startTime = moment().subtract(Number(periodQuantity), periodUnit as DurationInputArg2);
    dispatch(updateSelectedCustomLogsPeriod([startTime.valueOf(), endTime.valueOf()]));
    dispatch(setIsUsingCustomRange(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isInvalidTag = (tag: string) => {
      const tagLower = tag.toLowerCase();
      return bindingTags.includes(tagLower);
    };

    const firstElement = tags.length > 0 ? tags[0] : '';
    const lastElement = tags.length > 0 ? tags[tags.length - 1] : '';

    if (isInvalidTag(firstElement) || isInvalidTag(lastElement)) {
      setErrorIcon(true);
    }
  }, [tags]);

  const currentInterval = useMemo(
    () => getInterval(selectedCustomPeriod, selectedCustomLogsPeriod),
    [selectedCustomLogsPeriod, selectedCustomPeriod]
  );

  const disabledDate: RangePickerProps['disabledDate'] = useCallback((current: moment.Moment) => {
    return current && current > moment();
  }, []);

  const getDisabledButtons = useMemo(() => {
    if (isNil(selectedCustomLogsPeriod)) {
      return [2];
    }
    return [];
  }, [selectedCustomLogsPeriod]);

  const getCheckedDataTypeName = useMemo(() => {
    setHttpCodes(initialHttpStatusCodes);
    setIsTableDataLoading(true);
    setIsChartDataLoading(true);
    switch (checkedDataType) {
      case 0:
        return 'ADN';
      case 1:
        return 'WAF';
      case 2:
        return 'RL';
      case 3:
        return 'BOT';

      default:
        return 'ADN';
    }
  }, [checkedDataType]);

  const getDisabledChartTypeButtons = useMemo(() => {
    if (getCheckedDataTypeName === 'ADN') {
      return [];
    }
    return [1, 2];
  }, [getCheckedDataTypeName]);

  const getCheckedChartTypeName = useMemo(() => {
    switch (checkedChartType) {
      case 0:
        return LIST;
      case 1:
        return HISTOGRAM;
      case 2:
        return PIE;

      default:
        return LIST;
    }
  }, [checkedChartType]);

  const datesPeriod = useMemo(() => {
    if (isUsingCustomRange) {
      return [moment(selectedCustomLogsPeriod?.[0]), moment(selectedCustomLogsPeriod?.[1])];
    }
    const [periodUnit, periodQuantity] = getUnitAndQuantity(selectedCustomPeriod);
    const endTime = moment();
    const startTime = moment().subtract(Number(periodQuantity), periodUnit as DurationInputArg2);
    return [startTime, endTime];
  }, [isUsingCustomRange, selectedCustomLogsPeriod, selectedCustomPeriod]);

  const fetchChartData = useCallback(
    (searchTags: string[], datesStartEnd: moment.Moment[], dataType: string, interval: string) => {
      if (!isEmpty(datesStartEnd) && !searchTags.some((item) => item === '')) {
        setIsChartDataLoading(true);
        const [start, end] = datesStartEnd;
        if (
          propertyDetails?.success &&
          (dataType === 'BOT' || dataType === 'WAF' || dataType === 'RL') &&
          start.valueOf() !== end.valueOf()
        ) {
          getAggregateData({
            propertyId: String(directoryName),
            service: dataType,
            search: join(searchTags, ' '),
            hosts,
            from: start.utc().format('YYYY-MM-DDTHH:mm:ss'),
            to: end.utc().format('YYYY-MM-DDTHH:mm:ss'),
            includes: initialHttpStatusCodes,
            interval,
          })
            .unwrap()
            .then((resp) => {
              if (resp?.success) {
                setErrorIcon(false);
                setChartData(resp.data.aggregate.buckets as LogsChartData[]);
              } else {
                setChartData([] as LogsChartData[]);
              }
            })
            .then(() => setIsChartDataLoading(false))
            .catch(() => setErrorIcon(true));
        }

        if (
          propertyDetails?.success &&
          dataType === 'ADN' &&
          !searchTags.some((item) => item === '') &&
          start.valueOf() !== end.valueOf()
        ) {
          getAggregateData({
            analysis: 'yes',
            propertyId: String(directoryName),
            service: dataType,
            search: join(searchTags, ' '),
            hosts,
            from: start.utc().format('YYYY-MM-DDTHH:mm:ss'),
            to: end.utc().format('YYYY-MM-DDTHH:mm:ss'),
            includes: initialHttpStatusCodes,
            interval,
          })
            .unwrap()
            .then((resp) => {
              if (resp?.success) {
                setErrorIcon(false);
                setAggregateData(resp.data.aggregate.buckets as LogsAggregateChartData[]);
              } else {
                setAggregateData([] as LogsAggregateChartData[]);
              }
            })
            .then(() => setIsChartDataLoading(false))
            .catch(() => setErrorIcon(true));
        }
      }
    },
    [directoryName, getAggregateData, hosts, propertyDetails?.success]
  );

  const fetchTableData = useCallback(
    (
      searchTags: string[],
      datesStartEnd: moment.Moment[],
      dataType: string,
      httpStatusCodes: string[],
      interval: string,
      fieldParameter: string,
      groupBy: string[]
    ) => {
      if (!isEmpty(datesStartEnd) && !searchTags.some((item) => item === '')) {
        // eslint-disable-next-line no-plusplus
        const requestId = ++lastRequestIdRef.current;

        setIsTableDataLoading(true);
        const [start, end] = datesStartEnd;

        if (propertyDetails?.success && dataType === 'ADN' && start.valueOf() !== end.valueOf()) {
          const currentType = getCheckedChartTypeName === LIST ? '' : getCheckedChartTypeName;
          getData({
            propertyId: String(directoryName),
            service: dataType,
            search: join(searchTags, ' '),
            hosts,
            from: `${start.utc().format('YYYY-MM-DDTHH:mm:ss')}`,
            to: `${end.utc().format('YYYY-MM-DDTHH:mm:ss')}`,
            interval,
            includes: httpStatusCodes,
            type: currentType,
            field: currentType && fieldParameter,
            group_by_params: currentType && groupBy,
          })
            .unwrap()
            .then((resp) => {
              if (requestId === lastRequestIdRef.current) {
                if (getCheckedChartTypeName === HISTOGRAM) {
                  if (resp?.success) {
                    setHistogramData(resp.data as DataModelHistogramLogsItem[]);
                    setErrorIcon(false);
                  } else {
                    setHistogramData([]);
                  }
                }
                if (getCheckedChartTypeName === PIE) {
                  if (resp?.success) {
                    setChartPieData(resp.data as DataModelPieLogsItem[]);
                    setErrorIcon(false);
                  } else {
                    setChartPieData([]);
                  }
                }
                if (getCheckedChartTypeName === LIST) {
                  if (resp?.success) {
                    setLogs(resp.data as DataModelLogsItem[]);
                    setNextPage(resp.next_page ?? null);
                    setErrorIcon(false);
                  } else {
                    setLogs([] as DataModelLogsItem[]);
                    setNextPage(null);
                  }
                }
              }
            })
            .then(() => setIsTableDataLoading(false))
            .catch(() => setErrorIcon(true));
        }
        if (
          propertyDetails?.success &&
          (dataType === 'BOT' || dataType === 'WAF' || dataType === 'RL') &&
          start.valueOf() !== end.valueOf()
        ) {
          getData({
            propertyId: String(directoryName),
            service: dataType,
            search: join(searchTags, ' '),
            hosts,
            from: `${start.utc().format('YYYY-MM-DDTHH:mm:ss')}`,
            to: `${end.utc().format('YYYY-MM-DDTHH:mm:ss')}`,
            interval,
          })
            .unwrap()
            .then((resp) => {
              if (resp?.success) {
                setLogs(resp.data as DataModelLogsItem[]);
                setNextPage(resp.next_page ?? null);
                setErrorIcon(false);
              } else {
                setLogs([] as DataModelLogsItem[]);
                setNextPage(null);
              }
            })
            .then(() => setIsTableDataLoading(false))
            .catch(() => setErrorIcon(true));
        }
      }
    },
    [propertyDetails?.success, getCheckedChartTypeName, getData, directoryName, hosts]
  );

  const handleCustomRangeBtns = useCallback(
    (idx: number) => {
      const clearLogsPeriod = () => {
        clearStateChartHistory();
        setDisabledBackBtn([0]);
        if (index !== 0) {
          dispatch(updateSelectedCustomLogsPeriod(mainChartHistory));
        }
      };

      const updateLogsPeriod = (start: number, end: number) => {
        dispatch(updateSelectedCustomLogsPeriod([start, end]));
      };

      switch (idx) {
        case 1:
          clearLogsPeriod();
          setHttpCodes(initialHttpStatusCodes);

          break;

        case 0:
          let ind = 1;
          setIndex(index - 1);
          ind = index - 2;

          if (index === 0 || ind < 0) {
            clearLogsPeriod();
          } else {
            const currentLogsPeriod = chartHistory[ind];
            const startTime = moment(currentLogsPeriod[0]);
            const endTime = moment(currentLogsPeriod[1]);
            updateLogsPeriod(startTime.valueOf(), endTime.valueOf());
          }
          break;

        default:
          break;
      }
    },
    [chartHistory, dispatch, index, mainChartHistory]
  );

  const onPlayButtonsClick = (idx: number) => {
    const [periodUnit, periodQuantity] = getUnitAndQuantity(
      selectedCustomPeriod,
      selectedCustomLogsPeriod
    );
    switch (idx) {
      case 1:
        const endTime = moment();
        const startTime = moment().subtract(
          Number(periodQuantity),
          periodUnit as DurationInputArg2
        );
        dispatch(updateSelectedCustomLogsPeriod([startTime.valueOf(), endTime.valueOf()]));
        // dispatch(setIsUsingCustomRange(true));
        break;
      case 0:
        setIsCustomRange(true);
        if (!isNil(selectedCustomLogsPeriod)) {
          const newDates = [
            moment(selectedCustomLogsPeriod[0])
              .subtract(Number(periodQuantity), periodUnit as DurationInputArg2)
              .valueOf(),
            moment(selectedCustomLogsPeriod[1])
              .subtract(Number(periodQuantity), periodUnit as DurationInputArg2)
              .valueOf(),
          ];
          dispatch(updateSelectedCustomLogsPeriod(newDates));
          // dispatch(setIsUsingCustomRange(true));
        } else {
          dispatch(
            updateSelectedCustomLogsPeriod([
              moment()
                .subtract(Number(periodQuantity) * 2, periodUnit as DurationInputArg2)
                .valueOf(),
              moment()
                .subtract(Number(periodQuantity), periodUnit as DurationInputArg2)
                .valueOf(),
            ])
          );
        }
        break;

      case 2:
        setIsCustomRange(true);
        if (!isNil(selectedCustomLogsPeriod)) {
          if (
            moment(selectedCustomLogsPeriod[1])
              .add(Number(periodQuantity), periodUnit as DurationInputArg2)
              .isAfter(moment())
          ) {
            clearStateChartHistory();
          } else {
            dispatch(
              updateSelectedCustomLogsPeriod([
                moment(selectedCustomLogsPeriod[0])
                  .add(Number(periodQuantity), periodUnit as DurationInputArg2)
                  .valueOf(),
                moment(selectedCustomLogsPeriod[1])
                  .add(Number(periodQuantity), periodUnit as DurationInputArg2)
                  .valueOf(),
              ])
            );
          }
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (token && propertyDetails?.success) {
      fetchChartData(tags, datesPeriod, getCheckedDataTypeName, currentInterval);
    }
  }, [
    currentInterval,
    datesPeriod,
    fetchChartData,
    getCheckedDataTypeName,
    propertyDetails?.success,
    tags,
    token,
  ]);

  useEffect(() => {
    if (token && propertyDetails?.success && !groupByParams.includes('select parameter')) {
      fetchTableData(
        tags,
        datesPeriod,
        getCheckedDataTypeName,
        httpCodes,
        currentInterval,
        fieldParam,
        groupByParams.filter((item) => item !== 'select parameter')
      );
    }
  }, [
    currentInterval,
    datesPeriod,
    fetchTableData,
    fieldParam,
    getCheckedDataTypeName,
    groupByParams,
    httpCodes,
    propertyDetails?.success,
    tags,
    token,
  ]);

  const renderTableHeadControls = useMemo(() => {
    return (
      <>
        <Row gutter={16} align="middle" justify="space-between">
          <Col>
            <Typography variant="span" color="#192A3E" fontSize={12}>
              Service Time
            </Typography>
          </Col>
          <Col>
            <Switch checked={isChartVisible} onChange={setIsChartVisible} />
          </Col>
        </Row>
        <Row gutter={16} align="middle" justify="space-between">
          <Col>
            <Typography variant="span" color="#192A3E" fontSize={12}>
              Group by Status
            </Typography>
          </Col>
          <Col>
            <Switch
              checked={isGroupedByStatus}
              onChange={setIsGroupedByStatus}
              disabled={getCheckedDataTypeName !== 'ADN'}
            />
          </Col>
        </Row>
      </>
    );
  }, [isChartVisible, isGroupedByStatus, getCheckedDataTypeName]);

  const loadMoreData = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (nextPage && !isEmpty(datesPeriod)) {
      setIsTableDataLoading(true);
      const [start, end] = datesPeriod;
      if (getCheckedDataTypeName === 'ADN') {
        getData({
          propertyId: String(directoryName),
          service: getCheckedDataTypeName,
          search: join(tags, ' '),
          hosts,
          from: `${start.utc().format('YYYY-MM-DDTHH:mm:ss')}`,
          to: `${end.utc().format('YYYY-MM-DDTHH:mm:ss')}`,
          page: nextPage,
          includes: httpCodes,
        })
          .unwrap()
          .then((resp) => {
            if (!isEmpty(resp.data)) {
              const logsDataSumm = [...logs, ...resp.data];
              setLogs([...logsDataSumm] as DataModelLogsItem[]);
              setNextPage(resp.next_page ?? null);
            } else {
              setNextPage(null);
            }
          });
      } else {
        getData({
          propertyId: String(directoryName),
          service: getCheckedDataTypeName,
          hosts,
          page: nextPage,
          search: join(tags, ' '),
          from: `${start.utc().format('YYYY-MM-DDTHH:mm:ss')}`,
          to: `${end.utc().format('YYYY-MM-DDTHH:mm:ss')}`,
        })
          .unwrap()
          .then((resp) => {
            if (!isEmpty(resp.data)) {
              const logsDataSumm = [...logs, ...resp.data];
              setLogs([...logsDataSumm] as DataModelLogsItem[]);
              setNextPage(resp.next_page ?? null);
            } else {
              setNextPage(null);
            }
          });
      }
      setIsTableDataLoading(false);
    }
  }, [
    datesPeriod,
    directoryName,
    getCheckedDataTypeName,
    getData,
    isLoading,
    logs,
    nextPage,
    hosts,
    tags,
    httpCodes,
  ]);

  const handleChartHistory = useCallback(
    (preInterval: [number, number]) => {
      setIsCustomRange(true);
      if (index === 0) {
        setMainChartHistory(selectedCustomLogsPeriod);
        setDisabledBackBtn([]);
      }
      if (
        index > 1 &&
        chartHistory[index - 1][0] === preInterval[0] &&
        chartHistory[index - 1][1] === preInterval[1]
      ) {
        setChartHistory((prevState: ChartHistoryType) => ({
          ...prevState,
          [index - 1]: preInterval,
        }));
      } else {
        setChartHistory((prevState: ChartHistoryType) => ({
          ...prevState,
          [index]: preInterval,
        }));
        setIndex(index + 1);
        setDisabledBackBtn([]);
      }
    },
    [chartHistory, index, selectedCustomLogsPeriod]
  );

  useEffect(() => {
    const handleCalendarClick = () => setIsCustomRange(true);

    const calendar = document.getElementsByClassName('ant-picker-datetime-panel');
    if (calendar[0]) {
      calendar[0].addEventListener('click', handleCalendarClick);
    }

    return () => {
      if (calendar[0]) {
        calendar[0].removeEventListener('click', handleCalendarClick);
      }
    };
  });

  const renderChartHeaderControls = useMemo(() => {
    const extraFooterRender = () => {
      return (
        <ul className={styles.rangeDateBtnList}>
          {map(customDropdownValues, ({ name, value, label, display }, idx) => (
            <li key={label} style={{ display: display ? 'block' : 'none', padding: '1px 0' }}>
              <Button
                key={`${idx}${name}`}
                type="secondary"
                fluid
                active={isCustomRange ? label === 'null' : label === selectedCustomPeriod}
                onClick={() => {
                  setGroupByParams((prev) => prev.filter((i) => i !== 'select parameter'));
                  const valueDateTime = value();
                  setIsCustomRange(false);
                  const start = valueDateTime[0].valueOf();
                  const end = valueDateTime[1].valueOf();

                  dispatch(updateSelectedCustomLogsPeriod([start, end]));
                  dispatch(changeSelectedCustomPeriodInLogsPage(label));
                }}>
                {name}
              </Button>
            </li>
          ))}
        </ul>
      );
    };

    return (
      <Row gutter={16} justify="space-between" style={{ flex: 1, paddingLeft: 16 }}>
        <Row gutter={16} align="middle">
          <Col>
            <SectorButton
              isPill
              data={eventsTypeButtonData}
              checked={checkedDataType}
              onClick={setCheckedDataType}
            />
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col>
            <span className={styles.customLabel}>
              {selectedCustomLogsPeriod &&
                isUsingCustomRange &&
                !!(selectedCustomLogsPeriod?.[1] - selectedCustomLogsPeriod?.[0]) &&
                isCustomRange && (
                  <Label className="ProjectHead__label">
                    {convertToReadableTime(
                      selectedCustomLogsPeriod?.[1] - selectedCustomLogsPeriod?.[0],
                      false
                    )}
                  </Label>
                )}
            </span>
            {!isCustomRange && (
              <div className={styles.customRangeBtn}>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsDatesOpen(true);
                  }}>
                  {
                    customDropdownValues.filter((item) => item.label === selectedCustomPeriod)[0]
                      .name
                  }
                </span>
              </div>
            )}
            <div
              className={styles.customRangeWrapper}
              style={{
                top: !isCustomRange ? 0 : -10,
              }}>
              <RangePicker
                open={isDatesOpen}
                onOpenChange={(v) => setIsDatesOpen(v)}
                separator="-"
                className={styles.rangePicker}
                showTime={{ format: 'HH:mm:ss' }}
                format="D MMM, HH:mm:ss"
                disabledDate={disabledDate}
                clearIcon={null}
                allowClear={false}
                bordered={false}
                value={[
                  moment(selectedCustomLogsPeriod?.[0]),
                  moment(selectedCustomLogsPeriod?.[1]),
                ]}
                onCalendarChange={(v) => {
                  if (v !== null && v[0] !== null && v[1] !== null) {
                    const start = v[0]?.valueOf();
                    const end = v[1]?.valueOf();
                    if (end > start) {
                      dispatch(updateSelectedCustomLogsPeriod([start, end]));
                      setIsCustomRange(true);
                    } else {
                      dispatch(updateSelectedCustomLogsPeriod([end, start]));
                      setIsCustomRange(true);
                    }
                    setIsDatesOpen(false);
                  }
                }}
                popupClassName="ProjectLogsView__calendar-dropdown"
                renderExtraFooter={extraFooterRender}
                suffixIcon={
                  <Icon
                    className={classNames('ProjectHead__dropdown-icon', {
                      _active: isDatesOpen,
                    })}
                    name="expand-down"
                  />
                }
              />
            </div>
          </Col>

          <Col>
            {isUsingCustomRange && (
              <SectorButton
                isPill
                data={customRangeBtns}
                onClick={handleCustomRangeBtns}
                disabled={disabledBackBtn}
              />
            )}
          </Col>
        </Row>
      </Row>
    );
  }, [
    checkedDataType,
    disabledBackBtn,
    disabledDate,
    dispatch,
    handleCustomRangeBtns,
    isCustomRange,
    isDatesOpen,
    isUsingCustomRange,
    selectedCustomLogsPeriod,
    selectedCustomPeriod,
  ]);

  const groupByFiltersRender = useMemo(() => {
    return (
      getCheckedDataTypeName === 'ADN' &&
      (getCheckedChartTypeName === HISTOGRAM || getCheckedChartTypeName === PIE) && (
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 5, flexWrap: 'nowrap', marginLeft: 6 }}>
          <Col>
            <Row className={styles.groupIntoBtnsBlock}>
              <SelectButton
                disabled
                color="#f0faff"
                withConnector
                label="Group into"
                title="fields"
              />
              <SelectButton
                // disabledClick={isLoading}
                color="#ffffeb"
                withConnector
                data={fieldOptions}
                label="Show"
                text="Count unique of"
                title={fieldParam || 'all logs'}
                onClick={(value) => {
                  setFieldParam(value);
                }}
                onClickRemoveIcon={() => {
                  setFieldParam('');
                }}
              />
              {[...(groupByParams.length === 0 ? ['select parameter'] : groupByParams)].map(
                (item, idx) => (
                  <SelectButton
                    // disabledClick={isLoading}
                    color="#fff5fb"
                    isError={item === 'select parameter'}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item}${idx}`}
                    withConnector
                    title={item}
                    label={groupByParams.length > 1 && idx !== 0 ? 'and' : 'by'}
                    data={groupByOptions.filter((elem) => !groupByParams.includes(elem.value))}
                    onClick={(value) => {
                      const updatedData = [...groupByParams];
                      updatedData[idx] = value;
                      setGroupByParams(updatedData);
                    }}
                    onClickRemoveIcon={() => {
                      const updatedData = [...groupByParams];
                      updatedData[idx] = '';

                      if (groupByParams.length > 1) {
                        setGroupByParams(updatedData.filter((i) => Boolean(i)));
                      } else {
                        setGroupByParams([]);
                      }
                    }}
                  />
                )
              )}
              <div style={{ width: '16px' }} />
              <Button
                className={styles.addGroupByBtn}
                disabled={groupByParams.length >= 4 || groupByParams.includes('select parameter')}
                active={false}
                type="secondary"
                icon="plus"
                onClick={() => {
                  setGroupByParams((prevState) => [
                    ...prevState.filter((i) => Boolean(i)),
                    // 'select parameter',
                    groupByOptions.filter((elem) => !groupByParams.includes(elem.value))[0].value,
                  ]);
                }}
              />
            </Row>
          </Col>

          <Col>
            <Row>
              <SelectButton
                // disabledClick={isLoading}
                width="50px"
                title={limiToTop}
                label="limit to "
                data={topOptions}
                onClick={(value) => setLimitToTop(value)}
                onClickRemoveIcon={() => setLimitToTop('top 10')}
              />
            </Row>
          </Col>
        </Row>
      )
    );
  }, [
    getCheckedDataTypeName,
    getCheckedChartTypeName,
    // isLoading,
    fieldParam,
    groupByParams,
    limiToTop,
  ]);

  return (
    <div className={classNames(styles.page, { [styles.full]: isFull })}>
      <BlockWrapper
        isFullHeight={false}
        title="Events type"
        padding="16px 24px"
        minHeight={0}
        className={classNames(styles.chartWrapper)}
        renderHeaderControls={renderChartHeaderControls}
        headerFlexGrow={1}>
        <ProjectLogsViewControls
          errorIcon={errorIcon}
          setErrorIcon={setErrorIcon}
          dataType={getCheckedDataTypeName}
          withAdditionalParameters={
            getCheckedChartTypeName === HISTOGRAM || getCheckedChartTypeName === PIE
          }
        />

        <Row justify="space-between" style={{ marginBottom: 10, marginLeft: 6 }}>
          <Col>
            <Row className={styles.chartType}>
              <SectorButton
                withConnector
                title="Visualize as"
                data={vievButtonData}
                checked={getCheckedDataTypeName === 'ADN' ? checkedChartType : 0}
                onClick={setCheckedChartType}
                disabled={getDisabledChartTypeButtons}
              />
            </Row>
          </Col>

          <Col>
            <Row gutter={8}>
              <Col>
                <SectorButton
                  data={playButtonData}
                  onClick={onPlayButtonsClick}
                  disabled={getDisabledButtons}
                />
              </Col>
              <Col>
                <Button
                  type="secondary"
                  icon="full-screen"
                  onClick={() => setIsFull(!isFull)}
                  active={isFull}
                />
              </Col>
              <Col>
                <Dropdown
                  triggerElement={
                    <Button icon="settings" type="secondary">
                      Options
                    </Button>
                  }>
                  {renderTableHeadControls}
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
        {groupByFiltersRender}
        {isChartVisible && (
          <div style={{ height: 120 }}>
            <ProjectLogsViewList
              handleChartHistory={handleChartHistory}
              isLoading={isLoadingChartData}
              isGroupedByStatus={isGroupedByStatus}
              dataType={getCheckedDataTypeName}
              data={chartData}
              aggregateData={aggregateData}
              isChartDataLoading={isChartDataLoading}
              interval={currentInterval}
              updateHttpStatusCodes={updateHttpStatusCodes}
              httpCodes={httpCodes}
            />
          </div>
        )}
      </BlockWrapper>
      <BlockWrapper
        title="Total Events"
        padding="16px 24px"
        minHeight={0}
        isFullHeight={false}
        loading={isLoading}>
        <ProjectLogsViewListTable
          isFull={isFull}
          checkedDataType={checkedDataType}
          data={logs}
          loadMoreData={loadMoreData}
          nextLink={!!nextPage}
          isChartShown={isChartVisible}
          isLoading={isLoading}
          isWAF={checkedDataType === 1}
          isTableDataLoading={isTableDataLoading}
          chartType={getCheckedChartTypeName}
          chartPieData={chartPieData}
          chartHistogramData={chartHistogramData}
          groupByParams={groupByParams}
          dataType={getCheckedDataTypeName}
        />
      </BlockWrapper>

      {/* {!getCnameObject?.log_setup && (
        <div className={classNames(styles.emptyState, { [styles.collapsed]: isMenuColapsed })}>
          {isFetching ? (
            <Row
              align="middle"
              justify="center"
              gutter={120}
              style={{ padding: 32, height: '100%' }}>
              <Loader />
            </Row>
          ) : (
            <div style={{ width: '100%', height: '100%' }}>
              <Row align="middle" gutter={120} style={{ height: '100%' }}>
                <Col span={12}>
                  <h2 className={styles.emptyTitle}>
                    You don&apos;t have any logs yet. Setup your account to monitor and scale your
                    businesses safely
                  </h2>
                  <p className={styles.emptyText}>
                    Collect, configure, connect, explore, analyze and monitor your near real-time
                    log events to better understand the performance of your applications and take
                    fast action. Unified and centralized log management for full visibility and easy
                    access.
                  </p>
                </Col>
                <Col span={12} className={styles.imageBlock}>
                  <Ilustration className={styles.ilustration} />
                  <span className={styles.orange} />
                </Col>
              </Row>
            </div>
          )}
        </div>
      )} */}
    </div>
  );
});

export default ProjectLogsView;
