import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { RootState } from 'store';
import { BASE_URL } from 'urls';

export const baseQuery = (url: string | undefined = BASE_URL) =>
  fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });

export default baseQuery;
