import { ProjectEngineDraftItem } from 'components/views/Project/ProjectEngine/ProjectEngineDraft/ProjectEngineDraftItem';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

export const ProjectEngineDraftDetails: FC = () => {
  const { id } = useParams<{ id?: string }>();

  return <>{id && <ProjectEngineDraftItem id={id} />}</>;
};

export default ProjectEngineDraftDetails;
