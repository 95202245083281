/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
// import { HomeHeader } from 'components/views/home/HomeHeader';
// import { HomeTable } from 'components/views/home/HomeTable';
// import Modal from 'components/common/Modal';
// import { HomeAddModalNew } from 'components/views/home/HomeAddModalNew';
import { Col, Row } from 'antd';
// import { filter, includes, map, orderBy, size, some, toLower } from 'lodash';
import { IPropertyPayload } from 'models';
// import { useAppSelector } from 'store';
import { Notification } from 'components/common/Notification';
import { useNavigate } from 'react-router-dom';
// import { localStorageAsync } from 'helpers/localStorageAsync';
import { propertyAPI } from 'services';
import RouteTypes from 'router/types';
import { getFieldName } from 'helpers/utils';
import './CreateProperty.scss';
import { CreateProperty as CP } from '../../components/views/home/HomeAddModalNew/CreateProperty';

// const statuses = ['deployed', 'deploying', 'failed'];

export const CreateProperty: FC = withAuthenticationRequired(() => {
  const navigator = useNavigate();

  const [createProperty, { isLoading: isLoadingProperty }] =
    propertyAPI.useCreatePropertyMutation();

  const onCreateService = useCallback(
    (values: IPropertyPayload) => {
      createProperty(values)
        .unwrap()
        .then(() => {
          Notification({ type: 'check', title: 'Wait for your property to be created!' });
          navigator(`${RouteTypes.home}`);
        })
        .catch((error) => {
          if (error.data.data) {
            const errorData = Object.values(error.data.data).flat(2) ?? [];

            const errorMessage = (
              <Row>
                {errorData.map((item: any) => (
                  <Col span={24} key={item} style={{ margin: '3px 0' }}>
                    <span>The</span>
                    <span style={{ fontWeight: 600 }}>{` ${getFieldName(
                      item.split(' ')[1]
                    )} `}</span>
                    <span>{item.split(' ').slice(2).join(' ')}</span>
                  </Col>
                ))}
              </Row>
            );
            Notification({ type: 'cross', title: error.data.message, message: errorMessage });
          } else {
            Notification({ type: 'cross', title: 'Error', message: error.data.message });
          }
        });
    },
    [createProperty, navigator]
  );

  // const onCancel = useCallback(() => {
  //   navigator(`${RouteTypes.home}`);
  // }, [navigator]);

  return (
    <div className="createPropertyWrapper">
      <CP
        onFinish={onCreateService}
        // onCancel={onCancel}
        loading={isLoadingProperty}
      />
    </div>
  );
});

export default CreateProperty;
