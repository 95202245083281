import { createApi } from '@reduxjs/toolkit/query/react';
import { WafPayloadType, WafResponse } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const wafAPI = createApi({
  reducerPath: 'wafAPI',
  baseQuery: baseQuery(),
  tagTypes: ['Waf'],
  endpoints: (build) => ({
    fetchWaf: build.query<WafResponse, WafPayloadType>({
      query: ({ directoryName, cnameId }) => ({
        url: endpoints.waf(directoryName, cnameId),
      }),
      providesTags: () => ['Waf'],
    }),
    updateWaf: build.mutation<WafResponse, WafPayloadType>({
      query: ({ directoryName, cnameId, body }) => ({
        url: endpoints.waf(directoryName, cnameId),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Waf'],
    }),
  }),
});

export default wafAPI;
