/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { HomeHeader } from 'components/views/home/HomeHeader';
import { HomeTable } from 'components/views/home/HomeTable';
import Modal from 'components/common/Modal';
import { HomeAddModalNew } from 'components/views/home/HomeAddModalNew';
import { Col, Row } from 'antd';
import { filter, includes, map, orderBy, size, some, toLower } from 'lodash';
import { IPropertyData, IPropertyPayload } from 'models';
import { useAppSelector } from 'store';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Notification } from 'components/common/Notification';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { localStorageAsync } from 'helpers/localStorageAsync';
import { propertyAPI } from 'services';
import RouteTypes from 'router/types';
import { getFieldName } from 'helpers/utils';
import './Home.scss';

const statuses = ['deployed', 'deploying', 'failed'];

export const Home: FC = withAuthenticationRequired(() => {
  const [isGridView, setIsGridView] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const defaultSortType = 'az';
  const [selectedSortType, setSelectedSortType] = useState<string>(defaultSortType);

  useEffect(() => {
    const sortType: string = localStorageAsync.getItemSync('sortType') || defaultSortType;
    setSelectedSortType(sortType);
  }, [selectedSortType]);

  const [searchBoxValue, setSearchBoxValue] = useState<string>('');
  const { token } = useAppSelector(({ auth }) => auth);
  const [params] = useSearchParams();
  const navigator = useNavigate();

  const {
    data: properties,
    refetch,
    isLoading: isLoadingProperties,
  } = propertyAPI.useFetchAllPropertiesQuery('', {
    skip: !token,
    pollingInterval: isPolling ? 10000 : 0,
  });

  const [createProperty, { isLoading: isLoadingProperty }] =
    propertyAPI.useCreatePropertyMutation();

  if (size([...(properties?.data?.properties ?? [])]) > 0) {
    localStorageAsync.setItem('nOfPropts', String(size([...(properties?.data?.properties ?? [])])));
  }

  const onCreateService = useCallback(
    (values: IPropertyPayload) => {
      createProperty(values)
        .unwrap()
        .then(() => {
          Notification({ type: 'check', title: 'Wait for your property to be created!' });
          setCurrentStep(1);
        })
        .catch((error) => {
          if (error.data.data) {
            const errorData = Object.values(error.data.data).flat(2) ?? [];

            const errorMessage = (
              <Row>
                {errorData.map((item: any) => (
                  <Col span={24} key={item} style={{ margin: '3px 0' }}>
                    <span>The</span>
                    <span style={{ fontWeight: 600 }}>{` ${getFieldName(
                      item.split(' ')[1]
                    )} `}</span>
                    <span>{item.split(' ').slice(2).join(' ')}</span>
                  </Col>
                ))}
              </Row>
            );
            Notification({ type: 'cross', title: error.data.message, message: errorMessage });
            setCurrentStep(0);
          } else {
            Notification({ type: 'cross', title: 'Error', message: error.data.message });
            setCurrentStep(0);
          }
        });
    },
    [createProperty]
  );

  const filteredData = useMemo(() => {
    const mappedStatuses = map(statuses, (item) => toLower(item));

    const sortValues = (): [string, 'desc' | 'asc' | boolean] => {
      switch (selectedSortType) {
        case 'za':
          return ['name', 'desc'];
        case 'new':
          return ['created_at', 'desc'];
        case 'old':
          return ['created_at', 'asc'];
        case 'favorite':
          return ['is_favorite', 'desc'];

        default:
          return ['name', 'asc'];
      }
    };
    const [name, order] = sortValues();
    const filteredByStatus = filter(properties?.data?.properties, (item) =>
      includes([...mappedStatuses], toLower(item.status))
    );
    const filteredByInput = filter(filteredByStatus, (item) =>
      some(Object.values(item), (el) =>
        String(el).toLowerCase().includes(searchBoxValue.toLowerCase())
      )
    );
    return orderBy(filteredByInput, [name], [order]);
  }, [properties?.data?.properties, searchBoxValue, selectedSortType]);

  const onCancel = useCallback(() => {
    setVisible(false);
    setCurrentStep(0);
    navigator(`${RouteTypes.home}`);
  }, [navigator]);

  useEffect(() => {
    setIsPolling(
      properties?.data?.properties.some(
        (property: IPropertyData) => property.status === 'deploying'
      )
    );

    if (params.get('createProperty')) {
      setVisible(true);
      setIsPolling(false);
    }
  }, [params, properties?.data?.properties]);

  return (
    <>
      <HomeHeader
        // onAddClick={() => {
        //   // navigator(`${RouteTypes.home}?createProperty=true`);
        //   navigator(`${RouteTypes.create}?`);
        // }}
        selectedSortType={selectedSortType}
        onSelectedSortTypeChange={(v) => setSelectedSortType(v)}
        onInputChange={(e) => setSearchBoxValue(e)}
        isGridView={isGridView}
        setIsGridView={setIsGridView}
      />
      <HomeTable
        data={filteredData}
        updateList={refetch}
        isSortedByStatus={false}
        isLoading={isLoadingProperties}
        isGridView={isGridView}
        onCreateServiceClick={() => {
          // navigator(`${RouteTypes.home}?createProperty=true`);
          navigator(`${RouteTypes.create}`);
        }}
      />
      <Modal
        visible={visible}
        onCancel={() => onCancel()}
        hideHeader
        width={currentStep === 1 ? 400 : 860}
        destroyOnClose>
        {visible && (
          <HomeAddModalNew
            onCancel={() => onCancel()}
            onFinish={(values) => {
              onCreateService(values);
            }}
            loading={isLoadingProperty}
            currentStep={currentStep}
          />
        )}
      </Modal>
    </>
  );
});

export default Home;
