import { Col, Row } from 'antd';
import { BlockWrapper } from 'components/common/BlockWrapper';
import { ProjectOriginKey } from 'components/views/Project/ProjectOrigin';
import React, { FC } from 'react';

export const ProjectOriginApiView: FC = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <BlockWrapper minHeight="initial" padding="16px 24px" title="API Key" isSmall>
          <ProjectOriginKey />
        </BlockWrapper>
      </Col>
    </Row>
  );
};

export default ProjectOriginApiView;
