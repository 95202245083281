/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import {
  IField,
  inputNameObject,
  inputValueObject,
  selectMatchObject,
  selectRelativeObject,
  switchEncodedObject,
  switchObject,
} from './fieldsObjects';

export const urlPathLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /([-@_a-zA-Z0-9\~%:;=!,\.\+\*'\s\\]+|\?)/,
  },
  {
    id: 2,
    ...switchObject,
  },
  {
    id: 3,
    ...selectRelativeObject,
  },
  {
    id: 4,
    ...switchEncodedObject,
  },
];

export const urlPathRegex: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /.+/,
  },
  {
    id: 2,
    ...switchObject,
  },
  {
    id: 4,
    ...switchEncodedObject,
  },
];

export const urlPathWildcard: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /[-@_a-zA-Z0-9\~%:;=!,\.\+\*'\\ ]+/,
  },
  {
    id: 2,
    ...switchObject,
  },
  {
    id: 3,
    ...selectRelativeObject,
  },
  {
    id: 4,
    ...switchEncodedObject,
  },
];

export const urlPathDirectoryLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /([\*\][-_a-zA-Z0-9\~%:;=!,\.\+\*'\\ ]+[\*\][ ]?)+/,
  },
  {
    id: 2,
    ...switchObject,
  },
  {
    id: 3,
    ...selectRelativeObject,
  },
];

export const urlPathDirectoryWildcard: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /([\*\][-_a-zA-Z0-9\~%:;=!,\.\+\*'\\ ]+[\*\][ ]?)+/,
  },
  {
    id: 2,
    ...switchObject,
  },
  {
    id: 3,
    ...selectRelativeObject,
  },
];

export const urlPathExtensionLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /[\*\.-_a-zA-Z0-9 ]+/,
  },
  {
    id: 2,
    ...switchObject,
  },
];

export const urlPathExtensionWildcard: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /[\*\.-_a-zA-Z0-9 ]+/,
  },
  {
    id: 2,
    ...switchObject,
  },
];

export const urlPathFilenameLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /[-_a-zA-Z0-9~%:;=!,\.\+\*\\' ]+/,
  },
  {
    id: 2,
    ...switchObject,
  },
];

export const urlPathFilenameWildcard: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /[-_a-zA-Z0-9~%:;=!,\.\+\*\\' ]+/,
  },
  {
    id: 2,
    ...switchObject,
  },
];

export const urlQueryLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /([-@_a-zA-Z0-9\~%:;=!,\.\+\*'\s\\]+|\?)/,
  },
  {
    id: 2,
    ...switchObject,
  },
  {
    id: 3,
    ...switchEncodedObject,
  },
];

export const urlQueryRegex: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /.+/,
  },
  {
    id: 2,
    ...switchObject,
  },
  {
    id: 3,
    ...switchEncodedObject,
  },
];

export const urlQueryWildcard: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /[-@_a-zA-Z0-9\~%:;=!,\.\+\*'\\ ]+/,
  },
  {
    id: 2,
    ...switchObject,
  },
  {
    id: 3,
    ...switchEncodedObject,
  },
];

export const urlQueryParameterLiteral: IField[] = [
  {
    id: 0,
    ...inputNameObject,
    regexp: /[-_\.a-zA-Z0-9]+/,
  },
  {
    id: 1,
    ...selectMatchObject,
  },
  {
    id: 2,
    ...inputValueObject,
    regexp: /[-_a-zA-Z0-9\~%:;=!,\.\+\*'\\ ]+/,
  },
  {
    id: 3,
    ...switchObject,
  },
];

export const urlQueryParameterWildcard: IField[] = [
  {
    id: 0,
    ...inputNameObject,
    regexp: /[-_\.a-zA-Z0-9]+/,
  },
  {
    id: 1,
    ...selectMatchObject,
  },
  {
    id: 2,
    ...inputValueObject,
    regexp: /[-_a-zA-Z0-9\~%:;=!,\.\+\*'\\ ]+/,
  },
  {
    id: 3,
    ...switchObject,
  },
];
