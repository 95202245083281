import React, { FC } from 'react';
import cn from 'classnames';
import { Col, Row } from 'antd';
import { Label } from 'components/common/Label';
import { IRecord } from 'models';
import { Icon } from 'components/common/Icon';
import styles from './ProjectRoutingTableItem.module.scss';

interface IProjectRoutingTableItemProps {
  data: IRecord;
  name: string;
  onDeleteClick?: (data: IRecord) => void;
  onEditClick?: (data: IRecord) => void;
  isDefault?: boolean;
  isLast?: boolean;
}

export const ProjectRoutingTableItem: FC<IProjectRoutingTableItemProps> = ({
  data,
  name,
  onDeleteClick,
  onEditClick,
  isDefault,
  isLast,
}) => {
  const { Name, Rdata, TTL } = data;
  return (
    <Row
      className={cn(styles.row, styles.item, {
        [styles.last]: isLast,
      })}>
      <Col span={4} className={styles.col}>
        <Label className={styles.labelElement}>{name}</Label>
      </Col>
      <Col span={4} className={cn(styles.col, styles)}>
        <span className={styles.value}>{TTL}</span>
      </Col>
      <Col span={4} className={cn(styles.col)}>
        <span className={styles.value}>{Name}</span>
      </Col>
      <Col span={3} className={cn(styles.col, styles.right)}>
        <span className={styles.value}>{Rdata}</span>
      </Col>
      <Col span={3} className={cn(styles.col, styles.right)}>
        {!isDefault && (
          <Row gutter={12}>
            <Col>
              <Icon
                name="edit"
                className={styles.icon}
                onClick={() => onEditClick && onEditClick(data)}
              />
            </Col>
            <Col>
              <Icon
                name="remove"
                className={styles.icon}
                onClick={() => onDeleteClick && onDeleteClick(data)}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ProjectRoutingTableItem;
