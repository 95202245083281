import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ISslFreeResponse,
  ISslPayload,
  ISslResponse,
  ISslSharedPayload,
  ISslSharedResponse,
} from 'models/ssl';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const sslApi = createApi({
  reducerPath: 'sslApi',
  baseQuery: baseQuery(),
  tagTypes: ['Ssl', 'SslShared'],
  endpoints: (build) => ({
    fetchSsl: build.query<ISslResponse[], string>({
      query: (directoryName) => ({
        url: endpoints.ssl(directoryName),
      }),
      providesTags: () => ['Ssl'],
    }),
    updateSsl: build.mutation<ISslResponse[], { directoryName: string } & ISslPayload>({
      query: ({ directoryName, ...rest }) => ({
        url: endpoints.ssl(directoryName),
        method: 'POST',
        body: { ...rest },
      }),
      invalidatesTags: ['Ssl'],
    }),
    deleteSsl: build.mutation<{ message: string }, { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: `${endpoints.ssl(directoryName)}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Ssl'],
    }),
    cancelSsl: build.mutation<{ message: string }, { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: `${endpoints.sslCancelId(directoryName, id)}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Ssl'],
    }),
    fetchSslShared: build.query<ISslFreeResponse[], string>({
      query: (directoryName) => ({
        url: endpoints.sslShared(directoryName),
      }),
      transformResponse: (response: ISslSharedResponse) => {
        return response.data;
      },
      providesTags: () => ['SslShared'],
    }),
    updateSslShared: build.mutation<
      ISslFreeResponse[],
      { directoryName: string } & ISslSharedPayload
    >({
      query: ({ directoryName, ...rest }) => ({
        url: endpoints.sslShared(directoryName),
        method: 'POST',
        body: { ...rest },
      }),
      transformResponse: (response: ISslSharedResponse) => {
        return response.data;
      },
      invalidatesTags: ['SslShared'],
    }),
    deleteSslShared: build.mutation<{ message: string }, { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: endpoints.sslDeleteShared(directoryName, id),
        method: 'POST',
      }),
      invalidatesTags: ['SslShared'],
    }),
  }),
});

export default sslApi;
