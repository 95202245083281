import { Col, Row } from 'antd';
import classNames from 'classnames';
import { Label } from 'components/common/Label';
import { Loader } from 'components/common/Loader';
import { Typography } from 'components/common/Typography';
import { capitalize, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { rulesAPI } from 'services';
import { useAppSelector } from 'store';
import styles from './ProjectEngineDraftTable.module.scss';

export const ProjectEngineDraftTable: FC = () => {
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { selectedCdn } = useAppSelector(({ project }) => project);
  const { token } = useAppSelector(({ auth }) => auth);
  const { data: rules, isLoading: isRulesLoading } = rulesAPI.useGetRulesQuery(
    { cnameId: String(selectedCdn), directoryName: String(directoryName) },
    { skip: !token || !directoryName || !selectedCdn }
  );
  const navigate = useNavigate();

  const getLabel = useCallback((status: string) => {
    switch (status) {
      case 'draft':
        return <Label type="default">Draft</Label>;
      case 'deployed':
        return <Label type="info">Deployed</Label>;

      default:
        return <Label type="warning">Pending</Label>;
    }
  }, []);

  return (
    <>
      {isRulesLoading ? (
        <Row justify="center" style={{ padding: 24 }}>
          <Col>
            <Loader />
          </Col>
        </Row>
      ) : (
        <>
          {isEmpty(rules?.data) ? (
            <Row justify="center" style={{ padding: 24 }}>
              <Col>
                <Typography>
                  You don&apos;t have created policies yet. Please create first one from dropdown
                </Typography>
              </Col>
            </Row>
          ) : (
            <>
              <Row className={styles.row}>
                <Col span={4} className={styles.col}>
                  <span className={styles.label}>Name</span>
                </Col>
                <Col span={3} className={classNames(styles.col, styles.right)}>
                  <span className={styles.label}>Status</span>
                </Col>
                <Col span={9} className={classNames(styles.col, styles.right)}>
                  <span className={styles.label}>Updated at</span>
                </Col>
                <Col flex={1} className={classNames(styles.col, styles.right)}>
                  <span className={styles.label}>version</span>
                </Col>
              </Row>
              <>
                {map(rules?.data, (item) => (
                  <Row
                    className={classNames(styles.row, styles.item)}
                    key={item.id}
                    onClick={() => navigate(`${item.id}`)}>
                    <Col span={4} className={styles.col}>
                      <span className={styles.value}>{capitalize(item.profile)}</span>
                    </Col>
                    <Col span={3} className={classNames(styles.col, styles.right)}>
                      <span className={styles.value}>{getLabel(item.status)}</span>
                    </Col>
                    <Col span={9} className={classNames(styles.col, styles.right)}>
                      <span className={styles.value}>{moment(item.updated_at).format()}</span>
                    </Col>
                    <Col flex={1} className={classNames(styles.col, styles.right)}>
                      <span className={styles.value}>{item.version}</span>
                    </Col>
                  </Row>
                ))}
              </>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProjectEngineDraftTable;
