import React, { FC, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import { v4 } from 'uuid';
import './Switch.scss';

export const switchTestId = 'switch';
export const switchInputTestId = 'switch-input';

export interface Props {
  checked?: boolean;
  className?: string;
  onChange?: (e: boolean) => void;
  withText?: boolean;
  label?: string;
  disabled?: boolean;
}

export const Switch: FC<Props> = ({ checked, className, onChange, withText, label, disabled }) => {
  const id = `id-${v4()}`;

  const handleChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) => {
    onChange?.(e.target.checked);
  };

  return (
    <label
      data-testid={switchTestId}
      htmlFor={id}
      className={classNames('Switch', className, { '_with-text': withText, _disabled: disabled })}>
      {label && <span className="Switch__label">{label}</span>}
      <input
        disabled={disabled}
        data-testid={switchInputTestId}
        id={id}
        type="checkbox"
        checked={checked}
        value="value"
        onChange={handleChange}
        className="Switch__input"
      />
      <span className={classNames('Switch__area', { '_with-text': withText, _disabled: disabled })}>
        {withText && <span className="Switch__text">{checked ? 'on' : 'off'}</span>}
        <span className="Switch__dot" />
      </span>
    </label>
  );
};

export default Switch;
