import { Col, Row } from 'antd';
import classNames from 'classnames';
import { Icon } from 'components/common/Icon';
import { map, size } from 'lodash';
import { IRecord } from 'models';
import React, { FC, useState } from 'react';
import { ProjectRoutingTableItem } from '../ProjectRoutingTableItem';
import styles from './ProjectRoutingTableDefaultRecords.module.scss';

export const ProjectRoutingTableDefaultRecords: FC<{
  isEmpty?: boolean;
  defaultData: Record<string, Array<IRecord & { name: string }>>;
}> = ({ isEmpty, defaultData }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={classNames(styles.wrapper, { [styles.empty]: isEmpty })}>
      <button type="button" className={styles.button} onClick={() => setIsOpen(!isOpen)}>
        <Icon name="dropdown" className={classNames(styles.icon, { [styles.active]: isOpen })} />
        Default records
      </button>
      {isOpen && (
        <Row>
          {map(defaultData, (value, key) => {
            return (
              <React.Fragment key={key}>
                {map(value, (item, idx) => (
                  <Col span={24} key={idx}>
                    <ProjectRoutingTableItem
                      data={item}
                      name={item.name || ''}
                      isDefault
                      isLast={idx === Number(size(defaultData)) - 1}
                    />
                  </Col>
                ))}
              </React.Fragment>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default ProjectRoutingTableDefaultRecords;
