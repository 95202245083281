/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { collaborationApiv2 } from 'services';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { map, size } from 'lodash';
import { getSkeletonsSize, updateSkeletonsSize } from 'helpers/utils';
import { InvitationType } from 'models';
import { ProjectOriginInvitationsItem } from './ProjectOriginInvitationsItem';
import './ProjectOriginInvitations.module.scss';

interface ProjectOriginInvitationsProps {
  getPollingInterval: (time: number) => void;
  pollingIntervalTime: number;
}

export const ProjectOriginInvitations: FC<ProjectOriginInvitationsProps> = ({
  getPollingInterval,
  pollingIntervalTime,
}) => {
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const [skeletons] = useState(getSkeletonsSize('colInv', String(directoryName)));
  const { data: invitations, isLoading } = collaborationApiv2.useFetchInvitationsQuery(
    String(directoryName),
    {
      skip: !token,
      pollingInterval: pollingIntervalTime,
    }
  );

  useEffect(() => {
    if (invitations?.data) {
      if (size([...(invitations?.data as InvitationType[])])) {
        updateSkeletonsSize('colInv', String(directoryName), size([...(invitations?.data ?? [])]));
      }
    }
  }, [directoryName, invitations?.data]);

  useEffect(() => {
    if (invitations?.data) {
      const invUsers = invitations?.data.map((item) => item.status === 'pending');
      if (invUsers !== undefined && invUsers.length > 0) {
        getPollingInterval(1000);
      } else {
        getPollingInterval(0);
      }
    }
  }, [getPollingInterval, invitations?.data]);

  return (
    <div className="ProjectOriginCnames">
      <div className="ProjectOriginCnames__head">
        <Row justify="space-between" align="middle">
          <Col>
            <Row gutter={32} align="middle">
              <Col>
                <h4 className="ProjectOriginCnames__title">Invitations</h4>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Row className="ProjectOriginCnames__table-head">
        <Col span={8} className="ProjectOriginCnames__head-col">
          <span className="ProjectOriginCnames__head-label">User email</span>
        </Col>
        <Col span={8} className="ProjectOriginCnames__head-col">
          <span className="ProjectOriginCnames__head-label">Status</span>
        </Col>
      </Row>

      {isLoading ? (
        <>
          {Array.from({ length: skeletons }).map((i, indx) => (
            <Row key={String(i) + indx} style={{ borderBottom: '1px solid lightgray' }}>
              <Col span={24}>
                <Skeleton.Button
                  block
                  style={{ margin: '14px 24px', width: 'calc(100% - 50px)' }}
                  active
                  size="small"
                  key={String(i) + indx}
                />
              </Col>
            </Row>
          ))}
        </>
      ) : size(invitations?.data) === 0 ? (
        <Row key="0">
          <Col span={24}>
            <div style={{ margin: '14px 24px', color: 'gray', fontSize: 12, lineHeight: '24px' }}>
              You don&apos;t have any invitations yet
            </div>
          </Col>
        </Row>
      ) : (
        map(invitations?.data, (item) => (
          <ProjectOriginInvitationsItem
            getPollingInterval={getPollingInterval}
            data={item}
            key={item.id}
          />
        ))
      )}
    </div>
  );
};

export default ProjectOriginInvitations;
