/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { Icon } from 'components/common/Icon';
import { isEmpty } from 'lodash';
import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './InputWithTagsItem.module.scss';

interface IInputWithTagsItemProps {
  onChange: (v: string) => void;
  value: string;
  onRemoveClick?: () => void;
  setIsBackSpace: (b: boolean) => void;
  isBackSpace?: boolean;
  isHiddenDropDown?: boolean;
  dropDownRef: React.RefObject<HTMLDivElement>;
}

export const InputWithTagsItem: FC<IInputWithTagsItemProps> = ({
  onChange,
  value,
  onRemoveClick,
  isBackSpace,
  setIsBackSpace,
  isHiddenDropDown,
  dropDownRef,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);
  const [intermediateValue, setIntermediateValue] = useState('');
  const spanRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onLabelClick = useCallback(() => {
    setShowInput(true);
    setIntermediateValue(value);
  }, [value]);

  useLayoutEffect(() => {
    if (!value && onRemoveClick) onRemoveClick();
  }, [onRemoveClick, value]);

  useEffect(() => {
    if (isBackSpace) {
      onLabelClick();
    }
  }, [isBackSpace, onLabelClick, value]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        showInput &&
        spanRef.current &&
        !spanRef.current.contains(e.target as Node) &&
        // dropDownRef.current?.contains(e.target as Node) &&
        setIsBackSpace
      ) {
        setIsBackSpace(false);
        setShowInput(false);
        onChange(intermediateValue);
        setIntermediateValue('');
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [intermediateValue, onChange, setIsBackSpace, showInput, isHiddenDropDown, dropDownRef]);

  useEffect(() => {
    if (spanRef?.current) {
      setInputWidth(spanRef.current?.children[0].clientWidth);
    }
  }, [spanRef]);

  useEffect(() => {
    if (inputRef && showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  const colon = ':';
  const semiColon = ';';
  const hyphen = '-';
  const splitedValue = value.split(colon);
  const splitedUnionValue = value.split('(');
  const isExclude = value[0] === hyphen;

  return (
    <>
      <span className={styles.tag} ref={spanRef}>
        {!showInput && (
          <button type="button" onClick={onLabelClick} className={styles.label}>
            {value.includes(colon) ? (
              isExclude ? (
                <>
                  <span className={styles.tagColon}>{value[0]}</span>
                  <span className={styles.tagParam}>{splitedValue[0].replace(hyphen, '')}</span>
                  <span className={styles.tagColon}>{colon}</span>
                  <span className={styles.tagValue}>{splitedValue[1]}</span>
                </>
              ) : (
                <>
                  <span className={styles.tagParam}>{splitedValue[0]}</span>
                  <span className={styles.tagColon}>{colon}</span>
                  <span className={styles.tagValue}>{splitedValue[1]}</span>
                </>
              )
            ) : value.includes(semiColon) ? (
              <>
                <span className={styles.tagParam}>{splitedUnionValue[0]}</span>
                <span className={styles.tagColon}>(</span>
                <span className={styles.tagValue}>{splitedUnionValue[1].split(semiColon)[0]}</span>
                <span className={styles.tagColon}>{semiColon}</span>
                <span className={styles.tagValue}>
                  {splitedUnionValue[1].split(semiColon)[1].replace(')', '')}
                </span>
                <span className={styles.tagColon}>)</span>
              </>
            ) : (
              <span className={styles.tagValue}>{value}</span>
            )}
          </button>
        )}
        <input
          ref={inputRef}
          className={classNames(styles.tagInput, { [styles.active]: showInput })}
          value={intermediateValue}
          onKeyDown={(e) => {
            const val = intermediateValue.trim();
            if (e.code === 'Space' || e.code === 'Enter') {
              setIsBackSpace(false);
              setShowInput(false);
              onChange(val);
              setIntermediateValue(val);
              setIntermediateValue('');
              inputRef.current?.focus();
            }
            if (e.code === 'Backspace' && isEmpty(val) && onRemoveClick) {
              onRemoveClick();
            }
          }}
          onChange={(v) => setIntermediateValue(v.target.value.trim())}
          style={{ width: inputWidth }}
        />
        <Icon name="cross" className={styles.tagIcon} onClick={onRemoveClick} />
      </span>
    </>
  );
};

export default InputWithTagsItem;
