import React, { FC, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, Row, Col } from 'antd';
import { Input } from 'components/common/Input';
import Button from 'components/common/Button';
import { UserPaymentsDetailsPayload } from 'models';
import { Notification } from 'components/common/Notification';
import { useAppSelector } from 'store';
import Select from 'components/common/Select';
import { map } from 'lodash';
import countriesBilling from 'libs/countriesWithFlags.json';
import Divider from 'components/common/Divider';
import { ReactComponent as Stripe } from 'assets/svg/Stripe.svg';
import { Loader } from 'components/common/Loader';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeCardNumberElementOptions } from '@stripe/stripe-js';
import { paymentMethodsAPI, userDetailsApi } from 'services';
import classNames from 'classnames';
import { ReactComponent as Image } from 'assets/svg/PaymentEmptyImage.svg';
import styles from './UserSettingsBillingEmptyState.module.scss';

export const UserSettingsBillingEmptyState: FC = () => {
  const { token } = useAppSelector(({ auth }) => auth);
  const [settingsForm] = Form.useForm<UserPaymentsDetailsPayload>();
  const ref = useRef<HTMLButtonElement>(null);

  const [updateUserBillingDetails, { isLoading: isUpdateUserBillingDetailsLoading }] =
    userDetailsApi.useUpdateUserBillingDetailsMutation();

  const {
    data: intentData,
    isLoading,
    isError,
  } = paymentMethodsAPI.useFetchIntentQuery('', {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });
  const [createPaymentMethod] = paymentMethodsAPI.useCreatePaymentMethodsMutation();
  const stripe = useStripe();
  const elements = useElements();
  // const options = useOptions();
  const [loadingStripe, setLoadingStripe] = useState(false);

  const options: StripeCardNumberElementOptions = useMemo(
    () => ({
      classes: {
        base: styles.input,
        invalid: classNames(styles.input, styles.invalid),
        focus: classNames(styles.input, styles.focus),
      },
      style: {
        base: {
          backgroundColor: 'inherit',
          fontSize: '13px',
          fontWeight: '400',
          color: '#192a3e',
          fontFamily: 'inherit',
          '::placeholder': {
            color: '#90a0b7',
          },
        },
        invalid: {
          color: '#db5960',
        },
      },
      showIcon: true,
    }),
    []
  );

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const date = elements.getElement(CardExpiryElement);
      const cv = elements.getElement(CardCvcElement);

      if (cardElement) {
        setLoadingStripe(true);
        const { error, setupIntent } = await stripe.confirmCardSetup(
          String(intentData?.client_secret),
          {
            payment_method: {
              card: cardElement,
            },
          }
        );
        if (error) {
          Notification({ type: 'cross', title: 'Stripe error', message: error?.message });
          setLoadingStripe(false);
        }
        if (setupIntent) {
          createPaymentMethod(setupIntent.payment_method)
            .unwrap()
            .then((v) => {
              Notification({ type: 'check', title: 'Card added successfuly!', message: v.message });
              setLoadingStripe(false);
              cardElement.clear();
              date?.clear();
              cv?.clear();
            })
            .finally(() => setLoadingStripe(false));
        }
      }
    },
    [createPaymentMethod, elements, intentData?.client_secret, stripe]
  );

  const onFinishThirdStep = useCallback(
    (values: UserPaymentsDetailsPayload) => {
      updateUserBillingDetails(values)
        .unwrap()
        .then(() => {
          if (ref) {
            ref?.current?.click();
          }
        });
    },
    [updateUserBillingDetails]
  );

  useEffect(() => {
    if (isError) {
      Notification({ type: 'check', message: 'Something wrong with intent get' });
    }
  }, [isError]);
  return (
    <div className={styles.empty}>
      <Row>
        <Col span={12} className={styles.image}>
          <Image />
          <div className={styles.stripe}>
            <Stripe />
          </div>
        </Col>
        <Col span={12} className={styles.rightBlock}>
          <div className={styles.form}>
            <>
              {isLoading ? (
                <Row justify="center" style={{ height: 100 }} align="middle">
                  <Loader />
                </Row>
              ) : (
                <form onSubmit={handleSubmit}>
                  <h4 className={styles.cardTitle}>Add your billing details</h4>
                  <Row gutter={[16, 14]}>
                    <Col span={24}>
                      <label className={styles.label}>
                        <span className={styles.inputName}>Card Number</span>
                        <CardNumberElement
                          options={{ ...options, placeholder: 'XXXX XXXX XXXX XXXX' }}
                        />
                      </label>
                    </Col>
                    <Col span={24}>
                      <Row gutter={14}>
                        <Col span={12}>
                          <label className={styles.label}>
                            <span className={styles.inputName}>Expiration Date</span>
                            <CardExpiryElement options={options} />
                          </label>
                        </Col>
                        <Col span={12}>
                          <label className={styles.label}>
                            <span className={styles.inputName}>CVV</span>
                            <CardCvcElement options={{ ...options, placeholder: '***' }} />
                          </label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <button type="submit" style={{ display: 'none' }} ref={ref}>
                    submit
                  </button>
                </form>
              )}
            </>
            <Divider type="horizontal" />
            <Form form={settingsForm} onFinish={onFinishThirdStep}>
              <Row gutter={[24, 14]} align="bottom" style={{ marginBottom: 22 }}>
                <Col span={24}>
                  <Form.Item name="payer" noStyle>
                    <Input label="Card Owner" />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name="country" noStyle>
                    <Select
                      showSearch
                      label="Country"
                      data={map(countriesBilling, ({ code, name, image }) => ({
                        name,
                        value: code,
                        image,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name="city" noStyle>
                    <Input label="City" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="tax" noStyle>
                    <Input label="TAX / VAT" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} align="bottom" style={{ marginBottom: 22 }}>
                <Col span={10}>
                  <Form.Item name="address" noStyle>
                    <Input label="Address 1" />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name="address_2" noStyle>
                    <Input label="Address 2" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="zip_code" noStyle>
                    <Input label="Zip Code" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="HomeAddModal__footer">
                <Form.Item shouldUpdate noStyle>
                  {() => (
                    <Button
                      fluid
                      loading={isLoading || loadingStripe || isUpdateUserBillingDetailsLoading}
                      disabled={
                        !stripe ||
                        isError ||
                        !settingsForm.isFieldsTouched(
                          ['payer', 'country', 'city', 'address', 'zip_code'],
                          true
                        ) ||
                        !!settingsForm.getFieldsError().filter(({ errors }) => errors.length).length
                      }
                      submit>
                      Add card
                    </Button>
                  )}
                </Form.Item>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserSettingsBillingEmptyState;
