/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react';
import { IDeploymentDetailsResponse, IDeploymentsResponse } from 'models/deployments';
import { endpoints } from '../urls';
import { baseQuery } from './baseV2';

export const deplymentServiceAPIv2 = createApi({
  reducerPath: 'deplymentServicev2',
  baseQuery: baseQuery(),
  tagTypes: ['Deployments', 'DeploymentDetails'],
  endpoints: (build) => ({
    fetchAllDeployments: build.query<IDeploymentsResponse, string>({
      query: (prepertyId) => ({
        url: endpoints.v2.deployments.getAllDeployments(prepertyId),
      }),
      providesTags: () => ['Deployments'],
    }),
    fetchDeploymentDetails: build.query<IDeploymentDetailsResponse, string>({
      query: (prepertyId) => ({
        url: endpoints.v2.deployments.getDeploymentDetails(prepertyId),
      }),
      providesTags: () => ['DeploymentDetails'],
    }),
  }),
});

export default deplymentServiceAPIv2;
