/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Icon } from '../Icon';
import './SelectButton.scss';

export interface DataType {
  value: string;
  label?: string;
}

interface Props {
  disabled?: boolean;
  disabledClick?: boolean;
  color?: string;
  title?: string;
  label?: string;
  text?: string;
  withConnector?: boolean;
  data?: DataType[];
  onClick?: (item: string) => void;
  onClickRemoveIcon?: () => void;
  width?: string;
  isError?: boolean;
}

export const SelectButton: FC<Props> = ({
  disabled,
  disabledClick,
  color,
  title,
  label,
  text,
  withConnector,
  data,
  onClick,
  onClickRemoveIcon,
  width,
  isError,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dropDownListRef = useRef<HTMLUListElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [isButton, setIsButton] = useState(true);
  const [intermediateValue, setIntermediateValue] = useState('');
  const [placeholderData, setPlaceholderData] = useState('');
  const [currentData, setCurrentData] = useState(data);
  const [removeOnly, setRemoveOnly] = useState(false);

  useEffect(() => {
    const hoveredOut = () => {
      setRemoveOnly(false);
    };
    const hovered = () => {
      setRemoveOnly(true);
    };

    const currentDropDownRef = dropDownRef.current; // Зберігаємо поточне значення ref в змінну

    const removeIconElem = document.getElementsByClassName('SelectButton-removeIcon');
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (!isButton && currentDropDownRef && !currentDropDownRef.contains(e.target as Node)) {
        setIsButton(true);
        setIntermediateValue(placeholderData);
      }
    };

    if (removeIconElem.length > 0) {
      [...removeIconElem].forEach((link) => {
        link.addEventListener('mouseover', hovered);
      });
      [...removeIconElem].forEach((link) => {
        link.addEventListener('mouseleave', hoveredOut);
      });
    }
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      if (removeIconElem.length > 0) {
        [...removeIconElem].forEach((link) => {
          link.removeEventListener('mouseover', hovered);
        });
        [...removeIconElem].forEach((link) => {
          link.removeEventListener('mouseleave', hoveredOut);
        });
      }
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  });

  useEffect(() => {
    if (!isButton && inputRef) {
      inputRef.current?.focus();
    }
  });

  useEffect(() => {
    const curData = data?.filter((elem) => elem.value.includes(intermediateValue));
    setCurrentData(curData?.filter((elem) => elem.value !== placeholderData));
    title && setPlaceholderData(title);
  }, [data, intermediateValue, placeholderData, title]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      const dropdownList = dropDownListRef.current?.children;
      if (dropdownList && dropdownList.length > 0) {
        if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
          e.preventDefault();

          let currentIndex = 0;

          for (let i = 0; i < dropdownList.length; i += 1) {
            if (dropdownList[i].classList.contains('SelectButton-dropDown-item-active')) {
              currentIndex = i;
              break;
            }
          }

          if (e.code === 'ArrowUp') {
            if (currentIndex > 0) {
              dropdownList[currentIndex].classList.remove('SelectButton-dropDown-item-active');
              dropdownList[currentIndex - 1].classList.add('SelectButton-dropDown-item-active');

              const selectedElement = dropdownList[currentIndex - 1];
              const dropdownContainer = dropDownListRef.current;
              const selectedElementRect = selectedElement.getBoundingClientRect();
              const containerRect = dropdownContainer.getBoundingClientRect();

              if (selectedElementRect.top < containerRect.top) {
                dropdownContainer.scrollTop -= containerRect.top - selectedElementRect.top;
              }
            }
          }

          if (e.code === 'ArrowDown') {
            if (currentIndex < dropdownList.length - 1) {
              dropdownList[currentIndex].classList.remove('SelectButton-dropDown-item-active');
              dropdownList[currentIndex + 1].classList.add('SelectButton-dropDown-item-active');

              const selectedElement = dropdownList[currentIndex + 1];
              const dropdownContainer = dropDownListRef.current;
              const selectedElementRect = selectedElement.getBoundingClientRect();
              const containerRect = dropdownContainer.getBoundingClientRect();

              if (selectedElementRect.bottom > containerRect.bottom) {
                dropdownContainer.scrollTop += selectedElementRect.bottom - containerRect.bottom;
              }
            }
          }
        }
      }

      if (e.key === 'Enter') {
        if (dropdownList && dropdownList.length > 0) {
          const selectedValue = [...dropdownList]
            .filter((item) => item.classList.contains('SelectButton-dropDown-item-active'))[0]
            .getAttribute('data-value');

          setIntermediateValue(selectedValue || '');
          onClick && onClick(selectedValue || '');
          setIsButton(true);
        }
      }
      if (e.key === 'Escape') {
        setIsButton(true);
        setIntermediateValue(placeholderData);
      }
    },
    [onClick, placeholderData]
  );

  return (
    <>
      {withConnector && <span className="SelectButton-divider" />}
      <div
        className={cn('SelectButton', {
          _error: isError,
        })}>
        <div className="SelectButton-title-wrapper">{label}</div>
        {text && <div className="SelectButton-text-wrapper">{text}</div>}
        <div>
          <div className="SelectButton-button-wrapper">
            <button
              disabled={disabledClick}
              onClick={() => {
                setIsButton(false);
                setIntermediateValue('');
              }}
              className="SelectButton-button"
              type="button"
              // eslint-disable-next-line no-unneeded-ternary
              style={{ backgroundColor: color ? color : '#fff' }}>
              {placeholderData || title}
              {!disabled && (
                <>
                  {isError ? (
                    <Icon
                      name="expand-down"
                      className={cn('SelectButton-cross-icon')}
                      onClick={() => {
                        if (removeOnly) {
                          setIsButton(true);
                        }
                      }}
                    />
                  ) : (
                    <Icon
                      name="cross"
                      className={cn('SelectButton-cross-icon', 'SelectButton-removeIcon')}
                      onClick={() => {
                        if (removeOnly) {
                          // setIsButton(true);
                          onClickRemoveIcon && onClickRemoveIcon();
                        }
                      }}
                    />
                  )}
                </>
              )}
            </button>
          </div>

          {!isButton && !disabled && (
            <div
              ref={dropDownRef}
              className="SelectButton-dropDown-wrapper"
              style={{ width: width || '300px' }}>
              <input
                placeholder={placeholderData}
                ref={inputRef}
                className="SelectButton-input"
                value={intermediateValue}
                onChange={(v) => {
                  setIntermediateValue(v.target.value.trim());
                }}
                onKeyDown={handleKeyDown}
              />
              <Icon
                name="cross"
                className={cn('SelectButton-input-cross-icon', 'SelectButton-removeIcon')}
                onClick={() => {
                  if (removeOnly) {
                    setIsButton(true);
                    setIntermediateValue(placeholderData);
                    onClickRemoveIcon && onClickRemoveIcon();
                  }
                }}
              />

              <ul ref={dropDownListRef} className="SelectButton-dropDown">
                {currentData &&
                  currentData.map((item, idx) => (
                    <li
                      className="SelectButton-dropDown-item"
                      key={item.value}
                      data-value={item.value}
                      onClick={() => {
                        setIntermediateValue(item.value);
                        setPlaceholderData(item.value);
                        setIsButton(true);
                        onClick && onClick(item.value);
                      }}
                      tabIndex={idx}>
                      {item.value}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectButton;
