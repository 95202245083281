/* eslint-disable no-nested-ternary */
import { Col, Row } from 'antd';
import classNames from 'classnames';
import Dropdown from 'components/common/Dropdown';
import { Icon, IconType } from 'components/common/Icon';
import { includes, isNil, map } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { generatePath, matchRoutes, NavLink, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store';
import styles from './MainLayoutSubroute.module.scss';

interface Props {
  data: { to: string; name: string; end?: boolean; icon?: IconType }[];
  icon: IconType;
  name: string;
  directoryName: string;
  toUrl?: string;
  isEnd?: boolean;
}

export const MainLayoutSubroute: FC<Props> = ({
  data,
  icon,
  name,
  directoryName,
  toUrl,
  isEnd,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { isMenuColapsed } = useAppSelector(({ ui }) => ui);
  const location = useLocation();
  const route = matchRoutes(
    map(data, ({ to }) => ({ path: to })),
    location.pathname
  );
  useEffect(() => {
    if (!isNil(route) && !isExpanded) {
      const { path } = route[0].route;
      setIsExpanded(
        includes(
          map(data, ({ to }) => to),
          path
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div>
      {isMenuColapsed ? (
        <Dropdown
          isSmall
          placement="right"
          trigger={['hover']}
          triggerElement={
            toUrl ? (
              <button type="button" className={classNames(styles.trigger, styles.route)}>
                <NavLink
                  to={generatePath(toUrl, { directoryName: directoryName || '' })}
                  end={isEnd}
                  className={({ isActive }) =>
                    classNames(styles.route, { [styles.active]: isActive })
                  }>
                  <Icon name={icon} className={styles.routeIcon} />
                </NavLink>
              </button>
            ) : (
              <div style={{ paddingBottom: '5px' }}>
                <button type="button" className={classNames(styles.trigger, styles.route)}>
                  <Icon name={icon} className={styles.routeIcon} />
                </button>
              </div>
            )
          }>
          <div className={classNames(styles.subroutes, styles.collapsed)}>
            <Row className={styles.subrouts}>
              {map(data, ({ name: subName, to: subTo, end: subEnd }) => (
                <Col key={subTo} span={24} style={{ padding: '5px 0px' }}>
                  <NavLink
                    to={generatePath(subTo, { directoryName: directoryName || '' })}
                    end={subEnd}
                    className={({ isActive }) =>
                      classNames(styles.route, { [styles.active]: isActive })
                    }>
                    {subName}
                  </NavLink>
                </Col>
              ))}
            </Row>
          </div>
        </Dropdown>
      ) : toUrl ? (
        <div style={{ padding: '5px 0px' }}>
          <button type="button" className={styles.route} onClick={() => setIsExpanded(!isExpanded)}>
            <NavLink
              to={generatePath(toUrl, { directoryName: directoryName || '' })}
              end={isEnd}
              className={({ isActive }) => {
                // setIsExpanded(isActive);
                return classNames(styles.route, { [styles.active]: isActive });
              }}>
              <div className={styles.col}>
                <Icon name={icon} className={styles.routeIcon} />
                {name}
              </div>
              <Icon
                name="expand-down"
                className={classNames(
                  styles.routeIcon,
                  // { [styles.expanded]: true }
                  { [styles.expanded]: isExpanded }
                )}
              />
            </NavLink>
          </button>
        </div>
      ) : (
        <div style={{ paddingBottom: '5px' }}>
          <button type="button" className={styles.route} onClick={() => setIsExpanded(!isExpanded)}>
            <div className={styles.col}>
              <Icon name={icon} className={styles.routeIcon} />
              <span style={{ paddingLeft: 8 }}>{name}</span>
            </div>
            <Icon
              name="expand-down"
              style={{ position: 'absolute', right: '8px' }}
              className={classNames(styles.routeIcon, { [styles.expanded]: isExpanded })}
            />
          </button>
        </div>
      )}
      {isExpanded && !isMenuColapsed && (
        <div className={classNames(styles.subroutes)}>
          <Row className={styles.subrouts}>
            {map(data, ({ name: subName, to: subTo, end: subEnd, icon: subIcon }) => (
              <Col key={subTo} span={24}>
                <div style={{ padding: '5px 0px' }}>
                  <NavLink
                    to={generatePath(subTo, { directoryName: directoryName || '' })}
                    end={subEnd}
                    className={({ isActive }) => {
                      // if (isActive) {
                      //   setIsExpanded(true);
                      // }
                      return classNames(styles.route, { [styles.active]: isActive });
                    }}>
                    {toUrl ? (
                      <div className={styles.col}>
                        <Icon name={subIcon} className={styles.routeIcon} />
                        {subName}
                      </div>
                    ) : (
                      <>
                        <Icon name={subIcon} className={styles.routeIcon} />
                        <span style={{ paddingLeft: 8 }}>{subName}</span>
                      </>
                    )}
                  </NavLink>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default MainLayoutSubroute;
