import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPropertySecurityProfilesUpdate } from '../../models/service';

export interface ISecurityProfilesState {
  hostnames: IPropertySecurityProfilesUpdate[];
}

const initialState: ISecurityProfilesState = {
  hostnames: [],
};

export const securityProfiles = createSlice({
  name: 'securityProfiles',
  initialState,
  reducers: {
    // updatePropertyDetails: (state, action: PayloadAction<ISecurityProfilesState>) => {
    //   state.origins = action.payload.origins;
    //   state.hostnames = action.payload.hostnames;
    //   state.tls = action.payload.tls;
    //   state.rules = action.payload.rules;
    //   state.dns_domain_name = action.payload.dns_domain_name;
    //   state.name = action.payload.name;
    //   state.slug = action.payload.slug;
    //   state.status = action.payload.status;
    //   state.created_at = action.payload.created_at;
    //   state.environment_id = action.payload.environment_id;
    // },
    updateSecurity: (state, action: PayloadAction<IPropertySecurityProfilesUpdate[]>) => {
      state.hostnames = action.payload;
    },
  },
});

export const { updateSecurity } = securityProfiles.actions;

export default securityProfiles.reducer;
