/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import {
  IField,
  inputNameObject,
  inputValueObject,
  selectMatchObject,
  selectMethodObject,
  switchObject,
} from './fieldsObjects';

export const clientIpAddressLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp:
      /(([A-Fa-f0-9]{1,4}:){7,7}(:|[A-Fa-f0-9]{1,4})(\[0-9]{1,3})?|([A-Fa-f0-9]{1,4}:){1,3}(:[A-Fa-f0-9]{1,4}){1,4}(\[0-9]{1,3})?|([A-Fa-f0-9]{1,4}:){1,6}:[A-Fa-f0-9]{1,4}(\[0-9]{1,3})?|([A-Fa-f0-9]{1,4}:){1,5}:([A-Fa-f0-9]{1,4}:)?[A-Fa-f0-9]{1,4}(\[0-9]{1,3})?|([A-Fa-f0-9]{1,4}:){1,4}:([A-Fa-f0-9]{1,4}:){0,2}[A-Fa-f0-9]{1,4}(\[0-9]{1,3})?|([A-Fa-f0-9]{1,4}:){1,3}:([A-Fa-f0-9]{1,4}:){0,3}[A-Fa-f0-9]{1,4}(\[0-9]{1,3})?|([A-Fa-f0-9]{1,4}:){1,2}:([A-Fa-f0-9]{1,4}:){0,4}[A-Fa-f0-9]{1,4}(\[0-9]{1,3})?|[A-Fa-f0-9]{1,4}::([A-Fa-f0-9]{1,4}:){0,5}[A-Fa-f0-9]{1,4}(\[0-9]{1,3})?|::([A-Fa-f0-9]{1,4}:){0,6}[A-Fa-f0-9]{1,4}(\[0-9]{1,3})?|(\d{1,3}\.){3}\d{1,3}(\\d{1,2})?)/,
  },
];

export const cookieLiteral: IField[] = [
  {
    id: 0,
    ...inputNameObject,
    regexp: /[-_\.a-zA-Z0-9]+/,
  },
  {
    id: 1,
    ...selectMatchObject,
  },
  {
    id: 2,
    ...inputValueObject,
    regexp: /([-@_a-zA-Z0-9\~%:;=!,\.\+\*'\s\\]+|\?)/,
  },
  {
    id: 3,
    ...switchObject,
  },
];

export const cookieRegex: IField[] = [
  {
    id: 0,
    ...inputNameObject,
    regexp: /[-_\.a-zA-Z0-9]+/,
  },
  {
    id: 1,
    ...selectMatchObject,
  },
  {
    id: 2,
    ...inputValueObject,
    regexp: /.+/,
  },
  {
    id: 3,
    ...switchObject,
  },
];

export const cookieWildcard: IField[] = [
  {
    id: 0,
    ...inputNameObject,
    regexp: /[-_\.a-zA-Z0-9]+/,
  },
  {
    id: 1,
    ...selectMatchObject,
  },
  {
    id: 2,
    ...inputValueObject,
    regexp: /[-@_a-zA-Z0-9\~%:;=!,\.\+\*'\\ ]+/,
  },
  {
    id: 3,
    ...switchObject,
  },
];

export const domainLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /[-@_a-zA-Z0-9\~%:;=!,\.\+\*'\\ ]+/,
  },
  {
    id: 2,
    ...switchObject,
  },
];

export const domainWildcard: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputValueObject,
    regexp: /[-@_a-zA-Z0-9\~%:;=!,\.\+\*'\\ ]+/,
  },
  {
    id: 2,
    ...switchObject,
  },
];

export const headerLiteral: IField[] = [
  {
    id: 0,
    ...inputNameObject,
    regexp: /[-_\.a-zA-Z0-9]+/,
  },
  {
    id: 1,
    ...selectMatchObject,
  },
  {
    id: 2,
    ...inputValueObject,
    regexp: /([-@_a-zA-Z0-9\~%:;=!,\.\+\*'\s\\]+|\?)/,
  },
  {
    id: 3,
    ...switchObject,
  },
];

export const headerRegex: IField[] = [
  {
    id: 0,
    ...inputNameObject,
    regexp: /[-_\.a-zA-Z0-9]+/,
  },
  {
    id: 1,
    ...selectMatchObject,
  },
  {
    id: 2,
    ...inputValueObject,
    regexp: /.+/,
  },
  {
    id: 3,
    ...switchObject,
  },
];

export const headerWildcard: IField[] = [
  {
    id: 0,
    ...inputNameObject,
    regexp: /[-_\.a-zA-Z0-9]+/,
  },
  {
    id: 1,
    ...selectMatchObject,
  },
  {
    id: 2,
    ...inputValueObject,
    regexp: /[-@_a-zA-Z0-9\~%:;=!,\.\+\*'\\ ]+/,
  },
  {
    id: 3,
    ...switchObject,
  },
];

export const methodLiteral: IField[] = [
  {
    id: 0,
    ...selectMethodObject,
  },
];

export const schemeLiteral: IField[] = [
  {
    id: 0,
    ...selectMethodObject,
  },
];


