/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IPropertyResponse,
  IPropertyPayload,
  IPropertyPurgePayload,
  IPropertyUpdatePayload,
  IPropertyPurgeResponse,
  ChangeOwnerResponse,
  IPropertySecurityResponse,
  IPropertySecurityConfigurationData,
  IPropertySSLResponse,
  IPropertyCertificatesResponse,
} from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './baseV2';

export const propertyAPI = createApi({
  reducerPath: 'propertyAPI',
  baseQuery: baseQuery(),
  tagTypes: ['Service', 'ServiceDetails', 'AplicationTypes', 'ChangeOwnerUsers'],
  endpoints: (build) => ({
    fetchAllProperties: build.query<any, any>({
      query: () => ({
        url: endpoints.v2.properties.properties,
      }),
      providesTags: () => ['Service'],
    }),
    fetchProperty: build.query<IPropertyResponse, string>({
      query: (propertyId) => ({
        url: endpoints.v2.properties.property(propertyId),
      }),
      providesTags: ['ServiceDetails'],
    }),
    createProperty: build.mutation<IPropertyResponse, IPropertyPayload>({
      query: (service) => ({
        url: endpoints.v2.properties.properties,
        method: 'POST',
        body: service,
      }),
      invalidatesTags: ['Service'],
    }),
    deleteProperty: build.mutation<IPropertyResponse, string>({
      query: (propertyId) => ({
        url: endpoints.v2.properties.property(propertyId),
        method: 'DELETE',
      }),
      invalidatesTags: ['Service'],
    }),
    updateProperty: build.mutation<
      IPropertyResponse,
      {
        propertyId: string;
        payload: IPropertyUpdatePayload;
      }
    >({
      query: ({ propertyId, payload }) => ({
        url: endpoints.v2.properties.property(propertyId),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Service'],
    }),
    purgeAllProperty: build.mutation<
      IPropertyResponse,
      {
        propertyId: string;
        payload: IPropertyPurgePayload;
      }
    >({
      query: ({ propertyId, payload }) => ({
        url: endpoints.v2.properties.purgeAllProperty(propertyId),
        method: 'POST',
        body: payload,
      }),
    }),
    purgeUrlProperty: build.mutation<
      IPropertyResponse,
      {
        propertyId: string;
        payload: IPropertyPurgePayload;
      }
    >({
      query: ({ propertyId, payload }) => ({
        url: endpoints.v2.properties.purgeCustomProperty(propertyId),
        method: 'POST',
        body: payload,
      }),
    }),
    // genereteSSL: build.mutation<IPropertyResponse, string>({
    //   query: (propertyId) => ({
    //     url: endpoints.v2.generateSslProperty(propertyId),
    //     method: 'PUT',
    //   }),
    // }),
    fetchDomains: build.query<IPropertySSLResponse, string>({
      query: (propertyId) => ({
        url: endpoints.v2.properties.domainsTLSProperty(propertyId),
      }),
    }),
    changeOwner: build.mutation<ChangeOwnerResponse, { propertyId: string; id: string }>({
      query: ({ propertyId, id }) => ({
        url: endpoints.v2.properties.changeOwner(propertyId),
        method: 'POST',
        body: {
          user_id: id,
        },
      }),
      invalidatesTags: ['ChangeOwnerUsers'],
    }),
    purgeAll: build.mutation<IPropertyPurgeResponse, { propertyId: string; url: string[] }>({
      query: ({ propertyId, url }) => ({
        url: endpoints.v2.properties.purgeAllProperty(propertyId),
        method: 'POST',
        body: {
          url,
        },
      }),
    }),
    purgeCustom: build.mutation<IPropertyPurgeResponse, { propertyId: string; url: string[] }>({
      query: ({ propertyId, url }) => ({
        url: endpoints.v2.properties.purgeCustomProperty(propertyId),
        method: 'POST',
        body: {
          url,
        },
      }),
    }),
    genereteTLS: build.mutation<any, string>({
      query: (propertyId) => ({
        url: endpoints.v2.properties.generateTlsCertificates(propertyId),
        method: 'PUT',
      }),
    }),
    uploadTLS: build.mutation<
      any,
      {
        propertyId: string;
        primary_cert: string;
        intermediate_cert: string;
        private_key: string;
      }
    >({
      query: ({ propertyId, ...rest }) => ({
        url: endpoints.v2.properties.uploadTLSCertificate(propertyId),
        method: 'POST',
        body: { ...rest },
      }),
    }),
    fetchTLSCerificates: build.query<IPropertyCertificatesResponse, string>({
      query: (propertyId) => ({
        url: endpoints.v2.properties.allTlsCertificates(propertyId),
      }),
    }),
    updateSecurity: build.mutation<
      IPropertySecurityResponse,
      { propertyId: string; payload: IPropertySecurityConfigurationData }
    >({
      query: ({ propertyId, payload }) => ({
        url: endpoints.v2.properties.updateSecurityProfile(propertyId),
        method: 'POST',
        body: payload,
      }),
    }),
    getHistoryOfSecurity: build.query<IPropertySecurityResponse, string>({
      query: (propertyId) => ({
        url: endpoints.v2.properties.securityProfile(propertyId),
      }),
      providesTags: ['ServiceDetails'],
    }),
  }),
});

export default propertyAPI;
