import classNames from 'classnames';
import { Icon } from 'components/common/Icon';
import { isEmpty } from 'lodash';
import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './InputWithTagsItemOperators.module.scss';

interface IInputWithTagsItemOperatorsProps {
  onChange: (v: string) => void;
  value: string;
  onRemoveClick?: () => void;
  setIsBackSpace: (b: boolean) => void;
  setErrorIcon: (b: boolean) => void;
  isBackSpace?: boolean;
  isHiddenDropDown?: boolean;
  errorIcon: boolean;
}

export const InputWithTagsItemOperators: FC<IInputWithTagsItemOperatorsProps> = ({
  onChange,
  value,
  onRemoveClick,
  isBackSpace,
  setIsBackSpace,
  errorIcon,
  setErrorIcon,
  isHiddenDropDown,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);
  const [intermediateValue, setIntermediateValue] = useState('');
  const spanRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onLabelClick = useCallback(() => {
    setShowInput(true);
    setIntermediateValue(value);
  }, [value]);

  useEffect(() => {
    if (isBackSpace) {
      onLabelClick();
    }
  }, [isBackSpace, onLabelClick, value]);

  useLayoutEffect(() => {
    if (!value && onRemoveClick) onRemoveClick();
  }, [onRemoveClick, value]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        showInput &&
        spanRef.current &&
        !spanRef.current.contains(e.target as Node) &&
        setIsBackSpace &&
        !isHiddenDropDown
      ) {
        setIsBackSpace(false);
        setShowInput(false);
        onChange(intermediateValue);
        setIntermediateValue('');
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [intermediateValue, onChange, setIsBackSpace, showInput, isHiddenDropDown]);

  useEffect(() => {
    if (spanRef?.current) {
      setInputWidth(spanRef.current?.children[0].clientWidth);
    }
  }, [spanRef]);

  useEffect(() => {
    if (inputRef && showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  const handleRemoveErrorIcon = useCallback(() => {
    if (onRemoveClick) {
      setErrorIcon(false);
      onRemoveClick();
    }
  }, [setErrorIcon, onRemoveClick]);

  return (
    <span className={styles.tag} ref={spanRef}>
      {!showInput && (
        <>
          <button type="button" onClick={onLabelClick} className={styles.label}>
            {value}
          </button>
          {errorIcon && (
            <Icon
              name="cross-in-circle"
              className={styles.errorIcon}
              onClick={handleRemoveErrorIcon}
            />
          )}
        </>
      )}
      <input
        ref={inputRef}
        className={classNames(styles.tagInput, { [styles.active]: showInput })}
        value={intermediateValue}
        onKeyDown={(e) => {
          if (e.code === 'Space' || e.code === 'Enter') {
            setIsBackSpace(false);
            setShowInput(false);
            onChange(intermediateValue);
            setIntermediateValue(intermediateValue);
            setIntermediateValue('');
            inputRef.current?.focus();
          }
          if (e.code === 'Backspace' && isEmpty(intermediateValue)) {
            handleRemoveErrorIcon();
          }
        }}
        onChange={(v) => setIntermediateValue(v.target.value.trim())}
        style={{ width: inputWidth }}
      />
    </span>
  );
};

export default InputWithTagsItemOperators;
