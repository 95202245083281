import React, { FC, useCallback, useMemo } from 'react';
import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from 'react-fusioncharts';
import cn from 'classnames';
import moment from 'moment';
import { size } from 'lodash';
import { Bucket, DataModelHistogramLogsItem, DynamicKeyData } from 'models';
import styles from './ProjectLogsHistogramChart.module.scss';

charts(FusionCharts);

interface ChartSeries {
  seriesname: string;
  data: { value: number }[];
  totalSum?: number;
}

export const ProjectLogsHistogramChart: FC<{
  data: DataModelHistogramLogsItem[];
  groupByParams: string[];
  isLoading: boolean;
}> = ({ data, groupByParams, isLoading }) => {
  const sortedData = useMemo(() => {
    const sortByKey = (array: DataModelHistogramLogsItem[]) =>
      [...array].sort((a, b) => a.key - b.key);
    return sortByKey(data);
  }, [data]);

  const uniqueKeys = useMemo(() => {
    const extractUniqueKeys = (
      arr: DataModelHistogramLogsItem[] | Bucket[],
      depth = 0,
      parentKey = 'first'
    ): string[] => {
      const keys = new Set<string>();

      arr.forEach((item) => {
        if (depth < groupByParams.length) {
          const dynamicData = item[groupByParams[depth]] as DynamicKeyData;
          dynamicData?.buckets.forEach((bucket: Bucket) => {
            const bucketKey = bucket.key || ``;
            const currentKey =
              parentKey === 'first' ? `${bucketKey}` : `${parentKey} > ${bucketKey}`;

            if (bucket[groupByParams[depth + 1]]) {
              const nestedKeys = extractUniqueKeys([bucket], depth + 1, currentKey);
              nestedKeys.forEach((key) => keys.add(key));
            } else {
              keys.add(currentKey);
            }
          });
        }
      });

      return Array.from(keys);
    };

    return extractUniqueKeys(sortedData);
  }, [sortedData, groupByParams]);

  const aggregateDataForChart = useCallback(
    (chartData: DataModelHistogramLogsItem[], keys: string[]): ChartSeries[] => {
      const aggregateData = (
        dataX: DataModelHistogramLogsItem | Bucket,
        keyParts: string[],
        index: number,
        path = ''
      ): { value: number } => {
        if (index >= keyParts.length) {
          return { value: dataX.aggregate_doc_count?.value || 0 };
        }
        const keyPart = keyParts[index];
        const dynamicData = (dataX as DataModelHistogramLogsItem | Bucket)[
          groupByParams[index]
        ] as DynamicKeyData;
        const foundBucket = dynamicData?.buckets.find((bucket: Bucket) => bucket.key === keyPart);
        if (!foundBucket) {
          return { value: 0 };
        }
        const newPath = path ? `${path} > ${keyPart}` : keyPart;
        return aggregateData(foundBucket, keyParts, index + 1, newPath);
      };
      if (keys.length === 0) {
        return chartData.map((item) => ({
          seriesname: '',
          data: [{ value: item.aggregate_doc_count.value }],
        }));
      }
      const datasetMap: Record<string, ChartSeries> = {};
      keys.forEach((element) => {
        const keyParts = element.split(' > ');
        const seriesLabel =
          element
            .split(' > ')
            .map((keyPart) => (!keyPart ? 'Undefined' : keyPart))
            .join(' > ') || 'Undefined';

        datasetMap[element] = {
          seriesname: seriesLabel,
          data: [],
        };
        chartData.forEach((item) => {
          const aggregatedValue = aggregateData(item, keyParts, 0);
          datasetMap[element].data.push(aggregatedValue);
        });
      });
      return Object.values(datasetMap);
    },
    [groupByParams]
  );

  const curData = useMemo(() => {
    return aggregateDataForChart(sortedData, uniqueKeys);
  }, [aggregateDataForChart, sortedData, uniqueKeys]);

  // eslint-disable-next-line consistent-return
  const chartConfig = useMemo(() => {
    if (curData.length > 0)
      return {
        type: 'stackedcolumn2d',
        renderAt: 'chart-container',
        width: '100%',
        height: '390',
        dataFormat: 'json',

        dataSource: {
          chart: {
            alignLegendWithCanvas: '0',
            minimiseWrappingInLegend: '0',
            animationDuration: 0.3,
            plotHoverEffect: '1',
            plotHighlightEffect: 'fadeout',
            legendItemFontSize: '12',
            baseFontColor: '#90A0B7',
            baseFontSize: '10',
            toolTipBGColor: '#fff',
            toolTipBorderColor: '#fff',
            toolTipBorderRadius: '4',
            toolTipColor: '#445A84',
            canvasbgColor: '#cddce7,#ffffff',
            canvasbgAlpha: '15',
            canvasBgAngle: '90',
            showToolTipShadow: 1,
            numDivLines: 0,
            xAxisValuesStep: size(sortedData) / 20,
            showSum: '0',
            theme: 'fusion',
            plotToolText: `<div style="padding: 4px"><strong>$seriesname: $dataValue</strong><br>$label</div>`,
          },

          categories: [
            {
              category: sortedData.map((i) => ({
                label: moment(i.key_as_string).format('YYYY-MM-DD HH:mm:ss'),
              })),
            },
          ],
          dataset:
            groupByParams.length < 1
              ? [{ data: sortedData.map((item) => ({ value: item.aggregate_doc_count.value })) }]
              : curData,
        },
      };
  }, [curData, groupByParams.length, sortedData]);

  return (
    <div
      className={cn(styles.wrapperChart, {
        [styles.origin]: !isLoading,
        [styles.grayed]: isLoading,
      })}>
      {chartConfig && <ReactFusioncharts {...chartConfig} />}
    </div>
  );
};

export default ProjectLogsHistogramChart;
