/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import { Col, Collapse, Row } from 'antd';
import { IShields } from 'models';
import { SelectValue } from 'antd/lib/select';
import Select from 'components/common/Select';
import { apacOptions, emeaOptions, usEastOptions, usWestOptions } from './shieldOptions';
import './HomeAddModalNew.scss';

const { Panel } = Collapse;

const Shields = ({
  shields,
  onChangeShields,
}: {
  shields: IShields;
  onChangeShields: (data: IShields) => void;
}) => {
  const [apacValue, setApacValue] = useState('');
  const [emeaValue, setEmeaValue] = useState('');
  const [usWestValue, setUsWestValue] = useState('');
  const [usEastValue, setUsEastValue] = useState('');

  useEffect(() => {
    if (shields.apac && shields.emea && shields.us_west && shields.us_east) {
      setApacValue(shields.apac ?? 'bypass');
      setEmeaValue(shields.emea ?? 'bypass');
      setUsEastValue(shields.us_east ?? 'bypass');
      setUsWestValue(shields.us_west ?? 'bypass');
    }
  }, [shields.apac, shields.emea, shields.us_east, shields.us_west]);

  const onChangeApac = (value: SelectValue) => {
    setApacValue(value as string);
    const newShields = {
      apac: value as string,
      emea: emeaValue,
      us_east: usEastValue,
      us_west: usWestValue,
    };
    onChangeShields(newShields);
  };
  const onChangeEmea = (value: SelectValue) => {
    setEmeaValue(value as string);
    const newShields = {
      apac: apacValue,
      emea: value as string,
      us_east: usEastValue,
      us_west: usWestValue,
    };
    onChangeShields(newShields);
  };
  const onChangeUsWest = (value: SelectValue) => {
    setUsWestValue(value as string);
    const newShields = {
      apac: apacValue,
      emea: emeaValue,
      us_east: usEastValue,
      us_west: value as string,
    };
    onChangeShields(newShields);
  };
  const onChangeUsEast = (value: SelectValue) => {
    setUsEastValue(value as string);
    const newShields = {
      apac: apacValue,
      emea: emeaValue,
      us_east: value as string,
      us_west: usWestValue,
    };
    onChangeShields(newShields);
  };

  return (
    <Collapse collapsible="header" defaultActiveKey={['0']}>
      <Panel header="Shields" key="1">
        <Row align="middle" gutter={16}>
          <Col span={12}>
            <Select
              label="APAC"
              placeholder="Bypass"
              value={apacValue}
              data={apacOptions}
              width="100%"
              onChange={onChangeApac}
            />
          </Col>
          <Col span={12}>
            <Select
              label="EMEA"
              placeholder="Bypass"
              value={emeaValue}
              data={emeaOptions}
              width="100%"
              onChange={onChangeEmea}
            />
          </Col>
        </Row>
        <Row className="shieldsDivider" />
        <Row align="middle" gutter={16}>
          <Col span={12}>
            <Select
              label="US West"
              placeholder="Bypass"
              value={usWestValue}
              data={usWestOptions}
              width="100%"
              onChange={onChangeUsWest}
            />
          </Col>
          <Col span={12}>
            <Select
              label="US East"
              placeholder="Bypass"
              value={usEastValue}
              data={usEastOptions}
              width="100%"
              onChange={onChangeUsEast}
            />
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default Shields;
