import { ButtonType } from 'components/common/SectorButton';
import moment from 'moment';
import { CustomPeriodInLogsPage } from 'store/slices/project';

export const LIST = 'list';
export const HISTOGRAM = 'histogram';
export const PIE = 'pie';

export const bindingTags = ['or', '@or', 'and', '@and'];

export const customDropdownLogsValues: { name: string; value: CustomPeriodInLogsPage }[] = [
  { name: 'Last 15 Minutes', value: CustomPeriodInLogsPage.M15 },
  { name: 'Last 30 Minutes', value: CustomPeriodInLogsPage.M30 },
  { name: 'Last 60 Minutes', value: CustomPeriodInLogsPage.M60 },
  { name: 'Last 3 Hours', value: CustomPeriodInLogsPage.H3 },
  { name: 'Last 6 Hours', value: CustomPeriodInLogsPage.H6 },
  { name: 'Last 24 Hours', value: CustomPeriodInLogsPage.H24 },
  { name: 'Last 7 Days', value: CustomPeriodInLogsPage.D7 },
  { name: 'Last 30 Days', value: CustomPeriodInLogsPage.D30 },
  { name: 'Last 90 Days', value: CustomPeriodInLogsPage.D90 },
];

export const customDropdownValues = [
  {
    name: 'Last 15 Minutes',
    value: () => [moment().subtract(15, 'minutes'), moment()],
    label: CustomPeriodInLogsPage.M15,
    display: true,
    active: true,
  },
  {
    name: 'Last 30 Minutes',
    value: () => [moment().subtract(30, 'minutes'), moment()],
    label: CustomPeriodInLogsPage.M30,
    display: true,
    active: false,
  },
  {
    name: 'Last 60 Minutes',
    value: () => [moment().subtract(60, 'minutes'), moment()],
    label: CustomPeriodInLogsPage.M60,
    display: true,
    active: false,
  },
  {
    name: 'Last 3 Hours',
    value: () => [moment().subtract(3, 'hours'), moment()],
    label: CustomPeriodInLogsPage.H3,
    display: true,
    active: false,
  },
  {
    name: 'Last 6 Hours',
    value: () => [moment().subtract(6, 'hours'), moment()],
    label: CustomPeriodInLogsPage.H6,
    display: true,
    active: false,
  },
  {
    name: 'Last 24 Hours',
    value: () => [moment().subtract(1, 'days'), moment()],
    label: CustomPeriodInLogsPage.H24,
    display: true,
    active: false,
  },
  {
    name: 'Last 7 Days',
    value: () => [moment().subtract(7, 'days'), moment()],
    label: CustomPeriodInLogsPage.D7,
    display: true,
    active: false,
  },
  {
    name: 'Last 30 Days',
    value: () => [moment().subtract(30, 'days'), moment()],
    label: CustomPeriodInLogsPage.D30,
    display: true,
    active: false,
  },
  {
    name: 'Last 90 Days',
    value: () => [moment().subtract(90, 'days'), moment()],
    label: CustomPeriodInLogsPage.D90,
    display: true,
    active: false,
  },
  {
    name: 'Custom Range',
    value: () => [moment(), moment()],
    label: CustomPeriodInLogsPage.NULL,
    display: false,
    active: false,
  },
];

export const eventsTypeButtonData: ButtonType[] = [
  { label: 'Delivery' },
  { label: 'WAF' },
  { label: 'Rate limiting' },
  { label: 'Bot Management' },
];

export const vievButtonData: ButtonType[] = [
  { label: 'List', icon: 'list' },
  {
    label: 'Timeseries',
    icon: 'linechart',
  },
  {
    label: 'Pie Chart',
    icon: 'pie',
  },
];

export const playButtonData: ButtonType[] = [
  {
    icon: 'slow',
  },
  {
    icon: 'auto-renew',
  },
  {
    icon: 'forward',
  },
];

export const customRangeBtns: ButtonType[] = [
  {
    icon: 'line-arrow-left',
  },
  {
    icon: 'cross-in-circle',
  },
];

export const fieldOptions = [
  {
    value: 'client_ip',
    label: 'client_ip',
  },
  {
    value: 'client_city',
    label: 'client_city',
  },
  {
    value: 'client_country_code',
    label: 'client_country_code',
  },
  {
    value: 'cache_status',
    label: 'cache_status',
  },
  {
    value: 'client_as_org',
    label: 'client_as_org',
  },
  {
    value: 'client_isp',
    label: 'client_isp',
  },
  {
    value: 'user_agent',
    label: 'user_agent',
  },
  {
    value: 'host',
    label: 'host',
  },
  {
    value: 'method',
    label: 'method',
  },
  {
    value: 'path',
    label: 'path',
  },
  {
    value: 'pop',
    label: 'pop',
  },
];
export const groupByOptions = [
  {
    value: 'client_ip',
    label: 'client_ip',
  },
  {
    value: 'client_city',
    label: 'client_city',
  },
  {
    value: 'client_country_code',
    label: 'client_country_code',
  },
  {
    value: 'cache_status',
    label: 'cache_status',
  },
  {
    value: 'client_as_org',
    label: 'client_as_org',
  },
  {
    value: 'client_asn',
    label: 'client_asn',
  },
  {
    value: 'client_isp',
    label: 'client_isp',
  },
  {
    value: 'user_agent',
    label: 'user_agent',
  },
  {
    value: 'status_code',
    label: 'status_code',
  },
  {
    value: 'host',
    label: 'host',
  },
  {
    value: 'method',
    label: 'method',
  },

  {
    value: 'total_time',
    label: 'total_time',
  },
  {
    value: 'waf_audit_alert',
    label: 'waf_audit_alert',
  },
  {
    value: 'waf_prod_action',
    label: 'waf_prod_action',
  },
  {
    value: 'waf_prod_alert',
    label: 'waf_prod_alert',
  },
  {
    value: 'path',
    label: 'path',
  },
  {
    value: 'pop',
    label: 'pop',
  },
  {
    value: 'rl_alert',
    label: 'rl_alert',
  },
];

export const topOptions = [{ value: 'top 10' }, { value: 'top 20' }, { value: 'top 50' }];
