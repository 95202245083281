import { createApi } from '@reduxjs/toolkit/query/react';
import { InvitationsResponse, UsersCollaborationResponse } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const collaborationApi = createApi({
  reducerPath: 'collaborationApi',
  baseQuery: baseQuery(),
  tagTypes: ['CollaborationUsers', 'Invitations'],
  endpoints: (build) => ({
    fetchCollaborationUsers: build.query<UsersCollaborationResponse, string>({
      query: (directoryName) => ({
        url: endpoints.collaboration.users(directoryName),
      }),
      providesTags: () => ['CollaborationUsers'],
    }),
    inviteCollaborator: build.mutation<unknown, { directoryName: string; email: string }>({
      query: ({ directoryName, email }) => ({
        url: endpoints.collaboration.users(directoryName),
        method: 'POST',
        body: {
          users: [email],
        },
      }),
      invalidatesTags: ['CollaborationUsers', 'Invitations'],
    }),
    deleteCollaborator: build.mutation<unknown, { directoryName: string; id: number }>({
      query: ({ directoryName, id }) => ({
        url: endpoints.collaboration.usersDelete(directoryName, id),
        method: 'POST',
      }),
      invalidatesTags: ['CollaborationUsers'],
    }),

    fetchInvitations: build.query<InvitationsResponse, string>({
      query: (directoryName) => ({
        url: endpoints.collaboration.invitations(directoryName),
      }),
      providesTags: () => ['Invitations'],
    }),
    resendInvitation: build.mutation<InvitationsResponse, { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: endpoints.collaboration.invitationsResend(directoryName, id),
        method: 'POST',
      }),
      invalidatesTags: ['Invitations'],
    }),
    deleteInvitation: build.mutation<InvitationsResponse, { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: endpoints.collaboration.invitationsDetails(directoryName, id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Invitations'],
    }),
    changeOwner: build.mutation<InvitationsResponse, { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: endpoints.collaboration.changeOwner(directoryName),
        method: 'POST',
        body: {
          user_id: id,
        },
      }),
      invalidatesTags: ['CollaborationUsers'],
    }),
  }),
});

export default collaborationApi;
