import { Col, Row, Spin, Form, FormInstance } from 'antd';
import classNames from 'classnames';
import { BlockWrapper } from 'components/common/BlockWrapper';
import Button from 'components/common/Button';
import { Icon } from 'components/common/Icon';
import { Input } from 'components/common/Input';
import Modal from 'components/common/Modal';
import { Notification } from 'components/common/Notification';
import { Typography } from 'components/common/Typography';
import { isEmpty, map } from 'lodash';
import React, { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { whiteListAPI } from 'services';
import { useAppSelector } from 'store';
import styles from './ProjectSecurityWhiteListTable.module.scss';

const { Item } = Form;

export interface IAccessControllPayload {
  ip?: string;
}

interface IProjectSecurityWhiteListTableProps {
  isBlackList?: boolean;
  form: FormInstance<IAccessControllPayload>;
}

export const ProjectSecurityWhiteListTable: FC<IProjectSecurityWhiteListTableProps> = ({
  isBlackList,
  form,
}) => {
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { selectedCdn } = useAppSelector(({ project }) => project);
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');
  const [validateMessageApi, setValidateMessageApi] = useState('');

  const { data, isLoading } = whiteListAPI.useGetWhiteListQuery(
    {
      cnameId: String(selectedCdn),
      directoryName: String(directoryName),
    },
    { skip: !token || !directoryName || !selectedCdn }
  );

  const [createItem, { isLoading: isCreateLoading }] = whiteListAPI.useUpdateWhiteListMutation();
  const [deleteItem, { isLoading: isDeleteLoading }] =
    whiteListAPI.useDeleteWhiteListItemMutation();

  const onAddIp = useCallback(() => {
    setVisible(true);
  }, []);

  const onOk = useCallback(() => {
    createItem({ cnameId: String(selectedCdn), directoryName: String(directoryName), ip: value })
      .unwrap()
      .then(() => {
        Notification({ type: 'check', title: 'IP was created successfuly!' });
        setVisible(false);
        setValue('');
      })
      .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
  }, [createItem, directoryName, selectedCdn, value]);

  const onDelete = useCallback(
    (id: string | number) => {
      deleteItem({
        cnameId: String(selectedCdn),
        directoryName: String(directoryName),
        id: String(id),
      })
        .unwrap()
        .then(() => Notification({ type: 'check', title: 'IP was deleted successfuly!' }))
        .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
    },
    [deleteItem, directoryName, selectedCdn]
  );

  const onFinishAdd = useCallback(() => {
    createItem({ cnameId: String(selectedCdn), directoryName: String(directoryName), ip: value })
      .unwrap()
      .then(() => {
        Notification({ type: 'check', title: 'IP was created successfuly!' });
        setVisible(false);
        setValue('');
      })
      .catch((e) => {
        if (e?.data?.message) {
          setValidateMessageApi(e?.data?.message);
        }
      });
  }, [createItem, directoryName, selectedCdn, value]);
  return (
    <BlockWrapper
      isSmall
      minHeight="initial"
      isSkeleton={isLoading}
      padding={isLoading ? '16px 24px' : undefined}
      isFullHeight={false}>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title="Add IP Address to White List"
        okButtonLabel="Add"
        okLoading={isCreateLoading}
        onOk={onOk}>
        <Form className="HomeAddModal" form={form} onFinish={onFinishAdd}>
          <Row style={{ width: '100%' }}>
            <Col span={24}>
              <Item
                name="ip"
                rules={[
                  { required: true, message: 'Please input IP address' },
                  {
                    pattern:
                      /(?:^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$)|(?:^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$)/gm,
                    message: 'Please input a valid IP adress',
                  },
                ]}
                validateStatus={validateMessageApi ? 'error' : undefined}
                {...(validateMessageApi ? { help: validateMessageApi } : {})}
                className="input1">
                <Input
                  placeholder="Enter IP address"
                  value={value}
                  onChange={(v: React.FormEvent<HTMLInputElement>) => {
                    setValue(v.currentTarget.value);
                    setValidateMessageApi('');
                  }}
                />
              </Item>
            </Col>
          </Row>
          <div className={classNames('Modal__footer')}>
            <Button
              className="Modal__footer-btn"
              type="secondary"
              onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button type="primary" loading={isCreateLoading} submit>
              Add
            </Button>
          </div>
        </Form>
      </Modal>
      <div className={styles.head}>
        <Row justify="space-between" align="middle">
          <Col>
            <Row gutter={32} align="middle">
              <Col>
                <h4 className={styles.title}>{isBlackList ? 'IP Black List' : 'IP White List'}</h4>
              </Col>
              <Col>
                <Input icon="search" placeholder="Search" width={260} />
              </Col>
            </Row>
          </Col>
          <Col>
            <Button icon="plus" type="secondary" onClick={onAddIp}>
              Add New IP
            </Button>
          </Col>
        </Row>
      </div>
      <Row className={styles.tableHead}>
        <Col span={12} className={styles.headCol}>
          <span className={styles.headLabel}>IP</span>
        </Col>
        <Col span={8} className={styles.headCol}>
          <span className={styles.headLabel}>Notes</span>
        </Col>
      </Row>

      {isEmpty(data?.data) ? (
        <Row style={{ padding: 32 }} justify="center">
          <Col>
            <Typography>IP White List is empty</Typography>
          </Col>
        </Row>
      ) : (
        map(data?.data, (item) => (
          <Row key={item.id} className={styles.item}>
            <Col span={12} className={styles.col}>
              <span className={styles.tableLabel}>{item.ip}</span>
            </Col>
            <Col span={8} className={styles.col}>
              <span className={styles.tableLabel} />
            </Col>
            <Col flex={1} className={classNames(styles.col, styles.rightAlign)}>
              {/* <Icon name="edit" className={classNames(styles.linkIcon, styles.ml)} /> */}
              {isDeleteLoading ? (
                <Spin size="small" />
              ) : (
                <Icon
                  name="remove"
                  className={classNames(styles.linkIcon)}
                  onClick={() => onDelete(item.id)}
                />
              )}
            </Col>
          </Row>
        ))
      )}
    </BlockWrapper>
  );
};

export default ProjectSecurityWhiteListTable;
