/* eslint-disable no-console */
import './UserSettingsBillingReports.scss';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Col, DatePicker, Empty, Row } from 'antd';
import { filter, groupBy, includes, isEmpty, map, size, xor } from 'lodash';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { propertyAPI } from 'services';
import { useAppSelector } from 'store';
import { Loader } from 'components/common/Loader';
import moment, { Moment } from 'moment';
import { UserSettingsBillingReportsItem } from 'components';
import Dropdown from 'components/common/Dropdown';
import { Icon } from 'components/common/Icon';
import classNames from 'classnames';
import Divider from 'components/common/Divider';
import Checkbox from 'components/common/Checkbox';
import userDetailsApiV2 from 'services/userDetailsv2';
import { IBillingReport } from 'models';
import EmptyState from '../../../assets/svg/Empty.svg';

const { RangePicker } = DatePicker;

export const UserSettingsBillingReports: FC = withAuthenticationRequired(() => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isDatesOpen, setIsDatesOpen] = useState(false);
  const [projectsIds, setProjectsIds] = useState<Array<number | string>>([]);
  const [dates, setDates] = useState<[Moment, Moment]>([moment().startOf('M'), moment()]);
  const { token } = useAppSelector(({ auth }) => auth);

  const { data: billingReports, isLoading } = userDetailsApiV2.useFetchReportsQuery(
    {
      end_date: dates[1].format('DD/MM/YYYY'),
      start_date: dates[0].format('DD/MM/YYYY'),
    },
    { skip: !token }
  );

  const { data: propertiesData } = propertyAPI.useFetchAllPropertiesQuery('', {
    skip: !token,
  });

  const groupedData = useMemo(() => {
    const filteredById = filter(billingReports?.data, (item) =>
      includes([...projectsIds], item.property_id)
    );
    return groupBy(filteredById, 'property_id');
  }, [billingReports?.data, projectsIds]);

  const renderContent = useMemo(() => {
    if (isLoading) {
      return (
        <Row justify="center" align="bottom" style={{ minHeight: 170 }}>
          <Col>
            <Loader />
          </Col>
        </Row>
      );
    }

    if (!isLoading && isEmpty(billingReports?.data)) {
      return (
        <div className="UserSettingsInvoices__empty-state">
          <Row justify="center" align="bottom" style={{ minHeight: 200 }}>
            <Col span={24} className="HomeTable__empty-state">
              <Empty
                image={EmptyState}
                imageStyle={{ height: 90 }}
                description="You don't have any billing reports yet"
              />
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div>
        <Row style={{ paddingTop: 22, paddingBottom: 22 }} gutter={20}>
          <Col span={24}>
            <Row className="UserSettingsBillingReports__table-head" gutter={20}>
              <Col span={6} className="UserSettingsBillingReports__table-head-item _first">
                <span className="_first">Property</span>
              </Col>
              <Col span={6} className="UserSettingsBillingReports__table-head-item">
                trafic usage
              </Col>
              <Col span={6} className="UserSettingsBillingReports__table-head-item">
                billed trafic
              </Col>
              {/* <Col span={8} className="UserSettingsBillingReports__table-head-item">
                hits
              </Col>
              <Col span={2} className="UserSettingsBillingReports__table-head-item _last">
                <span className="_last">billed hits</span>
              </Col> */}
            </Row>
          </Col>
          {groupedData &&
            map(groupedData, (items: IBillingReport[], k: number) => {
              return <UserSettingsBillingReportsItem key={k} data={items} />;
            })}
        </Row>
      </div>
    );
  }, [billingReports?.data, groupedData, isLoading]);

  useEffect(() => {
    if (propertiesData?.data?.properties) {
      setProjectsIds(map(propertiesData?.data?.properties, ({ id }) => id));
    }
  }, [propertiesData?.data?.properties]);

  return (
    <div className="UserSettingsBillingReports">
      <h2 className="UserSettingsBillingReports__title">
        <p className="UserSettingsBillingReports__subtitle">{billingReports?.message}</p>
      </h2>
      <div className="UserSettingsBillingReports__controls">
        <Dropdown
          onVisibleChange={(v) => setIsFiltersOpen(v)}
          triggerElement={
            <button type="button" className="UserSettingsBillingReports__dropdown-trigger">
              Properties{' '}
              <Icon
                className={classNames('UserSettingsBillingReports__dropdown-icon', {
                  _active: isFiltersOpen,
                })}
                name="expand-down"
              />
            </button>
          }>
          <>
            <Checkbox
              className="HomeHeader__checkbox"
              onChange={(v) => {
                if (v.currentTarget.checked)
                  return setProjectsIds(map(propertiesData?.data?.properties, ({ id }) => id));
                return setProjectsIds([]);
              }}
              checked={size(projectsIds) === size(propertiesData?.data?.properties)}>
              All
            </Checkbox>
            <Divider style={{ margin: '12px 0' }} type="horizontal" />
            <ul className="HomeHeader__list">
              {map(propertiesData?.data?.properties, ({ id, name }) => (
                <li key={id} className="HomeHeader__item">
                  <Checkbox
                    className="HomeHeader__checkbox"
                    value={id}
                    onChange={() => id && setProjectsIds(xor(projectsIds, [id]))}
                    checked={projectsIds}>
                    {name}
                  </Checkbox>
                </li>
              ))}
            </ul>
          </>
        </Dropdown>
        <Row align="middle">
          <Col>
            <Icon name="calendar" className="UserSettingsBillingReports__dropdown-icon" />
          </Col>
          <Col>
            <RangePicker
              onOpenChange={(v) => setIsDatesOpen(v)}
              className="UserSettingsBillingReports__calendar"
              bordered={false}
              format="DD/MM/YYYY"
              value={dates}
              onChange={(v) => setDates(v as [Moment, Moment])}
              suffixIcon={
                <Icon
                  className={classNames('UserSettingsBillingReports__dropdown-icon', {
                    _active: isDatesOpen,
                  })}
                  name="expand-down"
                />
              }
              separator={<span className="UserSettingsBillingReports__separator" />}
            />
          </Col>
        </Row>
      </div>
      {renderContent}
    </div>
  );
});

export default UserSettingsBillingReports;
