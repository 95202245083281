import { createApi } from '@reduxjs/toolkit/query/react';
import { map } from 'lodash';
import { IAdnCnamesResponce } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const adnCnamesAPI = createApi({
  reducerPath: 'adnCnamesAPI',
  baseQuery: baseQuery(),
  tagTypes: ['AdnCnames'],
  endpoints: (build) => ({
    fetchAdnCnames: build.query<IAdnCnamesResponce[], string>({
      query: (directoryName) => ({
        url: endpoints.adnCnames.cnames(directoryName),
      }),
      providesTags: (result) =>
        result
          ? [...map(result, ({ id }) => ({ type: 'AdnCnames' as const, id })), 'AdnCnames']
          : ['AdnCnames'],
    }),
    fetchAdnCname: build.query<IAdnCnamesResponce[], { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: `${endpoints.adnCnames.cnames(directoryName)}/${id}`,
      }),
    }),
    createAdnCname: build.mutation<IAdnCnamesResponce, { directoryName: string; hostname: string }>(
      {
        query: ({ directoryName, hostname }) => ({
          url: endpoints.adnCnames.cnames(directoryName),
          method: 'POST',
          body: { hostname },
        }),
        invalidatesTags: ['AdnCnames'],
      }
    ),
    removeAdnCname: build.mutation<IAdnCnamesResponce, { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: `${endpoints.adnCnames.cnames(directoryName)}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AdnCnames'],
    }),
    editAdnCname: build.mutation<
      IAdnCnamesResponce,
      { directoryName: string; id: string; hostname: string }
    >({
      query: ({ directoryName, id, hostname }) => ({
        url: `${endpoints.adnCnames.cnames(directoryName)}/${id}`,
        method: 'PUT',
        body: { hostname },
      }),
      invalidatesTags: ['AdnCnames'],
    }),
    changePrimaryAdnCname: build.mutation<
      { data: IAdnCnamesResponce; message: string },
      { directoryName: string; adnId: string | number }
    >({
      query: ({ directoryName, adnId }) => ({
        url: endpoints.adnCnames.cnamesChangePrimary(directoryName, adnId),
        method: 'PUT',
      }),
      invalidatesTags: ['AdnCnames'],
    }),
    changeRedirectAdnCname: build.mutation<
      { data: IAdnCnamesResponce; message: string },
      { directoryName: string; adnId: string | number }
    >({
      query: ({ directoryName, adnId }) => ({
        url: endpoints.adnCnames.cnamesChangeRedirect(directoryName, adnId),
        method: 'PUT',
      }),
      invalidatesTags: ['AdnCnames'],
    }),
    removeRedirectAdnCname: build.mutation<
      { data: IAdnCnamesResponce; message: string },
      { directoryName: string; adnId: string | number }
    >({
      query: ({ directoryName, adnId }) => ({
        url: endpoints.adnCnames.cnamesChangeRedirect(directoryName, adnId),
        method: 'DELETE',
      }),
      invalidatesTags: ['AdnCnames'],
    }),
  }),
});

export default adnCnamesAPI;
