import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { ProjectOriginDeployments } from 'components/views/Project/ProjectOrigin/ProjectOriginDeployments';

export const ProjectOriginDeploymentsView: FC = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <ProjectOriginDeployments />
      </Col>
    </Row>
  );
};

export default ProjectOriginDeploymentsView;
