/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import { IField, switchEnabledObject } from './fieldsObjects';

export const accessDeny: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export default accessDeny;
