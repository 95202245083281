import { createApi } from '@reduxjs/toolkit/query/react';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const ipAPI = createApi({
  reducerPath: 'ipAPI',
  baseQuery: baseQuery(),
  tagTypes: ['Ip'],
  endpoints: (build) => ({
    getIp: build.mutation<{ data: { ip: string } }, { domain: string }>({
      query: ({ domain }) => ({
        url: endpoints.ip,
        method: 'POST',
        body: {
          domain,
        },
      }),
    }),
  }),
});

export default ipAPI;
