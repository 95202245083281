import React, { FC, useEffect, useMemo } from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { useParams } from 'react-router-dom';
import { IChartData, TTimeDuration } from 'store/slices/project';
import { chartConfigs } from '../configs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore <no type at library>
FusionCharts.options.license({
  key: 'bj1A1hwA-13F6B1H5D2J2B2C4C3B1E2D4G4I3xkcA3D5trxfsA2B2jE-11oE1G4E1A2B6C4D4F4B2E3C3H2E1C8B7B5E-11acE3E3G2sA4B2C2feI-8D1H4B3zD-13mD1D3G4nvrB9D2C6E2C4B1I4F1A9C11A5uD-11C-9A2I3NC5qD-17jD2E2F1H-8jC2a1A5C7fg1qH4G1B8C2D2C2E1C5F1C1G1A11D9E4i==',
  creditLabel: false,
});

ReactFC.fcRoot(FusionCharts, Widgets, FusionTheme);

interface IProjectChartsRealtimeBW {
  data?: IChartData;
  update?: number;
  selectedCdn?: string | number;
  timeDuration?: TTimeDuration;
}
interface IProjectChartsRealtimeBW {
  data?: IChartData;
  update?: number;
  selectedCdn?: string | number;
  timeDuration?: TTimeDuration;
}
export interface IChartValue {
  value: number;
}
export const ProjectChartsRealtimeBW: FC<IProjectChartsRealtimeBW> = ({
  data,
  update,
  selectedCdn,
  timeDuration,
}) => {
  const { directoryName } = useParams<{ directoryName?: string }>();

  const config = useMemo(
    () =>
      chartConfigs({
        height: 210,
        dataset: [{ data: [{ value: 0 }] }],
        id: 'realtimeBW',
        numberSuffix: 'b/s',
        type: 'realtimearea',
        numdisplaysets: timeDuration ? `${(timeDuration * 60) / 5}` : '60',
        plotToolText:
          '<b style="font-size: 14px;">BW</b> </br> <span style="font-size: 14px;">$label - $dataValue</span>',
      }),
    [timeDuration]
  );

  useEffect(() => {
    const chartRef = FusionCharts('realtimeBW');
    if (chartRef && selectedCdn && chartRef.clearChart && directoryName) {
      chartRef.clearChart();
    }
  }, [selectedCdn, directoryName]);

  useEffect(() => {
    if (data && update) {
      const chartRef = FusionCharts('realtimeBW');
      if (chartRef && chartRef.feedData) {
        const strData = `&label=${data.date}&value=${Number(data.data.BW?.value)}`;
        chartRef.feedData(strData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedCdn, update]);

  return <ReactFC {...config} />;
};

export default ProjectChartsRealtimeBW;
