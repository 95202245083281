import './Error.scss';
import React, { FC, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DATA } from './mockData';

interface Props {
  errorNumber: 401 | 403 | 404 | 500;
}

export const Error: FC<Props> = ({ errorNumber }) => {
  const { logout } = useAuth0();

  useEffect(() => {
    if (errorNumber === 401) {
      logout({ returnTo: window.location.origin });
    }
  }, [errorNumber, logout]);

  const { title, name, text } = DATA[errorNumber];
  return (
    <div className="Error">
      <div className="Error__wrapper">
        <h1 className="Error__title">{title}</h1>
        <h4 className="Error__subtitle">{name}</h4>
        <p className="Error__text">{text}</p>
      </div>
    </div>
  );
};

export default Error;
