/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useMemo, useState } from 'react';
import FusionCharts from 'fusioncharts';
import Maps from 'fusioncharts/fusioncharts.maps';
import World from 'fusioncharts/maps/fusioncharts.world';
import ReactFC from 'react-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { IChartsMapDataResponse } from 'models';
import { map, find, toUpper, filter, isEmpty } from 'lodash';
import { MAP_HEIGHT, MAP_WIDTH } from 'helpers/map';
import countries from 'libs/countries.json';
import FirstMarker from '../../../../../assets/svg/1.svg';
import SecondMarker from '../../../../../assets/svg/3.svg';
import ThirdMarker from '../../../../../assets/svg/5.svg';
import FourthMarker from '../../../../../assets/svg/8.svg';
import FifthMarker from '../../../../../assets/svg/10.svg';

ReactFC.fcRoot(FusionCharts, Maps, World, FusionTheme);

type FilteredArrayProp = {
  id: string;
  shapeid: string;
  value: number;
  x: number;
  y: number;
  tooltext: string;
};

export const ProjectChartsRealtimeMap: FC<{
  data?: IChartsMapDataResponse;
  isEmptyData: boolean;
}> = ({ data, isEmptyData }) => {
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMap(true);
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [data]);

  const defaultChartProps = useMemo(() => {
    return {
      chart: {
        showlegend: 0,
        showentitytooltip: '0',
        showentityhovereffect: '0',
        showmarkerlabels: '0',
        markertooltext: '$label',
        theme: 'fusion',
        nullentityfillcolor: '#c4cfde',
      },
      color: {
        code: '#c4cfde',
      },
      data: [],
    };
  }, []);

  const chartProps = useMemo(() => {
    const chart = (arrayProp?: FilteredArrayProp[]) => {
      return {
        ...defaultChartProps,
        markers: {
          items: arrayProp || [],
          shapes: [
            {
              id: 'we-first',
              type: 'image',
              url: FirstMarker,
              xscale: '60',
              yscale: '60',
              labelpadding: '5',
            },
            {
              id: 'we-second',
              type: 'image',
              url: SecondMarker,
              xscale: '70',
              yscale: '70',
              labelpadding: '5',
            },
            {
              id: 'we-third',
              type: 'image',
              url: ThirdMarker,
              xscale: '80',
              yscale: '80',
              labelpadding: '5',
            },
            {
              id: 'we-fourth',
              type: 'image',
              url: FourthMarker,
              xscale: '90',
              yscale: '90',
              labelpadding: '5',
            },
            {
              id: 'we-fifth',
              type: 'image',
              url: FifthMarker,
              xscale: '100',
              yscale: '100',
              labelpadding: '5',
            },
          ],
        },
      };
    };
    return chart;
  }, [defaultChartProps]);

  const dataSource = useMemo(() => {
    if (data?.success) {
      const mapedArray = map(data.data.POPs.POPCode.buckets, (bucket) => {
        const getShape = () => {
          switch (true) {
            case Number(bucket.BW?.value) / 1000 <= 3:
              return 'we-first';
            case Number(bucket.BW?.value) / 1000 <= 5:
              return 'we-second';
            case Number(bucket.BW?.value) / 1000 <= 8:
              return 'we-third';
            case Number(bucket.BW?.value) / 1000 <= 10:
              return 'we-fourth';
            default:
              return 'we-fifth';
          }
        };

        const findCoordinates = find(
          countries,
          (item) => toUpper(item.Abbreviation) === toUpper(bucket.key)
        );
        return {
          id: bucket.key,
          shapeid: getShape(),
          value: Number(bucket.BW?.value) / 1000,
          x: findCoordinates?.X || 0,
          y: findCoordinates?.Y || 0,
          tooltext: `${findCoordinates?.Location} <br> BW: <b>$dataValue</b> Kb/s`,
        };
      });

      const filteredArray = filter(mapedArray, (item) => !isEmpty(item.id) && item.x && item.y);
      return {
        ...chartProps(filteredArray as FilteredArrayProp[]),
      };
    }
    return {
      ...chartProps(),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultChartProps]);

  return (
    <>
      {showMap && (
        <ReactFC
          type="maps/world"
          width={MAP_WIDTH}
          height={MAP_HEIGHT}
          dataFormat="JSON"
          dataSource={isEmptyData ? defaultChartProps : dataSource}
          id="map"
        />
      )}
    </>
  );
};

export default ProjectChartsRealtimeMap;
