import classNames from 'classnames';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { Icon } from 'components/common/Icon';
import { isNumber } from 'lodash';
import React, { FC, useMemo } from 'react';
import styles from './CodeBlockItem.module.scss';

interface ICodeBlockItemProps {
  objectKey: string;
  val: string | number;
  onAddFilterClick?: (objectKey: string, val: string | number) => void;
  onExcludeClick?: (objectKey: string, val: string | number) => void;
}

export const CodeBlockItem: FC<ICodeBlockItemProps> = ({
  objectKey,
  val,
  onAddFilterClick,
  onExcludeClick,
}) => {
  const renderDropdownContent = useMemo(() => {
    return (
      <div className={styles.tooltip}>
        <div className={styles.button}>
          <Button
            type="subtle"
            icon="search"
            onClick={() => onAddFilterClick && onAddFilterClick(objectKey, val)}>
            <span className={styles.label}>
              Filter by <span>{`@${objectKey}:${val}`}</span>
            </span>
          </Button>
        </div>
        <div className={styles.button}>
          <Button
            type="subtle"
            icon="cross-in-circle"
            onClick={() => onExcludeClick && onExcludeClick(objectKey, val)}>
            <span className={styles.label}>
              Exclude <span>{`@${objectKey}:${val}`}</span>
            </span>
          </Button>
        </div>
      </div>
    );
  }, [objectKey, onAddFilterClick, onExcludeClick, val]);
  return (
    <tr className={styles.item}>
      <td className={styles.col}>
        <Dropdown
          withArrow
          align={{ targetOffset: [-5, 5] }}
          placement="leftTop"
          triggerElement={<Icon name="settings" className={styles.icon} />}>
          {renderDropdownContent}
        </Dropdown>

        <span className={styles.key}>{objectKey}</span>
      </td>
      <td className={styles.colRight}>
        <span className={classNames(styles.value, { [styles.number]: isNumber(val) })}>{val}</span>
      </td>
    </tr>
  );
};

export default CodeBlockItem;
