import { Col, Row } from 'antd';
import classNames from 'classnames';
import { Icon } from 'components/common/Icon';
import { map } from 'lodash';
import { InvoicesResponseItem } from 'models';
import React, { FC, useState } from 'react';
import { UserSettingsInvoicesItemSubitem } from './UserSettingsInvoicesItemSubitem';

interface ItemType {
  items: InvoicesResponseItem[];
  offset: number;
  name: string;
  className?: string;
}

export const UserSettingsInvoicesItem: FC<ItemType> = ({ items, offset, name, className }) => {
  const [childrensVisibility, setChildrensVisibility] = useState(false);

  const handleRowClick = () => {
    setChildrensVisibility((prevState) => !prevState);
  };

  return (
    <>
      <Col span={24} className={classNames('UserSettingsInvoices__table-item _primary', className)}>
        <Row>
          <Col onClick={() => handleRowClick()} style={{ paddingLeft: offset }} span={7}>
            <div
              className={classNames('UserSettingsInvoices__table-item-first _primary', className)}>
              <Icon
                className={classNames('UserSettingsInvoices__expand-icon', {
                  _active: childrensVisibility,
                })}
                name="expand-down"
              />
              <span className="UserSettingsInvoices__text">{name}</span>
            </div>
          </Col>
          <Col span={2} className={classNames('UserSettingsInvoices__col _primary')}>
            <span className="UserSettingsInvoices__text"> </span>
          </Col>
          <Col span={6} className={classNames('UserSettingsInvoices__col _primary')}>
            <span className="UserSettingsInvoices__text"> </span>
          </Col>
          <Col span={4} className={classNames('UserSettingsInvoices__col _primary')}>
            <span className="UserSettingsInvoices__text"> </span>
          </Col>

          <Col span={5} className={classNames('UserSettingsInvoices__col _primary')}>
            {/* <button type="button" className="UserSettingsInvoices__filter">
              <Icon name="export" className="UserSettingsInvoices__upload-icon" />
            </button> */}
          </Col>
        </Row>
      </Col>
      {childrensVisibility &&
        map(items, (subitem, idx) => {
          return (
            <UserSettingsInvoicesItemSubitem
              item={subitem}
              key={subitem.id}
              className={idx === 0 ? '_first' : ''}
            />
          );
        })}
      {/* {item.children &&
        childrensVisibility &&
        map(item.children, (subitem) => (
          <UserSettingsInvoicesItem offset={offset + 30} key={subitem.key} item={subitem} />
        ))} */}
    </>
  );
};

export default UserSettingsInvoicesItem;
