import { Col, Row } from 'antd';
import classNames from 'classnames';
import { isObject, keys, map } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
// import { useAppSelector } from 'store';
import styles from './CodeBlock.module.scss';
import { CodeBlockItem } from './CodeBlockItem';

export interface IObject {
  [key: string]: string | number | IObject;
}

interface ICodeBlockProps {
  value?: IObject;
  isFirst?: boolean;
  onAddFilterClick?: (objectKey: string, val: string | number) => void;
  onExcludeClick?: (objectKey: string, val: string | number) => void;
}
export const CodeBlock: FC<ICodeBlockProps> = ({
  value,
  isFirst = true,
  onAddFilterClick,
  onExcludeClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [currentValue, setCurrentValue] = useState<null | IObject | undefined>(null);
  // const { tags } = useAppSelector(({ logs }) => logs);

  // const isStringInArray = useMemo(() => {
  //   return findIndex(tags, (tag) => tag === str) === -1
  // }, [])

  const objectRow = useCallback(
    (key: string, val: IObject) => {
      return (
        <Row key={key} align="middle" gutter={[4, 8]} style={{ padding: '10px 6px' }}>
          <Col className={styles.col}>
            <button
              type="button"
              className={classNames(styles.button, { [styles.expanded]: isExpanded })}
              onClick={() => setIsExpanded(!isExpanded)}>
              <span>{isExpanded ? '-' : '+'}</span>
            </button>
          </Col>
          <Col className={styles.col}>
            <span className={styles.key}>{key}</span>
          </Col>
          <Col className={styles.col}>
            <span className={styles.curly}>{'{'}</span>
          </Col>
          {isExpanded && (
            <Col span={24}>
              <Row style={{ paddingLeft: 24 }}>
                <CodeBlock value={val} isFirst={false} />
              </Row>
            </Col>
          )}
        </Row>
      );
    },
    [isExpanded]
  );

  useEffect(() => {
    if (typeof value === 'string') {
      setCurrentValue(JSON.parse(value));
    }
    setCurrentValue(value);
  }, [value]);

  return (
    <Row className={styles.code}>
      {isFirst && (
        <Col span={24} className={styles.col}>
          <span className={styles.curly}>{'{'}</span>
        </Col>
      )}
      <Col span={24} style={{ paddingLeft: 8 }}>
        <table className={styles.table}>
          <tbody>
            {currentValue &&
              map(keys(currentValue), (item) => {
                if (!isObject(currentValue[item]))
                  return (
                    <CodeBlockItem
                      key={item}
                      objectKey={item}
                      val={currentValue[item] as string}
                      onAddFilterClick={onAddFilterClick}
                      onExcludeClick={onExcludeClick}
                    />
                  );
                return null;
              })}
          </tbody>
        </table>
        <table className={styles.table}>
          <tbody>
            {currentValue &&
              map(keys(currentValue), (item) => {
                if (isObject(currentValue[item]))
                  return (
                    <>
                      <table>
                        <tbody>
                          <tr>{objectRow(item, currentValue[item] as IObject)}</tr>
                        </tbody>
                      </table>
                    </>
                  );
                return null;
              })}
          </tbody>
        </table>
      </Col>
      <Col span={24} className={styles.col}>
        <span className={styles.curly}>{'}'}</span>
      </Col>
    </Row>
  );
};

export default CodeBlock;
