import { createApi } from '@reduxjs/toolkit/query/react';
import { ISslPayload, ISslResponse, ISslUpdatePayload } from 'models/ssl';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const sslCertificateApi = createApi({
  reducerPath: 'sslCertificateApi',
  baseQuery: baseQuery(),
  tagTypes: ['Ssl', 'SslShared'],
  endpoints: (build) => ({
    createSsl: build.mutation<ISslResponse[], { directoryName: string } & ISslPayload>({
      query: ({ directoryName, ...rest }) => ({
        url: endpoints.ssl(directoryName),
        method: 'POST',
        body: { ...rest },
      }),
      invalidatesTags: ['Ssl'],
    }),
    fetchSsl: build.query<ISslResponse[], string>({
      query: (directoryName) => ({
        url: endpoints.ssl(directoryName),
        method: 'GET',
      }),
      providesTags: () => ['Ssl'],
    }),
    deleteSsl: build.mutation<{ message: string }, { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: `${endpoints.sslId(directoryName, id)}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Ssl'],
    }),
    cancelSsl: build.mutation<{ message: string }, { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: endpoints.sslCancelId(directoryName, id),
        method: 'PUT',
      }),
      invalidatesTags: ['Ssl'],
    }),
    updateSsl: build.mutation<
      ISslResponse[],
      { directoryName: string; id: string } & ISslUpdatePayload
    >({
      query: ({ directoryName, id, ...rest }) => ({
        url: `${endpoints.sslId(directoryName, id)}`,
        method: 'PUT',
        body: { ...rest },
      }),
      invalidatesTags: ['Ssl'],
    }),
    validateSsl: build.mutation<{ message: string }, { directoryName: string; id: string }>({
      query: ({ directoryName, id }) => ({
        url: endpoints.sslValidateId(directoryName, id),
        method: 'PUT',
      }),
      invalidatesTags: ['Ssl'],
    }),
  }),
});

export default sslCertificateApi;
