import './UserSettingsDeleteAccount.scss';
import React, { FC, useCallback, useState } from 'react';
import Button from 'components/common/Button';
import { Notification } from 'components/common/Notification';
import { Col, Row } from 'antd';
import { userDetailsApi } from 'services';
import Modal from 'components/common/Modal';
import { ShowUserResponse } from 'models';

import { DeleteAccountModal } from './DeleteAccountModal';

interface Props {
  data?: ShowUserResponse | undefined;
  logOut: () => void;
}

export const UserSettingsDeleteAccount: FC<Props> = ({ data, logOut }) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [deleteService, { isLoading, isSuccess }] = userDetailsApi.useDeleteAccountMutation();

  const onDeleteClick = useCallback(() => {
    deleteService(data?.data?.email || '')
      .unwrap()
      .then((resp) => {
        setDeleteModalVisible(false);
        Notification({ type: 'check', title: resp?.data });
        logOut();
      })
      .catch((err) => {
        setDeleteModalVisible(false);
        Notification({ type: 'cross', title: err?.data?.message });
      });
  }, [data?.data?.email, deleteService, logOut]);

  return (
    <div className="UserSettingsDeleteAccount">
      <Row justify="space-between" align="middle">
        <Col>
          <h2 className="UserSettingsDeleteAccount__title">Delete Account</h2>
          <p className="UserSettingsDeleteAccount__text">
            Once you delete an account, there is no going back.
          </p>
        </Col>
        <Col>
          <Button
            disabled={!data?.data?.email}
            className="UserSettingsDeleteAccount__btn"
            loading={isLoading}
            onClick={() => setDeleteModalVisible(true)}
            type="danger">
            Delete Account
          </Button>
        </Col>
      </Row>
      <Modal
        visible={deleteModalVisible}
        title="Delete Account"
        width="400px"
        onCancel={() => setDeleteModalVisible(false)}>
        <DeleteAccountModal
          email={data?.data?.email || ''}
          onCancel={() => setDeleteModalVisible(false)}
          onFinish={onDeleteClick}
          loading={isLoading}
          isSuccess={isSuccess}
        />
      </Modal>
    </div>
  );
};

export default UserSettingsDeleteAccount;
