import React, { FC, useCallback, useMemo } from 'react';
import { Col, Row } from 'antd';
import { IPropertyPayload } from 'models';
import Button from 'components/common/Button';
import { Loader } from 'components/common/Loader';
import { CreateProperty } from './CreateProperty';
import './HomeAddModalNew.scss';

interface Props {
  onFinish: (values: IPropertyPayload) => void;
  onCancel: () => void;
  currentStep: number;
  loading?: boolean;
}

export interface PusherResponse {
  message: string;
  percentage: number;
  step: number;
}

export const HomeAddModalNew: FC<Props> = ({ onFinish, onCancel, loading, currentStep }) => {
  const onCancelClick = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const renderFirstModal = useMemo(() => {
    return (
      <>
        {loading && (
          <div className="loaderPopr">
            <Loader />
          </div>
        )}
        <CreateProperty onFinish={onFinish} loading={loading} />
      </>
    );
  }, [loading, onFinish]);

  const renderSecondModal = useMemo(() => {
    return (
      <>
        <h4
          style={{ fontSize: 20, fontWeight: 700, bottom: 15, position: 'relative' }}
          className="HomeAddModal__title">
          Congratulations!
        </h4>

        <div className="ProjectOriginOrigin">
          <Row gutter={[20, 20]} align="middle">
            <Col span={24}>
              <div>Your property has been created successfully!</div>
              <div>Please, wait for your property to be deployed!</div>
            </Col>

            <Col span={24} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button loading={loading} type="primary" onClick={onCancelClick}>
                Ok
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
  }, [loading, onCancelClick]);

  const renderContent = useMemo(() => {
    switch (currentStep) {
      case 1:
        return renderSecondModal;

      default:
        return renderFirstModal;
    }
  }, [currentStep, renderFirstModal, renderSecondModal]);

  return <>{renderContent}</>;
};

export default HomeAddModalNew;
