import React, { FC, ReactNode } from 'react';
import { Drawer as AntDrawer, DrawerProps } from 'antd';
import styles from './Drawer.module.scss';
import { Icon } from '../Icon';

interface IDrawerProps extends DrawerProps {
  renderHeaderContent?: ReactNode;
  headerTitle?: ReactNode;
  children?: ReactNode;
}

export const Drawer: FC<IDrawerProps> = ({
  onClose,
  open,
  footer,
  children,
  renderHeaderContent,
  headerTitle,
  width = 800,
  ...rest
}) => {
  return (
    <AntDrawer
      {...rest}
      placement="right"
      onClose={onClose}
      open={open}
      mask={false}
      width={width}
      style={{ top: 0, bottom: 0, height: '100%' }}
      contentWrapperStyle={{ boxShadow: '-5px 0px 30px rgba(25, 42, 62, 0.08' }}
      headerStyle={{ display: 'none' }}
      bodyStyle={{ padding: 0 }}
      footer={footer}>
      <div className={styles.content}>
        <div className={styles.header}>
          {headerTitle}
          {renderHeaderContent}
          <button type="button" onClick={onClose} className={styles.button}>
            <Icon name="cross" className={styles.icon} />
          </button>
        </div>
        {children}
      </div>
    </AntDrawer>
  );
};

export default Drawer;
