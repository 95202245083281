import './Modal.scss';
import React, { DOMAttributes, FC, ReactNode, useContext } from 'react';
import { Modal as AntModal } from 'antd';
import { ThemeContext } from 'context/theme';
import cn from 'classnames';
import { Button, ButtonType } from '../Button';

interface Props {
  visible: boolean;
  onCancel?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void | DOMAttributes<HTMLButtonElement>['onClick'];
  footer?: boolean;
  onOk?: DOMAttributes<HTMLButtonElement>['onClick'];
  okButtonLabel?: string;
  cancelButtonLabel?: string;
  title?: string;
  width?: number | string;
  okDisabled?: boolean;
  okLoading?: boolean;
  isDanger?: boolean;
  maskStyle?: React.CSSProperties;
  okButtonType?: ButtonType;
  isFooterCentered?: boolean;
  destroyOnClose?: boolean;
  children?: ReactNode;
  hideHeader?: boolean;
}

export const Modal: FC<Props> = ({
  visible,
  onCancel,
  children,
  footer,
  onOk,
  okButtonLabel = 'Submit',
  cancelButtonLabel = 'Cancel',
  title,
  width = 402,
  okDisabled = false,
  okLoading = false,
  isDanger = false,
  maskStyle,
  okButtonType = 'primary',
  isFooterCentered,
  destroyOnClose,
  hideHeader,
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <AntModal
      bodyStyle={{
        padding: 0,
      }}
      maskStyle={
        maskStyle || {
          background: theme === 'light' ? 'rgba(43, 61, 91, 0.4)' : 'rgba(31, 33, 35, 0.6)',
        }
      }
      wrapClassName={cn('Modal', { _danger: isDanger, _hideHeader: hideHeader })}
      title={title}
      open={visible}
      onCancel={onCancel}
      width={width}
      centered
      destroyOnClose={destroyOnClose}
      footer={null}>
      <div className="Modal__content">{children}</div>
      {footer && (
        <div className={cn('Modal__footer', { _centered: isFooterCentered })}>
          <Button className="Modal__footer-btn" type="secondary" onClick={onCancel}>
            {cancelButtonLabel}
          </Button>
          <Button loading={okLoading} disabled={okDisabled} onClick={onOk} type={okButtonType}>
            {okButtonLabel}
          </Button>
        </div>
      )}
    </AntModal>
  );
};

export default Modal;
