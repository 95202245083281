/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import './App.scss';
import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import RouterView from 'router';
import { authSlice } from 'store/slices/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { paymentMethodsAPI, userDetailsApi } from 'services';
import { useAppDispatch, useAppSelector } from '../store';

export const App: FC = () => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(() =>
    Promise.resolve(null)
  );
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const dispatch = useAppDispatch();
  const { setToken } = authSlice.actions;
  const { token } = useAppSelector(({ auth }) => auth);

  const { data: stripeKeyData } = paymentMethodsAPI.useFetchLoadStripeQuery('', {
    skip: !token,
  });

  const { data } = userDetailsApi.useFetchIntercomQuery('', {
    skip: !token,
  });

  useEffect(() => {
    if (stripeKeyData?.success && stripeKeyData?.data?.api_key) {
      const stripe = loadStripe(stripeKeyData.data.api_key);
      setStripePromise(stripe);
    }
  }, [stripeKeyData?.data?.api_key, stripeKeyData?.success]);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((resp) => dispatch(setToken(resp)));
    }
  }, [dispatch, getAccessTokenSilently, setToken, isAuthenticated]);

  useEffect(() => {
    // @ts-ignore<script from html config>
    if (window.Intercom && data) {
      const { api_base, app_id, created_at, email, user_hash, name } = data.data;

      // @ts-ignore<script from html config>
      window.intercomSettings = {
        api_base,
        app_id,
        name,
        email,
        created_at,
      };
      // @ts-ignore<script from html config>
      window.Intercom('boot', {
        api_base,
        app_id,
        email,
        user_hash,
        created_at,
        name,
      });
    }
  }, [data]);

  if (!stripePromise) return null;

  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <RouterView />
      </BrowserRouter>
    </Elements>
  );
};

export default App;
