import React, { FC, HTMLAttributes, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import './Radio.scss';
import { isBoolean } from 'lodash';

export const radioTestId = 'radio';
export const radioInputTestId = 'radio-input';

export type ValueType = string | number;

export interface Props {
  value: string | number;
  checked?: ValueType | boolean;
  name?: string;
  disabled?: boolean;
  className?: string;
  children?: ReactNode | string;
  style?: HTMLAttributes<HTMLLabelElement>['style'];
  onChange: (e: ValueType) => void;
}

export const Radio: FC<Props> = ({
  value,
  checked,
  name,
  className,
  children,
  disabled,
  onChange,
  style,
}) => {
  const computedChecked = useMemo(() => {
    if (!isBoolean(checked)) {
      return checked === value;
    }
    return checked;
  }, [checked, value]);

  return (
    <label
      style={style}
      data-testid={radioTestId}
      className={classNames('Radio', className, { _disabled: disabled })}>
      <input
        data-testid={radioInputTestId}
        type="radio"
        checked={computedChecked}
        value={value}
        disabled={disabled}
        onChange={() => onChange(value)}
        className="Radio__input"
        name={name}
      />
      <span className="Radio__area">
        <span className="Radio__check" />
      </span>
      {children && <span className="Radio__text">{children}</span>}
    </label>
  );
};

export default Radio;
