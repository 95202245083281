/* eslint-disable import/no-extraneous-dependencies */
import * as Sentry from '@sentry/react';
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { Notification } from 'components/common/Notification';
import { environment } from 'config';
import RouteTypes from 'router/types';

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401) {
      // eslint-disable-next-line no-empty
      if (window.location.pathname === RouteTypes.error401) {
        Notification({
          type: 'cross',
          title: 'Error',
          message: 'Something went wrong. Please try again.',
        });
      } else {
        window.location.href = RouteTypes.error401;
      }
    }

    if (action.payload.status === 'PARSING_ERROR') {
      Notification({
        title: `Success`,
        message: action.payload.data,
        type: 'check',
      });
    } else {
      // Notification({
      //   title: `Status - ${action.payload.status}`,
      //   message: action.payload.data?.message,
      //   type: 'cross',
      // });
      const message = `Status: ${action.payload.status}\nmessage:${action.payload.data?.message}\nendpointName:${action.meta?.arg?.endpointName}`;

      const scope = new Sentry.Scope();
      scope.setExtras({ ...action });
      scope.setExtra('environment', environment);
      Sentry.captureException(new Error(message), scope);
    }
  }

  return next(action);
};

export default rtkQueryErrorLogger;
