/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import { IField } from './fieldsObjects';

export const maxKeepAliveRequests: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'requests',
    label: 'Requests',
  },
];

export const proxySpecialHeaders: IField[] = [
  {
    id: 1,
    type: 'input',
    fieldName: 'names',
    label: 'Names',
    regexp: /[-_\.a-zA-Z0-9]+/,
    tooltip: 'Regex Pattern: ',
  },
];
