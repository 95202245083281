import { Col, Form, FormInstance, Row } from 'antd';
import Button from 'components/common/Button';
import { Input } from 'components/common/Input';
import Select, { SelectItemType } from 'components/common/Select';
import React, { FC, useEffect, useState } from 'react';
import { FormType, IPayloadElement } from '../ProjectRoutingTable';
import styles from './ProjectRoutingTableAddModal.module.scss';

interface IProjectRoutingTableAddModalProps {
  onFinish: (values: FormType) => void;
  form: FormInstance<FormType>;
  onCancel: () => void;
  loading?: boolean;
  selectedItem?: IPayloadElement;
}

const DATA: SelectItemType[] = [
  {
    name: 'A (IPv4 Address)',
    value: 'A',
  },
  {
    name: 'AAAA (IPv6 Address)',
    value: 'AAAA',
  },
  {
    name: 'CNAME (Canonical Name)',
    value: 'CNAME',
  },
  {
    name: 'MX (Mail Exchange)',
    value: 'MX',
  },
  {
    name: 'NS (Name Server)',
    value: 'NS',
  },
  {
    name: 'PTR (Pointer)',
    value: 'PTR',
  },
  {
    name: 'SPF (Sender Policy Framework)',
    value: 'SPF',
  },
  {
    name: 'SRV (Service Locator)',
    value: 'SRV',
  },
  {
    name: 'TXT (Text)',
    value: 'TXT',
  },
  {
    name: 'CAA (Certificate Authority Authorization)',
    value: 'CAA',
  },
];

export const ProjectRoutingTableAddModal: FC<IProjectRoutingTableAddModalProps> = ({
  onFinish,
  form,
  onCancel,
  loading,
  selectedItem,
}) => {
  const [typeName, setTypeName] = useState('');
  const [namePlaceholder, setNamePlaceholder] = useState('');
  const [rDataPlaceholder, setRDataPlaceholder] = useState('');

  useEffect(() => {
    if (selectedItem && form) {
      form.setFieldsValue({
        Name: selectedItem.record?.Name,
        Rdata: selectedItem.record?.Rdata,
        TTL: selectedItem.record?.TTL,
        key: selectedItem.key,
      });
    }
    if (!selectedItem && form) {
      form.setFieldsValue({
        TTL: 3600,
      });
    }
  }, [form, selectedItem]);

  useEffect(() => {
    if (typeName === 'A' || typeName === 'AAAA' || typeName === 'CNAME') {
      setNamePlaceholder('www');
      if (typeName === 'A') {
        setRDataPlaceholder('127.0.0.1');
      }
      if (typeName === 'AAAA') {
        setRDataPlaceholder('0:0:0:0:0:0:0:1');
      }
      if (typeName === 'CNAME') {
        setRDataPlaceholder('my.domain.com.');
      }
    }
    if (
      typeName === 'MX' ||
      typeName === 'NS' ||
      typeName === 'SPF' ||
      typeName === 'TXT' ||
      typeName === 'CAA'
    ) {
      setNamePlaceholder('@');
      if (typeName === 'MX') {
        setRDataPlaceholder('10 smtp1.my.domain.com.');
      }
      if (typeName === 'NS') {
        setRDataPlaceholder('ns1.mydomain.com');
      }
      if (typeName === 'TXT') {
        setRDataPlaceholder('Text Here');
      }
      if (typeName === 'CAA') {
        setRDataPlaceholder('<flags 0-255> \n<issue|issuewild!iodef> \n<“value|ca.domain.net”>');
      }
      if (typeName === 'SPF') {
        setRDataPlaceholder(' ');
      }
    }
    if (typeName === 'PTR') {
      setNamePlaceholder('0-255');
      if (typeName === 'PTR') {
        setRDataPlaceholder('domainname-public-dns.mydomain.com');
      }
    }
    if (typeName === 'SRV') {
      setNamePlaceholder('_http._tcp');
      if (typeName === 'SRV') {
        setRDataPlaceholder('Priority Weight port target \n0 5 80 www.mydomain.com');
      }
    }
  }, [typeName]);

  return (
    <Form form={form} onFinish={onFinish}>
      <Row gutter={[20, 20]} style={{ paddingBottom: 24 }}>
        <Col span={24}>
          <Form.Item noStyle name="key" rules={[{ required: true, message: 'Please select type' }]}>
            <Select
              placeholder="Select type"
              label="Type"
              data={DATA}
              onChange={(value) => setTypeName(String(value))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={14}>
              <Form.Item
                noStyle
                name="Name"
                rules={[{ required: true, message: 'Please enter name' }]}>
                <Input placeholder={namePlaceholder || 'www'} label="Name" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                noStyle
                name="TTL"
                rules={[{ required: true, message: 'Please enter TTL' }]}>
                <Input placeholder="3600" label="TTL" suffix="Sec" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item
            noStyle
            name="Rdata"
            rules={[{ required: true, message: 'Please enter value' }]}>
            <Input textarea placeholder={rDataPlaceholder || '127.0.0.1'} label="Value" />
          </Form.Item>
        </Col>
      </Row>
      <div className={styles.footer}>
        <Button className={styles.footerBtn} type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Form.Item shouldUpdate noStyle>
          {() => (
            <Button
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length).length
              }
              submit
              loading={loading}>
              Save
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};

export default ProjectRoutingTableAddModal;
