import React, { FC, useMemo } from 'react';
import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from 'react-fusioncharts';
import { IHistoricalStatisticsResponce } from 'models';
import moment from 'moment';
import { isEmpty, map, size } from 'lodash';
import { historicalChartConfigBW } from '../configs';

charts(FusionCharts);

interface IProjectChartsBandwidthNewProps {
  data?: IHistoricalStatisticsResponce[];
}

export const ProjectChartsBandwidthNew: FC<IProjectChartsBandwidthNewProps> = ({ data }) => {
  const returnChartData = useMemo(() => {
    if (!isEmpty(data)) {
      const mapedData = map(data, ({ bytes_out, timestamp }) => ({
        label: moment(timestamp).format('DD/MM/YYYY HH:mm'),
        value: bytes_out,
      }));
      return historicalChartConfigBW({
        xAxisValuesStep: size(data) > 0 ? `${size(data) / 5}` : '0',
        height: '204',
        type: 'area2d',
        data: mapedData,
        plottooltext: '<div style="padding: 4px"><strong>$label<br/>$dataValue</strong></div>',
      });
    }
    return historicalChartConfigBW({
      xAxisValuesStep: size(data) > 0 ? `${size(data) / 5}` : '0',
      height: '240',
      type: 'area2d',
      data: [],
    });
  }, [data]);
  return <>{!isEmpty(data) && <ReactFusioncharts {...returnChartData} />}</>;
};

export default ProjectChartsBandwidthNew;
