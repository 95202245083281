import { SelectItemType } from 'components/common/Select';

export const matchesSelectData: SelectItemType[] = [
  {
    name: 'Location',
    value: 'location',
  },
  {
    name: 'Request',
    value: 'request',
  },
  {
    name: 'URL',
    value: 'url',
  },
];

export const featuresSelectData: SelectItemType[] = [
  {
    name: 'Access',
    value: 'access',
  },
  {
    name: 'Caching',
    value: 'caching',
  },
  {
    name: 'General',
    value: 'general',
  },
  {
    name: 'Headers',
    value: 'headers',
  },
  {
    name: 'Logs',
    value: 'logs',
  },
  {
    name: 'Origin',
    value: 'origin',
  },
  {
    name: 'Specialty',
    value: 'specialty',
  },
  {
    name: 'URL',
    value: 'url',
  },
];

export const locationData: SelectItemType[] = [
  {
    name: 'AS Number',
    value: 'match.location.as-number.literal',
  },
  {
    name: 'City Name Literal',
    value: 'match.location.city-name.literal',
  },
  {
    name: 'City Name Regex',
    value: 'match.location.city-name.regex',
  },
  {
    name: 'Continent',
    value: 'match.location.continent.literal',
  },
  {
    name: 'Country',
    value: 'match.location.country.literal',
  },
  {
    name: 'DMA Code',
    value: 'match.location.dma-code.literal',
  },
  {
    name: 'Latitude',
    value: 'match.location.latitude.literal',
  },
  {
    name: 'Longitude',
    value: 'match.location.longitude.literal',
  },
  {
    name: 'Metro Code',
    value: 'match.location.metro-code.literal',
  },
  {
    name: 'Postal Code',
    value: 'match.location.postal-code.literal',
  },
  {
    name: 'Region Code',
    value: 'match.location.region-code.literal',
  },
];

export const requestData: SelectItemType[] = [
  {
    name: 'Client IP Address',
    value: 'match.request.client-ip-address.literal',
  },
  {
    name: 'Cookie Parameter Literal',
    value: 'match.request.cookie.literal',
  },
  {
    name: 'Cookie Parameter Regex',
    value: 'match.request.cookie.regex',
  },
  {
    name: 'Cookie Parameter Wildcard',
    value: 'match.request.cookie.wildcard',
  },
  {
    name: 'Referring Domain Literal',
    value: 'match.request.referring-domain.literal',
  },
  {
    name: 'Referring Domain Wildcard',
    value: 'match.request.referring-domain.wildcard',
  },
  {
    name: 'Request Header Literal',
    value: 'match.request.request-header.literal',
  },
  {
    name: 'Request Header Regex',
    value: 'match.request.request-header.regex',
  },
  {
    name: 'Request Header Wildcard',
    value: 'match.request.request-header.wildcard',
  },
  {
    name: 'Request Method',
    value: 'match.request.request-method.literal',
  },
  {
    name: 'Request Scheme',
    value: 'match.request.request-scheme.literal',
  },
];

export const urlData: SelectItemType[] = [
  {
    name: 'URL Path Literal',
    value: 'match.url.url-path.literal',
  },
  {
    name: 'URL Path Regex',
    value: 'match.url.url-path.regex',
  },
  {
    name: 'URL Path Wildcard',
    value: 'match.url.url-path.wildcard',
  },
  {
    name: 'URL Path Directory Literal',
    value: 'match.url.url-path-directory.literal',
  },
  {
    name: 'URL Path Directory Wildcard',
    value: 'match.url.url-path-directory.wildcard',
  },
  {
    name: 'URL Path Extension Literal',
    value: 'match.url.url-path-extension.literal',
  },
  {
    name: 'URL Path Extension Wildcard',
    value: 'match.url.url-path-extension.wildcard',
  },
  {
    name: 'URL Path Filename Literal',
    value: 'match.url.url-path-filename.literal',
  },
  {
    name: 'URL Path Filename Wildcard',
    value: 'match.url.url-path-filename.wildcard',
  },
  {
    name: 'URL Query Literal',
    value: 'match.url.url-query.literal',
  },
  {
    name: 'URL Query Regex',
    value: 'match.url.url-query.regex',
  },
  {
    name: 'URL Query Wildcard',
    value: 'match.url.url-query.wildcard',
  },
  {
    name: 'URL Query Parameter Literal',
    value: 'match.url.url-query-param.literal',
  },
  {
    name: 'URL Query Parameter Wildcard',
    value: 'match.url.url-query-param.wildcard',
  },
];

export const accessData: SelectItemType[] = [
  {
    name: 'Deny Access (403)',
    value: 'feature.access.deny-access',
  },
];

export const cachingData: SelectItemType[] = [
  {
    name: 'Bandwidth Parameters',
    value: 'feature.caching.bandwidth-parameters',
  },
  {
    name: 'Bandwidth Throttling',
    value: 'feature.caching.bandwidth-throttling',
  },
  {
    name: 'Bypass Cache',
    value: 'feature.caching.bypass-cache',
  },
  {
    name: 'Cache-Control Header Treatment',
    value: 'feature.caching.cache-control-header-treatment',
  },
  {
    name: 'Cache-Key Query String',
    value: 'feature.caching.cache-key-query-string',
  },
  {
    name: 'Cache-Key Rewrite',
    value: 'feature.caching.cache-key-rewrite',
  },
  {
    name: 'Compress File Types',
    value: 'feature.caching.compress-file-types',
  },
  {
    name: 'Default Internal Max-Age',
    value: 'feature.caching.default-internal-max-age',
  },
  {
    name: 'Expires Header Treatment',
    value: 'feature.caching.expires-header-treatment',
  },
  {
    name: 'External Max-Age',
    value: 'feature.caching.external-max-age',
  },
  {
    name: 'Force Internal Max-Age',
    value: 'feature.caching.force-internal-max-age',
  },
  {
    name: 'H.264 Support (HTTP Progressive Download)',
    value: 'feature.caching.h264-support',
  },
  {
    name: 'H.264 Support Video Seek Params',
    value: 'feature.caching.h264-support-video-seek-params',
  },
  {
    name: 'Honor No-Cache Request',
    value: 'feature.caching.honor-no-cache-request',
  },
  {
    name: 'Ignore Origin No-Cache',
    value: 'feature.caching.ignore-origin-no-cache',
  },
  {
    name: 'Ignore Unsatisfied Ranges',
    value: 'feature.caching.ignore-unsatisfiable-ranges',
  },
  {
    name: 'Internal Max-Stale',
    value: 'feature.caching.internal-max-stale',
  },
  {
    name: 'Partial Cache Sharing',
    value: 'feature.caching.partial-cache-sharing',
  },
  {
    name: 'Prevalidate Cached Content',
    value: 'feature.caching.prevalidate-cached-content',
  },
  {
    name: 'Refresh Zero-Bytes Cache Files',
    value: 'feature.caching.refresh-zero-byte-cache-files',
  },
  {
    name: 'Set Cacheable Status Code',
    value: 'feature.caching.set-cacheable-status-codes',
  },
  {
    name: 'Stale Content Delivery on Error',
    value: 'feature.caching.stale-content-delivery-on-error',
  },
  {
    name: 'Stale While Revalidate',
    value: 'feature.caching.stale-while-revalidate',
  },
];

export const generalData: SelectItemType[] = [{ name: 'Comment', value: 'feature.comment' }];

export const headersData: SelectItemType[] = [
  {
    name: 'Age Response Header',
    value: 'feature.headers.age-response-header',
  },
  {
    name: 'Debug Cache Response Header',
    value: 'feature.headers.debug-cache-response-headers',
  },
  {
    name: 'Modify Client Request Header',
    value: 'feature.headers.modify-client-request-header',
  },
  {
    name: 'Modify Client Response Header',
    value: 'feature.headers.modify-client-response-header',
  },
  {
    name: 'Set Client IP Custom Header',
    value: 'feature.headers.set-client-ip-custom-header',
  },
];

export const logsData: SelectItemType[] = [
  {
    name: 'Custom Log Field 1',
    value: 'feature.logs.custom-log-field-1',
  },
  {
    name: 'Log Query String',
    value: 'feature.logs.log-query-string',
  },
  {
    name: 'Mask Client Subnet',
    value: 'feature.logs.mask-client-subnet',
  },
];

export const originData: SelectItemType[] = [
  {
    name: 'Maximum Keep-Alive Requests',
    value: 'feature.origin.max-keep-alive-requests',
  },
  {
    name: 'Proxy Special Headers',
    value: 'feature.origin.proxy-special-headers',
  },
];

export const specialtyData: SelectItemType[] = [
  {
    name: 'Cacheable HTTP Methods',
    value: 'feature.specialty.cacheable-http-methods',
  },
  {
    name: 'Cacheable Request Body Size',
    value: 'feature.specialty.cacheable-request-body-size',
  },
  {
    name: 'Revalidate While Stale',
    value: 'feature.specialty.revalidate-while-stale',
  },
  {
    name: 'Revalidate While Stale Timer',
    value: 'feature.specialty.revalidate-while-stale-timer',
  },
  {
    name: 'feature.specialty.user-variable',
    value: 'feature.origin.max-keep-alive-requests',
  },
];

export const urlFeatureData: SelectItemType[] = [
  {
    name: 'Follow Redirects',
    value: 'feature.url.follow-redirects',
  },
  {
    name: 'URL Redirect',
    value: 'feature.url.url-redirect',
  },
  {
    name: 'URL Rewrite',
    value: 'feature.url.url-rewrite',
  },
];
