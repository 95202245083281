import React, {
  useState,
  useContext,
  createContext,
  FC,
  Dispatch,
  useEffect,
  ReactNode,
} from 'react';

export type ThemeType = 'light' | 'dark';

interface ThemeContextType {
  theme: ThemeType;
  setTheme?: Dispatch<React.SetStateAction<ThemeContextType['theme']>>;
  toggleTheme?: () => void;
}

export const ThemeContext = createContext<ThemeContextType>({
  theme: 'light',
  setTheme: () => undefined,
  toggleTheme: () => undefined,
});

export const useTheme = (): ThemeContextType => useContext(ThemeContext);

export const ThemeContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeContextType['theme']>(
    (localStorage.getItem('theme') as ThemeType) || 'light'
  );

  const setThemeClass = (themeName: ThemeType) => {
    document.documentElement.className = themeName;
  };

  const toggleTheme = () => {
    const nextTheme: ThemeType = theme === 'light' ? 'dark' : 'light';
    setTheme(nextTheme);
  };

  useEffect(() => {
    if (theme) {
      setThemeClass(theme);
      localStorage.setItem('theme', theme);
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
