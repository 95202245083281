import { useEffect, useState } from 'react';
import { propertyAPI } from 'services';
import { Origin, Hostname, IPropertySSLData } from 'models';
import { useAppDispatch } from 'store';
import {
  updateHostnamesDetails,
  updateOriginsDetails,
  updatePropertyDetails,
  updateStatus,
  updateTLSDetails,
} from 'store/slices/property';

const usePropertyHook = (directoryName: string | undefined, token: string) => {
  const dispatch = useAppDispatch();
  const [isPolling, setIsPollling] = useState<boolean>(false);

  const {
    data: propertyDetails,
    refetch: refetchProperty,
    isLoading: isPropertyLoading,
    isFetching: isPropertyFetching,
  } = propertyAPI.useFetchPropertyQuery(String(directoryName), {
    pollingInterval: isPolling ? 5000 : 0,
    skip: !token,
  });

  const { data: tlsValue, isLoading: isTlsLoading } = propertyAPI.useFetchDomainsQuery(
    String(directoryName),
    {
      pollingInterval: isPolling ? 5000 : 0,
      skip: !token,
    }
  );

  const [originsData, setOriginsData] = useState<Origin[]>([
    ...(propertyDetails?.data?.origins ?? []),
  ]);
  const [hostnamesData, setHostnamesData] = useState<Hostname[]>([
    ...(propertyDetails?.data?.hostnames ?? []),
  ]);
  const [statusValue, setStatusValue] = useState<string>('');
  const [tlsData, setTlsData] = useState<IPropertySSLData[]>(tlsValue?.data ?? []);

  useEffect(() => {
    if (propertyDetails?.success) dispatch(updatePropertyDetails(propertyDetails?.data));
  }, [dispatch, propertyDetails?.data, propertyDetails?.success]);

  useEffect(() => {
    dispatch(updateHostnamesDetails(hostnamesData));
  }, [dispatch, hostnamesData]);

  useEffect(() => {
    dispatch(updateOriginsDetails(originsData));
  }, [dispatch, originsData]);

  useEffect(() => {
    dispatch(updateStatus(statusValue));
  }, [dispatch, statusValue]);

  useEffect(() => {
    dispatch(updateTLSDetails(tlsData));
  }, [dispatch, tlsData]);

  useEffect(() => {
    if (statusValue === 'deploying') {
      setIsPollling(true);
    } else {
      setIsPollling(false);
    }
  }, [statusValue]);

  useEffect(() => {
    if (propertyDetails?.success && tlsValue?.success) {
      const { origins, hostnames, status } = propertyDetails?.data;

      const originsWithShieldsValues =
        origins.map((i: Origin) => {
          if (!i.shields) {
            return {
              ...i,
              shields: {
                apac: 'bypass',
                emea: 'bypass',
                us_east: 'bypass',
                us_west: 'bypass',
              },
            };
          }
          return {
            ...i,
          };
        }) ?? [];

      if (propertyDetails?.data?.origins.map((i) => i.shields).filter((i) => Boolean(i))) {
        setOriginsData(originsWithShieldsValues);
        setHostnamesData(hostnames);
        setTlsData(tlsValue?.data as IPropertySSLData[]);
        setStatusValue(status);
      } else {
        setOriginsData(origins);
        setHostnamesData(hostnames);
        setTlsData(tlsValue?.data as IPropertySSLData[]);
        setStatusValue(status);
      }
    }
  }, [
    dispatch,
    propertyDetails?.data,
    propertyDetails?.success,
    tlsValue?.data,
    tlsValue?.success,
  ]);

  return {
    propertyDetails,
    refetchProperty,
    isPropertyLoading,
    isPropertyFetching,
    originsData,
    setOriginsData,
    hostnamesData,
    setHostnamesData,
    statusValue,
    tlsData,
    isTlsLoading,
  };
};

export default usePropertyHook;
