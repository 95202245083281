export const MAP_WIDTH = 400;
export const MAP_HEIGHT = 250;

export const convertCoordinates = (lat: string | number, lon: string | number) => {
  const y = (-1 * Number(lat) + 90) * (Number(405) / 180);
  const x = (Number(lon) + 180) * (Number(715) / 360);
  return { x, y };
};

export const convertCoordinates2 = (lat: string | number, lon: string | number) => {
  const cosLat = Math.cos((Number(lat) * Math.PI) / 180);
  //   const sinLat = Math.sin((Number(lat) * Math.PI) / 180);
  const cosLon = Math.cos((Number(lon) * Math.PI) / 180);
  const sinLon = Math.sin((Number(lon) * Math.PI) / 180);
  const rad = MAP_WIDTH * 2;
  const x = rad * cosLat * cosLon;
  const y = rad * cosLat * sinLon;
  return { x, y };
};

export const bytesToSize = (bytes: number): string => {
  const sizes: string[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];
  if (bytes === 0) return `0 byte`;
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
  return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
};

export default convertCoordinates;
