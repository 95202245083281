import React, { FC } from 'react';
import ProjectOriginHostnames from 'components/views/Project/ProjectOrigin/ProjectOriginHostnames/ProjectOriginHostnames';

export const ProjectOriginCnamesView: FC = () => {
  return (
    <>
      <ProjectOriginHostnames />
    </>
  );
};

export default ProjectOriginCnamesView;
