/* eslint-disable no-underscore-dangle */

import { Col, Divider, Row, Skeleton } from 'antd';
// import { Icon } from 'components/common/Icon';
import { Label, LabelType } from 'components/common/Label';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { findIndex, isEmpty, map, orderBy, round, size } from 'lodash';
import { DataModelHistogramLogsItem, DataModelLogsItem, DataModelPieLogsItem } from 'models';
import moment from 'moment';
import { HISTOGRAM, PIE } from 'views/Project/ProjectLogsView/constants';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { colorBrewer } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useAppDispatch, useAppSelector } from 'store';
import { Drawer } from 'components/common/Drawer';
import { addTag } from 'store/slices/logs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ProjectLogsViewListTableDrawerContent } from './ProjectLogsViewListTableDrawerContent';
import { ProjectLogsPieChart } from './ProjectLogsPieChart/ProjectLogsPieChart';
import { ProjectLogsHistogramChart } from './ProjectLogsHistogramChart/ProjectLogsHistogramChart';
import styles from './ProjectLogsViewListTable.module.scss';

interface ActiveFilterType {
  name: 'timestamp' | 'delay' | 'time' | 'message';
  orderAsc: boolean;
}

interface IProjectLogsViewListTableProps {
  data?: DataModelLogsItem[];
  loadMoreData: () => void;
  nextLink: boolean;
  isChartShown?: boolean;
  checkedDataType: number;
  isLoading: boolean;
  isFull?: boolean;
  isWAF?: boolean;
  isTableDataLoading?: boolean;
  chartType: string;
  chartPieData: DataModelPieLogsItem[];
  chartHistogramData: DataModelHistogramLogsItem[];
  groupByParams: string[];
  dataType: string;
}

export const ProjectLogsViewListTable: FC<IProjectLogsViewListTableProps> = ({
  data,
  loadMoreData,
  nextLink,
  isChartShown,
  checkedDataType,
  isLoading,
  isFull,
  isWAF,
  isTableDataLoading,
  chartType,
  chartPieData,
  chartHistogramData,
  groupByParams,
  dataType,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  // const [currentData, setCurrentData] = useState([] as DataModelLogsItem[]);

  // useEffect(() => {
  //   if (!isTableDataLoading && !isLoading) setCurrentData([...(data || [])]);
  // }, [data, isLoading, isTableDataLoading]);

  useEffect(() => {
    if (checkedDataType !== tabIndex && !isTableDataLoading && !isLoading)
      setTabIndex(checkedDataType);
  }, [checkedDataType, isLoading, isTableDataLoading, tabIndex]);

  const isTableExtended = tabIndex === 0;

  const [activeFilter, setActiveFilter] = useState<ActiveFilterType>({
    name: 'timestamp',
    orderAsc: false,
  });
  const [selectedRow, setSelectedRow] = useState<DataModelLogsItem>();
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();
  const { tags } = useAppSelector(({ logs }) => logs);

  const handleSortClick = (name: ActiveFilterType['name']) => {
    if (activeFilter.name !== name) {
      setActiveFilter({
        name,
        orderAsc: false,
      });
    } else {
      setActiveFilter((prevState) => ({ ...prevState, orderAsc: !prevState.orderAsc }));
    }
  };

  const handleRowClick = useCallback(
    (item: DataModelLogsItem) => {
      if (item._id === selectedRow?._id) return;
      setSelectedRow(item);
      setVisible(true);
    },
    [selectedRow]
  );

  const handleCloseDrawerClick = useCallback(() => {
    setVisible(false);
    setSelectedRow(undefined);
  }, []);

  const sortedData = useMemo(() => {
    return orderBy(data, [activeFilter.name], [activeFilter.orderAsc ? 'asc' : 'desc']);
  }, [activeFilter, data]);

  const getStatusLabel = useCallback((status: string): LabelType => {
    switch (status) {
      case 'ok':
        return 'success';
      case 'info':
        return 'info';
      case 'notice':
        return 'default';
      case 'emergency':
        return 'danger';
      default:
        return 'default';
    }
  }, []);

  const handleUserKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (sortedData) {
        const idx = findIndex(sortedData, ({ _id }) => _id === selectedRow?._id);
        if (e.key === 'ArrowUp') {
          if (idx === 0) {
            return;
          }
          setSelectedRow(sortedData[idx - 1]);
        }
        if (e.key === 'ArrowDown') {
          if (idx === size(sortedData) - 1) {
            return;
          }
          setSelectedRow(sortedData[idx + 1]);
        }
      }
    },
    [sortedData, selectedRow?._id]
  );

  useEffect(() => {
    if (selectedRow) {
      document.addEventListener('keyup', handleUserKeyPress);
    }
    return () => document.removeEventListener('keyup', handleUserKeyPress);
  }, [handleUserKeyPress, selectedRow]);

  const renderHeaderContent = useMemo(() => {
    return (
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Col>
          <Row gutter={10}>
            <Col>
              <Label type={getStatusLabel(selectedRow?._source.status || 'info')}>
                {selectedRow?._source.status}
              </Label>
            </Col>
            <Col>
              <span className={styles.date}>
                {moment(selectedRow?._source.timestamp).format('MMM DD, YYYY - hh:mm:ss')}{' '}
                <span>({moment(selectedRow?._source.timestamp).fromNow()})</span>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }, [getStatusLabel, selectedRow?._source.status, selectedRow?._source.timestamp]);

  const onAddFilterClick = useCallback(
    (objectKey: string, val: string | number) => {
      const str = `@${objectKey}:${val}`;
      if (findIndex(tags, (tag) => tag === str) === -1) {
        dispatch(addTag(str));
      }
    },
    [dispatch, tags]
  );

  const onExcludeClick = useCallback(
    (objectKey: string, val: string | number) => {
      const str = `-@${objectKey}:${val}`;
      if (findIndex(tags, (tag) => tag === str) === -1) {
        dispatch(addTag(str));
      }
    },
    [dispatch, tags]
  );

  const emptyData = useMemo(() => {
    return (
      // !isLoading &&
      <div className="ant-empty ant-empty-normal">
        <div className="ant-empty-image">
          <svg
            className="ant-empty-img-simple"
            width="64"
            height="41"
            viewBox="0 0 64 41"
            xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(0 1)" fill="none" fillRule="evenodd">
              <ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7" />
              <g className="ant-empty-img-simple-g" fillRule="nonzero">
                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                <path
                  d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  className="ant-empty-img-simple-path"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="ant-empty-description">No data</div>
      </div>
    );
  }, []);

  if (dataType === 'ADN' && chartType === HISTOGRAM) {
    return (
      <>
        {!isEmpty(chartHistogramData) ? (
          <ProjectLogsHistogramChart
            data={chartHistogramData}
            groupByParams={groupByParams}
            isLoading={isLoading}
          />
        ) : (
          emptyData
        )}
      </>
    );
  }

  if (dataType === 'ADN' && chartType === PIE) {
    return (
      <>
        {!isEmpty(chartPieData) ? (
          <ProjectLogsPieChart
            data={chartPieData}
            groupByParams={groupByParams}
            isLoading={isLoading}
          />
        ) : (
          emptyData
        )}
      </>
    );
  }

  return (
    <>
      {!isEmpty(data) ? (
        <>
          <Drawer
            width={800}
            open={visible}
            onClose={handleCloseDrawerClick}
            renderHeaderContent={renderHeaderContent}>
            <ProjectLogsViewListTableDrawerContent
              data={selectedRow}
              onAddFilterClick={onAddFilterClick}
              onExcludeClick={onExcludeClick}
              isWAF={isWAF}
            />
          </Drawer>
          <Row className={styles.row}>
            <Col span={3} className={styles.col} onClick={() => handleSortClick('timestamp')}>
              <span className={styles.label}>date</span>
            </Col>
            {isTableExtended && (
              <Col
                span={2}
                className={cn(styles.col, styles.right)}
                onClick={() => handleSortClick('delay')}>
                <span className={styles.label}>Total Time</span>
              </Col>
            )}
            {isTableExtended && (
              <Col
                span={3}
                className={cn(styles.col, styles.right)}
                onClick={() => handleSortClick('time')}>
                <span className={styles.label}>Read time</span>
              </Col>
            )}
            <Col
              flex={1}
              className={cn(styles.col, styles.pl)}
              onClick={() => handleSortClick('message')}>
              <span className={styles.label}>message</span>
            </Col>
          </Row>
          <div
            id="scrollableDiv"
            className={cn(styles.wrapper, {
              [styles.withoutChart]: !isChartShown,
              [styles.isFull]: isFull,
            })}>
            <InfiniteScroll
              className={cn(styles.wrapper, {
                [styles.origin]: !isLoading || !isTableDataLoading,
                [styles.grayed]: isLoading || isTableDataLoading,
              })}
              dataLength={size(data)}
              next={loadMoreData}
              hasMore={nextLink}
              loader={
                <div style={{ padding: 24 }}>
                  <Skeleton paragraph={{ rows: 1 }} active />
                </div>
              }
              endMessage={<Divider plain>All logs loaded</Divider>}
              scrollableTarget="scrollableDiv">
              {map(data, (item) => {
                return (
                  <Row
                    className={cn(styles.row, styles.item, {
                      [styles.active]: selectedRow?._id === item._id,
                      [styles.bg2xx]:
                        item._source.status_code >= 200 && item._source.status_code < 300,
                      [styles.bg3xx]:
                        item._source.status_code >= 300 && item._source.status_code < 400,
                      [styles.bg4xx]:
                        item._source.status_code >= 400 && item._source.status_code < 500,
                      [styles.bg5xx]:
                        item._source.status_code >= 500 && item._source.status_code < 600,
                    })}
                    key={item._id}
                    onClick={() => handleRowClick(item)}>
                    <Col span={3} className={styles.col}>
                      <span className={styles.value}>
                        <span className={styles.color_status} />
                        {moment(item._source.timestamp).format('MMM DD HH:mm:ss.SSS')}
                      </span>
                    </Col>
                    {isTableExtended && (
                      <>
                        <Col span={2} className={cn(styles.col, styles.right)}>
                          <span className={styles.value}>
                            {round(item._source.total_time * 1000, 1) || 0}ms
                          </span>
                        </Col>
                        <Col span={3} className={cn(styles.col, styles.right)}>
                          <span className={styles.value}>
                            {round(item._source.write_time * 1000, 1) || 0}ms
                          </span>
                        </Col>
                      </>
                    )}
                    <Col flex={1} className={cn(styles.col, styles.pl)}>
                      <SyntaxHighlighter
                        language="json"
                        className={styles.code}
                        style={colorBrewer}
                        useInlineStyles={false}
                        codeTagProps={{ style: { color: '#3394dc' } }}
                        customStyle={{
                          padding: 0,
                          margin: 0,
                          width: '100%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          background: 'transparent',
                          fontSize: 12,
                        }}>
                        {JSON.stringify(item._source)}
                      </SyntaxHighlighter>
                    </Col>
                  </Row>
                );
              })}
            </InfiniteScroll>
          </div>
        </>
      ) : (
        emptyData
      )}
    </>
  );
};

export default ProjectLogsViewListTable;
