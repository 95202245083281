import { createApi } from '@reduxjs/toolkit/query/react';
import { IDataType } from 'components/views/Project/ProjectCharts/configs';
import { map, orderBy } from 'lodash';
// import { map } from 'lodash';
import {
  IHistoricalStatisticsCountryResponse,
  IHistoricalStatisticsCountryItemResponse,
  IHistoricalStatisticsDnsResponce,
  IHistoricalStatisticsResponce,
  ISecurityEventsResponse,
  ISecurityTopTenEventsResponse,
  ISecurityTopTenEventsResult,
  IHistoricalStatisticsResponseRaw,
  IHistoricalStatisticsItemResponseRaw,
  RawResponse,
  ILogsAggregatePayload,
  ILogsAggregateChartResponse,
} from 'models';
import moment from 'moment';
// import moment from 'moment';
import { BASE_URL_STATISTICS, endpoints } from '../urls';
import { baseQuery } from './base';

export const statisticsAPI = createApi({
  reducerPath: 'statisticsAPI',
  baseQuery: baseQuery(BASE_URL_STATISTICS),
  tagTypes: ['Statistics'],
  endpoints: (build) => ({
    fetchHistoricalStristics: build.query<
      IHistoricalStatisticsResponce[],
      {
        frequency: 5 | 15 | 60 | 1440;
        cname: string;
        startDate: string;
        endDate: string;
        by?: string;
      }
    >({
      query: ({ frequency, cname, startDate, endDate, by }) => ({
        url: endpoints.statistics.common(frequency, cname),
        params: {
          start_date: startDate,
          end_date: endDate,
          by,
        },
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformResponse: (response: IHistoricalStatisticsResponseRaw) => {
        return orderBy(
          map(
            response.data?.historical_data?.buckets,
            (item: IHistoricalStatisticsItemResponseRaw) => {
              return {
                byte_hit: Number(item?.byte_hit?.value || 0),
                byte_hit_percentage: Number(item?.byte_hit_percentage?.value || 0),
                byte_miss: Number(item?.byte_miss?.value || 0),
                byte_miss_percentage: Number(item?.byte_miss_percentage?.value || 0),
                bytes_in: Number(item?.bytes_in?.value || 0),
                bytes_out: Number(item?.bytes_out?.value || 0),
                cache_hit: Number(item?.cache_hit?.value || 0),
                cache_hit_percentage: Number(item?.cache_hit_percentage?.value || 0),
                cache_miss: Number(item?.cache_miss?.value || 0),
                cache_miss_percentage: Number(item?.cache_miss_percentage?.value || 0),
                client_city: String(item?.client_city?.value || ''),
                client_region: String(item?.client_region?.value || ''),
                completed_downloads: Number(item?.completed_downloads?.value || 0),
                config_nocache: Number(item?.config_nocache?.value || 0),
                download_attempts: Number(item?.download_attempts?.value || 0),
                client_continent_code: String(item?.client_continent_code || ''),
                file_size: Number(item?.file_size?.value || 0),
                host: String(item?.host || ''),
                pop: String(item?.pop || ''),
                request_count: Number(item?.request_count?.value || 0),
                request_rate: Number(item?.request_rate?.value || 0),
                status_2xx: Number(item?.status_2xx?.value || 0),
                status_2xx_percentage: Number(item?.status_2xx_percentage?.value || 0),
                status_3xx: Number(item?.status_3xx?.value || 0),
                status_3xx_percentage: Number(item?.status_3xx_percentage?.value || 0),
                status_4xx: Number(item?.status_4xx?.value || 0),
                status_4xx_percentage: Number(item?.status_4xx_percentage?.value || 0),
                status_5xx: Number(item?.status_5xx?.value || 0),
                status_5xx_percentage: Number(item?.status_5xx_percentage?.value || 0),
                tcp_client_refresh_miss: Number(item?.tcp_client_refresh_miss?.value || 0),
                tcp_expired_hit: Number(item?.tcp_expired_hit?.value || 0),
                tcp_expired_miss: Number(item?.tcp_expired_miss?.value || 0),
                tcp_hit: Number(item?.tcp_hit?.value || 0),
                tcp_mem_hit: Number(item?.tcp_mem_hit?.value || 0),
                tcp_miss: Number(item?.tcp_miss?.value || 0),
                tcp_partial_hit: Number(item?.tcp_partial_hit?.value || 0),
                tcp_partial_miss: Number(item?.tcp_partial_miss?.value || 0),
                total_time: Number(item?.total_time?.value || 0),
                transfer_rate: Number(item?.transfer_rate?.value || 0),
                uncacheable: Number(item?.uncacheable?.value || 0),
                timestamp: moment(item.key_as_string).format('MMM D, YYYY, HH:mm'),
                _id: { $oid: '' },
              };
            }
          ),
          ['timestamp'],
          ['asc']
        );
      },
      providesTags: () => ['Statistics'],
    }),
    fetchHistoricalStristicsByCountry: build.query<
      IHistoricalStatisticsCountryItemResponse,
      {
        frequency: 5 | 15 | 60 | 1440;
        cname: string;
        startDate: string;
        endDate: string;
        by?: string;
      }
    >({
      query: ({ frequency, cname, startDate, endDate, by }) => ({
        url: endpoints.statistics.countries(frequency, cname),
        params: {
          start_date: startDate,
          end_date: endDate,
          by,
        },
      }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore : todo
      transformResponse: (response: IHistoricalStatisticsCountryResponse) => {
        return orderBy(
          map(response.data.country.buckets, (item) => ({
            ...item,
            value: item.doc_count,
            client_country_code: item.key,
          })),
          ['client_country_code'],
          ['asc']
        );
      },
      providesTags: () => ['Statistics'],
    }),
    // routing page chart
    fetchHistoricalStristicsDns: build.query<
      IHistoricalStatisticsDnsResponce[],
      { frequency: string; cname: string; startDate: string; endDate: string }
    >({
      query: ({ frequency, cname, startDate, endDate }) => ({
        url: endpoints.statistics.dnsSummary(frequency, cname),
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
      transformResponse: (response: IHistoricalStatisticsDnsResponce[]) => {
        return map(response, (item) => ({
          ...item,
          QueryCount: Number(item.QueryCount),
        }));
      },
    }),

    // security page charts
    fetchHistoricalStristicsEvents: build.query<
      IDataType[],
      { cname: string; type: 1 | 5 | 24; startDate: string | number; endDate: string | number }
    >({
      query: ({ cname, startDate, endDate, type }) => ({
        url: endpoints.statistics.events(cname, type),
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
      transformResponse: (response: ISecurityEventsResponse) => {
        return map(response?.data, ({ count, timestamp }) => ({
          value: count,
          label: timestamp,
        }));
      },
    }),

    fetchHistoricalStristicsRateLimit: build.query<
      IDataType[],
      { cname: string; type: 1 | 5 | 24; startDate: string | number; endDate: string | number }
    >({
      query: ({ cname, startDate, endDate, type }) => ({
        url: endpoints.statistics.rateLimit(cname, type),
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
      transformResponse: (response: ISecurityEventsResponse) => {
        return map(response?.data, ({ count, timestamp }) => ({
          value: count,
          label: timestamp,
        }));
      },
    }),

    // security tables
    fetchHistoricalRaw: build.query<
      RawResponse,
      { cname: string; start_date?: string | number; end_date?: string | number; page?: number }
    >({
      query: ({ cname, start_date, end_date, page }) => ({
        url: endpoints.statistics.raw(cname),
        params: {
          start_date,
          end_date,
          page,
        },
      }),
    }),
    fetchHistoricalRawRateLimiting: build.query<
      RawResponse,
      { cname: string; start_date?: string | number; end_date?: string | number; page?: number }
    >({
      query: ({ cname, start_date, end_date, page }) => ({
        url: endpoints.statistics.rawRateLimiting(cname),
        params: {
          start_date,
          end_date,
          page,
        },
      }),
    }),
    fetchHistoricalRawBotManagement: build.query<
      RawResponse,
      { cname: string; start_date?: string | number; end_date?: string | number; page?: number }
    >({
      query: ({ cname, start_date, end_date, page }) => ({
        url: endpoints.statistics.botManagement(cname),
        params: {
          start_date,
          end_date,
          page,
        },
      }),
    }),
    fetchHistoricalRawTopTen: build.query<
      ISecurityTopTenEventsResult,
      { cname: string; property: string; start_date: string | number; end_date: string | number }
    >({
      query: ({ cname, property, start_date, end_date }) => ({
        url: endpoints.statistics.rawTopTen(cname),
        params: {
          type: 'count',
          property,
          start_date,
          end_date,
        },
      }),
      transformResponse: (
        response: ISecurityTopTenEventsResponse,
        meta,
        arg: { property: string }
      ) => {
        const result: ISecurityTopTenEventsResult = {
          data: [],
        };
        result.data = response.data?.[arg.property]?.buckets.map(
          (item: { key: string; doc_count: number }) => ({
            _id: item.key,
            count: item.doc_count,
          })
        );
        return result;
      },
    }),
    fetchHistoricalRawRateLimitingTopTen: build.query<
      ISecurityTopTenEventsResult,
      { cname: string; property: string; start_date: string | number; end_date: string | number }
    >({
      query: ({ cname, property, start_date, end_date }) => ({
        url: endpoints.statistics.rawRateLimitingTopTen(cname),
        params: {
          type: 'count',
          property,
          start_date,
          end_date,
        },
      }),
      transformResponse: (
        response: ISecurityTopTenEventsResponse,
        meta,
        arg: { property: string }
      ) => {
        const result: ISecurityTopTenEventsResult = {
          data: [],
        };
        result.data = response.data?.[arg.property]?.buckets.map(
          (item: { key: string; doc_count: number }) => ({
            _id: item.key,
            count: item.doc_count,
          })
        );
        return result;
      },
    }),
    fetchHistoricalRawBotManagementTopTen: build.query<
      ISecurityTopTenEventsResult,
      { cname: string; property: string; start_date: string | number; end_date: string | number }
    >({
      query: ({ cname, property, start_date, end_date }) => ({
        url: endpoints.statistics.botManagementTopTen(cname),
        params: {
          type: 'count',
          property,
          start_date,
          end_date,
        },
      }),
      transformResponse: (
        response: ISecurityTopTenEventsResponse,
        meta,
        arg: { property: string }
      ) => {
        const result: ISecurityTopTenEventsResult = {
          data: [],
        };
        result.data = response.data?.[arg.property]?.buckets.map(
          (item: { key: string; doc_count: number }) => ({
            _id: item.key,
            count: item.doc_count,
          })
        );
        return result;
      },
    }),

    // Logs
    aggregateLogsChart: build.mutation<ILogsAggregateChartResponse, ILogsAggregatePayload>({
      query: ({ collection, ...rest }) => ({
        url: endpoints.aggregateLogs(collection),
        method: 'POST',
        body: {
          ...rest,
        },
      }),
    }),
  }),
});

export default statisticsAPI;
