/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import { IField, selectCodeObject, switchEnabledObject } from './fieldsObjects';

export const followRedirects: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const urlRedirect: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'source',
    label: 'Source',
    regexp: /.+/,
    tooltip: 'Regex Pattern: ',
  },
  {
    id: 1,
    type: 'input',
    fieldName: 'destination',
    label: 'Destination',
  },
  {
    id: 2,
    ...selectCodeObject,
  },
];

export const urlRewrite: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'source',
    label: 'Source',
    regexp: /.+/,
    tooltip: 'Regex Pattern: ',
  },
  {
    id: 1,
    type: 'input',
    fieldName: 'destination',
    label: 'Destination',
  },
];
