import React from 'react';
import {
  ProjectEngineDraft,
  // ProjectEngineEngine,
  ProjectEngineHistory,
} from 'components/views/Project/ProjectEngine';
import { MainLayout } from 'components';
import { Routes, Route, Outlet } from 'react-router-dom';
import { ProjectEngineDraftTable } from 'components/views/Project/ProjectEngine/ProjectEngineDraft/ProjectEngineDraftTable';
import { ProjectEngineDraftDetails } from 'views/Project/ProjectEngine/ProjectEngineDraftDetails';
import { ProjectOriginDeploymentsView } from 'views/Project/ProjectOrigin/ProjectOriginDeploymentsView';
import ProjectOriginDeploymentDetailsView from 'views/Project/ProjectOrigin/ProjectOriginDeploymentDetailsView/ProjectOriginDeploymentDetailsView';
import CreateProperty from 'views/CreateProperty/CreateProperty';
import {
  Error,
  ProjectOrigin,
  ProjectEngine,
  ProjectDetails,
  UserSettings,
  UserSettingsBilling,
  UserSettingsInvoices,
  ProjectLogsView,
  ProjectRouting,
  UserSettingsSettings,
  ProjectSecurity,
  Home,
  Project,
  ProjectOriginOriginView,
  ProjectOriginSecurityView,
  ProjectOriginCnamesView,
  ProjectOriginAlertsView,
  ProjectOriginApiView,
  ProjectOriginServiceView,
  ProjectOriginCollaborationView,
  // ProjectOriginCacheView,
  ProjectOriginRulesView,
  ProjectOriginAccessControl,
  UserSettingsBillingReports,
  ProjectOriginTLSView,
} from '../views';
import { RouteTypes } from './types';
// import { ProjectOriginCacheView } from '../views/Project/ProjectOrigin/ProjectOriginCacheView/ProjectOriginCacheView';
// import { ProjectOriginRulesView } from '../views/Project/ProjectOrigin/ProjectOriginRulesView/ProjectOriginRulesView';

export const RouterView = () => {
  return (
    <Routes>
      <Route
        element={
          <MainLayout>
            <Outlet />
          </MainLayout>
        }>
        <Route path={RouteTypes.home} element={<Home />} />
        <Route path={RouteTypes.create} element={<CreateProperty />} />
        <Route path={RouteTypes.project} element={<Project />}>
          <Route path={RouteTypes.details} element={<ProjectDetails />} />
          <Route path={RouteTypes.settings} element={<ProjectOrigin />}>
            <Route path={RouteTypes.settings} element={<ProjectOriginOriginView />} />
            <Route path={RouteTypes.settingsSecurity} element={<ProjectOriginSecurityView />} />
            <Route
              path={RouteTypes.settingsDeployments}
              element={<ProjectOriginDeploymentsView />}
            />
            <Route
              path={RouteTypes.settingsDeploymentsDetails}
              element={<ProjectOriginDeploymentDetailsView />}
            />
            <Route path={RouteTypes.settingsHostnames} element={<ProjectOriginCnamesView />} />
            <Route path={RouteTypes.settingsAlerts} element={<ProjectOriginAlertsView />} />
            <Route path={RouteTypes.settingsApi} element={<ProjectOriginApiView />} />
            <Route path={RouteTypes.settingsService} element={<ProjectOriginServiceView />} />
            <Route path={RouteTypes.settingsAccess} element={<ProjectOriginAccessControl />} />
            <Route
              path={RouteTypes.settingsCollaboration}
              element={<ProjectOriginCollaborationView />}
            />
            <Route path={RouteTypes.settingsRules} element={<ProjectOriginRulesView />} />
            {/* <Route path={RouteTypes.settingsCache} element={<ProjectOriginCacheView />} /> */}
            <Route path={RouteTypes.settingsTLS} element={<ProjectOriginTLSView />} />
          </Route>
          <Route path={RouteTypes.routing} element={<ProjectRouting />} />
          <Route path={RouteTypes.security} element={<ProjectSecurity />} />
          <Route path={RouteTypes.engine} element={<ProjectEngine />}>
            {/* <Route path={RouteTypes.engine} element={<ProjectEngineEngine />} /> */}
            <Route path={RouteTypes.engineHistory} element={<ProjectEngineHistory />} />
            <Route path={RouteTypes.engineDraft} element={<ProjectEngineDraft />}>
              <Route path={RouteTypes.engineDraft} element={<ProjectEngineDraftTable />} />
              <Route path={RouteTypes.engineDraftDetails} element={<ProjectEngineDraftDetails />} />
            </Route>
          </Route>
          <Route path={RouteTypes.logs} element={<ProjectLogsView />} />
        </Route>
        <Route path={RouteTypes.userSettings} element={<UserSettings />}>
          <Route path={RouteTypes.userSettings} element={<UserSettingsSettings />} />
          <Route path={RouteTypes.billing} element={<UserSettingsBilling />} />
          <Route path={RouteTypes.invoices} element={<UserSettingsInvoices />} />
          <Route path={RouteTypes.billingReports} element={<UserSettingsBillingReports />} />
        </Route>
      </Route>

      <Route path={RouteTypes.error401} element={<Error errorNumber={401} />} />
      <Route path="*" element={<Error errorNumber={404} />} />
    </Routes>
  );
};

export default RouterView;
