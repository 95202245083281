import { Col, Row } from 'antd';
import {
  ProjectSecurityWhiteListTable,
  ProjectSecurityBlackListTable,
} from 'components/views/Project/ProjectSecurity';
import React, { FC } from 'react';

export const ProjectOriginAccessControl: FC = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <ProjectSecurityWhiteListTable />
      </Col>
      <Col span={24}>
        <ProjectSecurityBlackListTable />
      </Col>
    </Row>
  );
};

export default ProjectOriginAccessControl;
