import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_URL_AUTH0, endpoints } from '../urls';
import { baseQuery } from './base';

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: baseQuery(BASE_URL_AUTH0),
  tagTypes: ['Auth'],
  endpoints: (build) => ({
    resetPassword: build.mutation<
      unknown,
      { client_id: string; email: string; connection: string }
    >({
      query: (body) => ({
        url: endpoints.resetPassword,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export default authAPI;
