import './ProjectEngineEngineAddDropdown.scss';
import Dropdown from 'components/common/Dropdown';
import { Icon } from 'components/common/Icon';
import React, { CSSProperties, forwardRef, useImperativeHandle, useState } from 'react';
import classNames from 'classnames';

interface Props {
  onMatchClick?: () => void;
  onSelectFirstMatchClick?: () => void;
  onFeatureClick?: () => void;
  isSecondary?: boolean;
  style?: CSSProperties;
  isFirstMatchDissabled?: boolean;
  disabled?: boolean;
}

export interface RefProps {
  onMatchButtonClick: () => void;
  onSelectFirstMatchButtonClick: () => void;
  onFeatureButtonClick: () => void;
}

export const ProjectEngineEngineAddDropdown = forwardRef<RefProps, Props>(
  (
    {
      onMatchClick,
      onSelectFirstMatchClick,
      onFeatureClick,
      isSecondary,
      style,
      isFirstMatchDissabled,
      disabled,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      onMatchButtonClick() {
        onMatchClick?.();
        setIsOpen(false);
      },
      onSelectFirstMatchButtonClick() {
        onSelectFirstMatchClick?.();
        setIsOpen(false);
      },
      onFeatureButtonClick() {
        onFeatureClick?.();
        setIsOpen(false);
      },
    }));

    return (
      <div style={style}>
        {disabled ? (
          <button
            type="button"
            disabled={disabled}
            className={classNames('ProjectEngineEngineAddDropdown', {
              _secondary: isSecondary,
              _open: isSecondary && isOpen,
            })}>
            <span className="ProjectEngineEngineAddDropdown__line" />
            <Icon name="plus" className="ProjectEngineEngineAddDropdown__plus" />
            <Icon
              name="dropdown"
              className={classNames('ProjectEngineEngineAddDropdown__chevron', {
                _active: isOpen,
              })}
            />
          </button>
        ) : (
          <Dropdown
            trigger={['hover']}
            onVisibleChange={setIsOpen}
            visible={isOpen}
            triggerElement={
              <button
                type="button"
                className={classNames('ProjectEngineEngineAddDropdown', {
                  _secondary: isSecondary,
                  _open: isSecondary && isOpen,
                })}>
                <span className="ProjectEngineEngineAddDropdown__line" />
                <Icon name="plus" className="ProjectEngineEngineAddDropdown__plus" />
                <Icon
                  name="dropdown"
                  className={classNames('ProjectEngineEngineAddDropdown__chevron', {
                    _active: isOpen,
                  })}
                />
              </button>
            }>
            <div className="ProjectEngineEngineAddDropdown__dropdown">
              {isSecondary ? (
                <>
                  <button
                    type="button"
                    className="ProjectEngineEngineAddDropdown__dropdown-btn"
                    onClick={() => {
                      onMatchClick?.();
                      setIsOpen(false);
                    }}>
                    Match
                  </button>
                  <button
                    type="button"
                    className="ProjectEngineEngineAddDropdown__dropdown-btn"
                    onClick={() => {
                      onFeatureClick?.();
                      setIsOpen(false);
                    }}>
                    Feature
                  </button>{' '}
                </>
              ) : (
                <>
                  {!isFirstMatchDissabled && (
                    <button
                      type="button"
                      className="ProjectEngineEngineAddDropdown__dropdown-btn"
                      onClick={() => {
                        onSelectFirstMatchClick?.();
                        setIsOpen(false);
                      }}>
                      Select First Match
                    </button>
                  )}
                  <button
                    type="button"
                    className="ProjectEngineEngineAddDropdown__dropdown-btn"
                    onClick={() => {
                      onMatchClick?.();
                      setIsOpen(false);
                    }}>
                    Match
                  </button>{' '}
                </>
              )}
            </div>
          </Dropdown>
        )}
      </div>
    );
  }
);

export default ProjectEngineEngineAddDropdown;
