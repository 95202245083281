/* eslint-disable no-console */
// import './ProjectEngineEngine.scss';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { isEmpty, map } from 'lodash';
import { Col, Row, Form } from 'antd';
import { useAppSelector } from 'store';
import { useParams } from 'react-router-dom';
import { rulesAPI } from 'services';
import { Loader } from 'components/common/Loader';
import {
  ProjectEngineEngineAddDropdown,
  RefProps,
} from '../../../ProjectEngineEngine/ProjectEngineEngineAddDropdown/ProjectEngineEngineAddDropdown';
import { ProjectEngineItem } from '../../../ProjectEngineEngine/ProjectEngineItem';

const { useForm, List } = Form;

interface ProjectEngineDraftItemChildProps {
  id?: number | string;
}

export const ProjectEngineDraftItemChild: FC<ProjectEngineDraftItemChildProps> = ({ id }) => {
  const [form] = useForm();
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { selectedCdn } = useAppSelector(({ project }) => project);
  const { token } = useAppSelector(({ auth }) => auth);
  const matchDropdown = useRef<RefProps>(null);

  const { data: rule, isLoading: isRuleLoading } = rulesAPI.useGetRuleDetailsQuery(
    { cnameId: String(selectedCdn), directoryName: String(directoryName), ruleId: String(id) },
    { skip: !token || !directoryName || !selectedCdn || !id }
  );

  const onFinish = useCallback(() => {
    // Notification({
    //   type: 'check',
    //   title: 'Values from form',
    //   message: <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>,
    // });
    // console.log('Received values of form:', values);
    //   createRule({
    //     cnameId: String(selectedCdn),
    //     directoryName: String(directoryName),
    //     data: { rule: values },
    //   })
    //     .unwrap()
    //     .then((v) =>
    //       Notification({
    //         type: 'check',
    //         title: v.message as string,
    //       })
    //     );
  }, []);

  // const onAddFirstMatchClick = () => {
  //   const allMatches = form.getFieldValue('matches') || [];
  //   const indexOfFirstMatch = findIndex(allMatches, { type: 'select.first-match' });

  //   if (indexOfFirstMatch === -1) {
  //     form.setFieldsValue({
  //       ...form.getFieldsValue(),
  //       matches: [
  //         {
  //           type: 'select.first-match',
  //           useless: 'first-match',
  //           matches: [null, null],
  //         },
  //         ...(form.getFieldValue('matches') || []),
  //       ],
  //     });
  //   }
  // };

  //   const onDeployButtonClick = useCallback(() => {
  //     if (!rules || isEmpty(rules?.data)) {
  //       return form.submit();
  //     }
  //     if (!isEmpty(rules?.data) && rules.data[0].status === 'pending') {
  //       return cancelDeploy({
  //         cnameId: String(selectedCdn),
  //         directoryName: String(directoryName),
  //         ruleId: String(rules.data[0].id),
  //       })
  //         .unwrap()
  //         .then((v) =>
  //           Notification({
  //             type: 'check',
  //             title: v.message as string,
  //           })
  //         );
  //     }
  //     return deployRule({
  //       cnameId: String(selectedCdn),
  //       directoryName: String(directoryName),
  //       ruleId: String(rules.data[0].id),
  //     })
  //       .unwrap()
  //       .then((v) =>
  //         Notification({
  //           type: 'check',
  //           title: v.message as string,
  //         })
  //       );
  //   }, [cancelDeploy, deployRule, directoryName, form, rules, selectedCdn]);

  //   const getButtonLabel = useMemo(() => {
  //     if (!rules || isEmpty(rules?.data)) return 'Save';
  //     if (rules && !isEmpty(rules?.data) && rules?.data[0].status === 'pending')
  //       return 'Cancel Deploy';
  //     return 'Deploy';
  //   }, [rules]);

  useEffect(() => {
    if (rule && !isEmpty(rule?.data)) {
      form.setFieldsValue(rule.data.rule);
    }
  }, [form, rule]);

  return (
    <Form onFinish={onFinish} form={form} name="deploy" className="ProjectEngineEngine">
      <div className="ProjectEngineEngine__content">
        {isRuleLoading ? (
          <Row justify="center" style={{ padding: 24 }}>
            <Col>
              <Loader />
            </Col>
          </Row>
        ) : (
          <>
            <List name="matches">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <div className="ProjectEngineEngine__items">
                      {map(fields, (field) => (
                        <React.Fragment key={field.key}>
                          <ProjectEngineItem
                            form={form}
                            depth={0}
                            field={field}
                            onRemove={remove}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                    <ProjectEngineEngineAddDropdown
                      ref={matchDropdown}
                      style={{ display: 'none' }}
                      onMatchClick={add}
                      onSelectFirstMatchClick={() => {}}
                    />
                  </>
                );
              }}
            </List>
          </>
        )}
        {/* <Item shouldUpdate>
          {() => (
            <ProjectEngineEngineAddDropdown
              disabled
              onMatchClick={() => matchDropdown?.current?.onMatchButtonClick()}
              onSelectFirstMatchClick={onAddFirstMatchClick}
              isFirstMatchDissabled={
                findIndex(form.getFieldValue('matches'), { type: 'select.first-match' }) >= 0
              }
            />
          )}
        </Item> */}
      </div>
    </Form>
  );
};

export default ProjectEngineDraftItemChild;
