/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Skeleton, Empty } from 'antd';
import { Icon } from 'components/common/Icon';
import { Typography } from 'components/common/Typography';
import { localStorageAsync } from 'helpers/localStorageAsync';
import Button from 'components/common/Button';
import { Notification } from 'components/common/Notification';
import { map, size } from 'lodash';
import userDetailsv2 from 'services/userDetailsv2';
import cn from 'classnames';
import { IProperty } from 'models';
import { HomeTableTablePropertyItem } from './HomeTableTableItem';
import EmptyState from '../../../../../assets/svg/Empty.svg';

interface Props {
  data?: IProperty[];
  isLoading?: boolean;
  isGridView?: boolean;
  isSortedByStatus?: boolean;
  onCreateServiceClick: () => void;
  updateList: () => void;
}

export const HomeTableTable: FC<Props> = ({
  isLoading,
  data,
  isGridView,
  onCreateServiceClick,
  isSortedByStatus,
  updateList,
}) => {
  const [properties, setProperties] = useState(data);
  const [skeletons, setSkeletons] = useState(4);

  const [addFavorite] = userDetailsv2.useAddFavoriteMutation();
  const [removeFavorite] = userDetailsv2.useRemoveFavoriteMutation();

  const onStarClick = useCallback(
    (favorite: boolean, propertyId: string) => {
      if (!favorite) {
        return addFavorite(propertyId)
          .unwrap()
          .then((resp) => {
            Notification({ type: 'check', title: 'Added', message: resp.message });
            updateList();
          })
          .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.message }));
      }
      return removeFavorite(propertyId)
        .unwrap()
        .then((resp) => {
          Notification({ type: 'check', title: 'Removed', message: resp.message });
          updateList();
        })
        .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.message }));
    },
    [addFavorite, removeFavorite, updateList]
  );

  useEffect(() => {
    const a = Number(localStorageAsync.getItemSync('nOfPropts')) || 4;
    setSkeletons(a);
  }, []);

  useEffect(() => {
    setProperties(data || []);
  }, [data]);

  const renderTableBody = useMemo(() => {
    const descr = isSortedByStatus ? (
      <span>There are currently no projects that meet the specified filters.</span>
    ) : (
      <span>
        You haven&apos;t created any properties yet. <br />
        <br />
        Why not create your first property now? <br />
        If you need more information, feel free to check out our{' '}
        <a href="https://docs.edgeport.com">documentation</a>.
      </span>
    );
    if (!size(properties)) {
      return (
        <Col span={24} className="HomeTable__empty-state">
          <Empty
            className="emptyState"
            image={EmptyState}
            imageStyle={{ height: 90 }}
            description={descr}>
            <Button icon="plus" onClick={() => onCreateServiceClick()} type="primary">
              Add New Property
            </Button>
          </Empty>
        </Col>
      );
    }
    return (
      <Col span={24} className="HomeTable__table">
        <div className="HomeTable__table-scrollbox">
          <Row className="HomeTable__table-inner" gutter={isGridView ? [20, 20] : [8, 8]}>
            {size(properties) > 0 &&
              map(properties, (property, idx) => (
                <HomeTableTablePropertyItem
                  onStarClick={onStarClick}
                  item={property}
                  isLast={idx === size(properties) - 1}
                  // @ts-ignore
                  key={property.id}
                  isGridView={isGridView}
                  // stats={!property.id ? undefined : summaryStats?.data?.[property.id]}
                />
              ))}
          </Row>
        </div>
      </Col>
    );
  }, [isSortedByStatus, properties, isGridView, onCreateServiceClick, onStarClick]);

  return (
    <Row className={cn('HomeTable', { _empty: !size(properties) })}>
      {!isGridView && size(properties) ? (
        <Col span={24} className="HomeTable__head">
          <Row gutter={20} className="HomeTable__item-row">
            <Col className={cn('HomeTable__head-item', 'HomeTable__item-icon')} span={1}>
              <Icon
                className="HomeTable__head-icon _big"
                name="star-empty"
                style={{ transform: 'none', cursor: 'default' }}
              />
            </Col>
            <Col className="HomeTable__item-info">
              <Col className="HomeTable__head-item" span={6}>
                <Typography variant="span" className="HomeTable__table-head-name">
                  name
                </Typography>
              </Col>
              <Col className="HomeTable__head-item" span={8}>
                <Typography variant="span" className="HomeTable__table-head-name">
                  url
                </Typography>
              </Col>
              <Col className="HomeTable__head-item" span={3}>
                <Typography variant="span" className="HomeTable__table-head-name">
                  status
                </Typography>
              </Col>
              <Col
                className="HomeTable__head-item _last"
                span={6}
                flex={1}
                style={{ right: 0, position: 'absolute' }}>
                <Typography variant="span" className="HomeTable__table-head-name">
                  REQUESTS per second
                </Typography>
              </Col>
            </Col>
          </Row>
        </Col>
      ) : null}
      {isLoading ? (
        <Col span={24} style={{ padding: '0 30px', height: '100%' }}>
          <Row gutter={[20, 20]}>
            {Array.from({ length: skeletons }).map((i, id) => (
              // eslint-disable-next-line react/no-array-index-key
              <Skeleton.Button active size="large" block key={String(i) + id} />
            ))}
          </Row>
        </Col>
      ) : (
        renderTableBody
      )}
    </Row>
  );
};

export default HomeTableTable;
