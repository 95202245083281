/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, Skeleton, Tooltip } from 'antd';
import { Input } from 'components/common/Input';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/common/Button';
import { Notification } from 'components/common/Notification';
import Modal from 'components/common/Modal';
import './ProjectOriginHostnames.scss';
import { Icon } from 'components/common/Icon';
import { Hostname, IPropertySSLData } from 'models';
import { Drawer } from 'components/common/Drawer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import RouteTypes from 'router/types';
import cn from 'classnames';
import { propertyAPI } from 'services';
import { useAppDispatch, useAppSelector } from 'store';
import Select from 'components/common/Select';
import Errormessage from 'components/views/home/HomeAddModalNew/Errormessage';
import { isEqual, size } from 'lodash';
import { getSkeletonsSize, updateSkeletonsSize, urlRegexValidation } from 'helpers/utils';
import { updateHostnamesDetails, updateStatusOfDeployBtn } from 'store/slices/property';

type CombinedItem = Hostname & IPropertySSLData;

export const ProjectOriginHostnames: FC = () => {
  const dispatch = useAppDispatch();
  const { origins, hostnames, isDeployBtnDisabled } = useAppSelector(({ property }) => property);
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const urlRegex = useMemo(() => urlRegexValidation, []);
  const [hostnamesData, setHostnamesData] = useState<Hostname[]>(hostnames);
  const [combinedHostnames, setCombinedHostnames] = useState<CombinedItem[]>([]);
  const [isValidHostname, setIsValidHostname] = useState(true);
  const [hostNameConfigurationName, setHostNameConfigurationName] = useState<string>('');
  const [visibleDrawer, setVisibleDrawe] = useState(false);
  const [visible, setVisible] = useState(false);
  const [createValue, setCreateValue] = useState('');
  const [createHostnames, setCreateHostnames] = useState<string[]>(['']);
  const [skeletons] = useState(getSkeletonsSize('nOfHnms', String(directoryName)));
  const [refetchedName, setRefetchedName] = useState('');

  const hasSecondOccurrence = (arr: CombinedItem[]): boolean => {
    const seen: Record<string, number> = arr.reduce((acc, curr) => {
      const { hostname } = curr;
      acc[hostname] = (acc[hostname] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    return Object.values(seen).some((count) => count > 1);
  };

  useEffect(() => {
    dispatch(updateStatusOfDeployBtn(hasSecondOccurrence(combinedHostnames)));
  }, [combinedHostnames, dispatch]);

  const { data: propertyDetails, isLoading: isPropertyLoading } = propertyAPI.useFetchPropertyQuery(
    String(directoryName),
    {
      skip: !token,
    }
  );

  const {
    data: sslData,
    refetch: refetchSslData,
    isLoading: isSslCertifsLoading,
    isFetching: isSslCertifsFetching,
  } = propertyAPI.useFetchDomainsQuery(String(directoryName), { skip: !token });

  useEffect(() => {
    setHostnamesData((prev) => {
      if (isEqual(prev, hostnames)) {
        return prev;
      }
      return hostnames;
    });
  }, [hostnames, propertyDetails?.data?.hostnames]);

  useEffect(() => {
    dispatch(updateHostnamesDetails(hostnamesData));
    if (size([...hostnamesData])) {
      updateSkeletonsSize('nOfHnms', String(directoryName), size([...(hostnamesData ?? [])]));
    }
  }, [directoryName, dispatch, hostnamesData]);

  const refCopyBtn = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const showDrawer = useCallback((name: string) => {
    setVisibleDrawe(true);
    setHostNameConfigurationName(name);
  }, []);

  const onClose = useCallback(() => {
    setVisibleDrawe(false);
  }, []);

  const handleAddHostname = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
      setCreateHostnames((prev) => {
        const newArr = [...prev];
        newArr[id] = event.target.value;
        return newArr;
      });
    },
    []
  );

  const renderHostnameInput = useCallback(
    (hastnameValue: string, handleChange: any, handleValid: any, idx: number) => {
      return (
        <>
          <Input
            key={idx}
            label={idx === 0 ? 'Enter your custom hostname here.' : undefined}
            placeholder='e.g. "www.example.com" or "example.com"'
            value={hastnameValue}
            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(v, idx);
              if (hastnameValue) {
                handleValid(urlRegex.test(v.target.value));
              } else {
                handleValid(true);
              }
            }}
          />
        </>
      );
    },
    [urlRegex]
  );

  useEffect(() => {
    if (propertyDetails?.success && sslData?.success) {
      const hnValues = hostnamesData;
      const sslCerts = sslData?.data ?? [];

      const newArr = [
        ...hnValues.map((item) => {
          const match = sslCerts.find((item2) => item2.name === item.hostname);
          if (match) {
            return { ...item, ...match };
          }
          return {
            ...item,
            ...{
              createdAt: '',
              name: '',
              validDns: false,
              validTlsCaa: false,
              validTlsCname: false,
            },
          };
        }),
      ].filter((item) => item !== null);
      setHostnamesData(hnValues);
      setCombinedHostnames(newArr as CombinedItem[]);
    }
  }, [propertyDetails?.success, setHostnamesData, hostnamesData, sslData?.success, sslData?.data]);

  const handleCreateOk = useCallback(() => {
    const defaultOrName = propertyDetails?.data?.origins?.[0]?.name;
    setHostnamesData((prev) => [
      ...(prev ?? []),
      ...createHostnames.map((item) => ({
        default_origin_name: defaultOrName || '',
        hostname: item,
      })),
    ]);
    setCombinedHostnames((prev: any) => {
      return [
        ...(prev ?? []),
        ...createHostnames.map((item) => ({
          default_origin_name: defaultOrName || '',
          hostname: item,
          validDns: false,
          validTlsCname: false,
          validTlsCaa: false,
          name: createValue,
          createdAt: '',
        })),
      ];
    });

    setVisible(false);
    setCreateValue('');
    setCreateHostnames(['']);
  }, [createHostnames, createValue, propertyDetails?.data?.origins, setHostnamesData]);

  const rederDrawerFooter = useCallback(
    () => (
      <Row justify="end">
        <Button type="secondary" onClick={() => onClose()}>
          Close
        </Button>
      </Row>
    ),
    [onClose]
  );

  const renderConfiguration = useCallback(
    (name: string) => {
      return (
        <Drawer
          placement="right"
          onClose={onClose}
          open={visibleDrawer}
          mask={false}
          width={600}
          style={{ top: 0, bottom: 0, height: '100vh' }}
          contentWrapperStyle={{ boxShadow: '-5px 0px 30px rgba(25, 42, 62, 0.08' }}
          headerStyle={{ padding: '14px 24px', borderBottom: '1px solid #EBEFF2' }}
          closeIcon={<Icon name="cross" className="ProjectOriginTraffic__drawer-icon" />}
          headerTitle={<h4 className="ProjectOriginTraffic__drawer-title">DNS Configuration</h4>}
          bodyStyle={{ padding: 0 }}
          footer={rederDrawerFooter()}>
          <Row style={{ padding: 20 }} gutter={[20, 20]}>
            <Col span={24}>
              <span>In order for Edgio to serve your property at</span>
              <span style={{ fontWeight: 600 }}>{` ${name}`}</span>
              <span>
                , create a CNAME record with your domain registrar pointing to the following domain:
              </span>
            </Col>
            <Col span={24}>
              {propertyDetails && (
                <CopyToClipboard
                  text={String(propertyDetails?.data?.dns_domain_name)}
                  onCopy={() => Notification({ title: 'Successfuly copied to clipboard' })}>
                  <button
                    type="button"
                    ref={refCopyBtn}
                    style={{
                      padding: '2px 10px',
                      cursor: 'pointer',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    {propertyDetails?.data?.dns_domain_name}
                    <Icon name="copy" style={{ marginLeft: 10, fontSize: 18 }} />
                  </button>
                </CopyToClipboard>
              )}
            </Col>
          </Row>
        </Drawer>
      );
    },
    [onClose, propertyDetails, rederDrawerFooter, visibleDrawer]
  );

  const handleChangeHostname = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
      setCombinedHostnames((prev) =>
        prev.map((item2, idx) => {
          if (idx === id) {
            return {
              ...item2,
              hostname: event.target.value,
            };
          }
          return item2;
        })
      );
    },
    []
  );

  const handleBlurHostname = useCallback(
    (value: string, id: number) => {
      setHostnamesData((prev) =>
        [...(prev || [])].map((item2, idx) => {
          if (idx === id) {
            return {
              ...item2,
              hostname: value,
            };
          }
          return item2;
        })
      );
    },
    [setHostnamesData]
  );

  const hostnameInput = (hastnameValue: string, handleChange: any, handleBlur: any, id: number) => {
    return (
      <Input
        key={id}
        value={hastnameValue}
        className="hostnameInput"
        onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(v, id);
        }}
        onBlur={() => {
          return handleBlur(hastnameValue, id);
        }}
      />
    );
  };

  const refetchData = (name: string) => {
    setRefetchedName(name);
    refetchSslData()
      .unwrap()
      .then(() => setRefetchedName(''))
      .catch((err) =>
        Notification({
          type: 'cross',
          title: 'Error',
          message: err.data.message,
        })
      );
  };

  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <div>
          <div className="ProjectOriginCdnCnames">
            {renderConfiguration(hostNameConfigurationName)}

            <Modal
              visible={visible}
              title="Add Hostnames"
              onCancel={() => setVisible(false)}
              width="400px"
              footer
              okButtonLabel="Add"
              okDisabled={!isValidHostname || createHostnames.some((i) => i === '')}
              onOk={handleCreateOk}>
              <div>
                {createHostnames.map((item, idx) => (
                  <Row key={idx} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Col span={23} style={{ marginTop: idx !== 0 ? 5 : 0 }} key={idx}>
                      {renderHostnameInput(item, handleAddHostname, setIsValidHostname, idx)}
                    </Col>
                    <Col span={1}>
                      <Icon
                        className="ProjectOriginCdnCnames__removeModalIcon"
                        name="remove2"
                        onClick={() => {
                          if (size(createHostnames) > 1) {
                            setCreateHostnames((prev) => {
                              const newHostnames = prev ? [...prev] : [];
                              newHostnames.splice(idx, 1);
                              return newHostnames;
                            });
                          } else {
                            setCreateHostnames(['']);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                ))}

                <Row gutter={32} align="middle">
                  <Col span={24} style={{ marginTop: '20px' }}>
                    <Button
                      // disabled={isPropertyLoading}
                      disabled={isPropertyLoading || isSslCertifsLoading}
                      icon="plus"
                      type="secondary"
                      onClick={() => {
                        setCreateHostnames((prev) => {
                          const newArr = [...prev];
                          newArr.push('');
                          return newArr;
                        });
                        setIsValidHostname(false);
                      }}>
                      Add Hostname
                    </Button>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Row justify="space-between" align="middle">
              <Col>
                <Row>
                  <p style={{ fontSize: 12, fontWeight: 400 }}>
                    Here you can configure the hostnames that clients will use to access this
                    environment.
                  </p>
                </Row>
              </Col>
            </Row>
            <div style={{ overflow: 'auto' }}>
              <Row className="ProjectOriginCdnCnames__table-head">
                <Col span={6} className="ProjectOriginCdnCnames__head-col _ta-left">
                  <span className="ProjectOriginCdnCnames__head-label">Hostname</span>
                </Col>
                <Col span={5} className="ProjectOriginCdnCnames__head-col _ta-left">
                  <span className="ProjectOriginCdnCnames__head-label">Default Origin</span>
                </Col>
                <Col span={5} className="ProjectOriginCdnCnames__head-col _ta-left">
                  <span className="ProjectOriginCdnCnames__head-label">DNS</span>
                </Col>
                <Col span={7} className="ProjectOriginCdnCnames__head-col _ta-left">
                  <span className="ProjectOriginCdnCnames__head-label">TLS</span>
                </Col>
                <Col span={1} className="ProjectOriginCdnCnames__head-col _ta-right" />
              </Row>

              {isPropertyLoading || isSslCertifsLoading ? (
                <>
                  {Array.from({ length: skeletons }).map((i, indx) => (
                    <Row
                      key={String(i) + indx}
                      gutter={32}
                      className="ProjectOriginCdnCnames__table-head"
                      style={{ padding: '16px 40px' }}>
                      <Skeleton.Button active size="small" block key={String(i) + indx} />
                    </Row>
                  ))}
                </>
              ) : (
                <>
                  {
                    <>
                      {combinedHostnames.length > 0 ? (
                        combinedHostnames.map(
                          (item: CombinedItem, id: number) => {
                            return item.hostname === refetchedName && isSslCertifsFetching ? (
                              <Row
                                key={item.hostname}
                                gutter={32}
                                className="ProjectOriginCdnCnames__table-head"
                                style={{ padding: '16px 40px' }}>
                                <Skeleton.Button active size="small" block />
                              </Row>
                            ) : (
                              <Row className="ProjectOriginCdnCnames__table-row" key={id}>
                                <Col span={6} className="ProjectOriginCdnCnames__head-col">
                                  {hostnameInput(
                                    item.hostname,
                                    handleChangeHostname,
                                    handleBlurHostname,
                                    id
                                  )}
                                </Col>
                                <Col span={5} className="ProjectOriginCdnCnames__head-col _ta-left">
                                  {origins && origins.length > 1 ? (
                                    <Select
                                      className="ProjectOriginCdnCnames__select"
                                      defaultValue={item.default_origin_name}
                                      placeholder={item.default_origin_name}
                                      // value={origins[idx].name}
                                      data={origins.map((i) => ({
                                        value: i.name,
                                        name: i.name,
                                      }))}
                                      onChange={(v) => {
                                        setHostnamesData((prev) =>
                                          [...(prev || [])].map((item2, idx) => {
                                            if (idx === id) {
                                              return {
                                                ...item2,
                                                default_origin_name: v as string,
                                              };
                                            }
                                            return item2;
                                          })
                                        );
                                        setCombinedHostnames((prev) =>
                                          prev.map((item2, idx) => {
                                            if (idx === id) {
                                              return {
                                                ...item2,
                                                default_origin_name: v as string,
                                              };
                                            }
                                            return item2;
                                          })
                                        );
                                      }}
                                    />
                                  ) : (
                                    <>{item.default_origin_name}</>
                                  )}
                                </Col>
                                <Col span={5} className="ProjectOriginCdnCnames__head-col _ta-left">
                                  {!item.validDns ? (
                                    !visibleDrawer ? (
                                      <Row wrap={false}>
                                        <Tooltip
                                          placement="bottom"
                                          title="Click for instructions on how to set up DNS.">
                                          <div
                                            className="ProjectOriginCdnCnames__dnsLabel"
                                            onClick={() => {
                                              showDrawer(item.name);
                                            }}>
                                            <div className="dnsLabelInner">
                                              <Icon
                                                className="dnsLabelIcon"
                                                name="info-in-circle"
                                              />
                                              <span>Actions needed</span>
                                            </div>
                                          </div>
                                        </Tooltip>
                                        <span>
                                          <Icon
                                            className={cn('ProjectOriginTLSView__arrowIcon', {
                                              _arrowIconDisabled: isSslCertifsFetching,
                                            })}
                                            name="arrowR"
                                            onClick={() => refetchData(item.hostname)}
                                          />
                                        </span>
                                      </Row>
                                    ) : (
                                      <Row wrap={false}>
                                        <div
                                          className="ProjectOriginCdnCnames__dnsLabel"
                                          onClick={() => {
                                            showDrawer(item.name);
                                          }}>
                                          <div className="dnsLabelInner">
                                            <Icon className="dnsLabelIcon" name="info-in-circle" />
                                            <span>Actions needed</span>
                                          </div>
                                        </div>
                                        <span>
                                          <Icon
                                            className={cn('ProjectOriginTLSView__arrowIcon', {
                                              _arrowIconDisabled: isSslCertifsFetching,
                                            })}
                                            name="arrowR"
                                            onClick={() => refetchData(item.hostname)}
                                          />
                                        </span>
                                      </Row>
                                    )
                                  ) : (
                                    <div className="ProjectOriginCdnCnames__tlsLabel">
                                      <div className="tlsLabelInner">
                                        <span
                                          className="tlsLabelStatus"
                                          style={{ backgroundColor: 'green', color: 'green' }}
                                        />
                                        <span> Active</span>
                                      </div>
                                    </div>
                                  )}
                                </Col>
                                <Col span={7} className="ProjectOriginCdnCnames__head-col _ta-left">
                                  <div
                                    className="ProjectOriginCdnCnames__tlsLabel"
                                    style={{
                                      borderColor: item.validTlsCname ? 'green' : 'grey',
                                    }}>
                                    <Tooltip placement="bottom" title="Click to Configure TLS">
                                      <div
                                        className="tlsLabelInner"
                                        onClick={() => {
                                          navigate(
                                            generatePath(RouteTypes.settingsTLS, {
                                              directoryName: directoryName || '',
                                            })
                                          );
                                        }}
                                        style={{
                                          color: item.validTlsCname ? 'green' : 'grey',
                                        }}>
                                        <span
                                          className="tlsLabelStatus"
                                          style={{
                                            backgroundColor: item.validTlsCname ? 'green' : 'grey',
                                          }}
                                        />
                                        <span>
                                          {item.validTlsCname
                                            ? 'Active'
                                            : 'No active certificate. Configure TLS'}
                                        </span>
                                      </div>
                                    </Tooltip>
                                  </div>
                                </Col>
                                <Col
                                  span={1}
                                  className="ProjectOriginCdnCnames__head-col _ta-right">
                                  <Tooltip placement="bottom" title="Remove this hostname">
                                    <Icon
                                      className="ProjectOriginCdnCnames__removeIcon"
                                      name="remove2"
                                      onClick={() => {
                                        setHostnamesData((prev) => {
                                          const newHostnames = prev ? [...prev] : [];
                                          newHostnames.splice(id, 1);
                                          return newHostnames;
                                        });
                                        setCombinedHostnames((prev) => {
                                          const newHostnames = prev ? [...prev] : [];
                                          newHostnames.splice(id, 1);
                                          return newHostnames;
                                        });
                                      }}
                                    />
                                  </Tooltip>
                                </Col>
                              </Row>
                            );
                          }
                          // )
                        )
                      ) : (
                        <Row
                          gutter={32}
                          className="ProjectOriginCdnCnames__table-head"
                          justify="center"
                          align="middle">
                          <Col className="ProjectOriginCdnCnames__head-col _ta-left">
                            {`You don't have any hostnames yet.`}
                          </Col>
                        </Row>
                      )}
                    </>
                  }
                </>
              )}

              {isDeployBtnDisabled && (
                <Row align="middle" style={{ position: 'absolute' }}>
                  <Col span={24} style={{ margin: '0px' }}>
                    <Errormessage errormessage="All hostnames must be unique." />
                  </Col>
                </Row>
              )}
              <Row align="middle">
                <Col span={7} style={{ margin: '20px 0px' }}>
                  <Button
                    disabled={isPropertyLoading || isSslCertifsLoading || isDeployBtnDisabled}
                    icon="plus"
                    type="secondary"
                    onClick={() => setVisible(true)}>
                    Add Hostname
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ProjectOriginHostnames;
