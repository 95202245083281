import './Typography.scss';
import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';
  color?: string;
  marginBottom?: number;
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: number;
  centered?: boolean;
  children?: ReactNode;
}

export const Typography: FC<Props> = ({
  children,
  className,
  variant = 'p',
  color,
  marginBottom,
  fontSize,
  fontWeight,
  lineHeight,
  centered,
}) => {
  const Component = variant;

  return (
    <Component
      style={{
        color,
        marginBottom,
        fontSize,
        fontWeight,
        lineHeight,
        textAlign: centered ? 'center' : undefined,
      }}
      className={cn('Typography', className, `_${variant}`)}>
      {children}
    </Component>
  );
};

export default Typography;
