import './UserSettingsResetPassword.scss';
import React, { FC, useCallback } from 'react';
import Button from 'components/common/Button';
import { Col, Row } from 'antd';
import { authAPI } from 'services';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

interface Props {
  onFinish?: (values: unknown) => void;
}

export const UserSettingsResetPassword: FC<Props> = () => {
  const [reset, { isLoading }] = authAPI.useResetPasswordMutation();
  const { user } = useAuth0();

  const onResepButtonClick = useCallback(() => {
    if (user) {
      reset({
        client_id: process?.env?.REACT_APP_AUTH0_CLIENT_ID || '',
        connection: 'Username-Password-Authentication',
        email: String(user?.email),
      });
    }
  }, [reset, user]);

  return (
    <div className="UserSettingsResetPassword">
      <Row justify="space-between" align="middle">
        <Col>
          <h2 className="UserSettingsResetPassword__title">Password</h2>
          <p className="UserSettingsResetPassword__text">
            Last change: {moment(user?.updated_at).format('DD MMM, YYYY')}
          </p>
        </Col>
        <Col>
          <Button
            className="UserSettingsResetPassword__btn"
            loading={isLoading}
            onClick={onResepButtonClick}
            type="secondary">
            Change
          </Button>
        </Col>
      </Row>
      {/* <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        title="Change Your Password"
        footer
        okButtonLabel="Save">
        <Form
          form={resetPAsswordForm}
          name="reset-password-form"
          onFinish={onFinish}
          className="UserSettingsSettings__form">
          <Form.Item name="current">
            <Input label="Current Password" password />
          </Form.Item>
          <Form.Item name="password">
            <Input label="New Password" password />
          </Form.Item>
          <Form.Item name="c_password">
            <Input label="Confirm New Password" password />
          </Form.Item>
        </Form>
      </Modal> */}
    </div>
  );
};

export default UserSettingsResetPassword;
