import { IHistoricalStatisticsDnsResponce } from 'models';
import React, { FC, useMemo } from 'react';
import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from 'react-fusioncharts';
import { map, range, round, size } from 'lodash';
import moment from 'moment';

charts(FusionCharts);
// import styles from './ProjectLogsViewListBarChart.module.scss';

export const ProjectLogsViewListBarChart: FC<{
  data?: IHistoricalStatisticsDnsResponce[];
  isHoury?: boolean;
}> = ({ data, isHoury }) => {
  // eslint-disable-next-line consistent-return
  const chartConfig = useMemo(() => {
    if (data) {
      const counter = size(data) < 60 && 60 - size(data);
      const emptyValuesArray = counter ? map(range(counter), () => ({ label: '', value: 0 })) : [];
      const mapedData = map(data, ({ QueryCount, report_date }) => {
        return {
          label: moment(report_date).format(isHoury ? 'HH:mm:ss' : 'DD MMM'),
          value: Number(QueryCount),
        };
      });
      const result = () => {
        if (counter) {
          emptyValuesArray.splice(round(counter / 2), 0, ...mapedData);
          return emptyValuesArray;
        }
        return mapedData;
      };
      return {
        dataFormat: 'JSON',
        dataSource: {
          chart: {
            theme: 'fusion',
            palettecolors: '#9AC6A9',
            baseFont: 'Assistant',
            baseFontColor: '#90A0B7',
            baseFontSize: '10',
            toolTipBGColor: '#fff',
            toolTipBorderColor: '#fff',
            toolTipBorderRadius: '4',
            toolTipColor: '#445A84',
            canvasbgColor: '#cddce7,#ffffff',
            canvasbgAlpha: '15',
            canvasBgAngle: '90',
            showToolTipShadow: 1,
            numDivLines: 0,
            xAxisValuesStep: size(result()) / 20,
            plotToolText: `<div style="padding: 4px"><strong>Total: $dataValue</strong><br>$label</div>`,
          },
          data: result(),
        },
      };
    }
  }, [data, isHoury]);
  return (
    <div style={{ height: 100 }}>
      {chartConfig && (
        <ReactFusioncharts type="column2d" width="100%" height="120" {...chartConfig} />
      )}
    </div>
  );
};

export default ProjectLogsViewListBarChart;
