/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react';
import { IDataType } from 'components/views/Project/ProjectCharts/configs';
import { map, orderBy } from 'lodash';
import {
  ISecurityEventsLogResponse,
  ISecurityTopTenEventsResponse,
  ISecurityTopTenEventsResult,
  IHistoricalStatisticsItemResponseRaw,
  IHistoricalStatisticsCountryResponse,
  IHistoricalStatisticsResponseRaw,
  IChartsDataResponse,
  IChartsMapDataResponse,
  AnalyticsTopEntriesResponseData,
} from 'models';
import moment from 'moment';
import { IChartData } from 'store/slices/project';
import { endpoints } from '../urls';
import { baseQuery } from './baseV2';

export const analyticsApiv2 = createApi({
  reducerPath: 'analyticsApiv2',
  baseQuery: baseQuery(),
  tagTypes: ['Analyticsv2', 'HistoricalStatistics', 'Statistics', 'RealtimeAll', 'RealtimeMap'],
  endpoints: (build) => ({
    // useProjectDetails
    fetchHistoricalDataByCountry: build.query<
      any,
      {
        type: 5 | 15 | 60 | 1440;
        propertyId: string;
        startDate: string;
        endDate: string;
        hosts: string;
        country?: string;
      }
    >({
      query: ({ propertyId, type, country, startDate, endDate, hosts }) => ({
        url: endpoints.v2.analytics.historicalData(type, propertyId),
        params: {
          start_date: startDate,
          end_date: endDate,
          hosts,
          aggregate_by: country,
        },
      }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore : todo
      transformResponse: (
        response: IHistoricalStatisticsCountryResponse | IHistoricalStatisticsResponseRaw
      ) => {
        if ('country' in response.data) {
          return orderBy(
            map(response.data.country.buckets, (item) => ({
              ...item,
              value: item.doc_count,
              client_country_code: item.key,
            })),
            ['client_country_code'],
            ['asc']
          );
        }
        return orderBy(
          map(
            response.data?.historical_data?.buckets,
            (item: IHistoricalStatisticsItemResponseRaw) => {
              return {
                byte_hit: Number(item?.byte_hit?.value || 0),
                byte_hit_percentage: Number(item?.byte_hit_percentage?.value || 0),
                byte_miss: Number(item?.byte_miss?.value || 0),
                byte_miss_percentage: Number(item?.byte_miss_percentage?.value || 0),
                bytes_in: Number(item?.bytes_in?.value || 0),
                bytes_out: Number(item?.bytes_out?.value || 0),
                cache_hit: Number(item?.cache_hit?.value || 0),
                cache_hit_percentage: Number(item?.cache_hit_percentage?.value || 0),
                cache_miss: Number(item?.cache_miss?.value || 0),
                cache_miss_percentage: Number(item?.cache_miss_percentage?.value || 0),
                client_city: String(item?.client_city?.value || ''),
                client_region: String(item?.client_region?.value || ''),
                completed_downloads: Number(item?.completed_downloads?.value || 0),
                config_nocache: Number(item?.config_nocache?.value || 0),
                download_attempts: Number(item?.download_attempts?.value || 0),
                client_continent_code: String(item?.client_continent_code || ''),
                file_size: Number(item?.file_size?.value || 0),
                host: String(item?.host || ''),
                pop: String(item?.pop || ''),
                request_count: Number(item?.request_count?.value || 0),
                request_rate: Number(item?.request_rate?.value || 0),
                status_2xx: Number(item?.status_2xx?.value || 0),
                status_2xx_percentage: Number(item?.status_2xx_percentage?.value || 0),
                status_3xx: Number(item?.status_3xx?.value || 0),
                status_3xx_percentage: Number(item?.status_3xx_percentage?.value || 0),
                status_4xx: Number(item?.status_4xx?.value || 0),
                status_4xx_percentage: Number(item?.status_4xx_percentage?.value || 0),
                status_5xx: Number(item?.status_5xx?.value || 0),
                status_5xx_percentage: Number(item?.status_5xx_percentage?.value || 0),
                tcp_client_refresh_miss: Number(item?.tcp_client_refresh_miss?.value || 0),
                tcp_expired_hit: Number(item?.tcp_expired_hit?.value || 0),
                tcp_expired_miss: Number(item?.tcp_expired_miss?.value || 0),
                tcp_hit: Number(item?.tcp_hit?.value || 0),
                tcp_mem_hit: Number(item?.tcp_mem_hit?.value || 0),
                tcp_miss: Number(item?.tcp_miss?.value || 0),
                tcp_partial_hit: Number(item?.tcp_partial_hit?.value || 0),
                tcp_partial_miss: Number(item?.tcp_partial_miss?.value || 0),
                total_time: Number(item?.total_time?.value || 0),
                transfer_rate: Number(item?.transfer_rate?.value || 0),
                uncacheable: Number(item?.uncacheable?.value || 0),
                timestamp: moment(item.key_as_string).format('MMM D, YYYY, HH:mm'),
                _id: { $oid: '' },
              };
            }
          )
        );
      },
      providesTags: () => ['HistoricalStatistics'],
    }),

    // ProjectLogsView
    searchLogs: build.mutation<
      any,
      {
        propertyId: string;
        service: string;
        search?: string;
        hosts?: string[] | string;
        from?: string;
        to?: string;
        interval?: string;
        includes?: string[];
        type?: string;
        field?: string;
        group_by_params?: string | string[];
        page?: string;
      }
    >({
      query: ({ propertyId, ...rest }) => ({
        url: endpoints.v2.analytics.searchLogs(propertyId),
        method: 'POST',
        body: {
          ...rest,
        },
      }),
    }),

    // ProjectLogsView
    aggregateLogs: build.mutation<
      any,
      {
        analysis?: string;
        propertyId: string;
        service?: string;
        search?: string;
        hosts?: string[] | string;
        from?: string;
        to?: string;
        includes?: string[];
        interval?: string;
      }
    >({
      query: ({ propertyId, ...rest }) => ({
        url: endpoints.v2.analytics.aggregateLogs(propertyId),
        method: 'POST',
        body: {
          ...rest,
        },
      }),
    }),

    // ProjectSecurityCharts
    fetchTimeSeries: build.query<
      IDataType[],
      {
        propertyId: string;
        type: 'rate-limiting' | 'event' | 'bot';
        hosts?: string[] | string;
        duration: 'second' | 'minute' | 'hour' | 'day';
        startDate: string | number;
        endDate: string | number;
      }
    >({
      query: ({ propertyId, type, duration, startDate, endDate, hosts }) => ({
        url: endpoints.v2.analytics.timeseriesStat(propertyId, type),
        params: {
          start_date: startDate,
          end_date: endDate,
          hosts,
          duration,
        },
      }),
      transformResponse: (response: ISecurityEventsLogResponse) => {
        return map(response?.data?.aggregate?.buckets, ({ key_as_string, doc_count }) => ({
          value: Number(doc_count),
          label: moment(key_as_string).format('MMM DD YYYY HH:mm:ss'),
          // label: moment(timestamp).unix(),
        }));
      },
    }),

    // useProjectDetails
    fetchRealTimeStats: build.query<
      IChartData,
      {
        propertyId: string;
        hosts?: string[] | string;
      }
    >({
      query: ({ propertyId, hosts }) => ({
        url: endpoints.v2.analytics.realTimeStat(propertyId),
        params: {
          hosts,
        },
      }),
      transformResponse: (response: IChartsDataResponse) => {
        const values = response?.data?.data;
        const mapedData = {
          date: moment(response?.data?.timestamp).format('HH:mm'),
          data: { ...values },
        };
        return mapedData as IChartData;
      },
      providesTags: (_result, _error, arg) => [{ type: 'RealtimeAll', id: arg.propertyId }],
    }),

    // useProjectDetails
    fetchRealTimePopStats: build.query<
      IChartsMapDataResponse,
      {
        propertyId: string;
        hosts?: string[] | string;
      }
    >({
      query: ({ propertyId, hosts }) => ({
        url: endpoints.v2.analytics.realTimePopStat(propertyId),
        params: {
          hosts,
        },
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: (_result, _error, arg) => [{ type: 'RealtimeMap', id: arg.propertyId }],
    }),

    // ProjectSecurity
    fetchHistoricalTopTen: build.query<
      any,
      {
        propertyId: string;
        type: 'events' | 'rate-limiting' | 'bot';
        hosts?: string[] | string;
        startDate: string | number;
        endDate: string | number;
        property: string;
      }
    >({
      query: ({ propertyId, type, startDate, endDate, property }) => ({
        url: endpoints.v2.analytics.topTen(propertyId, type),
        params: {
          start_date: startDate,
          end_date: endDate,
          type_of_aggregation: 'count',
          property,
        },
      }),
      transformResponse: (
        response: ISecurityTopTenEventsResponse,
        meta,
        arg: { property: string }
      ) => {
        const result: ISecurityTopTenEventsResult = {
          data: [],
        };
        result.data = response.data?.[arg.property]?.buckets.map(
          (item: { key: string; doc_count: number }) => ({
            _id: item.key,
            count: item.doc_count,
          })
        );
        return result;
      },
    }),
    fetchHistoricalTopEntries: build.query<
      AnalyticsTopEntriesResponseData,
      {
        propertyId: string;
        type: 'event' | 'rate-limiting' | 'bot';
        hosts?: string[] | string;
        startDate: string | number;
        endDate: string | number;
        properties: string; //  list of property
        count: 10 | 20 | 50;
      }
    >({
      query: ({ propertyId, type, startDate, endDate, properties, count }) => ({
        url: endpoints.v2.analytics.topEntries(propertyId, type),
        params: {
          start_date: startDate,
          end_date: endDate,
          type_of_aggregation: 'count',
          properties,
          count,
        },
      }),
      // transformResponse: (
      //   response: ISecurityTopTenEventsResponse,
      //   meta,
      //   arg: { property: string }
      // ) => {
      //   const result: ISecurityTopTenEventsResult = {
      //     data: [],
      //   };
      //   result.data = response.data?.[arg.property]?.buckets.map(
      //     (item: { key: string; doc_count: number }) => ({
      //       _id: item.key,
      //       count: item.doc_count,
      //     })
      //   );
      //   return result;
      // },
    }),
  }),
});

export default analyticsApiv2;
