/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { BlockWrapper } from 'components/common/BlockWrapper';
import { Loader } from 'components/common/Loader';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { Icon } from 'components/common/Icon';
import { find, map } from 'lodash';
import { useAppDispatch, useAppSelector } from 'store';
import { changeSelectedCustomPeriod, CustomPeriodInSecurityPage } from 'store/slices/project';
import ReactFC from 'react-fusioncharts';
import TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import FusionCharts from 'fusioncharts';
import { ButtonType, SectorButton } from 'components/common/SectorButton';
import { analyticsApiv2 } from 'services';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  historicalTimeseriesChartConfigSecurity,
  binningConfig,
  IDataType,
} from '../../ProjectCharts/configs';
import styles from './ProjectSecurityCharts.module.scss';

ReactFC.fcRoot(FusionCharts, TimeSeries);
const schema = [
  {
    name: 'Time',
    type: 'date',
    format: '%-d/%-m/%Y %-I:%-M:%S',
  },
  {
    name: 'Events',
    type: 'number',
  },
];

const chartProps = {
  timeseriesDs: {
    type: 'timeseries',
    renderAt: 'container',
    width: '100%',
    height: '400',
    dataSource: {
      data: [],
      ...historicalTimeseriesChartConfigSecurity(),
    },
  },
};

const customDropdownRoutingValues: { name: string; value: CustomPeriodInSecurityPage }[] = [
  { name: 'Last 5 minutes', value: CustomPeriodInSecurityPage.MIN5 /* '5 minutes' */ },
  { name: 'Last 15 minutes', value: CustomPeriodInSecurityPage.MIN15 /* '15 minutes' */ },
  { name: 'Last Hour', value: CustomPeriodInSecurityPage.HOU1 /* '1 hour' */ },
  { name: 'Last 6 Hours', value: CustomPeriodInSecurityPage.HOU6 /* '6 hours' */ },
  { name: 'Last 12 Hours', value: CustomPeriodInSecurityPage.HOU12 /* '12 hours' */ },
  { name: 'Last Day', value: CustomPeriodInSecurityPage.DAY1 /* '1 day' */ },
  { name: 'Last 2 Days', value: CustomPeriodInSecurityPage.DAY2 /* '2 days' */ },
  { name: 'Last 7 Days', value: CustomPeriodInSecurityPage.DAY7 /* '7 days' */ },
  { name: 'Last 30 Days', value: CustomPeriodInSecurityPage.DAY30 /* '30 days' */ },
];

const vievButtonData: ButtonType[] = [
  {
    label: 'Threats',
  },
  // {
  //   label: 'Browser Challenges',
  // },
  {
    label: 'Rates',
  },
  // {
  //   label: 'Rate Enforcement',
  // },
  {
    label: 'Bot Management',
  },
];

export const ProjectSecurityCharts: FC<{
  checkedView?: number;
  setCheckedView?: (idx: number) => void;
  getCount: (v: 10 | 20 | 50) => void;
}> = ({ checkedView, setCheckedView, getCount }) => {
  const dispatch = useAppDispatch();
  const [countValue, setCountValue] = useState<10 | 20 | 50>(10);
  const [liveDropdownVisibility, setLiveDropdownVisibility] = useState(false);
  const [countDropdownVisibility, setCountDropdownVisibility] = useState(false);
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { selectedCustomPeriod } = useAppSelector(({ project }) => project);
  const [ds, setds] = useState(chartProps);
  const [isSkip, setIsSkip] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSkip(false);
    }, 500);

    return () => {
      clearTimeout(timer);
      setIsSkip(true);
    };
  }, []);

  const getSelectedCustomPeriod = useMemo(() => {
    switch (selectedCustomPeriod) {
      case '5 minutes':
        return [moment().unix(), moment().subtract(5, 'minutes').unix()];
      case '15 minutes':
        return [moment().unix(), moment().subtract(15, 'minutes').unix()];
      case '1 hour':
        return [moment().unix(), moment().subtract(1, 'hours').unix()];
      case '6 hours':
        return [moment().unix(), moment().subtract(6, 'hours').unix()];
      case '12 hours':
        return [moment().unix(), moment().subtract(12, 'hours').unix()];
      case '1 day':
        return [moment().unix(), moment().subtract(1, 'day').unix()];
      case '2 days':
        return [moment().unix(), moment().subtract(2, 'days').unix()];
      case '7 days':
        return [moment().unix(), moment().subtract(7, 'days').unix()];
      case '30 days':
        return [moment().unix(), moment().subtract(30, 'days').unix()];
      default:
        return [];
    }
  }, [selectedCustomPeriod]);

  const getDurationType = useMemo((): 'second' | 'minute' | 'hour' | 'day' => {
    switch (selectedCustomPeriod) {
      case CustomPeriodInSecurityPage.MIN5 /* '5 minutes' */:
        return 'second';
      case CustomPeriodInSecurityPage.MIN15 /* '15 minutes' */:
        return 'minute';
      case CustomPeriodInSecurityPage.HOU1 /* '1 hour' */:
        return 'minute';
      case CustomPeriodInSecurityPage.HOU6 /* '6 hours' */:
        return 'minute';
      case CustomPeriodInSecurityPage.HOU12 /* '12 hours' */:
        return 'minute';
      case CustomPeriodInSecurityPage.DAY1 /* '1 day' */:
        return 'hour';
      case CustomPeriodInSecurityPage.DAY2 /* '2 days' */:
        return 'hour';
      case CustomPeriodInSecurityPage.DAY7 /* '7 days' */:
        return 'day';
      case CustomPeriodInSecurityPage.DAY30 /* '30 days' */:
        return 'day';
      default:
        return 'hour';
    }
  }, [selectedCustomPeriod]);

  const getType = useMemo(() => {
    switch (checkedView) {
      case 1:
        return 'rate-limiting';
      case 2:
        return 'bot';

      default:
        return 'event';
    }
  }, [checkedView]);

  const {
    data: timeseriesDATA,
    currentData: timeseriesCurrentDATA,
    status: timeseriesDataStatus,
  } = analyticsApiv2.useFetchTimeSeriesQuery(
    {
      propertyId: String(directoryName),
      type: getType,
      hosts: '',
      duration: getDurationType,
      endDate: moment.unix(getSelectedCustomPeriod[0]).utc().format('YYYY-MM-DDTHH:mm:ss'),
      startDate: moment.unix(getSelectedCustomPeriod[1]).utc().format('YYYY-MM-DDTHH:mm:ss'),
    },
    { skip: isSkip || !token }
  );

  const getChartData = useMemo(() => {
    const amountOfDataCorrector = (arr: ((string | number)[] | null[])[]) => {
      if (selectedCustomPeriod === '12 hours') {
        return arr.slice(-(60 * 12));
      }
      if (selectedCustomPeriod === '6 hours') {
        return arr.slice(-(60 * 6));
      }
      if (selectedCustomPeriod === '1 hour') {
        return arr.slice(-(60 * 1));
      }
      if (selectedCustomPeriod === '15 minutes') {
        return arr.slice(-15);
      }
      return arr;
    };

    const formatDate = (dateString: string | number) =>
      moment(dateString, 'MMM DD YYYY HH:mm:ss').format('DD/MM/YYYY H:mm:ss');

    const normalizedData = [...(timeseriesDATA || [])]
      .reverse()
      .map((item) => {
        const defaultTimeStamp = new Date(item.label).getTime() / 1000;
        if (defaultTimeStamp < getSelectedCustomPeriod[0]) {
          return [formatDate(item.label), Number(item.value)];
        }
        return [null];
      })
      .filter((i) => !!i[0]);

    return amountOfDataCorrector(normalizedData) || [];
  }, [timeseriesDATA, selectedCustomPeriod, getSelectedCustomPeriod]);

  const getCustomBinning = useMemo(() => {
    if (selectedCustomPeriod === '5 minutes') {
      return binningConfig('sec');
    }
    if (
      selectedCustomPeriod === '15 minutes' ||
      selectedCustomPeriod === '1 hour' ||
      selectedCustomPeriod === '6 hours' ||
      selectedCustomPeriod === '12 hours'
    ) {
      return binningConfig('min');
    }
    if (selectedCustomPeriod === '1 day' || selectedCustomPeriod === '2 days') {
      return binningConfig('hour');
    }
    return binningConfig('day');
  }, [selectedCustomPeriod]);

  const updateChart = useCallback(
    (chartData: IDataType[] | undefined, currentChartData: IDataType[] | undefined) => {
      if ([...(chartData || [])].length === [...(currentChartData || [])].length) {
        const fusionTable = new FusionCharts.DataStore().createDataTable(getChartData, schema);

        const options = { ...ds };
        // @ts-ignore
        options.timeseriesDs.dataSource.data = fusionTable;
        options.timeseriesDs.dataSource.xAxis.binning = getCustomBinning;

        setds(options);
      }
    },
    [getChartData, getCustomBinning]
  );

  useEffect(() => {
    updateChart(timeseriesDATA, timeseriesCurrentDATA);
  }, [timeseriesCurrentDATA, timeseriesDATA]);

  const renderCustomRoutingTimeDropdown = useMemo(() => {
    return (
      <Row gutter={[14, 14]} style={{ width: 154 }}>
        {map(customDropdownRoutingValues, ({ name, value }) => (
          <Col span={24} key={value}>
            <button
              type="button"
              className={classNames('ProjectHead__dropdown-btn', {
                _active: value === selectedCustomPeriod,
              })}
              onClick={() => {
                dispatch(changeSelectedCustomPeriod(value));
              }}>
              {name}
              <Icon name="check" className="ProjectHead__dropdown-check" />
            </button>
          </Col>
        ))}
      </Row>
    );
  }, [dispatch, selectedCustomPeriod]);

  const renderCustomCount = useMemo(() => {
    return (
      <Row gutter={[14, 14]} style={{ width: 154 }}>
        {map([10, 20, 50], (item: 10 | 20 | 50) => (
          <Col span={24} key={item}>
            <button
              type="button"
              className={classNames('ProjectHead__dropdown-btn', {
                _active: item === countValue,
              })}
              onClick={() => {
                setCountValue(item);
                getCount(item);
              }}>
              {item}
              <Icon name="check" className="ProjectHead__dropdown-check" />
            </button>
          </Col>
        ))}
      </Row>
    );
  }, [countValue]);

  const renderCustomDropdown = useMemo(() => {
    return (
      <Row style={{ padding: '0 0 0 24px' }} justify="space-between" align="middle">
        <Col>
          <SectorButton data={vievButtonData} checked={checkedView} onClick={setCheckedView} />
        </Col>
        <Col>
          <div style={{ display: 'flex' }}>
            <Dropdown
              onVisibleChange={(v) => setLiveDropdownVisibility(v)}
              trigger={['hover']}
              triggerElement={
                <div className="ProjectHead__trigger-url">
                  <Button type="subtle">
                    <div className={classNames('ProjectHead__purge-text', '_blue')}>
                      {
                        find(
                          customDropdownRoutingValues,
                          (item) => item.value === selectedCustomPeriod
                        )?.name
                      }
                      <Icon
                        className="ProjectHead__small-arrow"
                        name="expand-down"
                        style={{
                          transform: liveDropdownVisibility ? 'rotate(180deg)' : 'rotate(0deg)',
                        }}
                      />
                    </div>
                  </Button>
                </div>
              }>
              {renderCustomRoutingTimeDropdown}
            </Dropdown>

            <div
              style={{
                width: '1px',
                height: '22px',
                backgroundColor: '#1a59d9',
                margin: '0px 5px',
              }}
            />
            <div
              style={{
                color: '#1a59d9',
                marginRight: '5px',
                fontWeight: 500,
                fontSize: '0.75rem',
                lineHeight: '22px',
              }}>
              Top :
            </div>

            <Dropdown
              onVisibleChange={(v) => setCountDropdownVisibility(v)}
              trigger={['hover']}
              triggerElement={
                <div className="ProjectHead__trigger-url">
                  <Button type="subtle">
                    <div className={classNames('ProjectHead__purge-text', '_blue')}>
                      {countValue}
                      <Icon
                        className="ProjectHead__small-arrow"
                        name="expand-down"
                        style={{
                          transform: countDropdownVisibility ? 'rotate(180deg)' : 'rotate(0deg)',
                        }}
                      />
                    </div>
                  </Button>
                </div>
              }>
              {renderCustomCount}
            </Dropdown>
          </div>
        </Col>
      </Row>
    );
  }, [
    checkedView,
    countDropdownVisibility,
    liveDropdownVisibility,
    renderCustomCount,
    renderCustomRoutingTimeDropdown,
    selectedCustomPeriod,
    setCheckedView,
  ]);

  const showLoader = useMemo(() => {
    return timeseriesDataStatus !== 'fulfilled';
  }, [timeseriesDataStatus]);

  return (
    <BlockWrapper
      loading={showLoader}
      title="All Events"
      renderHeaderControls={renderCustomDropdown}
      padding="16px 24px 0"
      height={462}
      headerFlexGrow={1}>
      {showLoader && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <Row className={showLoader ? styles.transparency : styles.notransparency}>
        <Col span={24}>
          <ReactFC {...ds.timeseriesDs} />
        </Col>
      </Row>
    </BlockWrapper>
  );
};

export default ProjectSecurityCharts;
