import { Col, Row } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Button from 'components/common/Button';
import { isUndefined, map } from 'lodash';
import classNames from 'classnames';
import { rulesAPI } from 'services';
import { useAppSelector } from 'store';
import { Notification } from 'components/common/Notification';
import Modal from 'components/common/Modal';
import { Icon, IconType } from 'components/common/Icon';
import { ProjectHeadEngineNav } from '../../ProjectHead/ProjectHeadEngineNav';
import {
  enableWordPressCache,
  enableWordPressCacheShort,
} from '../ProjectEngineEngine/preselectedValues';
import styles from './ProjectEngineDraft.module.scss';

const MODAL_ITEMS: { icon: IconType; title: string; text: string; color: string }[] = [
  {
    icon: 'static',
    title: 'WordPress & WooCommerce Static Cache',
    text: 'Cache static files and dynamic blocks to distribute from the edge cache.',
    color: '#549568',
  },
  {
    icon: 'dynamic',
    title: 'WordPress & WooCommerce Dynamic Cache',
    text: 'Cache static files only and bypass dunamic blocks from the edge',
    color: '#1A59D9',
  },
  {
    icon: 'primary-domain',
    title: 'Redirect CNAME to the primary domain',
    text: 'Redirect additional CNAMES to the primary domain',
    color: '#FBC447',
  },
  {
    icon: 'rule',
    title: 'Custom Rule',
    text: 'Coming soon',
    color: '#EC892E',
  },
];

export const ProjectEngineDraft: FC = () => {
  // const [dropdownProfileVisible, setDropdownProfileVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState<number>();
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { selectedCdn } = useAppSelector(({ project }) => project);
  const [createRule, { isLoading }] = rulesAPI.useCreateRuleMutation();

  const onFinish = useCallback(() => {
    switch (selectedIdx) {
      case 0:
        createRule({
          cnameId: String(selectedCdn),
          directoryName: String(directoryName),
          data: { rule: enableWordPressCache, profile: 'WordPress & WooCommerce Static Cache' },
        })
          .unwrap()
          .then((v) => {
            setVisible(false);
            setSelectedIdx(undefined);
            Notification({
              type: 'check',
              title: v.message as string,
            });
          });
        break;
      case 1:
        createRule({
          cnameId: String(selectedCdn),
          directoryName: String(directoryName),
          data: {
            rule: enableWordPressCacheShort,
            profile: 'WordPress & WooCommerce Dynamic Cache',
          },
        })
          .unwrap()
          .then((v) => {
            setVisible(false);
            setSelectedIdx(undefined);
            Notification({
              type: 'check',
              title: v.message as string,
            });
          });
        break;
      case 3:
        break;
      default:
        createRule({
          cnameId: String(selectedCdn),
          directoryName: String(directoryName),
          data: { rule: enableWordPressCache, profile: 'WordPress & WooCommerce Static Cache' },
        })
          .unwrap()
          .then((v) => {
            setVisible(false);
            setSelectedIdx(undefined);
            Notification({
              type: 'check',
              title: v.message as string,
            });
          });
        break;
    }
  }, [createRule, directoryName, selectedCdn, selectedIdx]);

  const renderModal = useMemo(() => {
    return (
      <Row gutter={[10, 10]}>
        {map(MODAL_ITEMS, ({ icon, title, text, color }, idx) => (
          <Col span={24} key={title} onClick={() => idx !== 3 && setSelectedIdx(idx)}>
            <div
              className={classNames(styles.card, {
                [styles.active]: selectedIdx === idx,
                [styles.disabled]: idx === 3,
              })}>
              <Icon name={icon} className={styles.modalIcon} style={{ color }} />
              <div className={styles.modalContent}>
                <h4 className={styles.modalTitle}>{title}</h4>
                <span className={styles.modalText}>{text}</span>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  }, [selectedIdx]);

  return (
    <div className={styles.draft}>
      <Row justify="space-between" align="middle" className={styles.head}>
        {/* <div className={styles.dropdown}>
          <Dropdown
            trigger={['click']}
            visible={dropdownProfileVisible}
            onVisibleChange={setDropdownProfileVisible}
            triggerElement={
              <Button icon="expand-down" type="subtle" iconPosition="right" loading={isLoading}>
                Add
              </Button>
            }>
            <Row gutter={[8, 8]} justify="start">
              <Col span={24}>
                <Row
                  align="middle"
                  onClick={() => onDropdownButtonClick(0)}
                  gutter={8}
                  className={classNames(styles.item)}>
                  <Col span={2}>
                    <BarsOutlined className={classNames(styles.icon, styles.green)} />
                  </Col>
                  <Col span={22}>
                    <h2 className={styles.name}> WordPress & WooCommerce Static Cache</h2>
                    <p className={styles.button}>
                      Cache static files and dynamic blocks to distribute from the edge cache.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row
                  align="middle"
                  onClick={() => onDropdownButtonClick(1)}
                  gutter={8}
                  className={classNames(styles.item)}>
                  <Col span={2}>
                    <BarsOutlined className={classNames(styles.icon, styles.blue)} />
                  </Col>
                  <Col span={22}>
                    <h2 className={styles.name}>WordPress & WooCommerce Dynamic Cache</h2>
                    <p className={styles.button}>
                      Cache static files only and bypass dynamic blocks from the edge cache.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row
                  align="middle"
                  onClick={() => onDropdownButtonClick(2)}
                  gutter={8}
                  className={classNames(styles.item)}>
                  <Col span={2}>
                    <BarsOutlined className={classNames(styles.icon, styles.yellow)} />
                  </Col>
                  <Col span={22}>
                    <h2 className={styles.name}>Redirect CNAME to the primary domain</h2>
                    <p className={styles.button}>
                      Redirect additional CNAMES to the primary domain
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row
                  align="middle"
                  onClick={() => {}}
                  gutter={8}
                  className={classNames(styles.item)}>
                  <Col span={2}>
                    <BarsOutlined className={classNames(styles.icon, styles.orange)} />
                  </Col>
                  <Col span={22}>
                    <h2 className={classNames(styles.name, styles.disabled)}>Custom Rule</h2>
                    <p className={classNames(styles.button, styles.disabled)}>Coming soon</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Dropdown>
        </div> */}
        <Col>
          <Row align="middle" gutter={16}>
            <Col>
              <span className={styles.name}>Engine Rules</span>
            </Col>
            <Col>
              <ProjectHeadEngineNav />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button onClick={() => setVisible(!visible)}>Add</Button>
          <Modal
            visible={visible}
            title="Add"
            onCancel={() => {
              setVisible(false);
              setSelectedIdx(undefined);
            }}
            onOk={onFinish}
            okDisabled={isUndefined(selectedIdx)}
            okLoading={isLoading}
            footer>
            {renderModal}
          </Modal>
        </Col>
      </Row>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default ProjectEngineDraft;
