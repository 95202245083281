import React, { FC, useMemo } from 'react';
import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from 'react-fusioncharts';
import moment from 'moment';
import { findIndex, forEach, isEmpty, map, size } from 'lodash';
import { IHistoricalStatisticsResponce } from 'models';
import { historicalChartConfigs, ICategory, IDataset } from '../configs';

charts(FusionCharts);

interface IProjectChartsHistoricalHTTPProps {
  data?: IHistoricalStatisticsResponce[];
  checkboxes?: {
    name: string;
    value: string;
    color: string;
  }[];
}

export const ProjectChartsHistoricalHTTP: FC<IProjectChartsHistoricalHTTPProps> = ({
  data,
  checkboxes,
}) => {
  const returnChartData = useMemo(() => {
    const mapedData: { category: ICategory[]; dataset: IDataset[] } = {
      category: [],
      dataset: map(checkboxes, ({ name }) => ({ seriesName: name, data: [] })),
    };
    if (!isEmpty(data)) {
      forEach(data, (item) => {
        mapedData.category.push({ label: moment(item.timestamp).format('DD/MM/YYYY HH:mm') });
        forEach(checkboxes, ({ name, value }) => {
          const dataItem = findIndex(mapedData.dataset, ({ seriesName }) => seriesName === name);
          mapedData.dataset[dataItem].data.push({
            value: Number(item[value as keyof IHistoricalStatisticsResponce]),
          });
        });
      });
    }
    return historicalChartConfigs({
      ...mapedData,
      xAxisValuesStep: size(data) > 0 ? `${size(data) / 5}` : '0',
      height: '246',
      plottooltext: `
        <style>
          .Project .BlockWrapper {
            overflow: visible;
            .date-block {
              position: absolute; 
              visibility: hidden;
            }
            .fc__tooltip :first-child {
              .date-block {
                position:relative;
                right: 7px;
                visibility: visible;
                display: block;
                color: #2C3649;
                margin-bottom: 2px;
              }
            }
            .value-block {
              padding: 3px;
            }
          }
        </style>
        <div class=date-block>
          <strong> $label </strong>
        </div>
        <div class="value-block"><strong>$seriesname: $dataValue</strong></div>
      `,
    });
  }, [checkboxes, data]);
  return <>{!isEmpty(data) && <ReactFusioncharts {...returnChartData} />}</>;
};

export default ProjectChartsHistoricalHTTP;
