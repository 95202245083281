import { createApi } from '@reduxjs/toolkit/query/react';
import { IApikeyResponce } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const apikeyAPI = createApi({
  reducerPath: 'apikeyAPI',
  baseQuery: baseQuery(),
  tagTypes: ['Apikey'],
  endpoints: (build) => ({
    fetchApikey: build.query<IApikeyResponce, string>({
      query: (directoryName) => ({
        url: endpoints.apikey(directoryName),
      }),
      providesTags: () => ['Apikey'],
    }),
    regenerateApikey: build.mutation<IApikeyResponce, string>({
      query: (directoryName) => ({
        url: endpoints.apikey(directoryName),
        method: 'POST',
      }),
      invalidatesTags: ['Apikey'],
    }),
  }),
});

export default apikeyAPI;
