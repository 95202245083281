import { createApi } from '@reduxjs/toolkit/query/react';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const whiteListAPI = createApi({
  reducerPath: 'whiteListAPI',
  baseQuery: baseQuery(),
  tagTypes: ['List'],
  endpoints: (build) => ({
    getWhiteList: build.query<
      { data: { ip: string; id: number }[] },
      { directoryName: string; cnameId: string }
    >({
      query: ({ cnameId, directoryName }) => ({
        url: endpoints.whiteList(directoryName, cnameId),
      }),
      providesTags: ['List'],
    }),
    updateWhiteList: build.mutation<
      unknown,
      { directoryName: string; cnameId: string; ip: string }
    >({
      query: ({ cnameId, directoryName, ip }) => ({
        url: endpoints.whiteList(directoryName, cnameId),
        method: 'POST',
        body: {
          ip,
        },
      }),
      invalidatesTags: ['List'],
    }),
    deleteWhiteListItem: build.mutation<
      unknown,
      { directoryName: string; cnameId: string; id: string }
    >({
      query: ({ cnameId, directoryName, id }) => ({
        url: `${endpoints.whiteList(directoryName, cnameId)}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['List'],
    }),
  }),
});

export default whiteListAPI;
