/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row, Skeleton } from 'antd';
import { Input } from 'components/common/Input';
import Button from 'components/common/Button';
import { collaborationApiv2 } from 'services';
import { useParams } from 'react-router-dom';
import { Notification } from 'components/common/Notification';
import { useAppSelector } from 'store';
import Modal from 'components/common/Modal';
import { map, size, sortBy } from 'lodash';
import { UsersColaborationType } from 'models';
import { getSkeletonsSize, updateSkeletonsSize } from 'helpers/utils';
import { ProjectOriginCollaborationItem } from './ProjectOriginCollaborationItem';

interface ProjectOriginCollaborationProps {
  pollingIntervalTime: number;
}

export const ProjectOriginCollaboration: FC<ProjectOriginCollaborationProps> = ({
  pollingIntervalTime,
}) => {
  const [visible, setVisible] = useState(false);
  const [userForm] = Form.useForm<{ email: string }>();
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const [skeletons] = useState(getSkeletonsSize('colUss', String(directoryName)));
  const { data: usersCollaboration, isLoading } =
    collaborationApiv2.useFetchCollaborationUsersQuery(String(directoryName), {
      skip: !token,
      pollingInterval: pollingIntervalTime,
    });

  useEffect(() => {
    if (usersCollaboration?.data) {
      if (size([...(usersCollaboration?.data as UsersColaborationType[])])) {
        updateSkeletonsSize(
          'colUss',
          String(directoryName),
          size([...(usersCollaboration?.data ?? [])])
        );
      }
    }
  }, [directoryName, usersCollaboration?.data]);

  const [inviteCollaborator, { isLoading: inviteColaboratorLoading }] =
    collaborationApiv2.useInviteCollaboratorMutation();

  const handleCreateOk = useCallback(() => {
    userForm?.submit();
  }, [userForm]);

  const onAddCollaborator = useCallback(
    (values: { email: string }) => {
      inviteCollaborator({ propertyId: directoryName || '', emails: [values.email] })
        .unwrap()
        .then(() => {
          userForm.resetFields();
          setVisible(false);
          Notification({ type: 'check', title: 'Success' });
        })
        .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));

      return null;
    },
    [directoryName, inviteCollaborator, userForm]
  );

  const sortedUsersCollaboration = useMemo(
    () => sortBy(usersCollaboration?.data, [(item: UsersColaborationType) => !item.is_owner]),
    [usersCollaboration?.data]
  );

  return (
    <div className="ProjectOriginCnames">
      <Modal
        visible={visible}
        title="Invite Collaborator"
        onCancel={() => setVisible(false)}
        width="400px"
        footer
        okButtonLabel="Add"
        onOk={handleCreateOk}
        okLoading={inviteColaboratorLoading}>
        <Form form={userForm} onFinish={onAddCollaborator}>
          <Form.Item
            validateTrigger={['onBlur']}
            name="email"
            rules={[{ type: 'email', message: 'Please input valid email', required: true }]}>
            <Input label="Email" />
          </Form.Item>
        </Form>
      </Modal>
      <div className="ProjectOriginCnames__head">
        <Row justify="space-between" align="middle">
          <Col>
            <Row gutter={32} align="middle">
              <Col>
                <h4 className="ProjectOriginCnames__title">Collaborators</h4>
              </Col>
              {/* <Col>
                <Input
                  icon="search"
                  placeholder="Search"
                  width={260}
                  value={searchBoxValue}
                  onChange={(e: FormEvent<HTMLInputElement>) =>
                    setSearchBoxValue(e.currentTarget.value)
                  }
                />
              </Col> */}
            </Row>
          </Col>
          <Col>
            <Button icon="plus" type="secondary" onClick={() => setVisible(true)}>
              Invite New Collaborator
            </Button>
          </Col>
        </Row>
      </div>

      <Row className="ProjectOriginCnames__table-head">
        <Col span={10} className="ProjectOriginCnames__head-col">
          <span className="ProjectOriginCnames__head-label">User email</span>
        </Col>
      </Row>

      {isLoading ? (
        <>
          {Array.from({ length: skeletons }).map((i, indx) => (
            <Row key={String(i) + indx} style={{ borderBottom: '1px solid lightgray' }}>
              <Col span={24}>
                <Skeleton.Button
                  block
                  style={{ margin: '14px 24px', width: 'calc(100% - 50px)' }}
                  active
                  size="small"
                  key={String(i) + indx}
                />
              </Col>
            </Row>
          ))}
        </>
      ) : size(usersCollaboration?.data) === 0 ? (
        <Row key="0">
          <Col span={24}>
            <div style={{ margin: '14px 24px', color: 'gray', fontSize: 12, lineHeight: '24px' }}>
              You don&apos;t have any collaborators yet
            </div>
          </Col>
        </Row>
      ) : (
        map(sortedUsersCollaboration, (item) => (
          <ProjectOriginCollaborationItem data={item} key={item.id} />
        ))
      )}
    </div>
  );
};

export default ProjectOriginCollaboration;
