export const THREATS_DATA = [
  {
    name: 'Rule Message',
    value: 'rule_message',
    varName: 'ruleMessage',
  },
  // {
  //   name: 'Referer',
  //   value: 'referer',
  //   varName: 'referer',
  // },
  // {
  //   name: 'Rules ID',
  //   value: 'rule_id',
  //   varName: 'rulesID',
  // },
  {
    name: 'Event ID',
    value: 'uuid',
    varName: 'eventID',
  },
  {
    name: 'Profile Type',
    value: 'waf_profile_type',
    varName: 'profileType',
  },
  {
    name: 'Action Type',
    value: 'action_type',
    varName: 'actionType',
  },
  {
    name: 'Client IP',
    value: 'client_ip',
    varName: 'clientIP',
  },
  {
    name: 'Country Name',
    value: 'client_country',
    varName: 'countryName',
  },
  {
    name: 'Country Code',
    value: 'client_country_code',
    varName: 'countryCode',
  },
  {
    name: 'URL',
    value: 'url',
    varName: 'url',
  },
  {
    name: 'User Agent',
    value: 'user_agent',
    varName: 'userAgent',
  },
  {
    name: 'Client City',
    value: 'client_city',
    varName: 'clientCity',
  },
  {
    name: 'Matched On',
    value: 'matched_on',
    varName: 'matchedOn',
  },
  {
    name: 'Matched Value',
    value: 'matched_value',
    varName: 'matchedValue',
  },
];

export const RATES_DATA = [
  {
    name: 'Rule Name',
    value: 'limit_id',
    varName: 'ruleNameRates',
  },
  {
    name: 'Referer',
    value: 'referer',
    varName: 'refererRates',
  },
  {
    name: 'Country Name',
    value: 'client_country',
    varName: 'countryNameRates',
  },
  {
    name: 'Country Code',
    value: 'client_country_code',
    varName: 'countryCodeRates',
  },
  {
    name: 'Client City',
    value: 'client_city',
    varName: 'clientCityRates',
  },
  {
    name: 'URL',
    value: 'url',
    varName: 'URLRates',
  },
  {
    name: 'Action Type',
    value: 'limit_action_type',
    varName: 'actionTypeRates',
  },
  {
    name: 'User Agent',
    value: 'user_agent',
    varName: 'userAgentRates',
  },
  {
    name: 'Method',
    value: 'method',
    varName: 'methodRates',
  },
];

export const BOT_DATA = [
  {
    name: 'Referer',
    value: 'referer',
    varName: 'botReferer',
  },
  {
    name: 'Rules ID',
    value: 'rule_id',
    varName: 'botRulesID',
  },
  {
    name: 'Action Type',
    value: 'action_type',
    varName: 'botActionType',
  },
  {
    name: 'Client IP',
    value: 'client_ip',
    varName: 'botClientIP',
  },
  {
    name: 'Country Name',
    value: 'client_country',
    varName: 'botCountryName',
  },
  {
    name: 'Country Code',
    value: 'client_country_code',
    varName: 'botCountryCode',
  },
  {
    name: 'URL',
    value: 'url',
    varName: 'botUrl',
  },
  {
    name: 'User Agent',
    value: 'user_agent',
    varName: 'botUserAgent',
  },
  {
    name: 'Client City',
    value: 'client_city',
    varName: 'botClientCity',
  },
  {
    name: 'Bot Score',
    value: 'bot_score',
    varName: 'botScore',
  },
  {
    name: 'Challenge Status',
    value: 'challenge_status',
    varName: 'botChallengeStatus',
  },
];
