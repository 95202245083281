import './BlockWrapper.scss';
import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Col, Row, Skeleton } from 'antd';
import { Loader } from '../Loader';

interface Props {
  className?: string;
  padding?: string;
  isSmall?: boolean;
  title?: string;
  renderHeaderControls?: ReactNode;
  minHeight?: number | string;
  height?: number | string;
  headerPadding?: string | number;
  label?: string;
  isFullHeight?: boolean;
  loading?: boolean;
  children?: ReactNode;
  headerFlexGrow?: string | number;
  isSkeleton?: boolean;
  rows?: number;
}

export const BlockWrapper: FC<Props> = ({
  children,
  className,
  padding,
  isSmall,
  title,
  renderHeaderControls,
  minHeight = 256,
  height,
  headerPadding,
  label,
  isFullHeight = true,
  loading,
  headerFlexGrow,
  isSkeleton,
  rows = 1,
}) => {
  return (
    <div
      style={{ padding, minHeight, height }}
      className={cn('BlockWrapper', className, { _small: isSmall, _full: isFullHeight })}>
      {title && (
        <Row
          style={{ padding: headerPadding }}
          className="BlockWrapper__header"
          justify="space-between">
          {title && (
            <Col>
              <Row gutter={8} align="bottom">
                <Col>
                  <h4 className="BlockWrapper__title">{title}</h4>
                </Col>
                {label && (
                  <Col>
                    <span className="BlockWrapper__label">{label}</span>
                  </Col>
                )}
                {loading && (
                  <Col className="BlockWrapper__loader">
                    <Loader />
                  </Col>
                )}
              </Row>
            </Col>
          )}
          {renderHeaderControls && (
            <Col style={headerFlexGrow ? { flex: headerFlexGrow } : {}}>{renderHeaderControls}</Col>
          )}
        </Row>
      )}
      {isSkeleton ? <Skeleton active paragraph={{ rows }} /> : children}
    </div>
  );
};

export default BlockWrapper;
