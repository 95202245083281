import { createApi } from '@reduxjs/toolkit/query/react';
import { ILogsChartResponse, ILogsPayload, LogsResponse } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const logsApi = createApi({
  reducerPath: 'logsApi',
  baseQuery: baseQuery(),
  tagTypes: ['Logs'],
  endpoints: (build) => ({
    searchLogs: build.mutation<LogsResponse, ILogsPayload>({
      query: ({ directoryName, cname_id, ...rest }) => ({
        url: endpoints.log(directoryName, cname_id),
        method: 'POST',
        body: {
          ...rest,
        },
      }),
    }),
    searchLogsChart: build.mutation<ILogsChartResponse, ILogsPayload>({
      query: ({ directoryName, cname_id, ...rest }) => ({
        url: endpoints.logChart(directoryName, cname_id),
        method: 'POST',
        body: {
          ...rest,
        },
      }),
    }),
  }),
});

export default logsApi;
