import React from 'react';
import { useAppSelector } from 'store';
import { Icon } from 'components/common/Icon';
import { Skeleton } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Notification } from 'components/common/Notification';
import './ProjectOriginEnvIdDetails.scss';

export const ProjectOriginEnvIdDetails = () => {
  const { environment_id: environmentId } = useAppSelector(({ property }) => property);

  return (
    <div className="ProjectOriginEnvIdDetails">
      {environmentId ? (
        <>
          <div className="coppy-block">
            <span className="coppy-label">{environmentId}</span>
            <CopyToClipboard
              text={String(environmentId)}
              onCopy={() =>
                Notification({
                  title: 'Copied successfuly',
                  message: `Value ${environmentId} successfuly copied to clipboard`,
                })
              }>
              <Icon name="copy" className="edit-icon" onClick={() => {}} />
            </CopyToClipboard>
          </div>
        </>
      ) : (
        <Skeleton.Input style={{ width: '300px' }} active />
      )}
    </div>
  );
};

export default ProjectOriginEnvIdDetails;
