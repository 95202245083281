/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import { IField, selectUnitsDataObject, switchEnabledObject } from './fieldsObjects';

export const cacheableMethods: IField[] = [
  {
    id: 1,
    type: 'input',
    fieldName: 'methods',
    label: 'Methods',
    regexp: /GET|POST|PUT/,
    tooltip: 'Regex Pattern: ',
  },
];

export const cacheableRequestBodySize: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'value',
    label: 'Value',
  },
  {
    id: 1,
    ...selectUnitsDataObject,
  },
];

export const revalidateWhileStale: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const revalidateWhileStaleTimer: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'value',
    label: 'Seconds',
  },
];

export const userVariable: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'name',
    label: 'Name',
    regexp: /[-_\.a-zA-Z0-9]+/,
    tooltip: 'Regex Pattern: ',
  },
  {
    id: 1,
    type: 'input',
    fieldName: 'value',
    label: 'Value',
  },
];
