/* eslint-disable react/no-array-index-key */
import React, { FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { Hostname, IPropertyPayload, Origin } from 'models';
import { SelectValue } from 'antd/lib/select';
import cn from 'classnames';
import { BlockWrapper } from 'components/common/BlockWrapper';
import { Input } from 'components/common/Input';
import Button from 'components/common/Button';
import Select from 'components/common/Select';
import { urlRegexValidation } from 'helpers/utils';
import { Icon } from 'components/common/Icon';
import OriginsRow from './OriginsRow';
import Errormessage from './Errormessage';
import './HomeAddModalNew.scss';

interface Props {
  onFinish: (values: IPropertyPayload) => void;
  // onCancel: () => void;
  loading?: boolean;
}

const defaultOrigin = {
  name: '',
  // override_host_header: '',
  hosts: [
    {
      scheme: 'match',
      // dns_preference: '',
      location: [
        {
          hostname: '',
          // port: 0,
        },
      ],
    },
  ],
  shields: { apac: 'bypass', emea: 'bypass', us_east: 'bypass', us_west: 'bypass' },
  balancer: 'primary_failover',
  tls_verify: {
    use_sni: false,
    allow_self_signed_certs: false,
  },
};

export const CreateProperty: FC<Props> = ({
  onFinish,
  // onCancel,
  loading,
}) => {
  const [serviceName, setServiceName] = useState('');
  const [hostNames, setHostNames] = useState<Hostname[]>([]);
  const [origins, setOrigins] = useState<Origin[]>([defaultOrigin]);
  const [nameOrigin, setNameOrigin] = useState('');

  const [isRequierdField, setIsRequiredField] = useState(false);

  const handleCreateProperty = useCallback(() => {
    // setLoading(true);
    setHostNames((prev) => {
      let updatedHostNames = prev;
      updatedHostNames = prev.map((item) => {
        if (item.default_origin_name === '') {
          item.default_origin_name = origins[0].name;
        }
        return { ...item };
      });
      onFinish({
        name: serviceName,
        origins,
        hostnames: updatedHostNames,
        rules: [],
      });
      return updatedHostNames;
    });

    setIsRequiredField(true);
  }, [onFinish, origins, serviceName]);

  // const onCancelClick = useCallback(() => {
  //   onCancel();
  // }, [onCancel]);

  const [isNameUnique, setIsNameUnique] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const HostnameInput: FC<{
    onInputValueChange: (v: string, id: number) => void;
    val: string;
    id: number;
  }> = ({ onInputValueChange, val, id }) => {
    const urlReg = useMemo(() => urlRegexValidation, []);
    const [value, setValue] = useState('');
    const [isValidName, setIsValidName] = useState(true);

    const isStringInArraySecondTime = (str: string, arr: string[]) => {
      const firstIndex = arr.indexOf(str);
      if (firstIndex !== -1) {
        const secondIndex = arr.indexOf(str, firstIndex + 1);
        return secondIndex !== -1;
      }
      return false;
    };

    useEffect(() => {
      setValue(val);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      val ? setIsValidName(urlReg.test(val)) : setIsValidName(true);
    }, [urlReg, val]);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isStringInArraySecondTime(
        val,
        [...hostNames].map((i) => i.hostname)
      )
        ? setIsNameUnique(false)
        : setIsNameUnique(true);
    }, [val]);

    return (
      <div key={val + id}>
        <Row>
          <Col span={23}>
            <Input
              placeholder='e.g. "www.mysite.com"'
              className={cn('OriginNameInput', {
                // _requiredField: isRequierdField && !value,
                _invalidURL: !isValidName || !isNameUnique,
              })}
              value={value}
              onChange={(v: React.FormEvent<HTMLInputElement>) => {
                setValue(v.currentTarget.value);
              }}
              onBlur={() => {
                onInputValueChange(value, id);
              }}
            />
          </Col>
          <Col span={1} className="createPropertyDeleteIcon">
            <Tooltip placement="bottom" title="Remove this hostname">
              <Icon
                className="ProjectOriginCdnCnames__removeIcon"
                name="remove2"
                onClick={() => {
                  setHostNames((prev) => {
                    const newHostnames = [...prev];
                    newHostnames.splice(id, 1);
                    return newHostnames;
                  });
                }}
              />
            </Tooltip>
          </Col>
        </Row>
        {!isValidName && (
          <Errormessage errormessage="The value you entered is not a valid hostname." />
        )}
        {!isNameUnique && <Errormessage errormessage="All hostnames must be unique." />}
      </div>
    );
  };

  return (
    <div>
      <h3 className="HomeAddModalNew__title">Create a New Property</h3>
      {/* <div className="HomeAddModalNew__subtitle">
        {loading
          ? 'Waiting...'
          : 'Enter the details for your property`s production environment below.'}
      </div> */}

      <div
        className="ProjectOriginOrigin"
        style={{
          pointerEvents: loading ? 'none' : 'auto',
        }}>
        <Row gutter={[20, 20]}>
          <Col
            span={24}
            className={cn('qweqwe', {
              _blur: loading,
            })}>
            <div style={{ padding: '16px 0px' }}>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <div className="createPropertyLabel">Property Name</div>
                  <Input
                    isRequired
                    placeholder='e.g. "My Property"'
                    value={serviceName}
                    onChange={(e: FormEvent<HTMLInputElement>) => {
                      setServiceName(e.currentTarget.value);
                    }}
                    className={cn('OriginNameInput', {
                      _requiredField: isRequierdField && !serviceName,
                    })}
                  />
                </Col>
                <Col span={24}>
                  <div className="createPropertyLabel">Hostnames</div>
                  <div className="createPropertySubtitle">
                    Configure the hostnames that users will use to access your property. When left
                    blank a hostname will automatically be generated for you.
                  </div>

                  {hostNames &&
                    [...hostNames].map((item, idx) => (
                      <div key={item.hostname + idx}>
                        <HostnameInput
                          onInputValueChange={(v, id) => {
                            if (idx === id) {
                              setHostNames((prevState) =>
                                prevState.map((i, indx) => {
                                  if (id === indx) {
                                    return {
                                      ...i,
                                      hostname: v,
                                      default_origin_name: nameOrigin,
                                    };
                                  }
                                  return i;
                                })
                              );
                            }
                          }}
                          val={item.hostname}
                          id={idx}
                        />
                      </div>
                    ))}

                  <div
                    className="
                  createPropertyMarginTop5">
                    <Button
                      icon="plus"
                      type="secondary"
                      onClick={() => {
                        setHostNames([
                          ...(hostNames ?? []),
                          { hostname: '', default_origin_name: '' },
                        ]);
                      }}>
                      Add Hostname
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row gutter={[20, 20]} className="createPropertyMarginTop20">
                <Col span={24}>
                  <div className="createPropertyLabel">Origins</div>
                  <div className="createPropertySubtitle">
                    Here you can configure origin hosts, load balancing, and origin TLS parameters.
                  </div>
                </Col>
                <Col span={24}>
                  {origins.map((item, ind) => (
                    <div key={item.name + ind}>
                      <OriginsRow
                        isRequierdField={isRequierdField}
                        vals={item}
                        key={item.name + ind}
                        id={ind}
                        onChangeName={(val) => {
                          setNameOrigin(val);
                        }}
                        onHostChange={(val, id) => {
                          if (val === undefined) {
                            setOrigins((prevState) => {
                              const newOrigins = [...prevState];
                              newOrigins.splice(id, 1);
                              return newOrigins;
                            });
                          } else {
                            setOrigins((prevState) =>
                              prevState.map((i, indx) => {
                                if (indx === id) {
                                  return val;
                                }
                                return i;
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  ))}
                </Col>
              </Row>

              <Row gutter={32} align="middle">
                <Col span={7} className="createPropertyMarginTop10">
                  <Button
                    icon="plus"
                    type="secondary"
                    onClick={() => {
                      setOrigins((prev) => [...(prev ?? []), defaultOrigin]);
                    }}>
                    Add Origin
                  </Button>
                </Col>
              </Row>

              {origins &&
                origins.length > 1 &&
                hostNames.filter((i) => Boolean(i.hostname)).length > 0 && (
                  <Row>
                    <Col span={24}>
                      <div className="createPropertyMarginTop20">
                        <h1 className="createPropertyMapHostnamesTitle">Map Hostnames</h1>
                        <span style={{ fontSize: '14px' }}>
                          Set a default origin for your Hostname(s)
                        </span>
                      </div>
                    </Col>
                    <Col span={24} className="createPropertyMarginTop5">
                      <BlockWrapper
                        minHeight="110px"
                        padding="16px 24px"
                        // title="Create a New Property"
                        // isSkeleton={isProjectLoading || !projectDetails}
                      >
                        <Row gutter={16} justify="space-between">
                          <Col span={12}>
                            <span className="createPropertyMapHostnamesLabel">Hostname</span>
                          </Col>
                          <Col span={12}>
                            <span className="createPropertyMapHostnamesLabel">Default Origin</span>
                          </Col>
                        </Row>
                        {hostNames &&
                          hostNames.map((item, idx) => (
                            <Row
                              key={idx}
                              justify="space-between"
                              gutter={16}
                              style={{ marginTop: idx !== 0 ? 10 : 0 }}>
                              <Col span={12}>
                                <Input value={item.hostname} />
                              </Col>
                              <Col span={12}>
                                <Select
                                  className={cn('OriginNameInput', {
                                    _requiredField:
                                      isRequierdField && !hostNames[idx].default_origin_name,
                                  })}
                                  placeholder="Select option"
                                  data={origins.map((i) => ({
                                    value: i.name,
                                    name: i.name,
                                  }))}
                                  onChange={(v: SelectValue) => {
                                    setHostNames((prev) => {
                                      const newHostnames = [...prev];
                                      newHostnames[idx].default_origin_name = v as string;
                                      return newHostnames;
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                          ))}
                      </BlockWrapper>
                    </Col>
                  </Row>
                )}
            </div>
          </Col>
          <Col span={24} className="createPropertyBtnBlock">
            {!serviceName ? (
              <Tooltip
                onOpenChange={() => setIsRequiredField(true)}
                placement="top"
                title="Required fields must be filled correctly">
                <div>
                  <Button disabled loading={loading} type="primary">
                    Create Property
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <Button
                disabled={!isNameUnique}
                loading={loading}
                type="primary"
                onClick={handleCreateProperty}>
                Create Property
              </Button>
            )}

            {/* <Button type="secondary" onClick={onCancelClick}>
              Cancel
            </Button> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateProperty;
