import { createApi } from '@reduxjs/toolkit/query/react';
import { map } from 'lodash';
import { InvoicesResponse } from 'models';
import moment from 'moment';
import { endpoints } from '../urls';
import { baseQuery } from './baseV2';

export const invoicesAPIv2 = createApi({
  reducerPath: 'invoicesAPIv2',
  baseQuery: baseQuery(),
  tagTypes: ['Auth'],
  endpoints: (build) => ({
    getInvoices: build.query<InvoicesResponse, { start_date: string; end_date: string }>({
      query: (params) => ({
        url: endpoints.v2.account.getInvoices,
        params,
      }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //   @ts-ignore <type problems>
      transformResponse: (response: InvoicesResponse) => {
        return {
          ...response,
          data: map(response.data, (item) => ({
            ...item,
            created: moment.unix(item.created).format('MMM, YYYY'),
          })),
        };
      },
    }),
    // downloadInvoice: build.mutation<unknown, string>({
    //   query: (invoiceID) => ({
    //     url: endpoints.invoices.download(invoiceID),
    //   }),
    // }),
  }),
});

export default invoicesAPIv2;
