import { createApi } from '@reduxjs/toolkit/query/react';
import { IApikeyResponce } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './baseV2';

export const apikeyAPIv2 = createApi({
  reducerPath: 'apikeyAPIv2',
  baseQuery: baseQuery(),
  tagTypes: ['Apikey'],
  endpoints: (build) => ({
    fetchApikey: build.query<IApikeyResponce, string>({
      query: (propertyId) => ({
        url: endpoints.v2.properties.apikey(propertyId),
      }),
      providesTags: () => ['Apikey'],
    }),
    regenerateApikey: build.mutation<IApikeyResponce, string>({
      query: (propertyId) => ({
        url: endpoints.v2.properties.apikey(propertyId),
        method: 'POST',
      }),
      invalidatesTags: ['Apikey'],
    }),
  }),
});

export default apikeyAPIv2;
