import React, { FC, useMemo } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { BlockWrapper } from 'components/common/BlockWrapper';
import {
  ProjectChartsBandwidthNew,
  ProjectChartsControls,
  ProjectChartsMapBlock,
  ProjectChartsRealtimeBW,
  ProjectChartsRealtimeCONN,
  ProjectChartsRealtimeHTTP,
  ProjectChartsHistoricalHTTP,
} from 'components/views/Project/ProjectCharts';
import { Typography } from 'components/common/Typography';
// import { ProjectLogs } from 'components/views/Project/ProjectLogs';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useProjectDetails } from 'components/views/Project/ProjectHooks';
import { HistoricalFilterType } from 'store/slices/project';
import { IHistoricalStatisticsResponce } from 'models';
import { bytesToSize } from 'helpers/map';

export const ProjectDetails: FC = withAuthenticationRequired(() => {
  const {
    realtime,
    realtimeFilter,
    disabledFilters,
    onRealtimeButtonClick,
    onFilterButtonClick,
    httpResponseCodesCheckboxesHistorical,
    cacheStatusesCheckboxesHistorical,
    historicalData,
    historicalTimeSelected,
    isHistoricalDataLoading,
    isFetching,
    // eventLogsData,
    selectedCdn,
    timeDuration,
    popsData,
    historicalDataMap,
    historicalDataMapStatus,
    allRealtimeStatistics,
    allRealtimeStatisticsUpdate,
    selectedPeriod,
  } = useProjectDetails();

  const getNormalizedHistoricalData = useMemo(() => {
    const sortData = (x: IHistoricalStatisticsResponce, y: IHistoricalStatisticsResponce) =>
      new Date(x?.timestamp) - new Date(y?.timestamp);

    return [...(historicalData || [])]
      .map((item) => {
        const defaultTimeStamp = new Date(item.timestamp).getTime();

        if (defaultTimeStamp <= moment(selectedPeriod[1]).valueOf()) {
          return { ...item };
        }
        return { ...item, timestamp: '' };
      })
      .filter((i) => Boolean(new Date(i.timestamp) <= Date.now()))
      .sort(sortData);
  }, [historicalData, selectedPeriod]);

  const bandwidthTotalBytes = useMemo(() => {
    const totalBytes = getNormalizedHistoricalData.reduce((sum, i) => sum + i.bytes_out, 0);
    if (totalBytes === 0) {
      return '';
    }
    return `- ${bytesToSize(totalBytes)}`;
  }, [getNormalizedHistoricalData]);

  return (
    <>
      <div className="Project">
        <Row gutter={[20, 20]}>
          <Col span={12} style={{ height: 256 }}>
            <BlockWrapper>
              <ProjectChartsMapBlock
                dataMap={popsData}
                realtime={realtime}
                dataMapHistorical={historicalDataMap}
                historicalDataMapStatus={historicalDataMapStatus}
              />
            </BlockWrapper>
          </Col>
          <Col span={12} style={{ height: 256, position: 'relative' }}>
            <BlockWrapper padding="16px 24px" loading={isHistoricalDataLoading || isFetching}>
              <ProjectChartsControls
                isLoading={isHistoricalDataLoading || isFetching}
                title={`Bandwidth ${
                  isHistoricalDataLoading || isFetching ? '' : bandwidthTotalBytes
                }`}
                className="Project__controls"
                onClick={
                  realtime
                    ? (idx) => onRealtimeButtonClick(idx)
                    : (idx) => onFilterButtonClick(idx as HistoricalFilterType)
                }
                checked={realtime ? realtimeFilter : historicalTimeSelected}
                isRealtime={realtime}
                disabled={disabledFilters}
              />
              <div className="Project__chart-wrapper">
                {realtime ? (
                  <ProjectChartsRealtimeBW
                    data={allRealtimeStatistics}
                    selectedCdn={selectedCdn}
                    timeDuration={timeDuration}
                    update={allRealtimeStatisticsUpdate}
                  />
                ) : (
                  <ProjectChartsBandwidthNew data={getNormalizedHistoricalData} />
                )}
              </div>
            </BlockWrapper>
          </Col>
          <Col span={12} style={{ height: 300 }}>
            <BlockWrapper padding="16px 24px" loading={isHistoricalDataLoading || isFetching}>
              <ProjectChartsControls
                isLoading={isHistoricalDataLoading || isFetching}
                title="HTTP Response Codes"
                className="Project__controls"
                onClick={
                  realtime
                    ? (idx) => onRealtimeButtonClick(idx)
                    : (idx) => onFilterButtonClick(idx as HistoricalFilterType)
                }
                checked={realtime ? realtimeFilter : historicalTimeSelected}
                isRealtime={realtime}
                disabled={disabledFilters}
              />
              <div className="Project__chart-wrapper">
                {realtime ? (
                  <ProjectChartsRealtimeHTTP
                    selectedCdn={selectedCdn}
                    data={allRealtimeStatistics}
                    isHttp
                    update={allRealtimeStatisticsUpdate}
                    timeDuration={timeDuration}
                  />
                ) : (
                  <ProjectChartsHistoricalHTTP
                    data={getNormalizedHistoricalData}
                    checkboxes={httpResponseCodesCheckboxesHistorical}
                  />
                )}
              </div>
            </BlockWrapper>
          </Col>
          <Col span={12} style={{ height: 300 }}>
            <BlockWrapper padding="16px 24px" loading={isHistoricalDataLoading || isFetching}>
              <ProjectChartsControls
                isLoading={isHistoricalDataLoading || isFetching}
                title="Cache Status"
                className="Project__controls"
                onClick={
                  realtime
                    ? (idx) => onRealtimeButtonClick(idx)
                    : (idx) => onFilterButtonClick(idx as HistoricalFilterType)
                }
                checked={realtime ? realtimeFilter : historicalTimeSelected}
                isRealtime={realtime}
                disabled={disabledFilters}
              />
              <div className="Project__chart-wrapper _big">
                {realtime ? (
                  <ProjectChartsRealtimeHTTP
                    selectedCdn={selectedCdn}
                    data={allRealtimeStatistics}
                    update={allRealtimeStatisticsUpdate}
                    timeDuration={timeDuration}
                  />
                ) : (
                  <ProjectChartsHistoricalHTTP
                    data={getNormalizedHistoricalData}
                    checkboxes={cacheStatusesCheckboxesHistorical}
                  />
                )}
              </div>
            </BlockWrapper>
          </Col>
          <Col span={24}>
            <BlockWrapper padding="16px 24px" loading={!allRealtimeStatistics}>
              <Typography variant="h4" fontWeight={600} color="#2C3649" marginBottom={12}>
                Connections
              </Typography>
              {realtime ? (
                <div className="Project__chart-wrapper">
                  <ProjectChartsRealtimeCONN
                    selectedCdn={selectedCdn}
                    data={allRealtimeStatistics}
                    update={allRealtimeStatisticsUpdate}
                  />
                </div>
              ) : (
                <div className="Project__palceholder">
                  <Typography variant="h2" fontWeight={600} color="#2C3649" marginBottom={12}>
                    Available in realtime mode
                  </Typography>
                </div>
              )}
            </BlockWrapper>
          </Col>
          {/* {realtime && (
            <Col span={24}>
              <BlockWrapper padding="16px 24px" title="Event Log">
                <ProjectLogs data={eventLogsData?.data} />
              </BlockWrapper>
            </Col>
          )} */}
        </Row>
      </div>
    </>
  );
});

export default ProjectDetails;
