/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { LogsAggregateChartData } from 'models';
import { size } from 'lodash';
import moment, { DurationInputArg2 } from 'moment';
import cn from 'classnames';
import { useAppDispatch } from 'store';
import { setIsUsingCustomRange } from 'store/slices/project';
import { updateSelectedCustomLogsPeriod } from 'store/slices/logs';
import { httpStatusCodes } from 'helpers/httpStatusCodes';
import FusionCharts from 'fusioncharts';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
// import charts from 'fusioncharts/fusioncharts.charts';

import ReactFusioncharts from 'react-fusioncharts';
import styles from '../ProjectLogsViewList.module.scss';

PowerCharts(FusionCharts);
// charts(FusionCharts);

export const ProjectLogsViewListStackedBarChart: FC<{
  data: LogsAggregateChartData[];
  interval: string;
  handleChartHistory: (preInterval: [number, number]) => void;
  updateHttpStatusCodes: (code: string) => void;
  httpCodes: string[];
  isLoading: boolean;
}> = ({ data, interval, handleChartHistory, updateHttpStatusCodes, httpCodes, isLoading }) => {
  const dispatch = useAppDispatch();

  const clickHandlerRef = useRef<(event: any, dataObject: any) => void>(null);
  const clickHandlerLegendRef = useRef<(event: any, dataObject: any) => void>(null);

  clickHandlerLegendRef.current = (event: any, dataObject: any) =>
    updateHttpStatusCodes(dataObject?.datasetName);

  clickHandlerRef.current = (event: any, dataObject: any) => {
    if (!interval) return;
    dispatch(setIsUsingCustomRange(true));
    const splitedPeriod = (interval as string).split('');
    const periodUnit = splitedPeriod.pop();
    const periodQuantity = Number(splitedPeriod.join(''));

    const newDates: [number, number] = [
      moment(dataObject.categoryLabel).valueOf(),
      moment(dataObject.categoryLabel)
        .add(periodQuantity, periodUnit as DurationInputArg2)
        .valueOf(),
    ];

    handleChartHistory(newDates);
    dispatch(updateSelectedCustomLogsPeriod(newDates));
  };

  const createDataSeries = useCallback(
    (seriesName: string, codesHttp: string[], chartData: LogsAggregateChartData[]) => ({
      seriesname: seriesName,
      data: chartData.map((item: LogsAggregateChartData) => {
        return item[seriesName];
      }),
      initiallyHidden: codesHttp.includes(seriesName) ? '0' : '1',
    }),
    []
  );

  // eslint-disable-next-line consistent-return
  const chartConfig = useMemo(() => {
    if (data) {
      const currentData = [...(data || [])].reverse();

      const getCategory = () =>
        currentData.map((i) => ({
          label: moment(i.key_as_string).format('YYYY-MM-DD HH:mm:ss'),
        }));
      const getDataSet = () =>
        httpStatusCodes.map((code) => createDataSeries(code, httpCodes, currentData));

      return {
        dataFormat: 'JSON',
        dataSource: {
          chart: {
            // plotHoverEffect: '1',
            // plotHighlightEffect: 'fadeout',
            // showtooltip: '1',
            animation: '1',
            animationDuration: 0.3,
            palettecolors: ['#0079d3', '#549568', '#ec892e', '#db5960', '#90a0b7'],
            theme: 'fusion',
            baseFont: 'Assistant',
            baseFontColor: '#90A0B7',
            baseFontSize: '10',
            toolTipBGColor: '#fff',
            toolTipBorderColor: '#fff',
            toolTipBorderRadius: '4',
            toolTipColor: '#445A84',
            canvasbgColor: '#cddce7,#ffffff',
            canvasbgAlpha: '15',
            canvasBgAngle: '90',
            showToolTipShadow: 1,
            numDivLines: 0,
            xAxisValuesStep: size(currentData) / 20,
            plottooltext: `<div style="padding: 4px"><strong>$seriesname: $dataValue</strong> {br} ${interval} @ $label</div>`,
          },
          categories: [
            {
              category: getCategory(),
            },
          ],
          dataset: getDataSet(),
        },
        events: {
          // Attach to beforeInitialize
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dataPlotClick: clickHandlerRef.current,
          legendItemClicked: clickHandlerLegendRef.current,
        },
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div
      style={{ height: 135 }}
      className={cn(styles.wrapperChart, {
        [styles.origin]: !isLoading,
        [styles.grayed]: isLoading,
      })}>
      {chartConfig && (
        <ReactFusioncharts type="stackedcolumn2d" width="100%" height="180" {...chartConfig} />
      )}
    </div>
  );
};

export default ProjectLogsViewListStackedBarChart;
