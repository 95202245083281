import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { storageService } from 'helpers';
import { isNil, remove } from 'lodash';

export interface INavigation {
  name?: string;
  directoryName?: string;
}

interface IUi {
  navigation: INavigation[];
  isMenuColapsed: boolean;
  pageName: string;
}

const initialState: IUi = {
  navigation: isNil(storageService.navigationList) ? [] : storageService.navigationList,
  isMenuColapsed: false,
  pageName: 'false',
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    updateNavigation: (state, action: PayloadAction<INavigation>) => {
      storageService.navigationList = [...state.navigation, action.payload];
      state.navigation = [...state.navigation, action.payload];
    },
    removeItemFromNavigation: (state, action: PayloadAction<string>) => {
      const shallowCopy = state.navigation;
      remove(state.navigation, ({ directoryName }) => directoryName === action.payload);
      storageService.navigationList = shallowCopy;
      state.navigation = shallowCopy;
    },
    clearNavigation: (state) => {
      storageService.navigationList = [];
      state.navigation = [];
    },
    toggleMenu: (state) => {
      state.isMenuColapsed = !state.isMenuColapsed;
    },
    updatePageName: (state, action: PayloadAction<string>) => {
      state.pageName = action.payload;
    },
  },
});

export const {
  updateNavigation,
  removeItemFromNavigation,
  clearNavigation,
  toggleMenu,
  updatePageName,
} = uiSlice.actions;

export default uiSlice.reducer;
