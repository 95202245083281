import { createApi } from '@reduxjs/toolkit/query/react';
import { IPropertySecurityProfilesResponse, IPropertySecurityProfilesUpdate } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './baseV2';

export const sucurityProfilesServiceAPIv2 = createApi({
  reducerPath: 'sucurityProfilesServiceAPIv2',
  baseQuery: baseQuery(),
  tagTypes: ['SecurityProfiles'],
  endpoints: (build) => ({
    fetchSecurityProfiles: build.query<IPropertySecurityProfilesResponse, string>({
      query: (propertyId) => ({
        url: endpoints.v2.securityProfiles.getSecurityProfiles(propertyId),
      }),
      providesTags: () => ['SecurityProfiles'],
    }),

    updateSeccurityProfiles: build.mutation<
      IPropertySecurityProfilesResponse,
      { propertyId: string; hostnames: IPropertySecurityProfilesUpdate[] }
    >({
      query: ({ propertyId, ...rest }) => ({
        url: endpoints.v2.securityProfiles.updateSecurityProfiles(propertyId),
        method: 'POST',
        body: { ...rest },
      }),
      invalidatesTags: ['SecurityProfiles'],
    }),
  }),
});

export default sucurityProfilesServiceAPIv2;
