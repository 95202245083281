import React, { FC, useCallback } from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import Button from 'components/common/Button';
import { Label } from 'components/common/Label';
import { InvitationType } from 'models';
import { Notification } from 'components/common/Notification';
// import { useParams } from 'react-router-dom';
import { upperFirst } from 'lodash';
import { collaborationApiv2 } from 'services';
import styles from './ProjectOriginInvitationsItem.module.scss';

interface Props {
  data?: InvitationType;
  getPollingInterval: (time: number) => void;
}

export const ProjectOriginInvitationsItem: FC<Props> = ({ data, getPollingInterval }) => {
  // const { directoryName } = useParams<{ directoryName?: string }>();

  const [resend, { isLoading: isResendLoading }] = collaborationApiv2.useResendInvitationMutation();
  const [deleteInvitation, { isLoading: isDeleteLoading }] =
    collaborationApiv2.useDeleteInvitationMutation();

  const onRemoveClick = useCallback(() => {
    deleteInvitation({ propertyInvitationId: String(data?.id) })
      .unwrap()
      .then(() => {
        Notification({ type: 'check', title: 'Invitation removed successfuly' });
      })
      .then(() => getPollingInterval(0))
      .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
  }, [data?.id, deleteInvitation, getPollingInterval]);

  const onResendClick = useCallback(() => {
    resend({ propertyInvitationId: String(data?.id) || '' })
      .unwrap()
      .then((resp) => {
        Notification({ type: 'check', title: resp.message });
      })
      .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
  }, [data?.id, resend]);

  return (
    <Row className={styles.item}>
      <Col span={8} className={styles.col}>
        <span className={styles.label}>{data?.user_email}</span>
      </Col>
      <Col span={8} className={styles.col}>
        {!data?.is_accepted ? (
          <Label type={data?.status === 'pending' ? 'warning' : 'info'} className={styles.badge}>
            {upperFirst(String(data?.status))}
          </Label>
        ) : (
          <Label type="success" className={styles.badge}>
            Accepted
          </Label>
        )}
      </Col>
      <Col offset={0} flex={1} className={classNames(styles.col, styles.taRight)}>
        <Button
          className={styles.resendBtn}
          type="subtle"
          onClick={() => onResendClick()}
          icon="auto-renew"
          loading={isResendLoading}>
          Resend invitation
        </Button>
        <Button
          type="subtle"
          onClick={() => onRemoveClick()}
          size="small"
          icon="remove"
          className={styles.remove}
          loading={isDeleteLoading}
        />
      </Col>
    </Row>
  );
};

export default ProjectOriginInvitationsItem;
