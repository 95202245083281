/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import { IField, switchEnabledObject } from './fieldsObjects';

export const customLogField: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'format',
    label: 'Format',
    regexp: /([-_\(\)\[\]= ':;,\.|a-zA-Z0-9]|%%|%\{[-_a-zA-Z0-9]+\}[io]|%[b])*/,
    tooltip: 'Regex Pattern: ',
  },
];

export const logQueryString: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];

export const maskClientSubnet: IField[] = [
  {
    id: 0,
    ...switchEnabledObject,
  },
];
