/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { HolderOutlined } from '@ant-design/icons';
import { SelectValue } from 'antd/lib/select';
import cn from 'classnames';
import { Icon } from 'components/common/Icon';
import { Input } from 'components/common/Input';
import Select from 'components/common/Select';
import { urlRegexValidation } from 'helpers/utils';
import { Host } from 'models';
import Errormessage from './Errormessage';

const OriginHostnameRow = ({
  vals,
  id,
  onHostChange,
  isRequierdField,
  numberOfHosts,
}: {
  vals: Host;
  id: number;
  onHostChange: (hs: Host | undefined, id: number) => void;
  isRequierdField: boolean;
  numberOfHosts: number;
}) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { location, scheme, dns_preference } = vals;

  const [isValidOriginName, setIsValidOriginName] = useState(true);

  const urlRegex = useMemo(() => urlRegexValidation, []);

  const [hostNameOrigin, setHostNameOrigin] = useState('');
  const [portValue, setPortValue] = useState('');
  const [schemeValue, setSchemeValue] = useState('match');
  const [ipVersion, setIpVersion] = useState('');

  useEffect(() => {
    hostNameOrigin
      ? setIsValidOriginName(urlRegex.test(hostNameOrigin))
      : setIsValidOriginName(true);
  }, [hostNameOrigin, urlRegex]);

  const handleName = () => {
    const newHost = {
      scheme: schemeValue,
      // dns_preference: ipVersion,
      location: [
        {
          hostname: hostNameOrigin,
          // port: schemeValue === 'match' ? undefined : +portValue,
        },
      ],
    };

    onHostChange(newHost, id);
  };

  const handleScheme = (v: string) => {
    setSchemeValue(v);
    const newHost = {
      scheme: v,
      // dns_preference: ipVersion,
      location: [
        {
          hostname: hostNameOrigin,
          // port: schemeValue === 'match' ? undefined : +portValue,
        },
      ],
    };

    onHostChange(newHost, id);
  };

  const handleIpV = (v: string) => {
    setIpVersion(v);
    const newHost = {
      scheme: schemeValue,
      // dns_preference: v,
      location: [
        {
          hostname: hostNameOrigin,
          // port: schemeValue === 'match' ? undefined : +portValue,
        },
      ],
    };

    onHostChange(newHost, id);
  };

  const handlePort = (v: string) => {
    setPortValue(v);
    const newHost = {
      scheme: schemeValue,
      // dns_preference: ipVersion,
      location: [
        {
          hostname: hostNameOrigin,
          // port: schemeValue === 'match' ? undefined : +v,
        },
      ],
    };

    onHostChange(newHost, id);
  };

  useEffect(() => {
    setHostNameOrigin(location[0]?.hostname?.toString() ?? '');
    setPortValue(location[0]?.port?.toString() ?? '');
    setSchemeValue(scheme);
    setIpVersion(dns_preference ?? '');
  }, [dns_preference, location, scheme, vals]);

  return (
    <Row gutter={[20, 20]} justify="space-between">
      <Col span={12}>
        <Row align="top">
          {numberOfHosts > 1 && (
            <Col className="draggableIcon" span={1}>
              <HolderOutlined rotate={90} style={{ fontSize: 16, paddingRight: 10 }} />
            </Col>
          )}

          <Col span={numberOfHosts > 1 ? 23 : 24}>
            <Input
              // addonBefore="asd"s
              // isRequired
              className={cn('OriginNameInput', {
                _requiredField: isRequierdField && !hostNameOrigin,
                _invalidURL: !isValidOriginName,
                _isFirst: numberOfHosts > 1 && id === 0,
              })}
              // label={id === 0 ? 'Origin Hostname' : ''}
              placeholder={'e.g. "origin.example.com"'}
              value={`${hostNameOrigin}`}
              onChange={(e: FormEvent<HTMLInputElement>) => {
                setHostNameOrigin(e.currentTarget.value);
              }}
              onBlur={() => {
                handleName();
              }}
            />
            {!isValidOriginName && (
              <Errormessage errormessage="The value you entered is not a valid hostname." />
            )}
          </Col>
        </Row>
      </Col>
      <Col span={4}>
        <Select
          disabled
          // label={id === 0 ? 'Scheme' : ''}
          data={[
            { value: 'match', name: 'match' },
            { value: 'http', name: 'http' },
            { value: 'https', name: 'https' },
          ]}
          width="100%"
          value={schemeValue}
          onChange={(value: SelectValue) => handleScheme(value as string)}
        />
      </Col>
      <Col span={3}>
        <Input
          disabled
          className="OriginNameInput"
          // disabled={schemeValue === 'match'}
          // label={id === 0 ? 'Port' : ''}
          value={`${portValue}`}
          onChange={(e: FormEvent<HTMLInputElement>) => handlePort(e.currentTarget.value)}
        />
      </Col>
      <Col span={4}>
        <Select
          disabled
          // label={id === 0 ? 'IP Version Preference' : ''}
          placeholder="No preference"
          data={[
            { value: 'IPv4 Preferred', name: 'IPv4 Preferred' },
            { value: 'IPv6 Preferred', name: 'IPv6 Preferred' },
            { value: 'IPv4 Only', name: 'IPv4 Only' },
            { value: 'IPv6 Only', name: 'IPv6 Only' },
          ]}
          width="100%"
          value={ipVersion}
          onChange={(value: SelectValue) => handleIpV(value as string)}
        />
      </Col>
      <Col
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          bottom: '8px',
        }}
        span={1}
        className="ProjectOriginCdnCnames__head-col _ta-right">
        <Tooltip placement="bottom" title="Remove this hostname">
          <Icon
            className="ProjectOriginCdnCnames__removeIcon"
            name="remove2"
            onClick={() => onHostChange(undefined, id)}
          />
        </Tooltip>
        {!isValidOriginName && <div style={{ height: '18.85px' }} />}
      </Col>
    </Row>
  );
};

export default OriginHostnameRow;
