import React, { FC, useMemo } from 'react';
import FusionCharts from 'fusioncharts';
import World from 'fusionmaps/maps/fusioncharts.worldwithcountries';
import ReactFC from 'react-fusioncharts';
import { IHistoricalStatisticsCountryItemResponse } from 'models';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { MAP_HEIGHT, MAP_WIDTH, bytesToSize } from 'helpers/map';
import countries from 'libs/countriesById.json';
import { find, map, reduce, toUpper } from 'lodash';
import { Loader } from 'components/common/Loader';
import { useViewportWidth } from '../hooks/useViewportWidth';
import { historicalChartMapConfigs } from '../configs';

ReactFC.fcRoot(FusionCharts, World, FusionTheme);

export const ProjectChartsHistoricalMap: FC<{
  data?: IHistoricalStatisticsCountryItemResponse[];
  historicalDataMapStatus?: string;
}> = ({ data, historicalDataMapStatus }) => {
  const { viewportWidth } = useViewportWidth();

  const dataSource = useMemo(() => {
    const dataMaped = map(data, (item) => {
      const id = find(countries, (country) => toUpper(country.label) === toUpper(item.key));
      const value = reduce(
        item?.historical_data?.buckets,
        (summ, { bytes_out }) => summ + Number(bytes_out?.value),
        0
      );
      return {
        id: String(id?.id),
        value,
        tooltext: `$lName <br>  BW: <b> ${bytesToSize(value)}</b>`,
      };
    });

    return {
      ...historicalChartMapConfigs(),
      data: dataMaped,
    };
  }, [data]);

  return (
    <>
      {historicalDataMapStatus !== 'fulfilled' ? (
        <div className="ProjectChartsMapBlock__loader">
          <Loader />
        </div>
      ) : (
        <ReactFC
          type="maps/worldwithcountries"
          width={MAP_WIDTH + (viewportWidth > 1330 ? 80 : 0)}
          height={MAP_HEIGHT}
          dataFormat="JSON"
          dataSource={dataSource}
          id="map"
        />
      )}
    </>
  );
};

export default ProjectChartsHistoricalMap;
