import { createApi } from '@reduxjs/toolkit/query/react';
import { RuleLatestResponse, RulesPredefinedResponse, RulesResponse } from 'models/rules';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const rulesAPI = createApi({
  reducerPath: 'rulesAPI',
  baseQuery: baseQuery(),
  tagTypes: ['Rules', 'RulesDetails', 'RulesLatest', 'PredefinedRules'],
  endpoints: (build) => ({
    getRules: build.query<
      RulesResponse,
      { directoryName: string; cnameId: string; status?: string; trashed?: boolean }
    >({
      query: ({ cnameId, directoryName, status, trashed }) => ({
        url: endpoints.rules.rules(directoryName, cnameId),
        params: {
          status,
          trashed,
        },
      }),
      providesTags: ['Rules'],
    }),

    getRuleLatest: build.query<
      RuleLatestResponse,
      { directoryName: string; cnameId: string; status?: string }
    >({
      query: ({ cnameId, directoryName }) => ({
        url: endpoints.rules.rulesLatest(directoryName, cnameId),
      }),
      providesTags: ['RulesLatest'],
    }),

    createRule: build.mutation<
      RulesResponse,
      { directoryName: string; cnameId: string; data?: unknown }
    >({
      query: ({ cnameId, directoryName, data }) => ({
        url: endpoints.rules.rules(directoryName, cnameId),
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Rules', 'RulesLatest'],
    }),

    getRuleDetails: build.query<
      RuleLatestResponse,
      { directoryName: string; cnameId: string; ruleId: string }
    >({
      query: ({ cnameId, directoryName, ruleId }) => ({
        url: endpoints.rules.rulesDetails(directoryName, cnameId, ruleId),
      }),
      providesTags: ['RulesDetails'],
    }),

    updateRule: build.mutation<
      RulesResponse,
      { directoryName: string; cnameId: string; ruleId: string; data: unknown }
    >({
      query: ({ cnameId, directoryName, ruleId, data }) => ({
        url: endpoints.rules.rulesDetails(directoryName, cnameId, ruleId),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Rules', 'RulesDetails'],
    }),

    removeRule: build.mutation<
      RulesResponse,
      { directoryName: string; cnameId: string; ruleId: string }
    >({
      query: ({ cnameId, directoryName, ruleId }) => ({
        url: endpoints.rules.rulesDetails(directoryName, cnameId, ruleId),
        method: 'DELETE',
      }),
      invalidatesTags: ['Rules'],
    }),

    deployRule: build.mutation<
      RulesResponse,
      { directoryName: string; cnameId: string; ruleId: string }
    >({
      query: ({ cnameId, directoryName, ruleId }) => ({
        url: endpoints.rules.rulesDeploy(directoryName, cnameId, ruleId),
        method: 'POST',
      }),
      invalidatesTags: ['Rules', 'RulesDetails'],
    }),

    cancelDeployRule: build.mutation<
      RulesResponse,
      { directoryName: string; cnameId: string; ruleId: string }
    >({
      query: ({ cnameId, directoryName, ruleId }) => ({
        url: endpoints.rules.rulesCancelDeploy(directoryName, cnameId, ruleId),
        method: 'POST',
      }),
      invalidatesTags: ['Rules', 'RulesDetails'],
    }),

    getPredefinedRules: build.query<
      RulesPredefinedResponse,
      { directoryName: string; cnameId: string }
    >({
      query: ({ cnameId, directoryName }) => ({
        url: endpoints.rules.predefinedRules(directoryName, cnameId),
      }),
      providesTags: ['PredefinedRules'],
    }),
    updatePredefinedRules: build.mutation<
      { message: string },
      { directoryName: string; cnameId: string; predefinedRules: number[] }
    >({
      query: ({ cnameId, directoryName, predefinedRules }) => ({
        url: endpoints.rules.predefinedRules(directoryName, cnameId),
        method: 'POST',
        body: {
          predefined_rules: predefinedRules,
        },
      }),
      invalidatesTags: ['PredefinedRules'],
    }),
  }),
});

export default rulesAPI;
