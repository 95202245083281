import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { matchRoutes, useLocation, useParams } from 'react-router-dom';
import { propertyAPI } from 'services';
import { useAppDispatch, useAppSelector } from 'store';
// import classNames from 'classnames';
import { isNil } from 'lodash';
// import { toggleMenu } from 'store/slices/ui';
import {
  changeSelectedCustomPeriod,
  // updateSelectedCdn,
  CustomPeriodInSecurityPage,
} from 'store/slices/project';
// import { Icon } from 'components/common/Icon';
import RouteTypes from 'router/types';
// import useSslHook from 'hooks/project/useSslHook';
import { toggleMenu } from 'store/slices/ui';
import { Icon } from 'components/common/Icon';
import { ProjectHeadPurgeDropdown } from './ProjectHeadPurgeDropdown';
// import { ProjectHeadTimeDropdown } from './ProjectHeadTimeDropdown';
// import { ProjectHeadEngineNav } from './ProjectHeadEngineNav';
import { ProjectHeadTimeDropdown } from './ProjectHeadTimeDropdown';
import './ProjectHead.scss';

const routes = [
  { path: RouteTypes.routing },
  { path: RouteTypes.logs },
  { path: RouteTypes.security },
  { path: RouteTypes.project },
  { path: RouteTypes.settings },
  { path: RouteTypes.engine },
  { path: RouteTypes.engineDraft },
  { path: RouteTypes.engineHistory },
];

export const ProjectHead: FC<{ isVisible: boolean }> = ({ isVisible }) => {
  const { directoryName } = useParams<{ directoryName: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { isMenuColapsed, pageName } = useAppSelector(({ ui }) => ui);

  // const { isSettled } = useSslHook(directoryName, token);

  // const { selectedCdn } = useAppSelector(({ project }) => project);

  const {
    data: project,
    // isLoading: isPropertyLoading,
    isFetching: isPropertyFetching,
  } = propertyAPI.useFetchPropertyQuery(String(directoryName), {
    skip: !token,
  });

  const [customDropdown, setCustomDropdown] = useState<
    'routing' | 'logs' | 'security' | 'settings' | 'project' | 'engine'
  >();

  const dispatch = useAppDispatch();

  const location = useLocation();

  const route = matchRoutes(routes, location.pathname);

  // const onUpdateCdnButtonClick = useCallback(
  //   (id: string | number) => {
  //     if (id === selectedCdn) {
  //       return;
  //     }
  //     dispatch(updateSelectedCdn(id));
  //   },
  //   [dispatch, selectedCdn]
  // );

  useEffect(() => {
    if (!isNil(route)) {
      switch (route[0].route.path) {
        case RouteTypes.routing:
          setCustomDropdown('routing');
          break;

        case RouteTypes.logs:
          setCustomDropdown('logs');
          break;

        case RouteTypes.security:
          setCustomDropdown('security');
          break;

        case RouteTypes.settings:
          setCustomDropdown('settings');
          break;
        case RouteTypes.engine:
          setCustomDropdown('engine');
          break;

        case RouteTypes.engineDraft:
          setCustomDropdown('engine');
          break;

        case RouteTypes.engineHistory:
          setCustomDropdown('engine');
          break;

        default:
          setCustomDropdown('project');
          break;
      }
    } else {
      setCustomDropdown(undefined);
    }
  }, [route]);

  useEffect(() => {
    if (customDropdown === 'routing') {
      dispatch(changeSelectedCustomPeriod(CustomPeriodInSecurityPage.DAY1));
    } else if (customDropdown === 'security') {
      dispatch(changeSelectedCustomPeriod(CustomPeriodInSecurityPage.DAY2));
    } else {
      dispatch(changeSelectedCustomPeriod(CustomPeriodInSecurityPage.MIN15));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customDropdown]);

  const [isBannerViewed, setIsBannerViewed] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsBannerViewed(document.querySelector('.bannerWrapper') !== null);
    }, 0);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Row
        justify="space-between"
        className="ProjectHead"
        style={{
          paddingTop: isBannerViewed ? '0px' : '16px',
          paddingBottom: '12px',
          // paddingBottom: isBannerViewed ? '12px' : '30px',
        }}>
        <Col>
          <Row style={{ display: 'flex' }}>
            {isMenuColapsed ? (
              <Icon
                style={{ fontSize: 16 }}
                name="menu-open2"
                className="ProjectHead__collapsed"
                onClick={() => dispatch(toggleMenu())}
              />
            ) : (
              <Icon
                style={{ fontSize: 16 }}
                name="menu-open"
                className="ProjectHead__collapsed"
                onClick={() => dispatch(toggleMenu())}
              />
            )}
            <span className="ProjectHead__pageName">{pageName}</span>
            {/* <Col> */}
            {/* <ProjectHeadCnamesDropdown
                    isLoading={isPropertyLoading}
                    data={project?.data as unknown as IPropertyData}
                    // onItemClick={onUpdateCdnButtonClick}
                    // selectedItem={selectedCdn}
                  /> */}
            {/* {!isSettled && (
                  <Col>
                    <NavLink
                      to={generatePath(RouteTypes.settingsTLS, {
                        directoryName: directoryName || '',
                      })}
                      className="ProjectHead__route">
                      To complete the account setup, please configure your SSL certificate
                    </NavLink>
                  </Col>
                )} */}
            {/* </Col> */}
          </Row>
        </Col>
        <Row align="middle" gutter={16}>
          <Col>{customDropdown === 'project' && <ProjectHeadTimeDropdown />}</Col>
          {!isNil(route) && route[0].route?.path === RouteTypes.project && (
            <>
              <Col style={{ maxHeight: 20 }}>
                <ProjectHeadPurgeDropdown
                  property={project}
                  isFetching={isPropertyFetching}
                  isVisible={isVisible}
                />
              </Col>
            </>
          )}
        </Row>
      </Row>
      {/* {!isBannerViewed && (
        <Row>
          <Col
            span={24}
            style={{
              height: '18px',
            }}
          />
        </Row>
      )} */}
    </>
  );
};

export default ProjectHead;
