import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IServicesResponse,
  UserBillingReportsResponse,
  UserDetailsPayload,
  UserDetailsResponse,
  ShowUserResponse,
  UserPaymentsDetailsPayload,
  UserPaymentsDetailsResponse,
  IDeleteAccountResponse,
} from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const userDetailsApi = createApi({
  reducerPath: 'userDetailsApi',
  baseQuery: baseQuery(),
  tagTypes: [
    'UserDetails',
    'UserPayments',
    'LastViewed',
    'Favorites',
    'ShowUser',
    'MeDetails',
    'RevertActing',
    'Delete Account',
  ],
  endpoints: (build) => ({
    fetchUserDetails: build.query<UserDetailsResponse, string>({
      query: () => ({
        url: endpoints.user.details,
      }),
      providesTags: () => ['UserDetails'],
    }),
    fetchIntercom: build.query<
      {
        data: {
          api_base: string;
          app_id: string;
          created_at: string;
          email: string;
          user_hash: string;
          name?: string;
        };
      },
      string
    >({
      query: () => ({
        url: endpoints.user.intercom,
      }),
    }),
    fetchUserSubscriptions: build.query<
      {
        data: {
          stripe_status: string;
        };
      },
      string
    >({
      query: () => ({
        url: endpoints.users.subscriptions,
      }),
    }),
    updateUserDetails: build.mutation<UserDetailsResponse, UserDetailsPayload>({
      query: (body) => ({
        url: endpoints.user.details,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserDetails'],
    }),
    fetchUserBillingDetails: build.query<UserPaymentsDetailsResponse, string>({
      query: () => ({
        url: endpoints.user.billingDetails,
      }),
      providesTags: () => ['UserPayments'],
    }),
    updateUserBillingDetails: build.mutation<
      UserPaymentsDetailsResponse,
      UserPaymentsDetailsPayload
    >({
      query: (body) => ({
        url: endpoints.user.billingDetails,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserPayments'],
    }),
    fetchLastViewed: build.query<{ data: IServicesResponse }, string>({
      query: () => ({
        url: endpoints.users.lastViewed,
      }),
      providesTags: () => ['LastViewed'],
    }),
    fetchFavorites: build.query<{ data: IServicesResponse }, string>({
      query: () => ({
        url: endpoints.users.favorites,
      }),
      providesTags: () => ['Favorites'],
    }),
    fetchShowUser: build.query<UserDetailsResponse, string>({
      query: (userId) => ({
        url: endpoints.user.showUser(userId),
      }),
      providesTags: () => ['ShowUser'],
    }),
    fetchMeDetails: build.query<ShowUserResponse, string>({
      query: () => ({
        url: endpoints.user.me,
      }),
      providesTags: () => ['MeDetails'],
    }),
    revertActing: build.mutation<UserDetailsResponse, string>({
      query: () => ({
        url: endpoints.user.revertActing,
        method: 'POST',
      }),
      invalidatesTags: ['RevertActing'],
    }),

    fetchReports: build.query<UserBillingReportsResponse, { start_date: string; end_date: string }>(
      {
        query: ({ end_date, start_date }) => ({
          url: endpoints.users.billingReports,
          params: {
            start_date,
            end_date,
          },
        }),
      }
    ),
    cancelTrial: build.mutation<UserDetailsResponse, string>({
      query: () => ({
        url: endpoints.users.cancelTrial,
        method: 'POST',
      }),
    }),
    deleteAccount: build.mutation<IDeleteAccountResponse, string>({
      query: (email) => ({
        url: endpoints.deleteAccount,
        method: 'DELETE',
        body: { email },
      }),
      invalidatesTags: ['Delete Account'],
    }),
  }),
});

export default userDetailsApi;
