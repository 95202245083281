import { Col, Row } from 'antd';
import classNames from 'classnames';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { Icon } from 'components/common/Icon';
import { ProjectRoutingTable } from 'components/views/Project/ProjectRouting';
import { find, map } from 'lodash';
import moment from 'moment';
import React, { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { routingAPI, statisticsAPI } from 'services';
import { useAppDispatch, useAppSelector } from 'store';
import { changeSelectedCustomPeriod } from 'store/slices/project';
import styles from './ProjectRouting.module.scss';

const customDropdownRoutingValues: { name: string; value: string }[] = [
  { name: 'Last 24 Hours', value: 'day' },
  { name: 'Last Month', value: 'month' },
];

export const ProjectRouting: FC = () => {
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { selectedCustomPeriod } = useAppSelector(({ project }) => project);
  const [liveDropdownVisibility, setLiveDropdownVisibility] = useState(false);
  const dispatch = useAppDispatch();
  const { data: tableData, isLoading } = routingAPI.useFetchRoutingQuery(
    { directoryName: String(directoryName) },
    { skip: !token }
  );

  const getPeriod = useMemo(() => {
    const endDate = moment().format('DD/MM/YYYY');
    if (selectedCustomPeriod.includes('day')) {
      const startDate = moment().subtract(24, 'h').format('DD/MM/YYYY');
      return [startDate, endDate];
    }
    const startDate = moment().startOf('M').format('DD/MM/YYYY');
    return [startDate, endDate];
  }, [selectedCustomPeriod]);

  const getFrequency = useMemo(
    () => (selectedCustomPeriod.includes('day') ? '1' : '24'),
    [selectedCustomPeriod]
  );

  const { data: chartData } = statisticsAPI.useFetchHistoricalStristicsDnsQuery(
    {
      frequency: getFrequency,
      cname: tableData?.DomainName || '',
      startDate: getPeriod[0],
      endDate: getPeriod[1],
    },
    { skip: !token || !tableData }
  );

  const renderCustomRoutingTimeDropdown = useMemo(
    () => (
      <Row gutter={[14, 14]} style={{ width: 154 }}>
        {map(customDropdownRoutingValues, ({ name, value }) => (
          <Col span={24} key={value}>
            <button
              type="button"
              className={classNames('ProjectHead__dropdown-btn', {
                _active: selectedCustomPeriod.includes(value),
              })}
              onClick={() => dispatch(changeSelectedCustomPeriod(value))}>
              {name}
              <Icon name="check" className="ProjectHead__dropdown-check" />
            </button>
          </Col>
        ))}
      </Row>
    ),
    [dispatch, selectedCustomPeriod]
  );

  const renderChartHeaderControls = useMemo(() => {
    return (
      // <Row gutter={16}>
      //   <Col>
      //     <Button type="secondary" icon="export">
      //       Export
      //     </Button>
      //   </Col>
      //   <Col>
      //     <Button type="secondary" icon="settings">
      //       Options
      //     </Button>
      //   </Col>
      // </Row>
      <Dropdown
        onVisibleChange={(v) => setLiveDropdownVisibility(v)}
        triggerElement={
          <div className="ProjectHead__trigger-url">
            <Button type="subtle">
              <div className={classNames('ProjectHead__purge-text', '_blue')}>
                {
                  find(customDropdownRoutingValues, (item) =>
                    selectedCustomPeriod.includes(item.value)
                  )?.name
                }
                <Icon
                  className="ProjectHead__small-arrow"
                  name="expand-down"
                  style={{
                    transform: liveDropdownVisibility ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </div>
            </Button>
          </div>
        }>
        {renderCustomRoutingTimeDropdown}
      </Dropdown>
    );
  }, [liveDropdownVisibility, renderCustomRoutingTimeDropdown, selectedCustomPeriod]);

  return (
    <Row className={styles.routing} gutter={[16, 16]}>
      <Col span={24}>
        <div className={styles.chart}>
          {/* <ProjectRoutingBarChart data={chartData} isHoury={selectedCustomPeriod === 'day'} /> */}
          <ProjectRoutingTable
            data={tableData}
            chartData={chartData}
            isHoury={selectedCustomPeriod.includes('day')}
            isLoadingData={isLoading}
            chartBlockHeader={renderChartHeaderControls}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ProjectRouting;
