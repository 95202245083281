import { createApi } from '@reduxjs/toolkit/query/react';
import { ICnamePayload, ICnameResponce } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const originAPI = createApi({
  reducerPath: 'originAPI',
  baseQuery: baseQuery(),
  tagTypes: ['Cname', 'Purge'],
  endpoints: (build) => ({
    createCname: build.mutation<ICnameResponce, ICnamePayload>({
      query: ({ directoryName, hostname, platform }) => ({
        url: `${endpoints.origin.cname}/${directoryName}`,
        method: 'POST',
        body: { hostname, platform },
      }),
    }),
    updateCname: build.mutation<ICnameResponce, ICnamePayload>({
      query: ({ directoryName, hostname, platform, edge_id }) => ({
        url: `${endpoints.origin.cname}/${directoryName}`,
        method: 'PUT',
        body: { hostname, platform, edge_id },
      }),
    }),
    deleteCname: build.mutation<ICnameResponce, ICnamePayload>({
      query: ({ directoryName, platform, edge_id }) => ({
        url: `${endpoints.origin.cname}/${directoryName}`,
        method: 'DEL',
        body: { platform, edge_id },
      }),
    }),
    purgeAll: build.mutation<ICnameResponce, string>({
      query: (directoryName) => ({
        url: endpoints.origin.purge.all(directoryName),
        method: 'POST',
        body: {
          platform: 'both',
        },
      }),
    }),
    purgeUrl: build.mutation<
      ICnameResponce,
      { directoryName: string; cnameId: string; path: string }
    >({
      query: ({ directoryName, cnameId, path }) => ({
        url: endpoints.origin.purge.url(directoryName, cnameId),
        method: 'POST',
        body: {
          path,
          platform: 'both',
        },
      }),
    }),
  }),
});

export default originAPI;
