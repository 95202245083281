/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import {
  IField,
  inputCodesObject,
  selectCompareObject,
  selectMatchObject,
  switchObject,
} from './fieldsObjects';

export const asNumber: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputCodesObject,
    regexp: /\d{1,10}|\?/,
  },
];

export const cityNameLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputCodesObject,
    regexp: /([-@_a-zA-Z0-9\~%:;=!,\.\+\*'\s\\]+|\?)/,
  },
  {
    id: 2,
    ...switchObject,
  },
];

export const cityNameRegex: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputCodesObject,
    regexp: /.+/,
  },
  {
    id: 2,
    ...switchObject,
  },
];

export const continentLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputCodesObject,
    regexp: /(AF|AS|EU|NA|OC|SA|\?)/,
  },
];

export const countryLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputCodesObject,
    regexp: /(A[12]|[A-Z]{1,2}|\?)/,
  },
];

export const dmaCodeLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputCodesObject,
    regexp: /\d{1,10}|\?/,
  },
];

export const latitudeLiteral: IField[] = [
  {
    id: 0,
    ...selectCompareObject,
  },
  {
    id: 1,
    ...inputCodesObject,
    regexp: /[-+]?[0-9]{1,3}(\.[0-9]*)?|\?/,
  },
];

export const longitudeLiteral: IField[] = [
  {
    id: 0,
    ...selectCompareObject,
  },
  {
    id: 1,
    ...inputCodesObject,
    regexp: /[-+]?[0-9]{1,3}(\.[0-9]*)?|\?/,
  },
];

export const metroCodeLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputCodesObject,
    regexp: /([A-Za-z0-9-]+|\?)/,
  },
];

export const postalCodeLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputCodesObject,
    regexp: /([A-Za-z0-9-]+|\?)/,
  },
];

export const regionCodeLiteral: IField[] = [
  {
    id: 0,
    ...selectMatchObject,
  },
  {
    id: 1,
    ...inputCodesObject,
    regexp: /([0-9A-Z]{1,3})$|\?/,
  },
];
