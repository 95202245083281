import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IBWResponse,
  ICreateServicePayload,
  IPreviewChartResponse,
  IServiceDetailsResponse,
  IServicesResponse,
  ISummaryStatsResponse,
} from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const projectAPI = createApi({
  reducerPath: 'projectAPI',
  baseQuery: baseQuery(),
  tagTypes: ['Service', 'ServiceDetails', 'AplicationTypes'],
  endpoints: (build) => ({
    fetchAllServices: build.query<IServicesResponse, string>({
      query: () => ({
        url: endpoints.projects,
      }),
      providesTags: () => ['Service'],
    }),
    fetchSummaryStats: build.query<ISummaryStatsResponse, string>({
      query: () => ({
        url: endpoints.summaryStats,

        // params: {
        //   start_date,
        //   end_date,
        // },
      }),
    }),
    fetchService: build.query<IServiceDetailsResponse, string>({
      query: (directoryName) => ({
        url: `${endpoints.projects}/${directoryName}`,
      }),
      providesTags: ['ServiceDetails'],
    }),
    fetchBW: build.query<
      IBWResponse,
      { directoryName: string; endDate: string; startDate: string }
    >({
      query: ({ directoryName, endDate, startDate }) => ({
        url: endpoints.projectsBW(directoryName),
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
    }),
    fetchPreviewChart: build.query<
      IPreviewChartResponse,
      { directoryName: string; endDate: string; startDate: string }
    >({
      query: ({ directoryName, endDate, startDate }) => ({
        url: endpoints.projectsPreviewChart(directoryName),
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
    }),
    createService: build.mutation<IServicesResponse, ICreateServicePayload>({
      query: (service) => ({
        url: endpoints.projects,
        method: 'POST',
        body: service,
      }),
      invalidatesTags: ['Service'],
    }),
    deleteService: build.mutation<IServicesResponse, string>({
      query: (directoryName) => ({
        url: `${endpoints.projects}/${directoryName}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Service'],
    }),
    updateService: build.mutation<
      IServicesResponse,
      {
        directoryName: string;
        ip: string[];
        load_balancing: string | boolean;
        follow_redirects: boolean | string;
        host_header: string | null;
      }
    >({
      query: ({ directoryName, follow_redirects, ip, load_balancing, host_header }) => ({
        url: `${endpoints.projects}/${directoryName}`,
        method: 'PUT',
        body: {
          follow_redirects,
          load_balancing,
          ip,
          host_header,
        },
      }),
      invalidatesTags: ['Service'],
    }),
    updateServicePlan: build.mutation<
      IServicesResponse,
      {
        directoryName: string;
        plan: string;
      }
    >({
      query: ({ directoryName, plan }) => ({
        url: endpoints.projectsPlan(directoryName),
        method: 'PUT',
        body: {
          plan,
        },
      }),
      invalidatesTags: ['ServiceDetails'],
    }),
    fetchAplicationTypes: build.query<
      {
        data: {
          description: string | null;
          id: number;
          logoUrl: string | null;
          name: string | null;
        }[];
      },
      string
    >({
      query: () => ({
        url: endpoints.aplicationTypes,
      }),
      providesTags: () => ['AplicationTypes'],
    }),
    updateNameserverSetup: build.mutation<
      {
        message: string;
      },
      string
    >({
      query: (directoryName) => ({
        url: endpoints.nameserverUpdate(directoryName),
        method: 'PUT',
      }),
      invalidatesTags: ['ServiceDetails'],
    }),
    addFavorite: build.mutation<
      {
        success: boolean;
        message: string;
      },
      string
    >({
      query: (directoryName) => ({
        url: endpoints.projectsAddFavorites(directoryName),
        method: 'POST',
      }),
      invalidatesTags: ['Service'],
    }),
    removeFavorite: build.mutation<
      {
        success: boolean;
        message: string;
      },
      string
    >({
      query: (directoryName) => ({
        url: endpoints.projectsRemoveFavorites(directoryName),
        method: 'POST',
      }),
      invalidatesTags: ['Service'],
    }),
  }),
});

export default projectAPI;
