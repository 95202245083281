import moment from 'moment';
import { CustomPeriod, CustomPeriodInLogsPage } from 'store/slices/project';

export const getIntervalFromCustomRange = (start: number, end: number) => {
  const diff = end - start;
  const diffInSeconds = diff / 1000;
  const isInRange = (value: number, min: number, max: number) => value >= min && value <= max;

  const ONE_MINUTE = 60;
  const ONE_HOUR = ONE_MINUTE * 60;
  const ONE_DAY = ONE_HOUR * 24;
  const THIRTY_DAYS = ONE_DAY * 30;
  const NINETY_DAYS = ONE_DAY * 90;

  let interval = '5h';
  if (isInRange(diffInSeconds, 1, 19)) {
    interval = '1s';
  } else if (isInRange(diffInSeconds, 20, 29)) {
    interval = '4s';
  } else if (isInRange(diffInSeconds, 30, 119)) {
    interval = '5s';
  } else if (isInRange(diffInSeconds, 2 * ONE_MINUTE, 29 * ONE_MINUTE)) {
    interval = '10s';
  } else if (isInRange(diffInSeconds, 30 * ONE_MINUTE, 59 * ONE_MINUTE)) {
    interval = '20s';
  } else if (isInRange(diffInSeconds, ONE_HOUR, 2 * ONE_HOUR - 1)) {
    interval = '40s';
  } else if (isInRange(diffInSeconds, 2 * ONE_HOUR, 6 * ONE_HOUR)) {
    interval = '3m';
  } else if (isInRange(diffInSeconds, 12 * ONE_HOUR, ONE_DAY)) {
    interval = '10m';
  } else if (isInRange(diffInSeconds, ONE_DAY + 1, 3 * ONE_DAY - 1)) {
    interval = '20m';
  } else if (isInRange(diffInSeconds, 3 * ONE_DAY, 7 * ONE_DAY - 1)) {
    interval = '1h';
  } else if (isInRange(diffInSeconds, 7 * ONE_DAY - 1, 29 * ONE_DAY)) {
    interval = '2h';
  } else if (isInRange(diffInSeconds, THIRTY_DAYS, NINETY_DAYS - 1)) {
    interval = '4h';
  } else if (isInRange(diffInSeconds, NINETY_DAYS + 1, NINETY_DAYS * 8)) {
    interval = '10h';
  }

  return interval;
};

export const convertToReadableTime = (diff: number, short = true) => {
  const durationMoment = moment.duration(diff);

  const years = durationMoment.years();
  const months = durationMoment.months();
  const days = durationMoment.days();
  const h = durationMoment.hours();
  const m = durationMoment.minutes();
  const s = durationMoment.seconds();

  let result = '';
  if (short) {
    result += years > 0 ? `${years}y ` : '';
    result += days > 0 ? `${days}d ` : '';
    result += h > 0 ? `${h}h ` : '';
    result += m > 0 ? `${m}m ` : '';
    result += s > 0 ? `${s}s` : '';
  } else {
    result += years > 0 ? `${years} ${years > 1 ? 'years' : 'year'} ` : '';
    result += months > 0 ? `${months} ${months > 1 ? 'months' : 'month'} ` : '';
    result += days > 0 ? `${days} ${days > 1 ? 'days' : 'day'} ` : '';
    result += h > 0 ? `${h} hr ` : '';
    result += m > 0 ? `${m} min ` : '';
    result += s > 0 ? `${s} sec` : '';
  }

  return result.trim();
};

export const getInterval = (customPeriod: CustomPeriod, customLogsPeriod: number[] | null) => {
  if (customLogsPeriod?.length === 2) {
    return getIntervalFromCustomRange(customLogsPeriod[0], customLogsPeriod[1]);
  }

  switch (customPeriod) {
    case CustomPeriodInLogsPage.M15: // '15m'
      return '10s';
    case CustomPeriodInLogsPage.M30: // '30m'
      return '20s';
    case CustomPeriodInLogsPage.M60: // '60m'
      return '40s';
    case CustomPeriodInLogsPage.H3: // '3h'
      return '2m';
    case CustomPeriodInLogsPage.H6: // '6h'
      return '3m';
    case CustomPeriodInLogsPage.H24: // '24h'
      return '10m';
    case CustomPeriodInLogsPage.D7: // '7d'
      return '2h';
    case CustomPeriodInLogsPage.D30: // '30d'
      return '4h';
    case CustomPeriodInLogsPage.D90: // '90d'
      return '5h';
    default:
      return '10m';
  }
};

export const getUnitAndQuantity = (
  customPeriod: CustomPeriod,
  customLogsPeriod?: number[] | null
) => {
  if (customLogsPeriod?.length === 2) {
    const diff = customLogsPeriod[1] - customLogsPeriod[0];
    const dayStamp = 86400000;
    if (diff >= dayStamp) return ['d', diff / dayStamp];
    const hourStamp = 3600000;
    if (diff >= hourStamp) return ['h', diff / hourStamp];
    const minuteStamp = 60000;
    if (diff >= minuteStamp) return ['m', diff / minuteStamp];
    const secondStamp = 1000;
    if (diff >= secondStamp) return ['s', diff / secondStamp];
    return ['m', '15'];
  }

  switch (customPeriod) {
    case CustomPeriodInLogsPage.M15: // '15m'
      return ['m', '15'];
    case CustomPeriodInLogsPage.M30: // '30m'
      return ['m', '30'];
    case CustomPeriodInLogsPage.M60: // '60m'
      return ['m', '60'];
    case CustomPeriodInLogsPage.H3: // '3h'
      return ['h', '3'];
    case CustomPeriodInLogsPage.H6: // '6h'
      return ['h', '6'];
    case CustomPeriodInLogsPage.H24: // '24h'
      return ['h', '24'];
    case CustomPeriodInLogsPage.D7: // '7d'
      return ['d', '7'];
    case CustomPeriodInLogsPage.D30: // '30d'
      return ['d', '30'];
    case CustomPeriodInLogsPage.D90: // '90d'
      return ['d', '90'];
    default:
      return ['m', '15'];
  }
};
