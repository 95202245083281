import React, { FC, useCallback } from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { find, map } from 'lodash';
import { IBillingReport } from 'models';
import { propertyAPI } from 'services';
import { useAppSelector } from 'store';
import styles from './UserSettingsBillingReportsItem.module.scss';

interface UserSettingsBillingReportsItemProps {
  data: IBillingReport[];
}

export const UserSettingsBillingReportsItem: FC<UserSettingsBillingReportsItemProps> = ({
  data,
}) => {
  const { token } = useAppSelector(({ auth }) => auth);
  const { data: propertiesData } = propertyAPI.useFetchAllPropertiesQuery('', {
    skip: !token,
  });

  const getLabel = useCallback((bytes: number, isNumber?: boolean, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = isNumber
      ? ['', 'K', 'M', 'G', 't', 'p', 'e', 'z', 'y']
      : ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }, []);

  return (
    <>
      {map(data, ({ bytes, stripe_billed_traffic, property_id }, idx) => {
        // const bytesPersents =
        //   Number(bytes) > Number(maximum_bytes)
        //     ? (Number(maximum_bytes) * 100) / Number(bytes)
        //     : (Number(bytes) * 100) / Number(maximum_bytes);

        // const hitsPersents =
        //   Number(hits) > Number(maximum_hits)
        //     ? (Number(maximum_hits) * 100) / Number(hits)
        //     : (Number(hits) * 100) / Number(maximum_hits);

        return (
          <Col key={property_id || idx} span={24}>
            <Row className={styles.item}>
              <Col span={6} className={classNames(styles.value)}>
                <span className={styles.first}>
                  {find(propertiesData?.data?.properties, ({ id }) => id === property_id)?.name}
                </span>
              </Col>
              <Col span={6} className={styles.value}>
                <Row align="middle" gutter={8}>
                  {/* <Col flex="100px">
                      <Progress
                        strokeColor="#DB5960"
                        showInfo={false}
                        width={100}
                        success={{ percent: bytesPersents, strokeColor: '#1A59D9' }}
                        percent={Number(bytes) > Number(maximum_bytes) ? 100 : bytesPersents}
                        size="small"
                      />
                    </Col> */}
                  <Col>
                    <span className={classNames(styles.value)}>
                      <span
                        className={classNames(styles.value, {
                          // [styles.red]: Number(bytes) > Number(maximum_bytes),
                          // [styles.blue]: Number(bytes) < Number(maximum_bytes),
                        })}>
                        {getLabel(bytes)}
                      </span>
                      {/* /{getLabel(Number(maximum_bytes))} used */}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={6} className={styles.value}>
                {getLabel(Number(stripe_billed_traffic))}
              </Col>
              {/* <Col span={8} className={styles.value}>
                  <Row align="middle" gutter={8}>
                    <Col flex="100px">
                      <Progress
                        strokeColor="#DB5960"
                        showInfo={false}
                        width={100}
                        percent={Number(hits) > Number(maximum_hits) ? 100 : hitsPersents}
                        success={{
                          percent: hitsPersents,
                          strokeColor: '#1A59D9',
                        }}
                        size="small"
                      />
                    </Col>
                    <Col>
                      <span className={classNames(styles.value)}>
                        <span
                          className={classNames(styles.value, {
                            [styles.red]: Number(hits) > Number(maximum_hits),
                            [styles.blue]: Number(hits) < Number(maximum_hits),
                          })}>
                          {getLabel(Number(hits), true)}
                        </span>
                        /{getLabel(Number(maximum_hits), true)} hits used
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={2} className={classNames(styles.value)}>
                  <span className={styles.last}>{stripe_billed_hits}</span>
                </Col> */}
            </Row>
          </Col>
        );
      })}
    </>
  );
};

export default UserSettingsBillingReportsItem;
