import './ProjectEngine.scss';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

export const ProjectEngine: FC = withAuthenticationRequired(() => {
  return (
    <div className="ProjectEngine">
      <Outlet />
    </div>
  );
});

export default ProjectEngine;
