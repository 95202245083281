import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Icon, IconType } from '../Icon';
import './Label.scss';

export const labelTestId = 'label';

export type LabelType = 'default' | 'danger' | 'success' | 'warning' | 'info';

export interface Props {
  className?: string;
  color?: string;
  background?: string;
  icon?: IconType;
  type?: LabelType;
  children?: ReactNode;
}

export const Label: FC<Props> = ({
  type = 'default',
  icon,
  className,
  background,
  color,
  children,
}) => {
  return (
    <span
      data-testid={labelTestId}
      className={classNames('Label', `_${type}`, className)}
      style={{ backgroundColor: background }}>
      {icon && <Icon style={{ fill: color }} className="Label__icon" name={icon} />}
      <span style={{ color }} className="Label__text">
        {children}
      </span>
    </span>
  );
};

export default Label;
