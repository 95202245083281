import { Col, Form, Row } from 'antd';
import React, { FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  cloneDeep,
  filter,
  findIndex,
  includes,
  isNil,
  map,
  mapValues,
  pickBy,
  range,
  remove,
  size,
  some,
  xor,
} from 'lodash';
import cn from 'classnames';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { Input } from 'components/common/Input';
import { Notification } from 'components/common/Notification';
import { useParams } from 'react-router-dom';
import { Icon } from 'components/common/Icon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Loader } from 'components/common/Loader';
import { BlockWrapper } from 'components/common/BlockWrapper';
import Dropdown from 'components/common/Dropdown';
import { IFailoverGroup, IHistoricalStatisticsDnsResponce, IRecord } from 'models';
import { projectAPI, routingAPI, userDetailsApi } from 'services';
import { ReactComponent as Ilustration } from 'assets/svg/RoutingEmptyIlustration.svg';
import { useAppSelector } from 'store';
import Switch from 'components/common/Switch';
import Checkbox from 'components/common/Checkbox';
import { ProjectRoutingTableItem } from './ProjectRoutingTableItem';
import { ProjectRoutingTableAddModal } from './ProjectRoutingTableAddModal';
import styles from './ProjectRoutingTable.module.scss';
import { ProjectRoutingTableCreateZoneModal } from './ProjectRoutingTableCreateZoneModal';
import { ProjectRoutingTableDeleteZoneModal } from './ProjectRoutingTableDeleteZoneModal';
import { ProjectLogsViewListBarChart } from '../ProjectRoutingBarChartNew';
import { ProjectRoutingTableDefaultRecords } from './ProjectRoutingTableDefaultRecords';

const { useForm } = Form;

const DATA = ['A', 'AAAA', 'CAA', 'CNAME', 'MX', 'NS', 'PTR', 'SPF', 'SRV', 'TXT'];
export interface IPayloadElement {
  key: string;
  idx: number;
  record?: IRecord;
}

export type FormType = IRecord & { key: string };
export interface ICreateForm {
  DomainName: string;
}

export const ProjectRoutingTable: FC<{
  data?: IFailoverGroup;
  chartData?: IHistoricalStatisticsDnsResponce[];
  isHoury?: boolean;
  isLoadingData?: boolean;
  chartBlockHeader?: React.ReactNode;
}> = ({ data, chartData, isHoury, isLoadingData, chartBlockHeader }) => {
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [visible, setVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [isVanity, setIsVanity] = useState(false);
  const [createZoneModalVisible, setCreateZoneModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IPayloadElement | undefined>();
  const [checked, setChecked] = useState([...DATA]);
  const [isAll, setIsAll] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { isMenuColapsed } = useAppSelector(({ ui }) => ui);
  const { data: dataUser } = userDetailsApi.useFetchMeDetailsQuery('', {
    skip: !token,
  });
  const isAdminHeaderDisplayed = dataUser?.data?.is_acting_as?.status;

  const { data: projectDetails, refetch } = projectAPI.useFetchServiceQuery(String(directoryName), {
    skip: !token,
  });

  const [addForm] = useForm<FormType>();
  const [createForm] = useForm<ICreateForm>();

  const [deleteRecord, { isLoading }] = routingAPI.useUpdateRoutingMutation();
  const [updateRecords, { isLoading: isUpdatingLoading }] = routingAPI.useUpdateRoutingMutation();
  const [deleteZone, { isLoading: isDeleteLoading, isSuccess }] =
    routingAPI.useDeleteRoutingMutation();
  const [updateNameserverSetup, { isLoading: isUpdateNameserverSetupLoading }] =
    projectAPI.useUpdateNameserverSetupMutation();
  const [addZone, { isLoading: isZoneAddLoading }] = routingAPI.useCreateZoneMutation();

  const togglAll = useCallback((value: React.ChangeEvent<HTMLInputElement>) => {
    setIsAll(value.target.checked);
    if (value.target.checked) {
      setChecked([...DATA]);
    } else {
      setChecked([]);
    }
  }, []);

  const renderTimeDropdown = useMemo(
    () => (
      <Row gutter={[14, 14]}>
        <Col span={24} className={styles.firstItem}>
          <Row gutter={8}>
            <Col>
              <Checkbox value="All" checked={isAll} onChange={togglAll} />
            </Col>
            <Col>
              <span className={styles.label}>All</span>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {map(DATA, (item) => (
            <Row key={item} gutter={8}>
              <Col>
                <Checkbox
                  value={item}
                  checked={checked}
                  onChange={() => setChecked(xor(checked, [item]))}
                />
              </Col>
              <Col>
                <span className={styles.label}>{item}</span>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    ),
    [checked, isAll, togglAll]
  );

  const onCancelClick = useCallback((modalType: string) => {
    switch (modalType) {
      case 'delete':
        setVisible(false);
        setSelectedItem(undefined);
        break;
      case 'edit':
        setAddModalVisible(false);
        setSelectedItem(undefined);
        break;
      case 'new':
        setAddModalVisible(false);
        break;
      case 'deleteZone':
        setDeleteModalVisible(false);
        break;
      default:
        setCreateZoneModalVisible(false);
        break;
    }
  }, []);

  const onDeleteClick = useCallback((record: IPayloadElement) => {
    setVisible(true);
    setSelectedItem(record);
  }, []);

  const onAddClick = useCallback((record?: IPayloadElement) => {
    if (record) {
      setSelectedItem(record);
      setAddModalVisible(true);
    } else {
      setAddModalVisible(true);
    }
  }, []);

  const onDeleteConfirmClick = useCallback(() => {
    if (selectedItem && data) {
      const { key } = selectedItem;
      const shallowCopy = cloneDeep(data);
      const arrayOfCurrentRecord = shallowCopy?.Records[key];
      const idx = findIndex(arrayOfCurrentRecord, {
        Name: selectedItem.record?.Name,
        Rdata: selectedItem.record?.Rdata,
        TTL: selectedItem.record?.TTL,
      });
      remove(arrayOfCurrentRecord, (_, index) => idx === index);
      shallowCopy.Records[key] = arrayOfCurrentRecord;
      const res = shallowCopy;
      deleteRecord({ body: res, directoryName: String(directoryName), id: String(res.id) })
        .then(() => {
          refetch();
          setVisible(false);
          setSelectedItem(undefined);
          Notification({ type: 'check', title: 'Record deleted successfuly' });
        })
        .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
    }
  }, [data, deleteRecord, directoryName, refetch, selectedItem]);

  const onAddFormFinish = useCallback(
    (values: FormType) => {
      const { Name, Rdata, TTL, key } = values;
      const shallowCopy = cloneDeep(data);

      if (isNil(shallowCopy?.Records)) {
        if (shallowCopy) {
          shallowCopy.Records = { [key]: [{ Name, Rdata, TTL }] };
          updateRecords({
            directoryName: String(directoryName),
            id: String(data?.id),
            body: shallowCopy,
          })
            .then(() => {
              refetch();
              setAddModalVisible(false);
              Notification({ type: 'check', title: 'Record added successfuly' });
              addForm.resetFields();
            })
            .catch((err) =>
              Notification({ type: 'cross', title: 'Error', message: err.data.message })
            );
        }
        return;
      }

      if (!selectedItem) {
        shallowCopy?.Records[key].push({ Name, Rdata, TTL });
        if (shallowCopy) {
          updateRecords({
            directoryName: String(directoryName),
            id: String(data?.id),
            body: shallowCopy,
          })
            .then(() => {
              setAddModalVisible(false);
              Notification({ type: 'check', title: 'Record added successfuly' });
              addForm.resetFields();
              refetch();
            })
            .catch((err) =>
              Notification({ type: 'cross', title: 'Error', message: err.data.message })
            );
        }
      } else {
        const arrayOfCurrentRecord = shallowCopy?.Records[key];
        const idx = findIndex(arrayOfCurrentRecord, {
          Name: selectedItem.record?.Name,
          Rdata: selectedItem.record?.Rdata,
          TTL: selectedItem.record?.TTL,
        });
        if (arrayOfCurrentRecord && shallowCopy) {
          arrayOfCurrentRecord.splice(idx, 1, { Name, Rdata, TTL });
          shallowCopy.Records[key] = arrayOfCurrentRecord;
          updateRecords({
            directoryName: String(directoryName),
            id: String(data?.id),
            body: shallowCopy,
          })
            .then(() => {
              setAddModalVisible(false);
              setSelectedItem(undefined);
              Notification({ type: 'check', title: 'Record updated successfuly' });
              addForm.resetFields();
            })
            .catch((err) =>
              Notification({ type: 'cross', title: 'Error', message: err.data.message })
            );
        }
      }
    },
    [addForm, data, directoryName, refetch, selectedItem, updateRecords]
  );

  const onCreateFormFinish = useCallback(
    (values: ICreateForm) => {
      addZone({ DomainName: values.DomainName, directoryName: String(directoryName) })
        .unwrap()
        .then(() => {
          refetch();
          Notification({ type: 'check', title: 'Zone created successfuly' });
          setCreateZoneModalVisible(false);
          addForm.resetFields();
        })
        .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
    },
    [addForm, addZone, directoryName, refetch]
  );

  const onDeleteZoneClick = useCallback(() => {
    deleteZone({ directoryName: String(directoryName), id: Number(data?.id) })
      .unwrap()
      .then(() => {
        refetch();
        Notification({ type: 'check', title: 'Zone deleted successfuly' });
        setDeleteModalVisible(false);
      })
      .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
  }, [data?.id, deleteZone, directoryName, refetch]);

  const addZoneClick = useCallback(() => {
    setCreateZoneModalVisible(true);
  }, []);

  const filteredData = useMemo(() => {
    const filterByType = pickBy(data?.Records, (value, key) => includes(checked, key));
    const filteredByInput = mapValues(filterByType, (array) =>
      filter(array, (item) =>
        some(Object.values(item), (el) =>
          String(el).toLowerCase().includes(inputValue.toLowerCase())
        )
      )
    );
    return filteredByInput;
  }, [checked, data?.Records, inputValue]);

  const onUpdateNameserverSetup = useCallback(() => {
    updateNameserverSetup(String(directoryName))
      .unwrap()
      .then((resp) => Notification({ title: resp.message, type: 'check' }))
      .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
  }, [directoryName, updateNameserverSetup]);

  const renderChartSection = useMemo(() => {
    if (isLoadingData) {
      return (
        <Row justify="center" align="middle" style={{ padding: '48px 0' }}>
          <Loader />
        </Row>
      );
    }
    if (!projectDetails?.nameserver_setup) {
      return (
        <Row gutter={[24, 18]} style={{ marginBottom: 12, marginTop: 12 }}>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                <span className={styles.namespaceText}>
                  Manage your DNS records using the form below. While DNS changes are deployed to
                  the instantly, it can take up to 24 hours to resolve.
                </span>
              </Col>
              <Col>
                <Button onClick={onUpdateNameserverSetup} loading={isUpdateNameserverSetupLoading}>
                  I confirm that i configured the name servers
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <div className={styles.namespaceBlock}>
              <Row>
                <Col>
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col span={12}>
                          <Row gutter={8} align="middle">
                            <Col>
                              <span className={styles.namespaceCounter}>1</span>
                            </Col>
                            <Col>
                              <span className={styles.namespaceName}>My Name Servers</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row align="middle" gutter={8}>
                            <Col>
                              <Switch checked={isVanity} onChange={(v) => setIsVanity(v)} />
                            </Col>
                            <Col>Vanity name servers</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <span className={styles.namespaceDescr}>
                        {isVanity
                          ? 'Use these records to create your own Vanity Name Servers to your domain.'
                          : 'Use these records to point your Domain Name to Edgeport.'}
                      </span>
                    </Col>
                    <Col span={12}>
                      <Row gutter={[10, 10]}>
                        {map(range(4), (item) => (
                          <Col key={item} span={24}>
                            <div className={styles.coppyBlock}>
                              <span className={styles.coppyLabel}>
                                {isVanity
                                  ? `ns${item + 1}.${data?.DomainName}`
                                  : `ns${item + 1}.edgeport.net`}
                              </span>
                              <CopyToClipboard
                                text={
                                  isVanity
                                    ? `ns${item + 1}.${data?.DomainName}`
                                    : `ns${item + 1}.edgeport.net`
                                }
                                onCopy={() =>
                                  Notification({
                                    title: 'Copied successfuly',
                                    message: `Value ns${item + 1}.${
                                      isVanity ? data?.DomainName : 'edgeport.net'
                                    } successfuly copied to clipboard`,
                                  })
                                }>
                                <Icon name="copy" className={styles.coppyIcon} onClick={() => {}} />
                              </CopyToClipboard>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    {isVanity && (
                      <Col span={12}>
                        <Row gutter={[10, 10]}>
                          {map(
                            ['192.16.16.5', '192.16.16.6', '198.7.29.5', '198.7.29.6'],
                            (item) => (
                              <Col key={item} span={24}>
                                <div className={styles.coppyBlock}>
                                  <span className={styles.coppyLabel}>{item}</span>
                                  <CopyToClipboard
                                    text={item}
                                    onCopy={() =>
                                      Notification({
                                        title: 'Copied successfuly',
                                        message: `Value ${item} successfuly copied to clipboard`,
                                      })
                                    }>
                                    <Icon
                                      name="copy"
                                      className={styles.coppyIcon}
                                      onClick={() => {}}
                                    />
                                  </CopyToClipboard>
                                </div>
                              </Col>
                            )
                          )}
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>

          <Col span={12}>
            <div className={styles.namespaceBlock}>
              <Row gutter={[8, 10]} align="middle" style={{ marginBottom: 8 }}>
                <Col>
                  <span className={styles.namespaceCounter}>2</span>
                </Col>
                <Col>
                  <span className={styles.namespaceName}>
                    Update your Name Servers at the Registrar
                  </span>
                </Col>
                <Col span={24}>
                  <span className={styles.namespaceDescr}>
                    Please input the nameservers from Step 1 into the designated name server fields
                    for your domain at your registrar.
                  </span>
                </Col>
              </Row>
              <ol className={styles.namespaceList}>
                <li className={styles.namespaceItem}>Log in at your domain Registrar</li>
                <li className={styles.namespaceItem}>
                  Go to <span>DNS Management</span>
                </li>
                <li className={styles.namespaceItem}>
                  Under <span>Nameservers</span>, click Change
                </li>
                <li className={styles.namespaceItem}>
                  Enter or paste the new Name Server entries and click <span>Save</span>
                </li>
                <li className={styles.namespaceItem}>
                  <span>Done!</span> (it can take up to 24 hours to fully propagate)
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <>
        <ProjectLogsViewListBarChart data={chartData} isHoury={isHoury} />
      </>
    );
  }, [
    chartData,
    data?.DomainName,
    isHoury,
    isLoadingData,
    isUpdateNameserverSetupLoading,
    isVanity,
    onUpdateNameserverSetup,
    projectDetails?.nameserver_setup,
  ]);

  useEffect(() => {
    if (checked.length === 10) {
      setIsAll(true);
    } else {
      setIsAll(false);
    }
  }, [checked.length]);

  const defaultData = useMemo(() => {
    const DEFAULT_DATA: Array<IRecord & { name: string }> = [
      {
        name: 'NS',
        TTL: 3600,
        Name: '@',
        Rdata: `ns1.${data?.DomainName}`,
      },
      {
        name: 'NS',
        TTL: 3600,
        Name: '@',
        Rdata: `ns2.${data?.DomainName}`,
      },
      {
        name: 'NS',
        TTL: 3600,
        Name: '@',
        Rdata: `ns3.${data?.DomainName}`,
      },
      {
        name: 'NS',
        TTL: 3600,
        Name: '@',
        Rdata: `ns4.${data?.DomainName}`,
      },
      {
        name: 'A',
        TTL: 3600,
        Name: 'ns1',
        Rdata: `192.16.16.5`,
      },
      {
        name: 'AAAA',
        TTL: 3600,
        Name: 'ns1',
        Rdata: `2606:2800:0003:0000:0000:0000:0000:0005`,
      },
      {
        name: 'A',
        TTL: 3600,
        Name: 'ns2',
        Rdata: `192.16.16.6`,
      },
      {
        name: 'AAAA',
        TTL: 3600,
        Name: 'ns2',
        Rdata: `2606:2800:0003:0000:0000:0000:0000:0006`,
      },
      {
        name: 'A',
        TTL: 3600,
        Name: 'ns3',
        Rdata: `198.7.29.5`,
      },
      {
        name: 'AAAA',
        TTL: 3600,
        Name: 'ns3',
        Rdata: `2606:2800:000C:0000:0000:0000:0000:0005`,
      },
      {
        name: 'A',
        TTL: 3600,
        Name: 'ns4',
        Rdata: `198.7.29.6`,
      },
      {
        name: 'AAAA',
        TTL: 3600,
        Name: 'ns4',
        Rdata: `2606:2800:000C:0000:0000:0000:0000:0006`,
      },
    ];
    return DEFAULT_DATA;
  }, [data?.DomainName]);

  const filteredDefaultData = useMemo(() => {
    const groupByType = (arr: Array<IRecord & { name: string }>) => {
      const result: Record<string, IRecord[]> = {};
      arr.forEach((item) => {
        const { name } = item;
        if (!result[name]) {
          result[name] = [];
        }
        result[name].push(item);
      });

      return result;
    };
    const filterByType = pickBy(groupByType(defaultData), (value, key) => includes(checked, key));
    const filteredByInput = mapValues(filterByType, (array) =>
      filter(array, (item) =>
        some(Object.values(item), (el) =>
          String(el).toLowerCase().includes(inputValue.toLowerCase())
        )
      )
    );

    return filteredByInput as Record<string, Array<IRecord & { name: string }>>;
  }, [checked, defaultData, inputValue]);

  return (
    <>
      <Modal
        visible={visible}
        title="Delete this record?"
        onCancel={() => onCancelClick('delete')}
        width="300px"
        footer
        okButtonLabel="Delete"
        onOk={onDeleteConfirmClick}
        okButtonType="danger"
        isFooterCentered
        okLoading={isLoading}>
        <div className={styles.modalText}>You cannot restore it</div>
      </Modal>
      <Modal
        visible={addModalVisible}
        title={selectedItem ? 'Edit Record' : 'Add Record'}
        width="400px"
        onCancel={() => onCancelClick('edit')}>
        <ProjectRoutingTableAddModal
          onCancel={() => onCancelClick('edit')}
          form={addForm}
          onFinish={onAddFormFinish}
          selectedItem={selectedItem}
          loading={isUpdatingLoading}
        />
      </Modal>
      <Modal
        visible={createZoneModalVisible}
        title="Create New Zone"
        width="400px"
        onCancel={() => onCancelClick('zone')}>
        <ProjectRoutingTableCreateZoneModal
          form={createForm}
          onFinish={onCreateFormFinish}
          onCancel={() => onCancelClick('zone')}
          loading={isZoneAddLoading}
        />
      </Modal>
      <Modal
        visible={deleteModalVisible}
        title="Delete Zone"
        width="400px"
        onCancel={() => onCancelClick('deleteZone')}>
        <ProjectRoutingTableDeleteZoneModal
          domain={data?.DomainName || ''}
          onCancel={() => onCancelClick('deleteZone')}
          onFinish={onDeleteZoneClick}
          loading={isDeleteLoading}
          isSuccess={isSuccess}
        />
      </Modal>
      {isNil(data) && !projectDetails?.routing_setup ? (
        <div className={cn(styles.emptyState, { [styles.collapsed]: isMenuColapsed })}>
          <Row align="middle" gutter={120} style={{ height: '100%' }}>
            <Col span={12}>
              <h2 className={styles.emptyTitle}>You don&apos;t have any zone created yet.</h2>
              <p className={styles.emptyText}>
                Experience the power of our Route solution, offering a diverse range of options to
                ensure you have access to high-performance and secure DNS services.
              </p>
              <Button icon="plus" onClick={() => addZoneClick()}>
                Create DNS Zone
              </Button>
            </Col>
            <Col span={12} className={styles.imageBlock}>
              <Ilustration className={styles.ilustration} />
              <span className={styles.orange} />
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <BlockWrapper
                title={!projectDetails?.nameserver_setup ? 'DNS Records' : 'Requests'}
                padding="16px 24px"
                minHeight={0}
                renderHeaderControls={chartBlockHeader}
                className={styles.chartWrapper}>
                {renderChartSection}
              </BlockWrapper>
            </Col>
            <Col span={24}>
              <BlockWrapper isFullHeight={false} className={styles.wrapper} minHeight="initial">
                <Col
                  span={24}
                  style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 16, paddingBottom: 16 }}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Input
                        icon="search"
                        width={400}
                        placeholder="Search records"
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                          setInputValue(e.currentTarget.value)
                        }
                      />
                    </Col>
                    <Col>
                      <Row align="middle" gutter={28}>
                        <Col>
                          <Dropdown
                            onVisibleChange={(v) => setDropdownVisibility(v)}
                            triggerElement={
                              <div className={styles.dropdown}>
                                <Button type="subtle">
                                  <div className={styles.button}>
                                    Type: All
                                    <Icon
                                      className={styles.iconDropdown}
                                      name="expand-down"
                                      style={{
                                        transform: dropdownVisibility
                                          ? 'rotate(180deg)'
                                          : 'rotate(0deg)',
                                      }}
                                    />
                                  </div>
                                </Button>
                              </div>
                            }>
                            {renderTimeDropdown}
                          </Dropdown>
                        </Col>
                        <Row
                          gutter={16}
                          style={{
                            position: 'fixed',
                            top: isAdminHeaderDisplayed ? 72 : 12,
                            right: 32,
                          }}>
                          <Col>
                            <Button
                              type="secondary"
                              icon={!data ? 'plus' : 'remove'}
                              onClick={() =>
                                !data ? addZoneClick() : setDeleteModalVisible(true)
                              }>
                              {!data ? 'Add New DNS Zone' : 'Remove DNS Zone'}
                            </Button>
                          </Col>
                          {data && (
                            <Col>
                              <Button icon="plus" onClick={() => onAddClick()}>
                                Add New Record
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <div className={styles.table}>
                  {data && (
                    <>
                      <Row className={cn(styles.row, styles.item)}>
                        <Col span={4} className={styles.col}>
                          <span className={styles.label}>Type</span>
                        </Col>
                        <Col span={4} className={cn(styles.col)}>
                          <span className={styles.label}>TTL</span>
                        </Col>
                        <Col span={4} className={cn(styles.col)}>
                          <span className={styles.label}>Name</span>
                        </Col>
                        <Col span={3} className={cn(styles.col, styles.right)}>
                          <span className={styles.label}>Value</span>
                        </Col>
                        <Col span={3} className={cn(styles.col, styles.right)} />
                        {/* <Col span={3} className={cn(styles.col, styles.right)}>
                        <Button type="subtle" icon="export">
                          Export
                        </Button>
                      </Col> */}
                      </Row>
                      <ProjectRoutingTableDefaultRecords
                        defaultData={filteredDefaultData}
                        isEmpty={size(filteredData) === 0}
                      />
                      {filteredData &&
                        map(filteredData, (value, key) => {
                          return (
                            <React.Fragment key={key}>
                              {map(value, (item, idx) => (
                                <ProjectRoutingTableItem
                                  data={item}
                                  name={key}
                                  key={item.Name + idx}
                                  onDeleteClick={(record) => onDeleteClick({ idx, key, record })}
                                  onEditClick={(record) => onAddClick({ idx, key, record })}
                                />
                              ))}
                            </React.Fragment>
                          );
                        })}
                    </>
                  )}
                </div>
              </BlockWrapper>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProjectRoutingTable;
