/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ProjectHead } from 'components/views/Project/ProjectHead';
import { useAppDispatch, useAppSelector } from 'store';
import BannerDeployChanges from 'components/common/BannerDeployChanges';
import { Col, Row } from 'antd';
import Modal from 'components/common/Modal';
import DiffViewer from 'components/common/DiffViewer';
import Button from 'components/common/Button';
import { Notification } from 'components/common/Notification';
import { isEqual, size } from 'lodash';
import usePropertyHook from 'hooks/usePropertyHook';
import { Origin } from 'models';
import { propertyAPI } from 'services';
import { getFieldName, getSkeletonsSize, updateSkeletonsSize } from 'helpers/utils';
import {
  updateHostnamesDetails,
  updateOriginsDetails,
  updateRulesDetails,
  // updateTLSDetails,
} from 'store/slices/property';
import './Project.scss';

export const Project: FC = withAuthenticationRequired(() => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector(({ auth }) => auth);
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { origins, hostnames, rules, status, isDeployBtnDisabled, tls } = useAppSelector(
    ({ property }) => property
  );

  // const { data: securityProfilesData, isLoading: isLoadingSecurityProfilesData } =
  //   sucurityProfilesServiceAPIv2.useFetchSecurityProfilesQuery(String(directoryName), {
  //     skip: !token,
  //     // pollingInterval: statusProfiles === 'in_progress' ? 5000 : 0,
  //   });

  // const [updateSecurityProfiles, { isLoading: isUpdateSecurityProfiles }] =
  //   sucurityProfilesServiceAPIv2.useUpdateSeccurityProfilesMutation();

  const { propertyDetails, refetchProperty, isPropertyLoading, isTlsLoading } = usePropertyHook(
    directoryName,
    token
  );
  const { data: tlsValues } = propertyAPI.useFetchDomainsQuery(String(directoryName), {
    skip: !token,
  });
  const [updateProperty, { isLoading: isUpdatePropLoading }] =
    propertyAPI.useUpdatePropertyMutation();

  // const [updatedSecurityProfilesData, setUpdatedSecurityProfiles] = useState<
  //   IPropertySecurityProfilesUpdate[]
  // >([]);

  const [diffModalVisible, setDiffModalVisible] = useState(false);
  const [isVisible, setIsvisible] = useState(false);
  // const [isVisibleSecurityProfiles, setIsVisibleSecurityProfiles] = useState(false);
  // const [isPageDisabled, setIsPageDisabled] = useState<boolean>(false);

  // const [isRevert, setIsRevert] = useState(false);

  useEffect(() => {
    const nOfOrs = getSkeletonsSize('nOfOrs', String(directoryName));
    const nOfHnms = getSkeletonsSize('nOfHnms', String(directoryName));
    const crtf = getSkeletonsSize('crtf', String(directoryName));

    nOfOrs
      ? updateSkeletonsSize('nOfOrs', String(directoryName), nOfOrs)
      : updateSkeletonsSize('nOfOrs', String(directoryName), size([...(origins ?? [])]));
    nOfHnms
      ? updateSkeletonsSize('nOfHnms', String(directoryName), nOfHnms)
      : updateSkeletonsSize('nOfHnms', String(directoryName), size([...(hostnames ?? [])]));
    crtf
      ? updateSkeletonsSize('crtf', String(directoryName), crtf)
      : updateSkeletonsSize('crtf', String(directoryName), size([...(tls ?? [])]));
  }, [directoryName, hostnames, origins, tls]);

  const oldOrigins = useMemo(() => {
    return propertyDetails?.data?.origins ?? [];
  }, [propertyDetails?.data?.origins]);

  const oldHostnames = useMemo(() => {
    return propertyDetails?.data?.hostnames ?? [];
  }, [propertyDetails?.data?.hostnames]);

  const oldRules = useMemo(() => {
    return propertyDetails?.data?.rules ?? [];
  }, [propertyDetails?.data?.rules]);

  const diffOrigins = useMemo(() => {
    return origins.filter(
      (item: Origin) =>
        Boolean(item.name) && Boolean(...item.hosts.filter((i) => Boolean(i.location[0].hostname)))
    );
  }, [origins]);

  const diffHostnames = useMemo(() => {
    return hostnames;
  }, [hostnames]);

  const diffRules = useMemo(() => {
    return rules;
  }, [rules]);

  const isVisibleBanner = useMemo(() => {
    const isDiff =
      !isEqual(
        JSON.stringify(oldOrigins, undefined, 2),
        JSON.stringify(diffOrigins, undefined, 2)
      ) ||
      !isEqual(
        JSON.stringify(oldHostnames, undefined, 2),
        JSON.stringify(diffHostnames, undefined, 2)
      ) ||
      !isEqual(JSON.stringify(oldRules, undefined, 2), JSON.stringify(diffRules, undefined, 2));

    // const isLoading = !isPropertyLoading;
    const isLoading = !isPropertyLoading || !isTlsLoading;

    return isDiff && isLoading;
  }, [
    diffHostnames,
    diffOrigins,
    diffRules,
    isPropertyLoading,
    isTlsLoading,
    oldHostnames,
    oldOrigins,
    oldRules,
  ]);

  // const hasInProgress = useMemo(
  //   () => securityProfilesData?.data?.some((item) => item.details.status === 'in_progress'),
  //   [securityProfilesData?.data]
  // );

  // const [statusProfiles, setStatusProfiles] = useState<string>('active');

  // useEffect(() => {
  //   if (hasInProgress) {
  //     setStatusProfiles('in_progress');
  //   } else {
  //     setStatusProfiles('active');
  //   }
  // }, [hasInProgress]);

  // const isSaveBtnDisabled = useCallback(
  //   () =>
  //     JSON.stringify(
  //       securityProfilesData?.data?.map((item) => ({
  //         hostname: item.hostname,
  //         options: item.details.configuration_data,
  //       }))
  //     ) === JSON.stringify(updatedSecurityProfilesData),
  //   [securityProfilesData, updatedSecurityProfilesData]
  // );

  // const normalizedData: IPropertySecurityProfilesUpdate[] = useMemo(
  //   () =>
  //     [...(securityProfilesData?.data || ([] as IPropertySecurityProfilesData[]))].map((item) => ({
  //       hostname: item.hostname,
  //       options: item.details.configuration_data,
  //     })),
  //   [securityProfilesData?.data]
  // );

  // const handleSaveSecurityProfiles = useCallback(() => {
  //   const propertyId = String(directoryName);

  //   updateSecurityProfiles({ propertyId, hostnames: updatedSecurityProfilesData })
  //     .unwrap()
  //     .then((respData) => {
  //       Notification({
  //         type: 'check',
  //         title: 'Changes saved successfully!',
  //         message: respData.message,
  //       });
  //     })
  //     .catch((error) => {
  //       const errorMessage = error.data.data
  //         ? Object.values(error.data.data)
  //             .flat(2)
  //             .map((item: any, idx) => (
  //               <Col span={24} key={idx} style={{ margin: '3px 0' }}>
  //                 <span>{item}</span>
  //               </Col>
  //             ))
  //         : error.data.message;
  //       Notification({ type: 'cross', title: 'Error', message: errorMessage });
  //     });
  // }, [directoryName, updateSecurityProfiles, updatedSecurityProfilesData]);

  // const handleRevertSecurityProfiles = useCallback(() => {
  //   setIsRevert(true);
  //   if (securityProfilesData?.data) {
  //     setUpdatedSecurityProfiles(normalizedData);
  //   }
  // }, [normalizedData, securityProfilesData?.data]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (propertyDetails?.success && tlsValues?.success) {
      timer = setTimeout(() => {
        if (status === 'deploying') {
          setIsvisible(true);
        } else {
          setIsvisible(isVisibleBanner);
        }
      }, 10);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isVisibleBanner, propertyDetails?.success, status, tlsValues?.success]);

  // useEffect(() => {
  //   let timer: NodeJS.Timeout;

  //   const intervalId = setInterval(() => {
  //     setIsPageDisabled(document.querySelector('._deployBanner') !== null);
  //   }, 100);

  //   if (securityProfilesData?.success) {
  //     timer = setTimeout(() => {
  //       setIsVisibleSecurityProfiles(true);
  //     }, 100);
  //   }

  //   return () => {
  //     clearTimeout(timer);
  //     clearInterval(intervalId);
  //   };
  // }, [securityProfilesData?.success]);

  const handleDeploy = useCallback(() => {
    updateProperty({
      propertyId: String(directoryName),
      payload: { origins, hostnames, rules },
    })
      .unwrap()
      .then(() => Notification({ type: 'check', title: 'Success' }))
      .then(() => {
        refetchProperty()
          .unwrap()
          .then((respProperty) => {
            const originValues = respProperty.data.origins;
            const hostnameValues = respProperty?.data?.hostnames;
            dispatch(updateHostnamesDetails(hostnameValues));
            dispatch(updateOriginsDetails(originValues));
          })
          .catch((err) => {
            Notification({ type: 'cross', title: 'Error', message: err?.data?.message || '' });
          });
      })
      .catch((err) => {
        if (err.data?.data) {
          const errorData = Object.values(err.data.data).flat(2) ?? [];

          const errorMessage = (
            <Row>
              {errorData.map((item: any) => (
                <Col span={24} key={item} style={{ margin: '3px 0' }}>
                  <span>The</span>
                  <span style={{ fontWeight: 600 }}>{` ${getFieldName(item.split(' ')[1])} `}</span>
                  <span>{item.split(' ').slice(2).join(' ')}</span>
                </Col>
              ))}
            </Row>
          );
          Notification({
            type: 'cross',
            title: err?.data?.message || '',
            message: errorMessage,
          });
        } else {
          Notification({ type: 'cross', title: 'Error', message: err?.data?.message || '' });
        }
      });
  }, [directoryName, dispatch, hostnames, origins, refetchProperty, rules, updateProperty]);

  const handleRevert = useCallback(() => {
    const originValues = propertyDetails?.data?.origins ?? [];
    const hnValues = propertyDetails?.data?.hostnames ?? [];
    const rulesValues = propertyDetails?.data?.rules ?? [];
    // const tls = tlsValues?.data ?? [];

    dispatch(updateHostnamesDetails(hnValues));
    dispatch(updateOriginsDetails(originValues));
    dispatch(updateRulesDetails(rulesValues));
    // dispatch(updateTLSDetails(tls));
  }, [
    dispatch,
    propertyDetails?.data?.hostnames,
    propertyDetails?.data?.origins,
    propertyDetails?.data?.rules,
  ]);

  const renderDiffModal = useMemo(() => {
    return (
      <Modal
        visible={diffModalVisible}
        title="Diff"
        width="90%"
        onCancel={() => setDiffModalVisible(false)}>
        <Row style={{ overflow: 'scroll', maxHeight: '70vh' }}>
          {!isEqual(
            JSON.stringify(oldHostnames, undefined, 2),
            JSON.stringify(diffHostnames, undefined, 2)
          ) && (
            <>
              <DiffViewer
                oldValue={JSON.stringify(oldHostnames, undefined, 2)}
                newValue={JSON.stringify(diffHostnames, undefined, 2)}
                title="Hostnames"
                iconType="hostnames"
              />
              <Col span={24} style={{ margin: '5px 0' }} />
            </>
          )}
          {!isEqual(
            JSON.stringify(oldOrigins, undefined, 2),
            JSON.stringify(diffOrigins, undefined, 2)
          ) && (
            <>
              <DiffViewer
                oldValue={JSON.stringify(oldOrigins, undefined, 2)}
                newValue={JSON.stringify(diffOrigins, undefined, 2)}
                title="Origins"
                iconType="origins"
              />
              <Col span={24} style={{ margin: '5px 0' }} />
            </>
          )}
          {!isEqual(
            JSON.stringify(oldRules, undefined, 2),
            JSON.stringify(diffRules, undefined, 2)
          ) && (
            <>
              <DiffViewer
                oldValue={JSON.stringify(oldRules, undefined, 2)}
                newValue={JSON.stringify(diffRules, undefined, 2)}
                title="Rules"
                iconType="rules"
              />
              <Col span={24} style={{ margin: '5px 0' }} />
            </>
          )}
        </Row>

        <Row justify="end" style={{ marginTop: 30 }}>
          <Col>
            <Button type="secondary" onClick={() => setDiffModalVisible(false)}>
              Close
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }, [diffModalVisible, oldHostnames, diffHostnames, oldOrigins, diffOrigins, oldRules, diffRules]);

  return (
    <>
      {renderDiffModal}

      {/* {(isVisibleSecurityProfiles && !isSaveBtnDisabled()) || hasInProgress ? (
        <BannerDeployChanges
          withOutDiff
          btnTitle="Save Changes"
          title="You have unsaved changes"
          deployLoading={isUpdateSecurityProfiles}
          onDeployClick={handleSaveSecurityProfiles}
          onRevertClick={handleRevertSecurityProfiles}
          status={statusProfiles}
          isDisabled={isLoadingSecurityProfilesData || isSaveBtnDisabled()}
          // style={{ position: 'relative', bottom: '14px' }}
        />
      ) : null} */}

      {isVisible && (
        <BannerDeployChanges
          isDeployBanner
          deployLoading={isUpdatePropLoading}
          // revertLoading={isPropertyFetching}
          onDeployClick={() => handleDeploy()}
          onDiffClick={() => setDiffModalVisible(true)}
          onRevertClick={() => handleRevert()}
          status={status}
          isDisabled={isDeployBtnDisabled}
        />
      )}
      <ProjectHead isVisible={isVisible} />

      <Outlet />
    </>
  );
});

export default Project;
