import { Col, Row } from 'antd';
import Button from 'components/common/Button';
import { CodeBlock, IObject } from 'components/common/CodeBlock';
import Divider from 'components/common/Divider';
import { Icon } from 'components/common/Icon';
import { Label, LabelType } from 'components/common/Label';
import { DataModelLogsItem } from 'models';
import React, { FC, useMemo } from 'react';
import styles from './ProjectLogsViewListTableDrawerContent.module.scss';

interface IProjectLogsViewListTableDrawerContentProps {
  data?: DataModelLogsItem;
  onAddFilterClick?: (objectKey: string, val: string | number) => void;
  onExcludeClick?: (objectKey: string, val: string | number) => void;
  isWAF?: boolean;
}

export const ProjectLogsViewListTableDrawerContent: FC<
  IProjectLogsViewListTableDrawerContentProps
> = ({ data, onAddFilterClick, onExcludeClick, isWAF }) => {
  const getStatusLabel = useMemo((): LabelType => {
    switch (data?._source.status) {
      case 'ok':
        return 'success';
      case 'info':
        return 'info';
      case 'notice':
        return 'default';
      case 'emergency':
        return 'danger';
      default:
        return 'default';
    }
  }, [data?._source.status]);

  const getOrderedByKeyNameObject = useMemo(() => {
    const dataObject = data?._source as unknown as IObject;
    if (dataObject) {
      return Object.keys(dataObject)
        .sort()
        .reduce((obj: IObject, key: string) => {
          obj[key] = dataObject[key];
          return obj;
        }, {});
    }
    return {};
  }, [data?._source]);

  return (
    <div className={styles.drawer}>
      <Row className={styles.header}>
        <Col className={styles.headerCol}>
          <span className={styles.title}>Host</span>
          <span className={styles.value}>
            <Icon className={styles.icon} name="datadog" />
            {data?._source.host}
          </span>
        </Col>
        <Col className={styles.headerCol}>
          <span className={styles.title}>Service</span>
          <span className={styles.value}>{data?._source.platform}</span>
        </Col>
        {/* <Col className={styles.headerCol}>
            <span className={styles.title}>Source</span>
            <span className={styles.value}>
              <Icon className={styles.icon} name="nginx" />
              nginx
            </span>
          </Col>
          <Col className={styles.headerCol}>
            <span className={styles.title}>Container Name</span>
            <span className={styles.value}>nginx</span>
          </Col>
          <Col className={styles.headerCol}>
            <span className={styles.title}>Docker Image</span>
            <span className={styles.value}>nginx</span>
          </Col> */}
      </Row>
      {/* <div className={styles.tags}>
          <h4 className={classNames(styles.title, styles.block)}>Tags</h4>
          <Row gutter={[4, 4]}>
            {map(range(6), (item) => (
              <Col key={item}>
                <Tag>Queueing Delay:&gt;450ms</Tag>
              </Col>
            ))}
          </Row>
        </div> */}
      <Divider />
      <div className={styles.body}>
        {!isWAF && (
          <Row className={styles.requestRow} align="middle" gutter={12}>
            <Col>
              <div className={styles.request}>
                <span className={styles.type}>{data?._source.method}</span>
                <span className={styles.requestName}>{data?._source.path}</span>
                <span className={styles.query}>
                  ?{data?._source.query}
                  <Icon name="dropdown" className={styles.dropdown} />
                </span>
              </div>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <Label type={getStatusLabel}>{data?._source.status_code}</Label>
            </Col>
          </Row>
        )}
        <CodeBlock
          value={getOrderedByKeyNameObject}
          onAddFilterClick={onAddFilterClick}
          onExcludeClick={onExcludeClick}
        />
      </div>
      <Row className={styles.footer} gutter={8} align="middle">
        <Col className={styles.footerCol}>
          <span className={styles.footerText}>Use</span>
        </Col>
        <Col className={styles.footerCol}>
          <Button size="smaller" icon="dropdown" type="secondary" />
        </Col>
        <Col className={styles.footerCol}>
          <Button size="smaller" icon="dropup" type="secondary" />
        </Col>
        <Col className={styles.footerCol}>
          <span className={styles.footerText}>to see the previous/next log</span>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectLogsViewListTableDrawerContent;
