/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import RouteTypes from 'router/types';

function useHistoryHook() {
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState<string>(RouteTypes.home);

  useEffect(() => {
    if (location.pathname !== RouteTypes.home) {
      setPreviousLocation(location.pathname);
    }
  }, [location.pathname]);

  return { previousLocation };
}

export default useHistoryHook;
