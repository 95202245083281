import { createApi } from '@reduxjs/toolkit/query/react';
import { SetupIntent } from '@stripe/stripe-js';
import { IntentResponse, PaymentMethodsResponse, ShowStripeKeyResponse } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const paymentMethodsAPI = createApi({
  reducerPath: 'paymentMethodsAPI',
  baseQuery: baseQuery(),
  tagTypes: ['PaymentsMethods', 'LoadStripe'],
  endpoints: (build) => ({
    fetchPaymentMethods: build.query<PaymentMethodsResponse[], string>({
      query: () => ({
        url: endpoints.paymentMethods.paymentMethods,
      }),
      providesTags: () => ['PaymentsMethods'],
    }),
    fetchIntent: build.query<IntentResponse, string>({
      query: () => ({
        url: endpoints.paymentMethods.createIntent,
      }),
    }),
    fetchLoadStripe: build.query<ShowStripeKeyResponse, string>({
      query: () => ({
        url: endpoints.paymentMethods.loadStripeKey,
      }),
      providesTags: () => ['LoadStripe'],
    }),
    createPaymentMethods: build.mutation<{ message: string }, SetupIntent['payment_method']>({
      query: (intent) => ({
        url: endpoints.paymentMethods.paymentMethods,
        method: 'POST',
        body: { payment_method: intent },
      }),
      invalidatesTags: ['PaymentsMethods'],
    }),
    updateDefaultPaymentMethods: build.mutation<{ message: string }, string | number>({
      query: (id) => ({
        url: endpoints.paymentMethods.updateDefault(id),
        method: 'POST',
      }),
      invalidatesTags: ['PaymentsMethods'],
    }),
    deletePaymentMethod: build.mutation<{ message: string }, string | number>({
      query: (id) => ({
        url: endpoints.paymentMethods.deletePayment(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['PaymentsMethods'],
    }),
  }),
});

export default paymentMethodsAPI;
