/* eslint-disable no-nested-ternary */
import React, { FC, useCallback } from 'react';
import { Col, Layout, Row } from 'antd';
import cn from 'classnames';
import { Typography } from 'components/common/Typography';
import Button from 'components/common/Button';
import { Notification } from 'components/common/Notification';
import { userDetailsApi } from 'services';
import { ShowUserPayload } from 'models/userDetails';
import styles from '../MainLayout/MainLayout.module.scss';

const { Header } = Layout;
export interface Props {
  userData: ShowUserPayload;
}

export const HeaderSuperAdmin: FC<Props> = ({ userData }) => {
  const [revertActing] = userDetailsApi.useRevertActingMutation();

  const onRevertActing = useCallback(
    (value: string) => {
      revertActing(value)
        .unwrap()
        .then(() => Notification({ type: 'check', title: 'Success' }))
        .then(() => {
          const homeUrl = window.location.origin;
          window.location.assign(homeUrl);
        })
        .catch((err) =>
          Notification({ type: 'cross', title: 'Error', message: err.data?.message })
        );
    },
    [revertActing]
  );

  return (
    <Header className={cn(styles.superAdminHeader)}>
      <Row justify="space-between" align="middle" className={cn(styles.superAdminHeaderBox)}>
        <Col>
          <Typography variant="h6">
            Managing: <span>{userData?.email || ''}</span>
          </Typography>
        </Col>
        <Col>
          <Row justify="end" align="middle">
            <Typography variant="h6">
              Admin: <span>{userData?.is_acting_as.actor || ''}</span>
            </Typography>
            <Button onClick={onRevertActing} size="small" className={cn(styles.superAdminExitBtn)}>
              Exit
            </Button>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderSuperAdmin;
