import './ProjectEngineItem.scss';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { map, split } from 'lodash';
import { Row, Form, Col, FormInstance } from 'antd';
import { Input } from 'components/common/Input';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { Icon } from 'components/common/Icon';
import Select from 'components/common/Select';
import Switch from 'components/common/Switch';
import classNames from 'classnames';
import Button from 'components/common/Button';
import { SelectValue } from 'antd/lib/select';
import {
  ProjectEngineEngineAddDropdown,
  RefProps,
} from '../ProjectEngineEngineAddDropdown/ProjectEngineEngineAddDropdown';
import {
  accessData,
  accessFieldsData,
  cachingData,
  cachingFieldsData,
  featuresSelectData,
  generalData,
  generalFieldsData,
  headersData,
  headersFieldsData,
  locationData,
  locationFieldsData,
  logsData,
  logsFieldsData,
  matchesSelectData,
  originData,
  originFieldsData,
  requestData,
  requestFieldsData,
  specialtyData,
  specialtyFieldsData,
  urlData,
  urlFeatureData,
  urlFeatureFieldsData,
  urlFieldsData,
} from '../../data';

interface Props {
  field: FormListFieldData;
  onRemove?: (index: number | number[]) => void;
  form?: FormInstance;
  depth: number;
  isFeature?: boolean;
  isSelectFirstMatchChild?: boolean;
  onAddElseIfClick?: () => void;
  lastKey?: number;
}

export const ProjectEngineItem: FC<Props> = ({
  field,
  onRemove,
  depth,
  form,
  isFeature,
  isSelectFirstMatchChild,
  onAddElseIfClick,
  lastKey,
}) => {
  const [firstSelectValue, setFirstSelectValue] = useState<SelectValue>();
  const [secondSelectValue, setSecondSelectValue] = useState<SelectValue>();
  const [isFirstMatch, setIsFirstMatch] = useState(false);
  const matchDropdown = useRef<RefProps>(null);
  const featuresDropdown = useRef<RefProps>(null);

  const getSecondSelectValues = () => {
    if (isFeature) {
      switch (firstSelectValue) {
        case 'access':
          return accessData;
        case 'caching':
          return cachingData;
        case 'general':
          return generalData;
        case 'headers':
          return headersData;
        case 'logs':
          return logsData;
        case 'origin':
          return originData;
        case 'specialty':
          return specialtyData;
        case 'url':
          return urlFeatureData;
        default:
          return [];
      }
    } else {
      switch (firstSelectValue) {
        case 'location':
          return locationData;
        case 'request':
          return requestData;
        case 'url':
          return urlData;
        default:
          return [];
      }
    }
  };

  const getFieldsData = () => {
    if (isFeature) {
      switch (firstSelectValue) {
        case 'access':
          return accessFieldsData;
        case 'caching':
          return cachingFieldsData;
        case 'general':
          return generalFieldsData;
        case 'headers':
          return headersFieldsData;
        case 'logs':
          return logsFieldsData;
        case 'origin':
          return originFieldsData;
        case 'specialty':
          return specialtyFieldsData;
        case 'url':
          return urlFeatureFieldsData;
        default:
          return accessFieldsData;
      }
    } else {
      switch (firstSelectValue) {
        case 'location':
          return locationFieldsData;
        case 'request':
          return requestFieldsData;
        case 'url':
          return urlFieldsData;
        default:
          return locationFieldsData;
      }
    }
  };

  const isNotElseIfItem = !isFeature && !isSelectFirstMatchChild && !isFirstMatch;

  const onAddElseIfMatchClick = (key: number) => {
    const currentMatch = form?.getFieldValue('matches');
    const currentMatchItem = form?.getFieldValue('matches')?.[key];

    currentMatch.splice(key, 1, {
      ...currentMatchItem,
      matches: [...currentMatchItem.matches, null],
    });
    form?.setFieldsValue({
      ...form.getFieldsValue(),
      matches: [...(form.getFieldValue('matches') || [])],
    });
  };

  const getIfElseIfName = (key: number) => (key !== 0 && !isNotElseIfItem ? 'Else If' : 'If');

  const updateState = useCallback((name: 'first' | 'second', value?: string) => {
    if (name === 'first') {
      setFirstSelectValue(value);
    }
    if (name === 'second') {
      setSecondSelectValue(value);
    }
  }, []);

  useEffect(() => {
    if (
      form?.getFieldValue('matches')?.[0]?.type === 'select.first-match' &&
      field.name === 0 &&
      depth === 0
    )
      setIsFirstMatch(true);
  }, [form, field, depth]);

  useEffect(() => {
    if (form?.getFieldsValue().matches?.[0]?.matches?.[field.name]?.useless) {
      updateState('first', form?.getFieldsValue().matches?.[0]?.matches?.[field.name]?.useless);
    }
    if (!form?.getFieldsValue().matches?.[0]?.matches?.[field.name]?.useless) {
      const value = split(
        form?.getFieldsValue().matches?.[0]?.matches?.[field.name]?.type,
        '.',
        2
      )[1];
      updateState('first', value);
    }
    if (form?.getFieldsValue().matches?.[0]?.matches?.[field.name]?.type && !isFirstMatch) {
      updateState('second', form?.getFieldsValue().matches?.[0]?.matches?.[field.name]?.type);
    }
    if (isFirstMatch) {
      updateState('second');
    }
    if (isFeature) {
      const value = split(
        form?.getFieldsValue().matches?.[0]?.matches?.[field.name]?.features?.[field.key].type,
        '.',
        2
      )[1];
      updateState('first', value);
      updateState(
        'second',
        form?.getFieldsValue().matches?.[0]?.matches?.[field.name]?.features?.[field.key]?.type
      );
    }
  }, [field.key, field.name, form, isFeature, isFirstMatch, updateState]);

  return (
    <Form.Item style={{ width: '100%' }} shouldUpdate>
      {() => (
        <div
          className={classNames('ProjectEngineItem', {
            _elseif: !isFeature && isSelectFirstMatchChild,
            _bordertop: !isFeature && isSelectFirstMatchChild && field.key === 0,
          })}>
          <div className="ProjectEngineItem__item-wrapper">
            {!isSelectFirstMatchChild && (
              <div
                className={classNames('ProjectEngineItem__item-line', { _feature: isFeature })}
              />
            )}
            <div className="ProjectEngineItem__item-body">
              {!isFeature && (
                <div className="ProjectEngineItem__item-label">
                  {isFirstMatch ? 'Select first match' : getIfElseIfName(field.key)}
                </div>
              )}
              <Row align="bottom">
                <Col style={(isFirstMatch && { display: 'none' }) || {}}>
                  <Form.Item
                    name={[field.name, 'useless']}
                    rules={[{ required: true, message: 'Required' }]}>
                    <Select
                      label={isFeature ? 'Feature' : 'Match'}
                      placeholder="Category"
                      disabled
                      data={isFeature ? featuresSelectData : matchesSelectData}
                      width={225}
                      onChange={setFirstSelectValue}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginRight: 16, display: isFirstMatch ? 'none' : 'block' }}>
                  <Form.Item
                    name={[field.name, 'type']}
                    rules={[{ required: true, message: 'Required' }]}>
                    <Select
                      placeholder="Match"
                      data={getSecondSelectValues()}
                      width={isFeature ? 323 : 225}
                      onChange={setSecondSelectValue}
                      disabled
                      // disabled={!firstSelectValue}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Row align="middle" gutter={16}>
                    {secondSelectValue &&
                      map(
                        getFieldsData()[secondSelectValue as string],
                        ({ type, fieldName, selectData, regexp, tooltip, label, id }) => {
                          if (type === 'select') {
                            return (
                              <Col key={id}>
                                <Form.Item
                                  name={[field.name, fieldName]}
                                  rules={[{ required: true, message: 'Required' }]}>
                                  <Select
                                    placeholder="Select"
                                    data={selectData || []}
                                    width={124}
                                    label={label}
                                    disabled
                                  />
                                </Form.Item>
                              </Col>
                            );
                          }
                          if (type === 'input') {
                            return (
                              <Col key={id}>
                                <Form.Item
                                  name={[field.name, fieldName]}
                                  rules={[
                                    { required: true, message: 'Required' },
                                    { pattern: regexp, message: 'Check pattern' },
                                  ]}>
                                  <Input
                                    label={label}
                                    tooltip={`${tooltip} ${regexp}`}
                                    width={124}
                                    disabled
                                  />
                                </Form.Item>
                              </Col>
                            );
                          }
                          if (type === 'switch') {
                            return (
                              <Col key={id}>
                                <Form.Item shouldUpdate noStyle>
                                  {() => {
                                    return (
                                      <Form.Item
                                        name={[field.name, fieldName]}
                                        valuePropName="checked">
                                        <Switch
                                          disabled
                                          label={label}
                                          className="ProjectEngineItem__switch"
                                        />
                                      </Form.Item>
                                    );
                                  }}
                                </Form.Item>
                              </Col>
                            );
                          }
                          return null;
                        }
                      )}
                  </Row>
                </Col>
              </Row>
              <Form.List name={[field.name, 'matches']}>
                {(fields, { add, remove }) => (
                  <>
                    {map(fields, (f) => {
                      return (
                        <React.Fragment key={f.key}>
                          <ProjectEngineItem
                            lastKey={
                              isFirstMatch &&
                              form?.getFieldsValue()?.matches?.[field.key]?.matches?.length
                            }
                            onAddElseIfClick={() => onAddElseIfMatchClick(field.key)}
                            isSelectFirstMatchChild={isFirstMatch}
                            form={form}
                            depth={depth + 1}
                            field={f}
                            onRemove={remove}
                          />
                        </React.Fragment>
                      );
                    })}
                    <ProjectEngineEngineAddDropdown
                      disabled
                      ref={matchDropdown}
                      style={{ display: 'none' }}
                      onMatchClick={add}
                    />
                  </>
                )}
              </Form.List>

              <Form.List name={[field.name, 'features']}>
                {(fields, { add, remove }) => (
                  <>
                    {map(fields, (f) => (
                      <React.Fragment key={f.key}>
                        <ProjectEngineItem
                          isFeature
                          form={form}
                          depth={depth + 1}
                          field={f}
                          onRemove={remove}
                        />
                      </React.Fragment>
                    ))}
                    <ProjectEngineEngineAddDropdown
                      disabled
                      ref={featuresDropdown}
                      style={{ display: 'none' }}
                      onFeatureClick={add}
                    />
                  </>
                )}
              </Form.List>
              {isNotElseIfItem && (
                <ProjectEngineEngineAddDropdown
                  disabled
                  isSecondary
                  onMatchClick={() => matchDropdown?.current?.onMatchButtonClick()}
                  onFeatureClick={() => featuresDropdown?.current?.onFeatureButtonClick()}
                />
              )}
              {!isNotElseIfItem && depth !== 0 && !isFeature && (
                <ProjectEngineEngineAddDropdown
                  disabled
                  isSecondary
                  onMatchClick={() => matchDropdown?.current?.onMatchButtonClick()}
                  onFeatureClick={() => featuresDropdown?.current?.onFeatureButtonClick()}
                />
              )}
              {!isNotElseIfItem && field.name === (lastKey && lastKey - 1) && (
                <Button
                  type="subtle"
                  icon="plus"
                  iconPosition="left"
                  onClick={onAddElseIfClick}
                  disabled
                  className="ProjectEngineItem__item-subtle">
                  Add Else If
                </Button>
              )}
            </div>
            <div
              className="ProjectEngineItem__item-controls"
              style={!isFeature && isSelectFirstMatchChild ? { right: 20 } : {}}>
              <div className="ProjectEngineItem__item-row">
                <Icon name="check-in-circle" className="ProjectEngineItem__item-check" />
                <button
                  disabled
                  type="button"
                  className="ProjectEngineItem__item-delete"
                  onClick={() => onRemove?.(field.name)}>
                  <span className="ProjectEngineItem__trash">
                    <Icon className="ProjectEngineItem__trash-icon" name="remove" />
                  </span>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Form.Item>
  );
};
export default ProjectEngineItem;
