import React, { FC, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { Icon } from 'components/common/Icon';
import { Input } from 'components/common/Input';
// import { useParams } from 'react-router-dom';
// import { propertyAPI, userDetailsApiV2 } from 'services';
import { useAppSelector } from 'store';
import Radio from 'components/common/Radio';
import styles from './MainLayoutProjectDropdown.module.scss';

export const MainLayoutProjectDropdown: FC = () => {
  const [isProjectDropdownVisible, setIsProjectDropdownVisible] = useState(false);
  const { isMenuColapsed } = useAppSelector(({ ui }) => ui);
  // const { token } = useAppSelector(({ auth }) => auth);
  // const { directoryName } = useParams<{ directoryName?: string }>();

  // const propertyName = storageService.navigationList?.filter(
  //   (i) => i.directoryName === directoryName
  // )[0]?.name;

  // const navigator = useNavigate();
  // const dispatch = useAppDispatch();
  // const location = useLocation();
  // const { data: projectDetails } = propertyAPI.useFetchPropertyQuery(String(directoryName), {
  //   skip: !token,
  // });
  // const { data: lastViewed, refetch } = userDetailsApiV2.useFetchLastViewedQuery('', {
  //   skip: !token,
  // });
  // const { data: favorites, refetch: refetchFavorites } = userDetailsApiV2.useFetchFavoritesQuery(
  //   '',
  //   {
  //     skip: !token,
  //   }
  // );

  // const { data: properties } = propertyAPI.useFetchAllPropertiesQuery('', {
  //   skip: !token,
  // });
  // const currentPath = location.pathname.split(`${directoryName}`)[1];

  const triggerElement = useMemo(
    () => (
      <Row justify="space-between" align="middle" style={{ cursor: 'pointer' }} wrap={false}>
        <Col span={22} className={styles.col}>
          <span className={styles.projectName}>Main</span>
        </Col>
        <Col>
          <Icon
            name="expand-down"
            className={classNames(styles.arrow, {
              [styles.rotate]: isProjectDropdownVisible,
              [styles.collapsed]: isMenuColapsed,
            })}
          />
        </Col>
      </Row>
    ),
    [isMenuColapsed, isProjectDropdownVisible]
  );

  // useEffect(() => {
  //   if (projectDetails) {
  //     refetch();
  //     refetchFavorites();
  //   }
  // }, [projectDetails, refetch, refetchFavorites]);

  // const filteredFavoritesData = useMemo(
  //   () =>
  //     filter(favorites?.data, (item) =>
  //       some(Object.values(item), (el) =>
  //         String(el).toLowerCase().includes(searchBoxValue.toLowerCase())
  //       )
  //     ),
  //   [favorites?.data, searchBoxValue]
  // );

  // const filteredLastViewedData = useMemo(
  //   () =>
  //     filter(lastViewed?.data, (item) =>
  //       some(Object.values(item), (el) =>
  //         String(el).toLowerCase().includes(searchBoxValue.toLowerCase())
  //       )
  //     ),
  //   [lastViewed?.data, searchBoxValue]
  // );

  return (
    <>
      <div className={classNames(styles.dropdown, { [styles.collapsed]: isMenuColapsed })}>
        <Dropdown
          triggerElement={triggerElement}
          onVisibleChange={(v) => setIsProjectDropdownVisible(v)}>
          <div className={styles.projectDropdown}>
            <Col span={24}>
              <div style={{ color: 'blue', position: 'relative', left: 27 }}>Production</div>
              <Radio
                value="Production"
                onChange={() => {
                  // onChangeBP(Number(v));
                }}
                checked
                name="Production">
                <Input width={200} value="Main" />
              </Radio>
            </Col>

            <Col span={24} style={{ marginTop: 16 }}>
              <Radio
                value="Staging"
                onChange={() => {
                  // onChangeBP(Number(v));
                }}
                checked={false}
                name="Staging">
                <Input width={200} value="Staging" />
              </Radio>
            </Col>

            <Row align="middle">
              <Col span={24} style={{ marginLeft: 40, marginTop: 16 }}>
                <Button
                  type="subtle"
                  icon="plus"
                  // onClick={() => navigator(`${RouteTypes.create}`)}
                >
                  Create Environment
                </Button>
              </Col>
            </Row>
          </div>
        </Dropdown>
      </div>

      {/* /////////////////////////////////// */}

      {/* <div className={classNames(styles.dropdown, { [styles.collapsed]: isMenuColapsed })}>
        <Dropdown
          triggerElement={triggerElement}
          onVisibleChange={(v) => setIsProjectDropdownVisible(v)}>
          <div className={styles.projectDropdown}>
            <div style={{ marginBottom: 12 }}>
              <Input
                width={224}
                icon="search"
                placeholder="Search"
                onChange={(e: FormEvent<HTMLInputElement>) =>
                  setSearchBoxValue(e.currentTarget.value)
                }
              />
            </div>
            <h4 className={styles.projectDropdownTitle}>Favorites</h4>
            <Row gutter={[12, 12]}>
              {isEmpty(filteredFavoritesData) ? (
                <Col span={24}>
                  <Row justify="center">
                    {searchBoxValue ? (
                      <Typography>Favorite properties not found</Typography>
                    ) : (
                      <Typography>You don&apos;t have favorite properties yet</Typography>
                    )}
                  </Row>
                </Col>
              ) : (
                <>
                  {map(filteredFavoritesData, ({ id, name }) => (
                    <Col key={id} span={24}>
                      <Row
                        onClick={() => {
                          dispatch(updateSelectedCdn(''));
                          navigator(
                            generatePath(`${RouteTypes.project}${currentPath}`, {
                              directoryName: String(id) || '',
                            })
                          );
                        }}
                        justify="space-between"
                        align="middle"
                        className={classNames(styles.route, {
                          [styles.active]: id === directoryName,
                        })}>
                        <Row gutter={8} align="middle">
                          <Col>
                            <Icon name="star-full" className={styles.projectDropdownStar} />
                          </Col>
                          <Col>
                            <span className={styles.projectDropdownName}>{name}</span>
                          </Col>
                        </Row>
                        <Icon name="check" className={styles.check} />
                      </Row>
                    </Col>
                  ))}
                </>
              )}
            </Row>
            <Divider style={{ margin: '18px 0 12px' }} />
            <h4 className={styles.projectDropdownTitle}>Last viewed</h4>
            <Row gutter={[12, 12]}>
              {isEmpty(filteredLastViewedData) ? (
                <Col span={24}>
                  <Row justify="center">
                    <Typography>No data</Typography>
                  </Row>
                </Col>
              ) : (
                <>
                  {map(slice(filteredLastViewedData, 0, 4), ({ id, is_favorite, name }) => (
                    <Col key={id} span={24}>
                      <Row
                        onClick={() => {
                          dispatch(updateSelectedCdn(''));
                          navigator(
                            generatePath(`${RouteTypes.project}${currentPath}`, {
                              directoryName: String(id) || '',
                            })
                          );
                        }}
                        justify="space-between"
                        align="middle"
                        className={classNames(styles.route, {
                          [styles.active]: id === directoryName,
                        })}>
                        <Row gutter={8} align="middle">
                          <Col>
                            <Icon
                              name={!is_favorite ? 'star-empty' : 'star-full'}
                              className={classNames(styles.projectDropdownStarEmpty, {
                                [styles.favorite]: is_favorite,
                              })}
                            />
                          </Col>
                          <Col>
                            <span className={styles.projectDropdownName}>{name}</span>
                          </Col>
                        </Row>
                        <Icon name="check" className={styles.check} />
                      </Row>
                    </Col>
                  ))}
                </>
              )}
            </Row>
            <Divider style={{ margin: '18px 0 12px' }} />
            <Row justify="space-between" align="middle">
              <NavLink to={RouteTypes.home}>
                <Button type="subtle">
                  <span>View all ({size(properties?.data?.properties)})</span>
                </Button>
              </NavLink>
              <Button type="subtle" icon="plus" onClick={() => navigator(`${RouteTypes.create}`)}>
                New Property
              </Button>
            </Row>
          </div>
        </Dropdown>
      </div> */}
    </>
  );
};

export default MainLayoutProjectDropdown;
