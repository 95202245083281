import { Col, Row } from 'antd';
import Button from 'components/common/Button';
import { Input } from 'components/common/Input';
import { isEqual } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import styles from './ProjectRoutingTableDeleteZoneModal.module.scss';

interface IProjectRoutingTableDeleteZoneModalProps {
  onFinish: () => void;
  onCancel: () => void;
  loading?: boolean;
  domain: string;
  isSuccess?: boolean;
}

export const ProjectRoutingTableDeleteZoneModal: FC<IProjectRoutingTableDeleteZoneModalProps> = ({
  onFinish,
  domain,
  onCancel,
  loading,
  isSuccess,
}) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    if (isSuccess) setValue('');
  }, [isSuccess]);
  return (
    <div>
      <Row gutter={[20, 20]} style={{ paddingBottom: 24 }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <span className={styles.title}>
            Are you sure want to delete the current zone?{' '}
            <span>The deletion of zone takes place immediately and cannot be undone</span>
          </span>
          <span className={styles.text}>
            To confirm the zone deletion, please type <span>{domain}</span> in the input field below
            and click the Delete Zone button
          </span>
        </Col>
        <Col span={24}>
          <Input
            label="Domain Name"
            value={value}
            onChange={(v: React.FormEvent<HTMLInputElement>) => setValue(v.currentTarget.value)}
          />
        </Col>
      </Row>
      <div className={styles.footer}>
        <Button className={styles.footerBtn} type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!isEqual(domain, value)}
          submit
          type="danger"
          loading={loading}
          onClick={onFinish}>
          Delete Zone
        </Button>
      </div>
    </div>
  );
};

export default ProjectRoutingTableDeleteZoneModal;
