import './HomeTable.scss';
import React, { FC } from 'react';
import { IProperty } from '../../../../models';
import { HomeTableTable } from './HomeTableTable';

interface Props {
  data?: IProperty[];
  isLoading?: boolean;
  isGridView?: boolean;
  isSortedByStatus?: boolean;
  onCreateServiceClick: () => void;
  updateList: () => void;
}

export const HomeTable: FC<Props> = ({
  data,
  isLoading,
  isGridView,
  onCreateServiceClick,
  isSortedByStatus,
  updateList,
}) => {
  return (
    <HomeTableTable
      updateList={updateList}
      data={data}
      isLoading={isLoading}
      isGridView={isGridView}
      isSortedByStatus={isSortedByStatus}
      onCreateServiceClick={onCreateServiceClick}
    />
  );
};

export default HomeTable;
