export const enableWordPressCache = {
  matches: [
    {
      type: 'select.first-match',
      useless: 'first-match',
      matches: [
        {
          type: 'match.url.url-path-extension.wildcard',
          useless: 'url',
          value: [
            'css',
            'js',
            'gif',
            'jpg',
            'jpeg',
            'bmp',
            'png',
            'ico',
            'img',
            'tga',
            'webp',
            'wmf',
            'svg',
          ],
          result: 'match',
          ordinal: 1,
          features: [
            {
              useless: 'caching',
              type: 'feature.caching.bypass-cache',
              enabled: false,
              ordinal: 1,
            },
          ],
          ignore_case: false,
        },
        {
          useless: 'request',
          type: 'match.request.request-header.regex',
          ordinal: 2,
          name: 'cookie',
          result: 'match',
          value:
            '((.*(wordpress_(?!test_)[a-zA-Z0-9_]+|wp-postpass|comment_author_[a-zA-Z0-9_]+|woocommerce_cart_hash|woocommerce_items_in_cart|wp_woocommerce_session_[a-zA-Z0-9]+|wordpress_logged_in_|comment_author)).*)',
          ignore_case: false,
          features: [
            {
              useless: 'caching',
              type: 'feature.caching.bypass-cache',
              ordinal: 1,
              enabled: true,
            },
          ],
        },
        {
          useless: 'url',
          type: 'match.url.url-path.regex',
          ordinal: 3,
          result: 'match',
          value:
            '((.*(wp-admin.*|wp-login.php|cart|login|wc-api.*|checkout|addons|logout|lost-password|product/.*|wordpress_logged_in_|comment_author|my-account/.*)).*)',
          ignore_case: false,
          encoded: false,
          features: [
            {
              useless: 'caching',
              type: 'feature.caching.bypass-cache',
              ordinal: 1,
              enabled: true,
            },
          ],
        },
        {
          useless: 'request',
          type: 'match.request.request-method.literal',
          ordinal: 4,
          value: 'GET',
          features: [
            {
              useless: 'caching',
              type: 'feature.caching.bypass-cache',
              ordinal: 1,
              enabled: false,
            },
          ],
        },
      ],
      ordinal: 2,
    },
  ],
};

export const enableWordPressCacheShort = {
  matches: [
    {
      type: 'select.first-match',
      useless: 'first-match',
      ordinal: 2,
      matches: [
        {
          type: 'match.url.url-path-extension.wildcard',
          ordinal: 1,
          useless: 'url',
          value: [
            'css',
            'js',
            'gif',
            'jpg',
            'jpeg',
            'bmp',
            'png',
            'ico',
            'img',
            'tga',
            'webp',
            'wmf',
            'svg',
          ],
          result: 'match',
          features: [
            {
              useless: 'caching',
              type: 'feature.caching.bypass-cache',
              enabled: false,
              ordinal: 1,
            },
          ],
          ignore_case: false,
        },
      ],
    },
  ],
};

export default enableWordPressCache;
