/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react';
import { IAccessControlPayload, IAccessControResponse } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './baseV2';

export const accessControlAPI = createApi({
  reducerPath: 'accessControlAPI',
  baseQuery: baseQuery(),
  tagTypes: ['AccessControlList', 'UpdateControlList'],
  endpoints: (build) => ({
    getControlList: build.query<IAccessControResponse, string>({
      query: (propertyId: string) => ({
        url: endpoints.v2.accessControl.getControlList(propertyId),
      }),
      providesTags: ['AccessControlList'],
    }),

    updateControlList: build.mutation<
      IAccessControResponse,
      {
        propertyId: string;
        payload: IAccessControlPayload;
      }
    >({
      query: ({ propertyId, payload }) => ({
        url: endpoints.v2.accessControl.updateControlList(propertyId),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['UpdateControlList'],
    }),
  }),
});

export default accessControlAPI;
