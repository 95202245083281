/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Col, Collapse, Row, Tooltip } from 'antd';
import { Host, Origin } from 'models';
import { SelectValue } from 'antd/lib/select';
import { isArray } from 'lodash';
import cn from 'classnames';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import { Input } from 'components/common/Input';
import Button from 'components/common/Button';
import { Icon } from 'components/common/Icon';
import Select from 'components/common/Select';
import { urlRegexValidation } from 'helpers/utils';
import OriginHostnameRow from './OriginHostnameRow';
import Errormessage from './Errormessage';
import Shields from './Shields';
import OriginTlsSettings from './OriginTlsSettings';
import './HomeAddModalNew.scss';

const { Panel } = Collapse;
const defaultHost = {
  scheme: 'match',
  location: [
    {
      hostname: '',
    },
  ],
};

const OriginsRow = ({
  vals,
  id,
  onHostChange,
  onChangeName,
  isRequierdField,
  isOrigin,
  notUsedNames,
  allUsedNames,
}: {
  vals: Origin;
  id: number;
  onHostChange: (hs: Origin | undefined, id: number) => void;
  onChangeName: (v: string) => void;
  isRequierdField: boolean;
  isOrigin?: boolean;
  notUsedNames?: string[];
  allUsedNames?: string[];
}) => {
  const urlRegex = useMemo(() => urlRegexValidation, []);
  const [isValidOverrideHostHeader, setIsValidOverrideHostHeader] = useState(true);
  const [useSNI, setUseSNI] = useState(false);
  const [allowCerts, setAllowCerts] = useState(false);
  const { name, hosts, override_host_header, balancer, tls_verify, shields } = vals;

  const [hostsVal, setHostsVal] = useState<Host[]>([defaultHost]);
  const [nameOrigin, setNameOrigin] = useState(`web-${id}`);
  const [isNotUnique, setIsNotUnique] = useState(false);
  const [hostHeaderOrigin, setHostHeaderOrigin] = useState<string>();
  const [sniValue, setSniValue] = useState('');
  const [balancerVal, setBalancerVal] = useState<string>(balancer);

  useEffect(() => {
    setNameOrigin(name);
    setHostsVal(hosts);
    if (override_host_header) {
      setHostHeaderOrigin(override_host_header);
    }
    setUseSNI(tls_verify.use_sni);
    if (tls_verify.sni_hint_and_strict_san_check) {
      setSniValue(tls_verify.sni_hint_and_strict_san_check);
    }
    if (tls_verify.allow_self_signed_certs) {
      setAllowCerts(tls_verify.allow_self_signed_certs);
    }
  }, [
    hosts,
    name,
    override_host_header,
    tls_verify.allow_self_signed_certs,
    tls_verify.sni_hint_and_strict_san_check,
    tls_verify.use_sni,
  ]);

  const notUsedOriginName = useMemo(() => {
    if (nameOrigin !== '') {
      return notUsedNames?.includes(name);
    }
    return true;
  }, [name, nameOrigin, notUsedNames]);

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const newOrigin = {
      name: nameOrigin,
      hosts: arrayMoveImmutable(hostsVal, oldIndex, newIndex),
      shields,
      balancer: balancerVal,
      tls_verify: {
        use_sni: useSNI,
        allow_self_signed_certs: allowCerts,
        sni_hint_and_strict_san_check: sniValue || undefined,
      },
      override_host_header: hostHeaderOrigin,
    };
    onHostChange(newOrigin, id);
  };

  useEffect(() => {
    if (isArray(allUsedNames) && allUsedNames?.length > 0) {
      const uniqueNames = allUsedNames.filter((i) => i !== name);
      setIsNotUnique(
        Boolean(...uniqueNames.map((i) => i === nameOrigin).filter((s) => Boolean(s)))
      );
    }
  }, [allUsedNames, id, name, nameOrigin]);

  return (
    <Collapse collapsible="header" defaultActiveKey={[String(id)]} key={id}>
      <Panel
        key={id}
        header={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div>Origin: {nameOrigin}</div>
            {notUsedOriginName && (
              <div>
                <Tooltip placement="bottom" title="Remove this hostname">
                  <Icon
                    className="ProjectOriginCdnCnames__removeIcon"
                    name="remove2"
                    onClick={() => {
                      onHostChange(undefined, id);
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        }>
        <div className="ProjectOriginOriginRow">
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Row gutter={[20, 20]} justify="space-between">
                <Col span={12}>
                  <Input
                    isRequired
                    disabled={isOrigin && !notUsedOriginName}
                    label="Name"
                    value={nameOrigin}
                    onChange={(e: FormEvent<HTMLInputElement>) => {
                      setNameOrigin(e.currentTarget.value);

                      if (!isNotUnique) {
                        onChangeName(e.currentTarget.value);
                      }
                    }}
                    onBlur={() => {
                      if (isNotUnique) {
                        onChangeName(name);
                      } else {
                        onChangeName(nameOrigin);

                        const newOrigin = {
                          name: nameOrigin,
                          hosts: hostsVal,
                          shields,
                          balancer: balancerVal,
                          tls_verify: {
                            use_sni: useSNI,
                            allow_self_signed_certs: allowCerts,
                            sni_hint_and_strict_san_check: sniValue || undefined,
                          },
                          override_host_header: hostHeaderOrigin,
                        };
                        onHostChange(newOrigin, id);
                      }
                    }}
                    className={cn('OriginNameInput', {
                      _requiredField: isRequierdField && !nameOrigin,
                      _invalidURL: isNotUnique,
                    })}
                  />
                  {isNotUnique && <Errormessage errormessage="This name is not unique" />}
                </Col>
                <Col span={12}>
                  <Input
                    // isRequired
                    label="Override Host Header"
                    className={cn('OriginNameInput', {
                      //   _requiredField: isRequierdField && !testhostname,
                      _invalidURL: !isValidOverrideHostHeader,
                    })}
                    value={hostHeaderOrigin}
                    onChange={(e: FormEvent<HTMLInputElement>) => {
                      const overrideHHvalue = hostHeaderOrigin ? e.currentTarget.value : undefined;
                      setHostHeaderOrigin(overrideHHvalue);
                      const newOrigin = {
                        name: nameOrigin,
                        hosts: hostsVal,
                        shields,
                        balancer: balancerVal,
                        tls_verify: {
                          use_sni: useSNI,
                          allow_self_signed_certs: allowCerts,
                          sni_hint_and_strict_san_check: sniValue || undefined,
                        },
                        override_host_header: e.currentTarget.value || undefined,
                      };
                      onHostChange(newOrigin, id);
                    }}
                    onBlur={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      hostHeaderOrigin
                        ? setIsValidOverrideHostHeader(urlRegex.test(hostHeaderOrigin))
                        : setIsValidOverrideHostHeader(true);
                    }}
                  />
                  {!isValidOverrideHostHeader && (
                    <Errormessage errormessage="The value you entered is not a valid." />
                  )}
                </Col>
              </Row>
              <Col span={24} className="originRowHostnames">
                <SortableList
                  allowDrag={hostsVal.length > 1}
                  lockAxis="y"
                  onSortEnd={onSortEnd}
                  className="list"
                  draggedItemClassName="dragged">
                  {hostsVal &&
                    [...hostsVal].map((host, idx) => (
                      <div className="originRowHostnamesRow" key={host.location[0].hostname + idx}>
                        {idx === 0 && (
                          <Row
                            gutter={[20, 20]}
                            justify="space-between"
                            className="OriginHostsLable">
                            <Col span={12}>
                              <div className="OriginHostsLableOriginHostname">
                                <div>
                                  Origin Hostname{' '}
                                  <span className="OriginHostsLableOriginHostname-required">
                                    Required
                                  </span>
                                </div>
                                {hostsVal.length > 1 && idx === 0 && (
                                  <div>The first host is the primary</div>
                                )}
                              </div>
                            </Col>
                            <Col span={4}>Scheme</Col>
                            <Col span={3}>Port</Col>
                            <Col span={4}>IP Version Preference</Col>
                            <Col span={1}>{`${''}`}</Col>
                          </Row>
                        )}

                        <SortableItem key={host.location[0].hostname + idx}>
                          <div className={hostsVal.length > 1 ? 'item' : undefined}>
                            <OriginHostnameRow
                              numberOfHosts={hostsVal.length || 0}
                              key={host.location[0].hostname + idx}
                              vals={host}
                              id={idx}
                              isRequierdField={isRequierdField}
                              onHostChange={(val, ind) => {
                                if (val === undefined) {
                                  setHostsVal((prevState) => {
                                    const a = [...prevState];
                                    a.splice(ind, 1);

                                    const newOrigin = {
                                      name: nameOrigin,
                                      hosts: a,
                                      shields,
                                      balancer: balancerVal,
                                      tls_verify: {
                                        use_sni: useSNI,
                                        allow_self_signed_certs: allowCerts,
                                        sni_hint_and_strict_san_check: sniValue || undefined,
                                      },
                                      override_host_header: hostHeaderOrigin,
                                    };
                                    onHostChange(newOrigin, id);

                                    return a;
                                  });
                                } else {
                                  setHostsVal((prevState) => {
                                    const a = [...prevState];
                                    a[ind] = val;

                                    const newOrigin = {
                                      name: nameOrigin,
                                      hosts: a,
                                      shields,
                                      balancer: balancerVal,
                                      tls_verify: {
                                        use_sni: useSNI,
                                        allow_self_signed_certs: allowCerts,
                                        sni_hint_and_strict_san_check: sniValue || undefined,
                                      },
                                      override_host_header: hostHeaderOrigin,
                                    };
                                    onHostChange(newOrigin, id);

                                    return a;
                                  });
                                }
                              }}
                            />
                          </div>
                        </SortableItem>
                        {/* </Col> */}
                      </div>
                    ))}
                </SortableList>
              </Col>

              <Row className="createPropertyMarginTop10" gutter={[20, 20]} justify="space-between">
                <Col>
                  <Button
                    icon="plus"
                    type="secondary"
                    onClick={() => {
                      setHostsVal((prev) => [...(prev ?? []), defaultHost]);
                    }}>
                    Add Host
                  </Button>
                </Col>
              </Row>
            </Col>

            {isOrigin && (
              <Col span={12}>
                <Select
                  label="Balancer type"
                  value={balancerVal}
                  data={[
                    { value: 'primary_failover', name: 'Primary / Failover' },
                    {
                      value: 'round_robin',
                      name: 'Round Robin',
                    },
                  ]}
                  width="100%"
                  onChange={(value: SelectValue) => {
                    setBalancerVal(value as string);
                    const newOrigin = {
                      name: nameOrigin,
                      hosts: hostsVal,
                      shields,
                      balancer: value as string,
                      tls_verify: {
                        use_sni: useSNI,
                        allow_self_signed_certs: allowCerts,
                        sni_hint_and_strict_san_check: sniValue || undefined,
                      },
                      override_host_header: hostHeaderOrigin,
                    };
                    onHostChange(newOrigin, id);
                  }}
                />
              </Col>
            )}

            <Col span={24}>
              <OriginTlsSettings
                tlsData={tls_verify}
                hostHeader={override_host_header}
                onChangeTls={(tlsValues) => {
                  const newOrigin = {
                    name: nameOrigin,
                    hosts: hostsVal,
                    shields,
                    balancer: balancerVal,
                    tls_verify: tlsValues,
                    override_host_header: hostHeaderOrigin,
                  };
                  onHostChange(newOrigin, id);
                }}
              />
            </Col>

            <Col span={24}>
              <Shields
                shields={shields}
                onChangeShields={(shieldsData) => {
                  const newOrigin = {
                    name: nameOrigin,
                    hosts: hostsVal,
                    shields: shieldsData,
                    balancer: balancerVal,
                    tls_verify: {
                      use_sni: useSNI,
                      allow_self_signed_certs: allowCerts,
                      sni_hint_and_strict_san_check: sniValue || undefined,
                    },
                    override_host_header: hostHeaderOrigin,
                  };
                  onHostChange(newOrigin, id);
                }}
              />
            </Col>
          </Row>
        </div>
      </Panel>
    </Collapse>
  );
};

export default OriginsRow;
