import './ProjectChartsBubble.scss';
import React, { FC } from 'react';
import cn from 'classnames';

interface Props {
  level: number;
}

export const ProjectChartsBubble: FC<Props> = ({ level }) => {
  return (
    <span className={cn('ProjectChartsBubble', `level-${level}`)}>
      {level < 10 ? level : `${level} BW/s`}
    </span>
  );
};

export default ProjectChartsBubble;
