import React, { FC, useMemo, useState } from 'react';
import Button from 'components/common/Button';
import { BlockWrapper } from 'components/common/BlockWrapper';
import { Col, Row } from 'antd';
import Checkbox from 'components/common/Checkbox';
import { Input } from 'components/common/Input';
import Select from 'components/common/Select';
import { ProjectOriginAlerts } from 'components/views/Project/ProjectOrigin';

const METRIC_DATA = [{ name: 'first', value: 'first' }];
const OPERATOR_DATA = [
  { name: '>', value: '>' },
  { name: '<', value: '<' },
  { name: '=', value: '=' },
  { name: '>=', value: '>=' },
  { name: '<=', value: '<=' },
  { name: '!=', value: '!=' },
];
const CONDITION_DATA = [
  { name: 'Condition Start', value: 'start' },
  { name: 'Condition End', value: 'end' },
  { name: 'Continuous', value: 'continuous' },
  { name: 'Condition Start end End', value: 'all' },
];

export const ProjectOriginAlertsView: FC = () => {
  const [addAlertMode, setAddAlertMode] = useState(false);

  const onCancel = () => {
    setAddAlertMode(false);
  };
  const onAddAlert = () => {
    setAddAlertMode(true);
  };

  const renderAddAlertContent = useMemo(
    () => (
      <Col span={24}>
        <BlockWrapper isSmall minHeight="initial">
          <div className="ProjectOrigin__section">
            <Checkbox type="big-rounded" className="ProjectOrigin__checkbox">
              Alert Enabled
            </Checkbox>
            <Input label="Name" width={400} />
          </div>
          <div className="ProjectOrigin__section">
            <h4 className="ProjectOrigin__section-title">Expression</h4>
            <Row gutter={16}>
              <Col>
                <Select data={METRIC_DATA} label="Metric" width={400} />
              </Col>
              <Col>
                <Select data={OPERATOR_DATA} label="Operator" width={80} />
              </Col>
              <Col>
                <Input label="Trigger Value" width={80} />
              </Col>
            </Row>
          </div>
          <div className="ProjectOrigin__section _last">
            <h4 className="ProjectOrigin__section-title">Interval</h4>
            <Row gutter={10} align="middle" style={{ marginBottom: 20 }}>
              <Col>
                <span className="ProjectOrigin__label">Every</span>
              </Col>
              <Col>
                <Input width={40} />
              </Col>
              <Col>
                <span className="ProjectOrigin__label">minutes</span>
              </Col>
            </Row>
            <Select data={CONDITION_DATA} label="Notify on:" width={400} />
          </div>
        </BlockWrapper>
      </Col>
    ),
    []
  );
  return (
    <>
      {addAlertMode && (
        <Row justify="space-between" align="middle" style={{ marginBottom: 12 }}>
          <Col>
            <Row gutter={16} align="middle">
              <Col>
                <Button type="secondary" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button>Add</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row gutter={[20, 20]}>
        {addAlertMode ? (
          renderAddAlertContent
        ) : (
          <Col span={24}>
            <BlockWrapper minHeight="initial" isSmall>
              <ProjectOriginAlerts onAddAlert={onAddAlert} />
            </BlockWrapper>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ProjectOriginAlertsView;
