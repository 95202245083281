import { Col, Form, FormInstance, Row } from 'antd';
import Button from 'components/common/Button';
import { Input } from 'components/common/Input';
import React, { FC } from 'react';
import { ICreateForm } from '../ProjectRoutingTable';
import styles from './ProjectRoutingTableCreateZoneModal.module.scss';

interface IProjectRoutingTableCreateZoneModalProps {
  onFinish: (values: ICreateForm) => void;
  form: FormInstance<ICreateForm>;
  onCancel: () => void;
  loading?: boolean;
}

export const ProjectRoutingTableCreateZoneModal: FC<IProjectRoutingTableCreateZoneModalProps> = ({
  onFinish,
  form,
  onCancel,
  loading,
}) => {
  return (
    <Form form={form} onFinish={onFinish}>
      <Row gutter={[20, 20]} style={{ paddingBottom: 24 }}>
        <Col span={24}>
          <Form.Item
            noStyle
            name="DomainName"
            rules={[{ required: true, message: 'Please enter domain name' }]}>
            <Input label="Domain Name" />
          </Form.Item>
        </Col>
      </Row>
      <div className={styles.footer}>
        <Button className={styles.footerBtn} type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Form.Item shouldUpdate noStyle>
          {() => (
            <Button
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length).length
              }
              submit
              loading={loading}>
              Create
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};

export default ProjectRoutingTableCreateZoneModal;
