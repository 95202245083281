import { createApi } from '@reduxjs/toolkit/query/react';
import { IFailoverGroup } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const dnsAPI = createApi({
  reducerPath: 'dnsAPI',
  baseQuery: baseQuery(),
  tagTypes: ['DNS'],
  endpoints: (build) => ({
    fetchRouting: build.query<IFailoverGroup, { envId: string }>({
      query: ({ envId }) => ({
        url: endpoints.v2.dns.dns(envId),
      }),
      providesTags: ['DNS'],
    }),
    createZone: build.mutation<IFailoverGroup, { envId: string; DomainName: string }>({
      query: ({ envId, DomainName }) => ({
        url: endpoints.v2.dns.dns(envId),
        method: 'POST',
        params: {
          DomainName,
        },
      }),
      invalidatesTags: () => ['DNS'],
    }),
    updateRouting: build.mutation<
      IFailoverGroup,
      { envId: string; dnsId: number; body: IFailoverGroup }
    >({
      query: ({ envId, dnsId, body }) => ({
        url: `${endpoints.v2.dns.dns(envId)}/${dnsId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['DNS'],
    }),
    deleteRouting: build.mutation<IFailoverGroup, { envId: string; dnsId: number }>({
      query: ({ envId, dnsId }) => ({
        url: `${endpoints.v2.dns.dns(envId)}/${dnsId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['DNS'],
    }),
  }),
});

export default dnsAPI;
