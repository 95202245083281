import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Hostname, IPropertySSLData, Origin } from '../../models/service';

export interface IPropertyState {
  origins: Origin[];
  hostnames: Hostname[];
  tls?: IPropertySSLData[];
  rules: [];
  dns_domain_name: string;
  name: string;
  slug: string;
  status: string;
  created_at: string;
  environment_id: string;
  isDeployBtnDisabled?: boolean;
}

const initialState: IPropertyState = {
  origins: [
    {
      name: '',
      hosts: [
        {
          scheme: 'match',
          location: [
            {
              hostname: '',
            },
          ],
        },
      ],
      balancer: 'primary_failover',
      tls_verify: {
        use_sni: false,
        allow_self_signed_certs: false,
      },
      shields: {
        apac: 'bypass',
        emea: 'bypass',
        us_east: 'bypass',
        us_west: 'bypass',
      },
    },
  ],
  hostnames: [
    {
      hostname: '',
      default_origin_name: '',
    },
  ],
  isDeployBtnDisabled: false,
  tls: [
    {
      createdAt: '',
      name: '',
      validDns: false,
      validTlsCaa: true,
      validTlsCname: false,
      validTls: false,
    },
  ],
  status: '',
  created_at: '',
  environment_id: '',
  dns_domain_name: '',
  name: '',
  rules: [],
  slug: '',
};

export const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    updatePropertyDetails: (state, action: PayloadAction<IPropertyState>) => {
      state.origins = action.payload.origins;
      state.hostnames = action.payload.hostnames;
      state.tls = action.payload.tls;
      state.rules = action.payload.rules;
      state.dns_domain_name = action.payload.dns_domain_name;
      state.name = action.payload.name;
      state.slug = action.payload.slug;
      state.status = action.payload.status;
      state.created_at = action.payload.created_at;
      state.environment_id = action.payload.environment_id;
    },
    updateOriginsDetails: (state, action: PayloadAction<Origin[]>) => {
      state.origins = action.payload;
    },
    updateRulesDetails: (state, action: PayloadAction<[]>) => {
      state.rules = action.payload;
    },
    updateHostnamesDetails: (state, action: PayloadAction<Hostname[]>) => {
      state.hostnames = action.payload;
    },
    updateTLSDetails: (state, action: PayloadAction<IPropertySSLData[]>) => {
      state.tls = action.payload;
    },
    updateStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    updateStatusOfDeployBtn: (state, action: PayloadAction<boolean>) => {
      state.isDeployBtnDisabled = action.payload;
    },
  },
});

export const {
  updatePropertyDetails,
  updateOriginsDetails,
  updateRulesDetails,
  updateHostnamesDetails,
  updateTLSDetails,
  updateStatus,
  updateStatusOfDeployBtn,
} = propertySlice.actions;

export default propertySlice.reducer;
