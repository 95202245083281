import React, { ChangeEventHandler, FC, HTMLAttributes, useMemo } from 'react';
import classNames from 'classnames';
import { includes, isBoolean } from 'lodash';
import { Icon } from '../Icon';
import './Checkbox.scss';

export const checkboxTestId = 'checkbox';
export const checkboxInputTestId = 'checkbox-input';

type ValueType = string | number;

export type CheckboxesColors =
  | 'standart'
  | 'g2'
  | 'g3'
  | 'purple'
  | 'salad'
  | 'teal'
  | 'y1'
  | 'y3'
  | 'ra'
  | 'oa'
  | 'b3'
  | 'pink'
  | 'light-pink'
  | 'mustard';

export interface Props {
  value?: ValueType;
  name?: string;
  checked?: boolean | ValueType[];
  className?: string;
  children?: string;
  disabled?: boolean;
  style?: HTMLAttributes<HTMLLabelElement>['style'];
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isAntd?: boolean;
  type?: 'small' | 'big' | 'big-rounded';
  color?: CheckboxesColors;
}

export const Checkbox: FC<Props> = ({
  name,
  checked,
  value,
  className,
  children,
  disabled,
  style,
  onChange,
  isAntd,
  type = 'small',
  color = 'standart',
}) => {
  const computedChecked = useMemo(() => {
    if (!isBoolean(checked)) {
      return includes(checked, value);
    }
    return checked;
  }, [checked, value]);

  // const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (onChange) onChange(e.target.checked);
  // };

  const inputProps = {
    'data-testid': checkboxInputTestId,
    type: 'checkbox',
    value,
    checked: isAntd ? undefined : computedChecked,
    disabled,
    onChange,
    className: 'Checkbox__input',
    name,
  };

  return (
    <label
      data-testid={checkboxTestId}
      style={style}
      className={classNames('Checkbox', { _disabled: disabled }, `_${type}`, className)}>
      <input {...inputProps} />
      <span className={classNames('Checkbox__area _red', `_${color}`)}>
        <Icon name="check" className="Checkbox__check" />
      </span>
      {children && <span className="Checkbox__text">{children}</span>}
    </label>
  );
};

export default Checkbox;
