import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import moment from 'moment';
import { ActivePeriodType, HistoricalFilterType } from 'store/slices/project';

let getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>;

export const sec = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  // eslint-disable-next-line no-return-assign
  setAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
  ) => (getAccessTokenSilently = func),
};

export const getTimePeriodSelectedValue = (period: ActivePeriodType) => {
  const dateFormat = 'DD/MM/YYYY';
  const startOfTheDay = moment().startOf('day');
  switch (period) {
    case 'yesterday':
      return [
        startOfTheDay.subtract(1, 'days').format(dateFormat),
        startOfTheDay.format(dateFormat),
      ];
    case 'thisWeek':
      return [moment().startOf('W').format(dateFormat), startOfTheDay.format(dateFormat)];
    case 'lastWeek':
      return [
        moment().startOf('W').subtract(1, 'weeks').format(dateFormat),
        moment().startOf('W').format(dateFormat),
      ];
    case 'thisMonth':
      return [moment().startOf('M').format(dateFormat), moment().format(dateFormat)];
    case 'lastMonth':
      return [
        moment().startOf('M').subtract(1, 'M').format(dateFormat),
        moment().startOf('M').format(dateFormat),
      ];
    case 'lastThreeMonths':
      return [
        moment().startOf('M').subtract(3, 'M').format(dateFormat),
        moment().startOf('M').format(dateFormat),
      ];
    case 'lastSixMonths':
      return [
        moment().startOf('M').subtract(6, 'M').format(dateFormat),
        moment().startOf('M').format(dateFormat),
      ];
    default:
      return 'today';
  }
};

export const getHistoricalFrequency = (idx: HistoricalFilterType) => {
  switch (idx) {
    case 1:
      return 15;
    case 2:
      return 60;
    case 3:
      return 1440;

    default:
      return 5;
  }
};

export default sec;
