import { createApi } from '@reduxjs/toolkit/query/react';
import { map } from 'lodash';
import { InvoicesResponse } from 'models';
import moment from 'moment';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export const invoicesAPI = createApi({
  reducerPath: 'invoicesAPI',
  baseQuery: baseQuery(),
  tagTypes: ['Auth'],
  endpoints: (build) => ({
    getInvoices: build.query<InvoicesResponse, { start_date: string; end_date: string }>({
      query: (params) => ({
        url: endpoints.invoices.get,
        params,
      }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //   @ts-ignore <type problems>
      transformResponse: (response: InvoicesResponse) => {
        return {
          ...response,
          data: map(response.data, (item) => ({
            ...item,
            created: moment.unix(item.created).format('MMM, YYYY'),
          })),
        };
      },
    }),
  }),
});

export default invoicesAPI;
