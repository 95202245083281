import './ProjectChartsControls.scss';
import React, { FC } from 'react';
import cn from 'classnames';
import { Typography } from 'components/common/Typography';
import { SectorButton, ButtonType } from 'components/common/SectorButton';
import Button from 'components/common/Button';
import { Loader } from 'components/common/Loader';
import { Col, Row } from 'antd';

interface Props {
  className?: string;
  title: string;
  onClick: (idx: number) => void;
  checked?: number;
  isRealtime?: boolean;
  disabled?: number[];
  isZoomAvailable?: boolean;
  onZoomClick?: () => void;
  isLoading?: boolean;
}

export const ProjectChartsControls: FC<Props> = ({
  title,
  className,
  onClick,
  checked,
  isRealtime,
  disabled,
  isZoomAvailable,
  onZoomClick,
  isLoading,
}) => {
  const data: ButtonType[] = isRealtime
    ? [
        {
          label: '5min',
        },
        {
          label: '10min',
        },
        {
          label: '15min',
        },
        {
          label: '30min',
        },
      ]
    : [
        {
          label: '5min',
        },
        {
          label: '15min',
        },
        {
          label: '60min',
        },
        {
          label: '24hours',
        },
      ];

  return (
    <div className={cn('ProjectChartsControls', className)}>
      {isZoomAvailable ? (
        <div className="ProjectChartsControls__col">
          <Typography variant="h4" fontWeight={600} color="#2C3649">
            {title}
          </Typography>
          <Button size="small" type="secondary" onClick={onZoomClick}>
            Apply Zoom
          </Button>
        </div>
      ) : (
        <Row gutter={16} align="middle">
          <Col>
            <Typography variant="h4" fontWeight={600} color="#2C3649">
              {title}
            </Typography>
          </Col>
          {isLoading && (
            <Col style={{ height: 25, position: 'relative', bottom: 6 }}>
              <Loader />
            </Col>
          )}
        </Row>
      )}

      <div className="ProjectChartsControls__wrapper">
        <span className="ProjectChartsControls__label">
          {isRealtime ? 'Time Frame' : 'Frequency'}:
        </span>
        <SectorButton
          disabled={disabled}
          size="small"
          data={data}
          checked={checked}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default ProjectChartsControls;
