import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILogs {
  inputValue: string;
  tags: string[];
  selectedCustomLogsPeriod: number[] | null;
}

const initialState: ILogs = {
  inputValue: '',
  tags: [],
  selectedCustomLogsPeriod: null,
};

export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    updateInputValue: (state, action: PayloadAction<string>) => {
      state.inputValue = action.payload;
    },
    clearInputValue: (state) => {
      state.inputValue = '';
    },
    updateTagsValue: (state, action: PayloadAction<string[]>) => {
      state.tags = action.payload;
    },
    addTag: (state, action: PayloadAction<string>) => {
      state.tags = [...state.tags, action.payload];
    },
    clearTagsValue: (state) => {
      state.tags = [];
    },
    updateSelectedCustomLogsPeriod: (state, action: PayloadAction<number[] | null>) => {
      state.selectedCustomLogsPeriod = action.payload;
    },
  },
});

export const {
  updateInputValue,
  clearInputValue,
  updateTagsValue,
  clearTagsValue,
  addTag,
  updateSelectedCustomLogsPeriod,
} = logsSlice.actions;

export default logsSlice.reducer;
