import React, { FC, useCallback, useState } from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { UsersColaborationType } from 'models';
import { Label } from 'components/common/Label';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { Notification } from 'components/common/Notification';
import { collaborationApiv2, propertyAPI, userDetailsApi } from 'services';
import { useAppSelector } from 'store';
import { ProjectOriginCollaborationDeleteModal } from 'components/views/Project/ProjectOrigin/ProjectOriginCollaborationDeleteModal';
import styles from './ProjectOriginCollaborationItem.module.scss';

interface Props {
  data?: UsersColaborationType;
}

export const ProjectOriginCollaborationItem: FC<Props> = ({ data }) => {
  const { token } = useAppSelector(({ auth }) => auth);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [changeOwnerModalVisible, setChangeOwnerModalVisible] = useState(false);
  const { directoryName } = useParams<{ directoryName?: string }>();
  const [
    deleteCollaborator,
    { isLoading: deleteColaboratorLoading, isSuccess: isSuccessDeleteCollaborator },
  ] = collaborationApiv2.useDeleteCollaboratorMutation();
  const [changeOwner, { isLoading: changeOwnerLoading, isSuccess: isSuccessChangeOwner }] =
    propertyAPI.useChangeOwnerMutation();

  const { data: propertytDetails } = propertyAPI.useFetchPropertyQuery(String(directoryName), {
    skip: !token,
  });

  const { data: meDatails } = userDetailsApi.useFetchMeDetailsQuery('', {
    skip: !token,
  });

  const onRemoveClick = useCallback(() => {
    deleteCollaborator({
      propertyId: directoryName || '',
      userId: String(data?.id),
    })
      .unwrap()
      .then(() => {
        Notification({ type: 'check', title: 'User removed successfuly' });
      })
      .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
  }, [data?.id, deleteCollaborator, directoryName]);

  const onChangeOwnerClikClick = useCallback(() => {
    changeOwner({
      propertyId: String(directoryName) || '',
      id: String(data?.id),
    })
      .unwrap()
      .then((resp) => {
        Notification({ type: 'check', title: resp.message });
        setChangeOwnerModalVisible(false);
      })
      .catch((err) => Notification({ type: 'cross', title: 'Error', message: err.data.message }));
  }, [changeOwner, data?.id, directoryName]);

  const isMe = meDatails?.data?.email === data?.email;

  return (
    <>
      <Modal
        visible={changeOwnerModalVisible}
        title="Change owner"
        width="400px"
        onCancel={() => setChangeOwnerModalVisible(false)}>
        <ProjectOriginCollaborationDeleteModal
          type="changeOwner"
          projectName={propertytDetails?.data.name || ''}
          onCancel={() => setChangeOwnerModalVisible(false)}
          onFinish={onChangeOwnerClikClick}
          loading={changeOwnerLoading}
          isSuccess={isSuccessChangeOwner}
        />
      </Modal>
      <Modal
        visible={deleteModalVisible}
        title="Delete collaborator"
        width="400px"
        onCancel={() => setDeleteModalVisible(false)}>
        <ProjectOriginCollaborationDeleteModal
          type="deleteCollaborator"
          projectName={propertytDetails?.data.name || ''}
          email={data?.email || ''}
          onCancel={() => setDeleteModalVisible(false)}
          onFinish={onRemoveClick}
          loading={deleteColaboratorLoading}
          isSuccess={isSuccessDeleteCollaborator}
        />
      </Modal>
      <Row className={styles.item}>
        <Col span={10} className={styles.col}>
          <span className={styles.label}>{data?.email}</span>
          {data?.is_owner && (
            <Label type="success" className={styles.badge}>
              Owner
            </Label>
          )}
        </Col>
        <Col offset={0} flex={1} className={classNames(styles.col, styles.taRight)}>
          {!data?.is_owner && !isMe && (
            <Row gutter={8} justify="end" align="middle">
              <Col>
                <Button
                  type="subtle"
                  icon="acount-in-circle"
                  onClick={() => setChangeOwnerModalVisible(true)}
                  // onClick={onChangeOwnerClikClick}
                  loading={changeOwnerLoading}>
                  Change owner
                </Button>
              </Col>
              <Col>
                <Button
                  type="subtle"
                  onClick={() => setDeleteModalVisible(true)}
                  // onClick={() => onRemoveClick()}
                  size="small"
                  icon="remove"
                  className={styles.remove}
                  loading={deleteColaboratorLoading}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProjectOriginCollaborationItem;
