/* eslint-disable no-console */
import './ProjectEngineEngine.scss';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { capitalize, find, findIndex, isEmpty } from 'lodash';
import { Col, Row, Form } from 'antd';
import Button from 'components/common/Button';
// import Divider from 'components/common/Divider';
// import Label from 'components/common/Label/Label';
import { Notification } from 'components/common/Notification';
import { useAppSelector } from 'store';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { projectAPI, rulesAPI } from 'services';
import { Loader } from 'components/common/Loader';
import { DataRulesType } from 'models/rules';
import { Label } from 'components/common/Label';
import RouteTypes from 'router/types';
import moment from 'moment';
import { Icon } from 'components/common/Icon';
import { ReactComponent as Ilustration } from 'assets/svg/RulesEmptyIlustration.svg';
// import {
//   // ProjectEngineEngineAddDropdown,
//   RefProps,
// } from './ProjectEngineEngineAddDropdown/ProjectEngineEngineAddDropdown';
// import { ProjectEngineItem } from './ProjectEngineItem';
import styles from './ProjectEngineEngine.module.scss';
import { ProjectHeadEngineNav } from '../../ProjectHead/ProjectHeadEngineNav';

const { Item, useForm } = Form;

export const ProjectEngineEngine: FC = () => {
  const [form] = useForm();
  const [, setState] = useState<unknown>();
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { selectedCdn } = useAppSelector(({ project }) => project);
  const { token } = useAppSelector(({ auth }) => auth);
  const [haveDeployedRule, setHaveDeployedRule] = useState<DataRulesType>();
  // const matchDropdown = useRef<RefProps>(null);
  const [, setDropdownVisible] = useState(false);

  const { data: projectDetails } = projectAPI.useFetchServiceQuery(String(directoryName), {
    skip: !token,
  });

  const getCnameObject = useMemo(() => {
    if (projectDetails && selectedCdn !== '') {
      const idx = findIndex(
        projectDetails.adn.cnames,
        ({ edge_id }) => Number(edge_id) === Number(selectedCdn)
      );
      if (idx >= 0) {
        return projectDetails.adn.cnames[idx];
      }
    }
    return undefined;
  }, [projectDetails, selectedCdn]);

  const { data: rules, isLoading: isRulesLoading } = rulesAPI.useGetRulesQuery(
    { cnameId: String(selectedCdn), directoryName: String(directoryName) },
    { skip: !token || !directoryName || !selectedCdn || !getCnameObject?.rules_setup }
  );
  const [createRule] = rulesAPI.useCreateRuleMutation();
  // const [deployRule, { isLoading: deployRuleLoading }] = rulesAPI.useDeployRuleMutation();
  // const [cancelDeploy, { isLoading: cancelDeployLoading }] = rulesAPI.useCancelDeployRuleMutation();
  const [deleteRule, { isLoading: deleteRuleLoading }] = rulesAPI.useRemoveRuleMutation();

  const onFinish = useCallback(
    (values: unknown) => {
      createRule({
        cnameId: String(selectedCdn),
        directoryName: String(directoryName),
        data: { rule: values },
      })
        .unwrap()
        .then((v) => {
          setState(undefined);
          form.resetFields();
          Notification({
            type: 'check',
            title: v.message as string,
          });
        });
    },
    [createRule, directoryName, form, selectedCdn]
  );

  // const onAddFirstMatchClick = () => {
  //   const allMatches = form.getFieldValue('matches') || [];
  //   const indexOfFirstMatch = findIndex(allMatches, { type: 'select.first-match' });

  //   if (indexOfFirstMatch === -1) {
  //     form.setFieldsValue({
  //       ...form.getFieldsValue(),
  //       matches: [
  //         {
  //           type: 'select.first-match',
  //           useless: 'first-match',
  //           matches: [null, null],
  //         },
  //         ...(form.getFieldValue('matches') || []),
  //       ],
  //     });
  //   }
  // };

  // const onDropdownButtonClick = useCallback(
  //   (idx: number) => {
  //     setState('1');
  //     setDropdownVisible(false);
  //     switch (idx) {
  //       case 0:
  //         form.setFieldsValue(enableWordPressCache);
  //         break;
  //       case 1:
  //         form.setFieldsValue(enableWordPressCacheShort);
  //         break;
  //       default:
  //         form.setFieldsValue(enableWordPressCache);
  //         break;
  //     }
  //   },
  //   [form]
  // );

  const onDeployButtonClick = useCallback(() => {
    const fidnDeployedItem = find(rules?.data, (item) => item.status === 'deployed');
    setDropdownVisible(false);
    if (!isEmpty(rules?.data) && fidnDeployedItem) {
      return deleteRule({
        cnameId: String(selectedCdn),
        directoryName: String(directoryName),
        ruleId: String(fidnDeployedItem.id),
      })
        .unwrap()
        .then((v) =>
          Notification({
            type: 'check',
            title: v.message as string,
          })
        );
    }
    return null;
  }, [deleteRule, directoryName, rules?.data, selectedCdn]);

  // const onSaveButtonClick = useCallback(() => {
  //   form.submit();
  //   setDropdownVisible(false);
  // }, [form]);

  // const getDropdownLabel = useMemo(() => {
  //   const fidnDeployedItem = find(rules?.data, (item) => item.status === 'deployed');
  //   return (
  //     <Row align="middle" gutter={8}>
  //       <Col>
  //         <BarsOutlined className={styles.icon} />
  //       </Col>
  //       <Col>{capitalize(fidnDeployedItem?.profile)}</Col>
  //     </Row>
  //   );
  // }, [rules?.data]);

  // const renderHeaderButton = useMemo(() => {
  //   const isEmptyForm =
  //     isEmpty(form.getFieldsValue().matches) &&
  //     !find(rules?.data, (item) => item.status === 'deployed');
  //   const isFormNotEmpty =
  //     !isEmpty(form.getFieldsValue().matches) &&
  //     !find(rules?.data, (item) => item.status === 'deployed');
  //   if (isFormNotEmpty) {
  //     return (
  //       <Dropdown
  //         trigger={['click']}
  //         visible={dropdownVisible}
  //         onVisibleChange={setDropdownVisible}
  //         triggerElement={
  //           <Button
  //             icon="expand-down"
  //             type="subtle"
  //             iconPosition="right"
  //             loading={createRuleLoading}>
  //             {getDropdownLabel}
  //           </Button>
  //         }>
  //         <Row>
  //           <Col span={24}>
  //             <Row
  //               align="middle"
  //               onClick={() => {
  //                 form.resetFields();
  //                 setDropdownVisible(false);
  //                 setTimeout(() => {
  //                   form.resetFields();
  //                   setState(undefined);
  //                 }, 100);
  //               }}
  //               gutter={8}
  //               className={classNames(styles.item)}>
  //               <Col span={2}>
  //                 <CloseCircleOutlined className={classNames(styles.icon, styles.red)} />
  //               </Col>
  //               <Col span={22}>
  //                 <h2 className={styles.name}>Clear Policy fields</h2>
  //                 <p className={styles.button}>Remove preselected rules</p>
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col span={24}>
  //             <Row
  //               align="middle"
  //               onClick={onSaveButtonClick}
  //               gutter={8}
  //               className={classNames(styles.item)}>
  //               <Col span={2}>
  //                 <SaveOutlined className={classNames(styles.icon, styles.green)} />
  //               </Col>
  //               <Col span={22}>
  //                 <h2 className={styles.name}>Save</h2>
  //                 <p className={styles.button}>Save policy to draft</p>
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>
  //       </Dropdown>
  //     );
  //   }
  //   if (isEmptyForm) {
  //     return null;
  //   }
  //   if (!isEmptyForm) {
  //     return (
  //       <Dropdown
  //         trigger={['click']}
  //         visible={dropdownVisible}
  //         onVisibleChange={setDropdownVisible}
  //         triggerElement={
  //           <Button
  //             icon="expand-down"
  //             type="subtle"
  //             iconPosition="right"
  //             loading={deleteRuleLoading}>
  //             {getDropdownLabel}
  //           </Button>
  //         }>
  //         <Row
  //           align="middle"
  //           onClick={() => onDeployButtonClick()}
  //           gutter={8}
  //           className={classNames(styles.item)}>
  //           <Col span={4}>
  //             <CloseCircleOutlined className={classNames(styles.icon, styles.red)} />
  //           </Col>
  //           <Col span={20}>
  //             <h2 className={styles.name}>Delete</h2>
  //             <p className={styles.button}>Delete deployed rules</p>
  //           </Col>
  //         </Row>
  //       </Dropdown>
  //     );
  //   }
  //   return null;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   deleteRuleLoading,
  //   createRuleLoading,
  //   form,
  //   onDeployButtonClick,
  //   onDropdownButtonClick,
  //   rules?.data,
  //   state,
  //   dropdownVisible,
  //   onSaveButtonClick,
  // ]);

  // console.log(haveDeployedRule);

  useEffect(() => {
    const fidnDeployedItem = find(rules?.data, (item) => item.status === 'deployed');

    if (rules && fidnDeployedItem) {
      setHaveDeployedRule(fidnDeployedItem);
    }
    return () => {
      setHaveDeployedRule(undefined);
      form.resetFields();
      setTimeout(() => form.resetFields(), 100);
    };
  }, [form, rules]);

  return (
    <Form onFinish={onFinish} form={form} name="deploy" className="ProjectEngineEngine">
      <div className={styles.dropdown}>
        {/* <Item shouldUpdate noStyle>
          {() => (
            <>
              <Row gutter={16}>
                <Col>{renderHeaderButton}</Col>
              </Row>
            </>
          )}
        </Item> */}
        {/* <Dropdown
          trigger={['click']}
          visible={dropdownProfileVisible}
          onVisibleChange={setDropdownProfileVisible}
          triggerElement={
            <Button icon="expand-down" type="subtle" iconPosition="right">
              Add
            </Button>
          }>
          <Row gutter={[8, 8]} justify="start">
            <Col span={24}>
              <Row
                align="middle"
                onClick={() => onDropdownButtonClick(0)}
                gutter={8}
                className={classNames(styles.item)}>
                <Col span={2}>
                  <BarsOutlined className={classNames(styles.icon, styles.green)} />
                </Col>
                <Col span={22}>
                  <h2 className={styles.name}> WordPress & WooCommerce Static Cache</h2>
                  <p className={styles.button}>
                    Cache static files and dynamic blocks to distribute from the edge cache.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                align="middle"
                onClick={() => onDropdownButtonClick(1)}
                gutter={8}
                className={classNames(styles.item)}>
                <Col span={2}>
                  <BarsOutlined className={classNames(styles.icon, styles.blue)} />
                </Col>
                <Col span={22}>
                  <h2 className={styles.name}>WordPress & WooCommerce Dynamic Cache</h2>
                  <p className={styles.button}>
                    Cache static files only and bypass dynamic blocks from the edge cache.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                align="middle"
                onClick={() => onDropdownButtonClick(2)}
                gutter={8}
                className={classNames(styles.item)}>
                <Col span={2}>
                  <BarsOutlined className={classNames(styles.icon, styles.yellow)} />
                </Col>
                <Col span={22}>
                  <h2 className={styles.name}>Redirect CNAME to the primary domain</h2>
                  <p className={styles.button}>Redirect additional CNAMES to the primary domain</p>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" onClick={() => {}} gutter={8} className={classNames(styles.item)}>
                <Col span={2}>
                  <BarsOutlined className={classNames(styles.icon, styles.orange)} />
                </Col>
                <Col span={22}>
                  <h2 className={styles.name}>Custom Rule</h2>
                  <p className={styles.button}>Coming soon</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Dropdown> */}
      </div>
      <Row justify="space-between" align="middle" className="ProjectEngineEngine__head">
        <Col>
          <Row align="middle" gutter={16}>
            <Col>
              <span className="ProjectEngineEngine__name">Engine Rules</span>
            </Col>
            <Col>
              <ProjectHeadEngineNav />
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="ProjectEngineEngine__content">
        {/* <Item name="description">
          <Input placeholder="Description..." />
        </Item> */}
        {isRulesLoading ? (
          <Row justify="center" style={{ padding: 24 }}>
            <Col>
              <Loader />
            </Col>
          </Row>
        ) : (
          <>
            <Item shouldUpdate noStyle>
              {() =>
                !getCnameObject?.rules_setup && (
                  <div className={styles.emptyState}>
                    <Row align="middle" gutter={120} style={{ padding: 32, height: '100%' }}>
                      <Col span={12}>
                        <h2 className={styles.emptyTitle}>
                          You don&apos;t have any rules yet. Setup your account to monitor and scale
                          your businesses safely
                        </h2>
                        <p className={styles.emptyText}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus
                          sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus
                          dolor purus non enim praesent elementum facilisis leo
                        </p>
                        <NavLink to={generatePath(RouteTypes.engineDraft, { directoryName })}>
                          <Button>Setup your account</Button>
                        </NavLink>
                      </Col>
                      <Col span={12} className={styles.imageBlock}>
                        <Ilustration className={styles.ilustration} />
                        <span className={styles.orange} />
                      </Col>
                    </Row>
                  </div>
                )
              }
            </Item>
            {/* <List name="matches">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <div className="ProjectEngineEngine__items">
                      {map(fields, (field) => (
                        <React.Fragment key={field.key}>
                          <ProjectEngineItem
                            form={form}
                            depth={0}
                            field={field}
                            onRemove={remove}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                    <ProjectEngineEngineAddDropdown
                      ref={matchDropdown}
                      style={{ display: 'none' }}
                      onMatchClick={add}
                      onSelectFirstMatchClick={() => {}}
                    />
                  </>
                );
              }}
            </List> */}
          </>
        )}
        {/* <Item shouldUpdate>
          {() => (
            <ProjectEngineEngineAddDropdown
              disabled
              onMatchClick={() => matchDropdown?.current?.onMatchButtonClick()}
              onSelectFirstMatchClick={onAddFirstMatchClick}
              isFirstMatchDissabled={
                findIndex(form.getFieldValue('matches'), { type: 'select.first-match' }) >= 0
              }
            />
          )}
        </Item> */}
        {/* <CodeBlock value={rules?.data?.[0] as unknown as IObject} /> */}
        {!!getCnameObject?.rules_setup && (
          <div className={styles.deployed}>
            <h4 className={styles.ruleTitle}>Deployed Policy</h4>
            <Row style={{ padding: '12px 0' }} gutter={[24, 12]}>
              <Col span={6} className={styles.col}>
                <span className={styles.ruleName}>
                  <Icon name="acount-in-circle" className={styles.nameIcon} />
                  Profile name
                </span>
                <span className={styles.ruleValue}>{capitalize(haveDeployedRule?.profile)}</span>
              </Col>
              <Col span={6} className={styles.col}>
                <span className={styles.ruleName}>
                  <Icon name="clock-outline-2" className={styles.nameIcon} />
                  Update time
                </span>
                <span className={styles.ruleValue}>
                  {moment(haveDeployedRule?.updated_at).format('MMM DD YYYY HH:mm:ss')}
                </span>
              </Col>
              <Col span={6} className={styles.col}>
                <span className={styles.ruleName}>
                  <Icon name="status" className={styles.nameIcon} />
                  Status
                </span>
                <Label type="info">Deployed</Label>
              </Col>

              <Col span={6} className={styles.col}>
                <span className={styles.ruleName}>
                  <Icon name="version" className={styles.nameIcon} />
                  Version
                </span>
                <span className={styles.ruleValue}>{haveDeployedRule?.version}</span>
              </Col>
            </Row>

            <Row style={{ paddingTop: 10 }}>
              <Button
                loading={deleteRuleLoading}
                type="danger"
                onClick={() => onDeployButtonClick()}>
                Delete
              </Button>
            </Row>
          </div>
          // <SyntaxHighlighter
          //   language="json"
          //   className="ProjectEngineEngine__code"
          //   style={colorBrewer}
          //   useInlineStyles={false}
          //   codeTagProps={{ style: { color: '#3394dc' } }}
          //   customStyle={{
          //     padding: 0,
          //     margin: 0,
          //     width: '100%',
          //     whiteSpace: 'nowrap',
          //     overflow: 'hidden',
          //     textOverflow: 'ellipsis',
          //     background: 'transparent',
          //     fontSize: 12,
          //   }}>
          //   {JSON.stringify(haveDeployedRule, undefined, 2)}
          // </SyntaxHighlighter>
        )}
      </div>
    </Form>
  );
};

export default ProjectEngineEngine;
