import { INavigation } from 'store/slices/ui';

export class StorageService {
  private alias = {
    navigation: 'adn-navigation',
  };

  set navigationList(list: INavigation[] | null) {
    if (list) {
      localStorage.setItem(this.alias.navigation, JSON.stringify(list));
    } else {
      localStorage.removeItem(this.alias.navigation);
    }
  }

  get navigationList(): INavigation[] | null {
    const data: string | null = localStorage.getItem(this.alias.navigation);
    if (typeof data === 'string') {
      return JSON.parse(data) as INavigation[];
    }
    return data;
  }
}

export const storageService = new StorageService();
