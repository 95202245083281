/* eslint-disable no-nested-ternary */
import React, { CSSProperties, FC } from 'react';
import { DiffOutlined, ReloadOutlined, LoadingOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { Row, Col, Spin } from 'antd';
import { Button } from '../Button';
import './BannerDeployChanges.scss';

export interface Props {
  title?: string;
  btnTitle?: string;
  deployLoading?: boolean;
  withOutDiff?: boolean;
  isDisabled?: boolean;
  // revertLoading?: boolean;
  onDeployClick?: () => void;
  onDiffClick?: () => void;
  onRevertClick?: () => void;
  status: string;
  isDeployBanner?: boolean;
  className?: string;
  style?: CSSProperties;
}

export const BannerDeployChanges: FC<Props> = ({
  title,
  btnTitle,
  deployLoading,
  withOutDiff,
  // revertLoading,
  onDeployClick,
  onDiffClick,
  onRevertClick,
  status,
  isDisabled,
  isDeployBanner,
  style,
  className,
}) => {
  const handleDeploy = () => {
    if (onDeployClick) {
      onDeployClick();
    }
  };
  const handleDiff = () => {
    if (onDiffClick) {
      onDiffClick();
    }
  };
  const handleRevert = () => {
    if (onRevertClick) {
      onRevertClick();
    }
  };
  return (
    <Row
      className={cn('bannerWrapper', className, { _deployBanner: isDeployBanner })}
      style={style}
      // className="bannerWrapper "
    >
      <Col className="bannerInner" span={24}>
        {status === 'deploying' || status === 'in_progress' ? (
          <div>
            <Spin
              indicator={
                <LoadingOutlined style={{ color: '#fff', fontSize: 20, marginRight: 20 }} spin />
              }
            />
            {status === 'deploying'
              ? 'Deploying ...'
              : status === 'in_progress'
              ? 'Saving ...'
              : ''}
          </div>
        ) : (
          <>
            <Col span={19}>
              <div className="bannerInner-deploySection">
                <span className="bannerInner-deploySection-title">
                  {title || 'There are undeployed changes.'}
                </span>
                <Button
                  disabled={isDisabled}
                  className="bannerInner-deploySection-button"
                  icon={btnTitle ? undefined : 'deployment'}
                  type="primary"
                  borderColor="#fff"
                  loading={deployLoading}
                  onClick={handleDeploy}>
                  <span>{btnTitle || 'Deploy Changes'}</span>
                </Button>
              </div>
            </Col>
            <Col>
              <Row justify="end" align="middle" className="bannerInner-actionsSection">
                {!withOutDiff && (
                  <>
                    <Col>
                      <Button
                        className="bannerInner-actionsSection-button"
                        type="primary"
                        borderColor="#1a59d9"
                        onClick={handleDiff}>
                        <>
                          <DiffOutlined /> Diff
                        </>
                      </Button>
                    </Col>
                    <Col>
                      <div className="bannerInner-actionsSection-divider" />
                    </Col>
                  </>
                )}
                <Col>
                  <Button
                    className="bannerInner-actionsSection-button"
                    type="primary"
                    borderColor="#1a59d9"
                    // loading={revertLoading}
                    onClick={handleRevert}>
                    <>
                      <ReloadOutlined /> Revert
                    </>
                  </Button>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Col>
    </Row>
  );
};

export default BannerDeployChanges;
