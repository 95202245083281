import { Col, Row } from 'antd';
import { ProjectOriginOrigin } from 'components/views/Project/ProjectOrigin';
import React, { FC } from 'react';

export const ProjectOriginOriginView: FC = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <ProjectOriginOrigin />
      </Col>
    </Row>
  );
};

export default ProjectOriginOriginView;
