/* eslint-disable @typescript-eslint/naming-convention */
import './UserSettingsSettings.scss';
import { Avatar, Col, Form, Row, Upload } from 'antd';
import { BlockWrapper } from 'components/common/BlockWrapper';
import Button from 'components/common/Button';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from 'components/common/Input';
import { UserSettingsResetPassword } from 'components/views/UserSettings';
import { UserSettingsDeleteAccount } from 'components/views/UserSettings/UserSettingsDeleteAccount';
import { userDetailsApi } from 'services';
import { useAppSelector } from 'store';
import ImgCrop from 'antd-img-crop';
import { forIn, isEmpty, isNil, random } from 'lodash';
import { UserDetailsPayload } from 'models';
import { useAuth0 } from '@auth0/auth0-react';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { BASE_URL_PUSHER_AUTH } from 'urls';
import { Notification } from 'components/common/Notification';
import { UserOutlined } from '@ant-design/icons';

export const UserSettingsSettings: FC = () => {
  const [settingsForm] = Form.useForm<UserDetailsPayload>();
  const [, forceUpdate] = useState({});
  const { token } = useAppSelector(({ auth }) => auth);
  const { data: dataMe } = userDetailsApi.useFetchMeDetailsQuery('', { skip: !token });
  const { data, isLoading } = userDetailsApi.useFetchUserDetailsQuery('', { skip: !token });
  const [update, { isLoading: isUpdating }] = userDetailsApi.useUpdateUserDetailsMutation();
  const { logout, user } = useAuth0();
  const [image, setImage] = useState<string | Blob | RcFile>();

  const logOut = useCallback(() => logout({ returnTo: window.location.origin }), [logout]);

  const onFinish = useCallback(
    (values: UserDetailsPayload) => {
      const formData = new FormData();
      forIn(values, (v, k) => {
        formData.append(k, v as string);
      });
      if (image && image !== 'delete') {
        localStorage.setItem('cacheClearValue', String(random(1, 100)));
        formData.append('image', image);
      }
      if (image === 'delete') {
        formData.append('image', 'delete');
      }
      if (!image) {
        formData.append('image', 'null');
      }
      update(formData as unknown as UserDetailsPayload)
        .unwrap()
        .then((d) => Notification({ title: d.message, type: 'check' }))
        .catch((err) => Notification({ title: err.data?.message, type: 'cross' }));
    },
    [image, update]
  );

  const onChange = useCallback((v: UploadChangeParam<UploadFile<unknown>>) => {
    return v;
  }, []);

  const getImageUrl = useMemo(() => {
    const cacheClearValue = localStorage.getItem('cacheClearValue');
    if (image && image !== 'delete') return URL.createObjectURL(image as Blob);
    if (data?.data?.profile_image && !isEmpty(data?.data?.profile_image) && !image)
      return `${BASE_URL_PUSHER_AUTH}${data?.data?.profile_image}?${cacheClearValue}`;
    return null;
  }, [data?.data?.profile_image, image]);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (data && !isNil(data.data)) {
      const { first_name, last_name, company_name } = data.data;
      settingsForm.setFieldsValue({
        first_name,
        last_name,
        company_name,
      });
    }
  }, [data, settingsForm]);

  return (
    <Row gutter={[20, 20]} className="UserSettingsSettings">
      <Col span={24}>
        <div>
          <Row gutter={16} align="middle" style={{ marginBottom: '32px' }}>
            <Col>
              <Avatar src={getImageUrl} size={94} icon={<UserOutlined />} />
            </Col>
            <Col>
              <h4 className="UserSettingsSettings__user-name">Your Photo</h4>
              <p style={{ fontSize: '12px', fontWeight: 400 }}>
                After uploading the photo, you will be able to edit or delete it.
              </p>
              <Button
                type="subtle"
                className="UserSettingsSettings__delete"
                onClick={() => setImage('delete')}>
                Delete
              </Button>
              <ImgCrop rotationSlider>
                <Upload
                  className="UserSettingsSettings__upload"
                  onChange={onChange}
                  customRequest={(d) => {
                    setImage(d.file);
                  }}>
                  <Button type="subtle">Change Avatar</Button>
                </Upload>
              </ImgCrop>
            </Col>
          </Row>
          <Form
            form={settingsForm}
            name="signup-form"
            onFinish={onFinish}
            className="UserSettingsSettings__form">
            <Row gutter={24} align="bottom">
              <Col span={24}>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item name="first_name">
                      <Input label="First Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="last_name">
                      <Input label="Last Name" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item name="company_name">
                      <Input label="Company Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Input label="Email" value={user?.email} disabled />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form.Item shouldUpdate noStyle>
                  {() => (
                    <Row justify="end">
                      <Button
                        className="UserSettingsSettings__btn"
                        submit
                        fluid
                        loading={isLoading || isUpdating}
                        type="secondary"
                        disabled={
                          !settingsForm.isFieldsTouched(true) ||
                          !!settingsForm.getFieldsError().filter(({ errors }) => errors.length)
                            .length
                        }>
                        Save
                      </Button>
                    </Row>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
      <Col span={24}>
        <div>
          <UserSettingsResetPassword />
        </div>
      </Col>
      <Col span={24} style={{ marginTop: 24 }}>
        <h2 className="UserSettingsSettings__title _main">Danger zone</h2>
        <p className="UserSettingsSettings__subtitle">Irreversible and destructive actions</p>
      </Col>
      <Col span={24}>
        <BlockWrapper isSmall padding="32px" minHeight="initial">
          <UserSettingsDeleteAccount data={dataMe} logOut={logOut} />
        </BlockWrapper>
      </Col>
    </Row>
  );
};

export default UserSettingsSettings;
