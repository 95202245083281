import React, { FC, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { ProjectSecuritySmallTable } from 'components/views/Project/ProjectSecurity';
import { ProjectSecurityCharts } from 'components/views/Project/ProjectSecurity/ProjectSecurityCharts';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { analyticsApiv2 } from 'services';
import { useAppSelector } from 'store';
import { AnalyticsBucket } from 'models';
import { BOT_DATA, THREATS_DATA, RATES_DATA } from './data';
import styles from './ProjectSecurity.module.scss';

export const ProjectSecurity: FC = () => {
  const [countValue, setCountValue] = useState<10 | 20 | 50>(10);
  const [checkedView, setCheckedView] = useState<number>(0);
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { selectedCustomPeriod } = useAppSelector(({ project }) => project);

  const getTypeTopTen = useMemo(() => {
    if (checkedView === 1) {
      return 'rate-limiting';
    }
    if (checkedView === 2) {
      return 'bot';
    }
    return 'event';
  }, [checkedView]);

  const getCount = (v: 10 | 20 | 50) => {
    setCountValue(v);
  };

  const getSelectedCustomPeriod = useMemo(() => {
    // const formatString = 'DD/MM/YYYY HH:mm';
    switch (selectedCustomPeriod) {
      case '5 minutes':
        return [moment().unix(), moment().subtract(5, 'minutes').unix()];
      case '15 minutes':
        return [moment().unix(), moment().subtract(15, 'minutes').unix()];
      case '1 hour':
        return [moment().unix(), moment().subtract(1, 'hours').unix()];
      case '6 hours':
        return [moment().unix(), moment().subtract(6, 'hours').unix()];
      case '12 hours':
        return [moment().unix(), moment().subtract(12, 'hours').unix()];
      case '1 day':
        return [moment().unix(), moment().subtract(1, 'day').unix()];
      case '2 days':
        return [moment().unix(), moment().subtract(2, 'days').unix()];
      case '7 days':
        return [moment().unix(), moment().subtract(7, 'days').unix()];
      case '30 days':
        return [moment().unix(), moment().subtract(30, 'days').unix()];
      default:
        return [moment().unix(), moment().subtract(2, 'days').unix()];
    }
  }, [selectedCustomPeriod]);

  const { data: botData, isLoading: isLoadingBotData } =
    analyticsApiv2.useFetchHistoricalTopEntriesQuery(
      {
        propertyId: String(directoryName),
        type: getTypeTopTen,
        hosts: '',
        properties: BOT_DATA.map((item) => item.value).join(','),
        startDate: getSelectedCustomPeriod[1],
        endDate: getSelectedCustomPeriod[0],
        count: countValue,
      },
      {
        skip: !token || checkedView !== 2,
      }
    );
  const { data: ratesData, isLoading: isLoadingRatesData } =
    analyticsApiv2.useFetchHistoricalTopEntriesQuery(
      {
        propertyId: String(directoryName),
        type: getTypeTopTen,
        hosts: '',
        properties: RATES_DATA.map((item) => item.value).join(','),
        startDate: getSelectedCustomPeriod[1],
        endDate: getSelectedCustomPeriod[0],
        count: countValue,
      },
      {
        skip: !token || checkedView !== 1,
      }
    );
  const { data: threatsData, isLoading: isLoadingThreatsData } =
    analyticsApiv2.useFetchHistoricalTopEntriesQuery(
      {
        propertyId: String(directoryName),
        type: getTypeTopTen,
        hosts: '',
        properties: THREATS_DATA.map((item) => item.value).join(','),
        startDate: getSelectedCustomPeriod[1],
        endDate: getSelectedCustomPeriod[0],
        count: countValue,
      },
      {
        skip: !token || checkedView !== 0,
      }
    );

  const getSecurityTables = useMemo(() => {
    const getTablesForTab1 = () => {
      if (ratesData?.data !== undefined && ratesData?.data !== null) {
        return (
          <>
            {Object.entries(ratesData?.data).map(([key, value]) => {
              const formattedData = value?.buckets?.map((item: AnalyticsBucket) => ({
                _id: item.key,
                count: item.doc_count,
              }));

              const rateDataItem = RATES_DATA.find((item) => item.value === key);

              const title = rateDataItem?.name || key;

              return (
                <Col span={12} key={title}>
                  <ProjectSecuritySmallTable
                    key={title}
                    loading={isLoadingRatesData}
                    data={formattedData}
                    title={title}
                  />
                </Col>
              );
            })}
          </>
        );
      }
      return null;
    };

    const getTablesForTab2 = () => {
      if (botData?.data !== undefined && botData?.data !== null) {
        return (
          <>
            {Object.entries(botData?.data).map(([key, value]) => {
              const formattedData = value?.buckets?.map((item: AnalyticsBucket) => ({
                _id: item.key,
                count: item.doc_count,
              }));

              const threatsDataItem = BOT_DATA.find((item) => item.value === key);

              const title = threatsDataItem?.name || key;

              return (
                <Col span={12} key={title}>
                  <ProjectSecuritySmallTable
                    key={title}
                    loading={isLoadingBotData}
                    data={formattedData}
                    title={title}
                  />
                </Col>
              );
            })}
          </>
        );
      }
      return null;
    };

    const getTablesForTab0 = () => {
      if (threatsData?.data !== undefined && threatsData?.data !== null) {
        return (
          <>
            {Object.entries(threatsData?.data).map(([key, value]) => {
              const formattedData = value?.buckets?.map((item: AnalyticsBucket) => ({
                _id: item.key,
                count: item.doc_count,
              }));

              const threatsDataItem = THREATS_DATA.find((item) => item.value === key);

              const title = threatsDataItem?.name || key;

              return (
                <Col span={12} key={title}>
                  <ProjectSecuritySmallTable
                    key={title}
                    loading={isLoadingThreatsData}
                    data={formattedData}
                    title={title}
                  />
                </Col>
              );
            })}
          </>
        );
      }
      return null;
    };

    switch (checkedView) {
      case 0:
        return getTablesForTab0();
      case 1:
        return getTablesForTab1();
      case 2:
        return getTablesForTab2();
      default:
        return null;
    }
  }, [
    botData?.data,
    checkedView,
    isLoadingBotData,
    isLoadingRatesData,
    isLoadingThreatsData,
    ratesData?.data,
    threatsData?.data,
  ]);

  return (
    <div className={styles.security}>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <ProjectSecurityCharts
            checkedView={checkedView}
            setCheckedView={setCheckedView}
            getCount={getCount}
          />
        </Col>
        {getSecurityTables}
      </Row>
    </div>
  );
};

export default ProjectSecurity;
