import React from 'react';
// import { useAppSelector } from 'store';
import { Icon } from 'components/common/Icon';
import './ProjectOriginPciDetails.scss';

export const ProjectOriginPciDetails = () => {
  // const { token } = useAppSelector(({ auth }) => auth);
  // const { dns_domain_name, status } = useAppSelector(({ property }) => property);

  return (
    <div className="ProjectOriginPciDetails">
      <div className="wrapper">
        <span className="iconPlanet">
          <Icon name="planet" style={{ fontSize: 20 }} />
        </span>

        <h1>
          This environment is deployed to Edgeport&apos;s entire network, including non
          PCI-certified VIPs.
        </h1>
      </div>

      <div>
        <span className="supportText">To change this setting, contact Edgeport support.</span>
      </div>
    </div>
  );
};

export default ProjectOriginPciDetails;
