import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { isDev } from 'config';
import {
  projectAPI,
  propertyAPI,
  originAPI,
  statisticsAPI,
  ipAPI,
  adnCnamesAPI,
  cdnCnamesAPI,
  apikeyAPI,
  apikeyAPIv2,
  realtimeStatisticApi,
  routingAPI,
  paymentMethodsAPI,
  logsApi,
  eventLogsApi,
  sslApi,
  sslCertificateApi,
  userDetailsApi,
  authAPI,
  invoicesAPI,
  rulesAPI,
  wafAPI,
  whiteListAPI,
  blackListAPI,
  analyticsApi,
  analyticsApiv2,
  accessControlAPI,
  collaborationApi,
  collaborationApiv2,
  sucurityProfilesServiceAPIv2,
  userDetailsApiV2,
  deplymentServiceAPIv2,
  dnsAPI,
  invoicesAPIv2,
} from 'services';
import authReducer from './slices/auth';
import uiReducer from './slices/ui';
import projectReducer from './slices/project';
import logsReducer from './slices/logs';
import propertyReducer from './slices/property';
import securityReducer from './slices/securityProfiles';
import { rtkQueryErrorLogger } from './middlewares';

const logger = createLogger({ collapsed: true });

const middlewares = [
  rtkQueryErrorLogger,
  projectAPI.middleware,
  propertyAPI.middleware,
  originAPI.middleware,
  statisticsAPI.middleware,
  adnCnamesAPI.middleware,
  cdnCnamesAPI.middleware,
  ipAPI.middleware,
  apikeyAPI.middleware,
  apikeyAPIv2.middleware,
  realtimeStatisticApi.middleware,
  routingAPI.middleware,
  paymentMethodsAPI.middleware,
  logsApi.middleware,
  eventLogsApi.middleware,
  sslApi.middleware,
  sslCertificateApi.middleware,
  userDetailsApi.middleware,
  userDetailsApiV2.middleware,
  deplymentServiceAPIv2.middleware,
  dnsAPI.middleware,
  authAPI.middleware,
  invoicesAPI.middleware,
  invoicesAPIv2.middleware,
  rulesAPI.middleware,
  wafAPI.middleware,
  whiteListAPI.middleware,
  blackListAPI.middleware,
  analyticsApi.middleware,
  analyticsApiv2.middleware,
  accessControlAPI.middleware,
  collaborationApi.middleware,
  collaborationApiv2.middleware,
  sucurityProfilesServiceAPIv2.middleware,
];

if (isDev) middlewares.push(logger);

export const rootReducer = combineReducers({
  [projectAPI.reducerPath]: projectAPI.reducer,
  [propertyAPI.reducerPath]: propertyAPI.reducer,
  [originAPI.reducerPath]: originAPI.reducer,
  [statisticsAPI.reducerPath]: statisticsAPI.reducer,
  [adnCnamesAPI.reducerPath]: adnCnamesAPI.reducer,
  [cdnCnamesAPI.reducerPath]: cdnCnamesAPI.reducer,
  [ipAPI.reducerPath]: ipAPI.reducer,
  [apikeyAPI.reducerPath]: apikeyAPI.reducer,
  [apikeyAPIv2.reducerPath]: apikeyAPIv2.reducer,
  [realtimeStatisticApi.reducerPath]: realtimeStatisticApi.reducer,
  [routingAPI.reducerPath]: routingAPI.reducer,
  [paymentMethodsAPI.reducerPath]: paymentMethodsAPI.reducer,
  [logsApi.reducerPath]: logsApi.reducer,
  [eventLogsApi.reducerPath]: eventLogsApi.reducer,
  [sslApi.reducerPath]: sslApi.reducer,
  [sslCertificateApi.reducerPath]: sslCertificateApi.reducer,
  [userDetailsApi.reducerPath]: userDetailsApi.reducer,
  [userDetailsApiV2.reducerPath]: userDetailsApiV2.reducer,
  [deplymentServiceAPIv2.reducerPath]: deplymentServiceAPIv2.reducer,
  [dnsAPI.reducerPath]: dnsAPI.reducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [invoicesAPI.reducerPath]: invoicesAPI.reducer,
  [invoicesAPIv2.reducerPath]: invoicesAPIv2.reducer,
  [rulesAPI.reducerPath]: rulesAPI.reducer,
  [wafAPI.reducerPath]: wafAPI.reducer,
  [whiteListAPI.reducerPath]: whiteListAPI.reducer,
  [blackListAPI.reducerPath]: blackListAPI.reducer,
  [analyticsApi.reducerPath]: analyticsApi.reducer,
  [analyticsApiv2.reducerPath]: analyticsApiv2.reducer,
  [accessControlAPI.reducerPath]: accessControlAPI.reducer,
  [collaborationApi.reducerPath]: collaborationApi.reducer,
  [collaborationApiv2.reducerPath]: collaborationApiv2.reducer,
  [sucurityProfilesServiceAPIv2.reducerPath]: sucurityProfilesServiceAPIv2.reducer,
  auth: authReducer,
  ui: uiReducer,
  project: projectReducer,
  logs: logsReducer,
  property: propertyReducer,
  securityProfiles: securityReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(...middlewares),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
