/* eslint-disable no-nested-ternary */
import './HomeHeader.scss';
import React, { FC, FormEvent, useState } from 'react';
import { Col, Row } from 'antd';
import { Input } from 'components/common/Input';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { find, map } from 'lodash';
import { Icon } from 'components/common/Icon';
import cn from 'classnames';
import { localStorageAsync } from 'helpers/localStorageAsync';

const SORT_TYPES = [
  {
    name: 'New first',
    value: 'new',
  },
  {
    name: 'Old first',
    value: 'old',
  },
  {
    name: 'Favorites first',
    value: 'favorite',
  },
  {
    name: 'A-Z',
    value: 'az',
  },
  {
    name: 'Z-A',
    value: 'za',
  },
];

interface Props {
  // onAddClick?: () => void;
  selectedSortType: string;
  onSelectedSortTypeChange: (value: string) => void;
  onInputChange: (v: string) => void;
  isGridView?: boolean;
  setIsGridView?: (v: boolean) => void;
}

export const HomeHeader: FC<Props> = ({
  // onAddClick,
  selectedSortType,
  onSelectedSortTypeChange,
  onInputChange,
  isGridView,
  setIsGridView,
}) => {
  const [sortVisibility, setSortVisibility] = useState(false);
  return (
    <Row gutter={24} className="HomeHeader" align="top">
      <Col>
        <span style={{ fontSize: 18, fontWeight: 600, color: '#192A3E' }}>Web Properties</span>
      </Col>
      <Col flex={1}>
        <Input
          width={300}
          icon="search"
          placeholder="Search"
          onChange={(e: FormEvent<HTMLInputElement>) => onInputChange(e.currentTarget.value)}
        />
      </Col>
      <Col>
        <Row align="middle" gutter={16}>
          <Col>
            <Dropdown
              onVisibleChange={(e) => setSortVisibility(e)}
              triggerElement={
                <Button
                  type="subtle"
                  icon={sortVisibility ? 'expand-up' : 'expand-down'}
                  iconPosition="right">
                  <span>
                    Sort by: {find(SORT_TYPES, (item) => item.value === selectedSortType)?.name}
                  </span>
                </Button>
              }>
              {/* <span className="HomeHeader__dropdown-title">Sort by</span> */}
              <ul className="HomeHeader__list">
                {map(SORT_TYPES, (item) => (
                  <li
                    key={item.name}
                    className={cn('HomeHeader__item', '_fixed', {
                      _active: item.value === selectedSortType,
                    })}>
                    <button
                      // disabled={item.value === 'favorite' && !hasFavorites}
                      type="button"
                      onClick={() => {
                        onSelectedSortTypeChange(item.value);
                        localStorageAsync.setItem('sortType', item.value);
                      }}
                      className="HomeHeader__btn">
                      <Icon name="check" className="HomeHeader__list-icon" />
                      <span
                        className={cn('HomeHeader__list-value', {
                          // _disabled: item.value === 'favorite' && !hasFavorites,
                        })}>
                        {item.name}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </Dropdown>
          </Col>
          <Col>
            <Row gutter={8}>
              <Col>
                <Button
                  active={!isGridView}
                  onClick={() => setIsGridView && setIsGridView(false)}
                  type="secondary"
                  icon="list"
                />
              </Col>
              <Col>
                <Button
                  active={isGridView}
                  onClick={() => setIsGridView && setIsGridView(true)}
                  type="secondary"
                  icon="resize"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      {/* <Col>
        <Divider type="vertical" style={{ margin: 0 }} />
      </Col> */}
      {/* <Col>
        <Button onClick={onAddClick} icon="plus">
          Add New Property
        </Button>
      </Col> */}
    </Row>
  );
};

export default HomeHeader;
