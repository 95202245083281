import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { BlockWrapper } from 'components/common/BlockWrapper';
import ProjectOriginDeploymentDetails from 'components/views/Project/ProjectOrigin/ProjectOriginDeploymentDetails/ProjectOriginDeploymentDetails';

export const ProjectOriginDeploymentDetailsView: FC = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <BlockWrapper minHeight="initial" isSmall padding="16px 24px">
          <ProjectOriginDeploymentDetails />
        </BlockWrapper>
      </Col>
    </Row>
  );
};

export default ProjectOriginDeploymentDetailsView;
