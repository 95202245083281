import { useState, useEffect } from 'react';

export interface ViewportDimensions {
  viewportWidth: number;
}

export const useViewportWidth = (): ViewportDimensions => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth || 0);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { viewportWidth };
};

export default useViewportWidth;
