import { Col, Row } from 'antd';
import Button from 'components/common/Button';
import Divider from 'components/common/Divider';
import { Label } from 'components/common/Label';
import { capitalize } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { rulesAPI } from 'services';
import { Notification } from 'components/common/Notification';
import RouteTypes from 'router/types';
import { useAppSelector } from 'store';
import styles from './ProjectEngineDraftItem.module.scss';
import { ProjectEngineDraftItemChild } from './ProjectEngineDraftItemChild';

interface IProjectEngineDraftItemProps {
  id?: number | string;
}

export const ProjectEngineDraftItem: FC<IProjectEngineDraftItemProps> = ({ id }) => {
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { selectedCdn } = useAppSelector(({ project }) => project);
  const { token } = useAppSelector(({ auth }) => auth);

  const { data: item } = rulesAPI.useGetRuleDetailsQuery(
    { cnameId: String(selectedCdn), directoryName: String(directoryName), ruleId: String(id) },
    { skip: !token || !directoryName || !selectedCdn || !id }
  );

  const [deployRule, { isLoading: deployRuleLoading }] = rulesAPI.useDeployRuleMutation();
  const [cancelDeploy, { isLoading: cancelDeployLoading }] = rulesAPI.useCancelDeployRuleMutation();
  const [deleteRule, { isLoading: deleteRuleLoading }] = rulesAPI.useRemoveRuleMutation();

  const navigate = useNavigate();

  const getLabel = useMemo(() => {
    switch (item?.data?.status) {
      case 'draft':
        return <Label type="default">Draft</Label>;
      case 'deployed':
        return <Label type="info">Deployed</Label>;

      default:
        return <Label type="warning">Pending</Label>;
    }
  }, [item?.data?.status]);

  const onDeplouButtonClick = useCallback(() => {
    if (item?.data?.status === 'pending' || item?.data?.status === 'deployed') {
      return cancelDeploy({
        cnameId: String(selectedCdn),
        directoryName: String(directoryName),
        ruleId: String(item.data.id),
      })
        .unwrap()
        .then((v) =>
          Notification({
            type: 'check',
            title: v.message as string,
          })
        );
    }
    return deployRule({
      cnameId: String(selectedCdn),
      directoryName: String(directoryName),
      ruleId: String(item?.data?.id),
    })
      .unwrap()
      .then((v) =>
        Notification({
          type: 'check',
          title: v.message as string,
        })
      );
  }, [cancelDeploy, deployRule, directoryName, item?.data?.id, item?.data?.status, selectedCdn]);

  const onDeleteButtonClick = useCallback(() => {
    return deleteRule({
      cnameId: String(selectedCdn),
      directoryName: String(directoryName),
      ruleId: String(item?.data?.id),
    })
      .unwrap()
      .then((v) => {
        Notification({
          type: 'check',
          title: v.message as string,
        });
        navigate(generatePath(RouteTypes.engineDraft, { directoryName }));
      });
  }, [deleteRule, directoryName, item?.data?.id, navigate, selectedCdn]);

  return (
    <Row className={styles.item}>
      <Col className={styles.head} span={24}>
        <Row align="middle" gutter={12} justify="space-between">
          <Col flex={1}>
            <Row align="middle" gutter={12}>
              <Col>
                <span className={styles.text}>{capitalize(item?.data?.profile)}</span>
              </Col>
              <>
                <Col>
                  <Divider style={{ margin: '0 4px' }} type="vertical" />
                </Col>
                <Col>
                  <span className={styles.subtext}>
                    {item?.data?.status === 'draft' ? 'Draft' : 'Production'}
                  </span>
                </Col>
                <Col>{getLabel}</Col>
              </>
            </Row>
          </Col>
          <Col>
            <Row gutter={8}>
              <Col>
                {item?.data?.status !== 'deployed' && (
                  <Button
                    onClick={onDeplouButtonClick}
                    loading={deployRuleLoading || cancelDeployLoading}>
                    {item?.data?.status === 'pending' ? 'Cancel Deploy' : 'Deploy'}
                  </Button>
                )}
              </Col>
              <Col>
                <Button loading={deleteRuleLoading} type="danger" onClick={onDeleteButtonClick}>
                  Delete
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <ProjectEngineDraftItemChild id={item?.data?.id} />
      </Col>
    </Row>
  );
};

export default ProjectEngineDraftItem;
