export const DATA = {
  401: {
    title: 401,
    name: 'No autorization found',
    text: 'This page is not publicaly available. To access it  please login first.',
  },
  403: {
    title: 403,
    name: 'You’re not permitted to see this.',
    text: 'The page you’re trying to access has restricted access. If you feel this is a mistake, contact your admin.',
  },
  404: {
    title: 404,
    name: 'This page was not found',
    text: 'We cannot find this page. You can go to the home page or back to the previous page.',
  },
  500: {
    title: 500,
    name: 'Internal server error.',
    text: 'The server has been deserted. Please be patient or try again later',
  },
};

export default DATA;
