/* eslint-disable class-methods-use-this */
export class LocalStorageAsync {
  async setItem(key: string, value: string): Promise<unknown> {
    await Promise.resolve();
    return localStorage.setItem(key, value);
  }

  async getItem(key: string): Promise<unknown> {
    await Promise.resolve();
    return localStorage.getItem(key);
  }

  getItemSync(key: string): string | null {
    return localStorage.getItem(key);
  }

  async clear(): Promise<void> {
    await Promise.resolve();
    return localStorage.clear();
  }
}

export const localStorageAsync = new LocalStorageAsync();
