/* eslint-disable no-console */
import 'antd/dist/antd.less';
import './theme/style.scss';

import React from 'react';

import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { environment, isDev } from 'config';
import { Provider } from 'react-redux';
import { BASE_URL_AUTH0 } from 'urls';
import * as Sentry from '@sentry/react';
import store from 'store';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: process?.env?.REACT_APP_SENTRY_URL || '',
  environment: `${environment}`,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Auth0Provider
    domain={BASE_URL_AUTH0}
    clientId={process?.env?.REACT_APP_AUTH0_CLIENT_ID || ''}
    cacheLocation="localstorage"
    audience={process?.env?.REACT_APP_AUTH0_AUDIENCE}
    redirectUri={window.location.origin}>
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
);

if (isDev) {
  reportWebVitals(console.log);
}
