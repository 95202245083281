import classNames from 'classnames';
import React, { FC } from 'react';
import { generatePath, useParams, NavLink } from 'react-router-dom';
import RouteTypes from 'router/types';
import styles from './ProjectHeadEngoneNav.module.scss';

export const ProjectHeadEngineNav: FC = () => {
  const { directoryName } = useParams<{ directoryName?: string }>();

  return (
    <div className={styles.nav}>
      <NavLink
        to={generatePath(RouteTypes.engine, { directoryName })}
        end
        className={({ isActive }) => classNames(styles.link, { [styles.active]: isActive })}>
        Active
      </NavLink>
      <NavLink
        to={generatePath(RouteTypes.engineDraft, { directoryName })}
        className={({ isActive }) => classNames(styles.link, { [styles.active]: isActive })}>
        Policies
      </NavLink>
      {/* <NavLink
        to={generatePath(RouteTypes.engineHistory, { directoryName })}
        className={({ isActive }) => classNames(styles.link, { [styles.active]: isActive })}>
        History
      </NavLink> */}
    </div>
  );
};

export default ProjectHeadEngineNav;
