import './ProjectChartsMapBlock.scss';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { Typography } from 'components/common/Typography';
import { Loader } from 'components/common/Loader';
import classNames from 'classnames';
import { IChartsMapDataResponse, IHistoricalStatisticsCountryItemResponse } from 'models';
import { ProjectChartsBubble } from '../ProjectChartsBubble';
import { ProjectChartsHistoricalMap } from '../ProjectChartsHistoricalMap';
import { ProjectChartsRealtimeMap } from '../ProjectChartsRealtimeMap';

interface Props {
  dataMap?: IChartsMapDataResponse;
  realtime?: boolean;
  dataMapHistorical?: IHistoricalStatisticsCountryItemResponse[];
  historicalDataMapStatus?: string;
}

export const ProjectChartsMapBlock: FC<Props> = ({
  dataMap,
  realtime,
  dataMapHistorical,
  historicalDataMapStatus,
}) => {
  const [isVisibleRealtime, setIsVisibleRealtime] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisibleHistorical, setIsVisibleHistorical] = useState(false);
  const isEmptyData = useMemo(() => isEmpty(dataMap?.data?.POPs?.POPCode?.buckets), [dataMap]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    setIsLoading(true);

    if (realtime) {
      timeoutId = setTimeout(() => {
        setIsVisibleRealtime(true);
        setIsVisibleHistorical(false);
        setIsLoading(false);
      }, 400);
    } else if (historicalDataMapStatus === 'fulfilled') {
      timeoutId = setTimeout(() => {
        setIsVisibleRealtime(false);
        setIsVisibleHistorical(true);
        setIsLoading(false);
      }, 400);
    }

    return () => {
      clearTimeout(timeoutId);
      setIsVisibleRealtime(false);
      setIsVisibleHistorical(false);
      setIsLoading(true);
    };
  }, [realtime, historicalDataMapStatus]);

  return (
    <div className="ProjectChartsMapBlock">
      <div className="ProjectChartsMapBlock__left-block">
        <div className="ProjectChartsMapBlock__header">
          <Typography
            variant="h4"
            fontWeight={600}
            color="#2C3649"
            marginBottom={12}
            className="ProjectChartsMapBlock__title">
            Global PoP Traffic
          </Typography>
          {isLoading && (
            <div className="loader">
              <Loader />
            </div>
          )}
        </div>
        {realtime ? (
          <div
            className={classNames(
              'ProjectChartsMapBlock__block',
              { _disabled: isEmptyData },
              { _opacity: isVisibleRealtime }
            )}>
            <ProjectChartsRealtimeMap data={dataMap} isEmptyData={isEmptyData} />
          </div>
        ) : (
          <div
            className={classNames(
              'ProjectChartsMapBlock__block',
              { _realtime: !realtime },
              { _opacity: isVisibleHistorical }
            )}>
            <ProjectChartsHistoricalMap
              data={dataMapHistorical}
              historicalDataMapStatus={historicalDataMapStatus}
            />
          </div>
        )}
      </div>
      {realtime && (
        <div className="ProjectChartsMapBlock__right-block">
          <ProjectChartsBubble level={1} />
          <ProjectChartsBubble level={3} />
          <ProjectChartsBubble level={5} />
          <ProjectChartsBubble level={8} />
          <ProjectChartsBubble level={10} />
        </div>
      )}
    </div>
  );
};

export default ProjectChartsMapBlock;
