import './Notification.scss';
import React, { FC, ReactNode } from 'react';
import { notification } from 'antd';
import cn from 'classnames';
import { Icon } from '../Icon';

interface Props {
  title?: string | string[];
  message?: string | ReactNode;
  time?: string;
  duration?: number;
  type?: 'check' | 'cross' | 'priority-hight' | 'info';
  key?: string;
}

export const Notification: FC<Props> = ({
  title,
  message,
  time,
  duration = 5,
  type = 'check',
  key,
}) => {
  const Desctiption: FC = () => {
    return (
      <div className="Notification__description">
        <p className="Notification__text">{message}</p>
        <span className="Notification__date">{time}</span>
      </div>
    );
  };
  notification.open({
    key,
    message: (
      <h4 className={cn('Notification__title', { '_with-message': message })}>
        {title || 'Error'}
      </h4>
    ),
    description: <Desctiption />,
    icon: (
      <div
        className={cn('Notification__status-icon-wrapper', `_${type}`, {
          '_with-message': message,
        })}>
        <Icon name={type} className="Notification__status-icon" />
      </div>
    ),
    className: cn('Notification', {
      '_with-message': message,
    }),
    closeIcon: <Icon name="cross" className="Notification__close-icon" />,
    duration,
  });
  return null;
};

export default Notification;
