import React, { FC, useEffect, useMemo } from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import httpResponseCodesCheckboxes, { cacheStatusCheckboxes } from 'assets/data/checkboxes';
import { IChartData, TTimeDuration } from 'store/slices/project';
import { forEach, map } from 'lodash';
import { useParams } from 'react-router-dom';
import { chartConfigs } from '../configs';

ReactFC.fcRoot(FusionCharts, Widgets, FusionTheme);

interface IProjectChartsRealtimeHTTPProps {
  data?: IChartData;
  isHttp?: boolean;
  update?: number;
  selectedCdn?: string | number;
  timeDuration?: TTimeDuration;
}

export const ProjectChartsRealtimeHTTP: FC<IProjectChartsRealtimeHTTPProps> = ({
  data,
  isHttp,
  update,
  selectedCdn,
  timeDuration,
}) => {
  const { directoryName } = useParams<{ directoryName?: string }>();
  const config = useMemo(
    () =>
      chartConfigs({
        dataset: map(
          isHttp ? httpResponseCodesCheckboxes : cacheStatusCheckboxes,
          ({ name, color }) => ({
            seriesName: name,
            data: [{ value: 0, legendIconBgColor: color }],
          })
        ),
        id: isHttp ? 'realtimeHTTP' : 'realtimeCache',
        type: 'realtimeline',
        isMultiline: true,
        height: 260,
        cols: isHttp ? 5 : 3,
        numdisplaysets: timeDuration ? `${(timeDuration * 60) / 5}` : '60',
        plotToolText:
          '<b style="font-size: 14px;">$seriesName</b> - <span style="font-size: 14px;">$dataValue</span>',
        palettecolors: isHttp
          ? '3394dc, 549568, 0079d3, 055d9e, ec892e, fbc447, ecb22e, d8a430, e48139, ec892e, db5960, FF5630, DE350B, BF2600'
          : '0079d3, ecb22e, 549568, FFAB00, FF991F, FF8F73, FF7452, FF5630, DE350B',
      }),
    [isHttp, timeDuration]
  );

  useEffect(() => {
    const chartRef = FusionCharts(isHttp ? 'realtimeHTTP' : 'realtimeCache');
    if (selectedCdn && chartRef.clearChart && directoryName) {
      chartRef.clearChart();
    }
  }, [isHttp, selectedCdn, directoryName]);
  useEffect(() => {
    if (data && update) {
      const chartRef = FusionCharts(isHttp ? 'realtimeHTTP' : 'realtimeCache');
      if (chartRef && chartRef.feedData) {
        const values = map(
          isHttp ? httpResponseCodesCheckboxes : cacheStatusCheckboxes,
          ({ value }) => value
        );
        let str = '';
        forEach(values, (item) => {
          if (str.length === 0) {
            str = data.data[item].value as string;
          }
          str = `${str}|${data.data[item].value}`;
        });
        const strData = `&label=${data.date}&value=${str}`;
        chartRef.feedData(strData);
      }
    }
  }, [data, isHttp, update]);

  return <ReactFC {...config} />;
};

export default ProjectChartsRealtimeHTTP;
