import { createApi } from '@reduxjs/toolkit/query/react';
import { IChartsDataResponse, IChartsMapDataResponse } from 'models';
import moment from 'moment';
import { IChartData } from 'store/slices/project';
import { endpoints } from '../urls';
import { baseQuery } from './base';

export type EventType = 'BW' | 'HttpStatus' | 'CacheStatus' | 'CONN';

export interface IRealtimeStatisticPayload {
  dirrectoryName: string;
  edge_id: string | number;
  event?: EventType;
}

export interface IRealtimeStatisticPayloadMap {
  dirrectoryName: string;
  edge_id: string | number;
}

export const realtimeStatisticApi = createApi({
  reducerPath: 'realtimeStatisticApi',
  baseQuery: baseQuery(),
  tagTypes: ['Realtime', 'RealtimeMap', 'RealtimeNew', 'RealtimeAll'],
  endpoints: (build) => ({
    fetchRealtimeStatistic: build.query<IChartsDataResponse, IRealtimeStatisticPayload>({
      query: (payload) => ({
        url: endpoints.realtimeStatistic(payload),
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: (_result, _error, arg) => [{ type: 'Realtime', id: arg.dirrectoryName }],
    }),
    fetchRealtimeStatisticMap: build.query<IChartsMapDataResponse, IRealtimeStatisticPayloadMap>({
      query: (payload) => ({
        url: endpoints.realtimeStatisticMap(payload),
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: (_result, _error, arg) => [{ type: 'RealtimeMap', id: arg.dirrectoryName }],
    }),
    fetchRealtimeStatisticAll: build.query<IChartData, IRealtimeStatisticPayload>({
      query: (payload) => ({
        url: endpoints.realtimeStatisticAll(payload),
      }),
      transformResponse: (response: IChartsDataResponse) => {
        const values = response?.data?.data;
        const mapedData = {
          date: moment(response?.data?.timestamp).format('HH:mm'),
          data: { ...values },
        };
        return mapedData as IChartData;
      },
      providesTags: (_result, _error, arg) => [{ type: 'RealtimeAll', id: arg.dirrectoryName }],
    }),
  }),
});

export default realtimeStatisticApi;
