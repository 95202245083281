import { createApi } from '@reduxjs/toolkit/query/react';
import { IServicesResponse, UserBillingReportsResponse } from 'models';
import { endpoints } from '../urls';
// import { baseQuery } from './base';
import { baseQuery } from './baseV2';

export const userDetailsApiV2 = createApi({
  reducerPath: 'userDetailsApiV2',
  baseQuery: baseQuery(),
  tagTypes: ['LastViewed', 'Favorites', 'Service'],
  endpoints: (build) => ({
    fetchReports: build.query<UserBillingReportsResponse, { start_date: string; end_date: string }>(
      {
        query: ({ end_date, start_date }) => ({
          url: endpoints.v2.account.billingReports,
          params: {
            start_date,
            end_date,
          },
        }),
      }
    ),
    fetchLastViewed: build.query<{ data: IServicesResponse }, string>({
      query: () => ({
        url: endpoints.v2.account.getLastViewedProperties,
      }),
      providesTags: () => ['LastViewed'],
    }),

    fetchFavorites: build.query<{ data: IServicesResponse }, string>({
      query: () => ({
        url: endpoints.v2.favorites.getAllFavorites,
      }),
      providesTags: () => ['Favorites'],
    }),
    addFavorite: build.mutation<
      {
        success: boolean;
        message: string;
      },
      string
    >({
      query: (propertyId) => ({
        url: endpoints.v2.favorites.addToFavorites(propertyId),
        method: 'POST',
      }),
      invalidatesTags: ['Service'],
    }),
    removeFavorite: build.mutation<
      {
        success: boolean;
        message: string;
      },
      string
    >({
      query: (propertyId) => ({
        url: endpoints.v2.favorites.removeFromFavorites(propertyId),
        method: 'POST',
      }),
      invalidatesTags: ['Service'],
    }),
  }),
});

export default userDetailsApiV2;
