/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import { IField } from './fieldsObjects';

export const generalComment: IField[] = [
  {
    id: 0,
    type: 'input',
    fieldName: 'value',
    label: 'Value',
  },
];

export default generalComment;
