export interface ListItemType {
  name: string;
  label: string;
  tooltip: string;
}

export const adnListOptions: ListItemType[] = [
  { name: 'auth_user', label: '@auth_user:', tooltip: 'Auth User' },
  {
    name: 'background_fill_wait_time',
    label: '@background_fill_wait_time:',
    tooltip: 'Background Fill Wait Time',
  },
  { name: 'bytes_in', label: '@bytes_in:', tooltip: 'Bytes In' },
  { name: 'bytes_out', label: '@bytes_out:', tooltip: 'Bytes Out' },
  { name: 'cache_status', label: '@cache_status:', tooltip: 'Cache Status' },
  { name: 'client_as_org', label: '@client_as_org:', tooltip: 'Client As Org' },
  { name: 'client_asn', label: '@client_asn:', tooltip: 'Client Asn' },
  { name: 'client_city', label: '@client_city:', tooltip: 'Client City' },
  {
    name: 'client_continent_code',
    label: '@client_continent_code:',
    tooltip: 'Client Continent Code',
  },
  { name: 'client_country_code', label: '@client_country_code:', tooltip: 'Client Country Code' },
  { name: 'client_geo_latitude', label: '@client_geo_latitude:', tooltip: 'Client Geo Latitude' },
  {
    name: 'client_geo_longitude',
    label: '@client_geo_longitude:',
    tooltip: 'Client Geo Longitude',
  },
  { name: 'client_ip', label: '@client_ip:', tooltip: 'Client Ip' },
  { name: 'client_ip_version', label: '@client_ip_version:', tooltip: 'Client Ip Version' },
  { name: 'client_isp', label: '@client_isp:', tooltip: 'Client Isp' },
  { name: 'client_port', label: '@client_port:', tooltip: 'Client Port' },
  { name: 'client_protocol', label: '@client_protocol:', tooltip: 'Client Protocol' },
  { name: 'client_region', label: '@client_region:', tooltip: 'Client Region' },
  { name: 'client_tls_cipher', label: '@client_tls_cipher:', tooltip: 'Client Tls Cipher' },
  { name: 'client_tls_ja3_md5', label: '@client_tls_ja3_md5:', tooltip: 'Client Tls Ja3 Md5' },
  { name: 'client_tls_version', label: '@client_tls_version:', tooltip: 'Client Tls Version' },
  { name: 'created_at', label: '@created_at:', tooltip: 'Created At' },
  { name: 'file_size', label: '@file_size:', tooltip: 'File Size' },
  { name: 'first_byte_served', label: '@first_byte_served:', tooltip: 'First Byte Served' },
  { name: 'host', label: '@host:', tooltip: 'Host' },
  { name: 'last_byte_served', label: '@last_byte_served:', tooltip: 'Last Byte Served' },
  { name: 'method', label: '@method:', tooltip: 'Method' },
  { name: 'total_time', label: '@total_time:', tooltip: 'Total Time' },
  { name: 'path', label: '@path:', tooltip: 'Path' },
  { name: 'platform', label: '@platform:', tooltip: 'Platform' },
  { name: 'pop', label: '@pop:', tooltip: 'Pop' },
  { name: 'prewrite_time', label: '@prewrite_time:', tooltip: 'Prewrite Time' },
  { name: 'proxy_ip', label: '@proxy_ip:', tooltip: 'Proxy Ip' },
  { name: 'proxy_port', label: '@proxy_port:', tooltip: 'Proxy Port' },
  { name: 'proxy_type', label: '@proxy_type:', tooltip: 'Proxy Type' },
];

export const wafListOptions: ListItemType[] = [
  { name: 'action_type', label: '@action_type:', tooltip: 'Action Type' },
  { name: 'client_city', label: '@client_city:', tooltip: 'Client City' },
  { name: 'client_country', label: '@client_country:', tooltip: 'Client Country' },
  { name: 'client_country_code', label: '@client_country_code:', tooltip: 'Client Country Code' },
  { name: 'client_ip', label: '@client_ip:', tooltip: 'Client Ip' },
  { name: 'client_tls_ja3_md5', label: '@client_tls_ja3_md5:', tooltip: 'Client Tls Ja3 Md5' },
  { name: 'created_at', label: '@created_at:', tooltip: 'Created At' },
  { name: 'host', label: '@host:', tooltip: 'Host' },
  { name: 'referer', label: '@referer:', tooltip: 'Referer' },
  { name: 'rule_message', label: '@rule_message:', tooltip: 'Rule Message' },
  { name: 'server_port', label: '@server_port:', tooltip: 'Server Port' },
  { name: 'sub_events_count', label: '@sub_events_count:', tooltip: 'Sub Events Count' },
  { name: 'timestamp', label: '@timestamp:', tooltip: 'Timestamp' },
  { name: 'total_time', label: '@total_time:', tooltip: 'Total Time' },
  { name: 'url', label: '@url:', tooltip: 'URL' },
  { name: 'user_agent', label: '@user_agent:', tooltip: 'User Agent' },
  { name: 'uuid', label: '@uuid:', tooltip: 'UUID' },
  { name: 'waf_instance_name', label: '@waf_instance_name:', tooltip: 'WAF Instance Name' },
  { name: 'waf_profile_name', label: '@waf_profile_name:', tooltip: 'WAF Profile Name' },
  { name: 'waf_profile_type', label: '@waf_profile_type:', tooltip: 'WAF Profile Type' },
];

export const rlListOptions: ListItemType[] = [
  { name: 'client_city', label: '@client_city:', tooltip: 'Client City' },
  { name: 'client_country', label: '@client_country:', tooltip: 'Client Country' },
  { name: 'client_country_code', label: '@client_country_code:', tooltip: 'Client Country Code' },
  { name: 'client_ip', label: '@client_ip:', tooltip: 'Client Ip' },
  { name: 'client_tls_ja3_md5', label: '@client_tls_ja3_md5:', tooltip: 'Client Tls Ja3 Md5' },
  { name: 'created_at', label: '@created_at:', tooltip: 'Created At' },
  { name: 'host', label: '@host:', tooltip: 'Host' },
  {
    name: 'limit_action_duration',
    label: '@limit_action_duration:',
    tooltip: 'Limit Action Duration',
  },
  {
    name: 'limit_action_percentage',
    label: '@limit_action_percentage:',
    tooltip: 'Limit Action Percentage',
  },
  { name: 'limit_action_type', label: '@limit_action_type:', tooltip: 'Limit Action Type' },
  { name: 'limit_id', label: '@limit_id:', tooltip: 'Limit ID' },
  { name: 'limit_name', label: '@limit_name:', tooltip: 'Limit Name' },
  {
    name: 'limit_start_timestamp',
    label: '@limit_start_timestamp:',
    tooltip: 'Limit Start Timestamp',
  },
  { name: 'method', label: '@method:', tooltip: 'Method' },
  { name: 'referer', label: '@referer:', tooltip: 'Referer' },
  { name: 'scope_id', label: '@scope_id:', tooltip: 'Scope ID' },
  { name: 'scope_name', label: '@scope_name:', tooltip: 'Scope Name' },
  { name: 'total_time', label: '@total_time:', tooltip: 'Total Time' },
  { name: 'timestamp', label: '@timestamp:', tooltip: 'Timestamp' },
  { name: 'url', label: '@url:', tooltip: 'URL' },
  { name: 'user_agent', label: '@user_agent:', tooltip: 'User Agent' },
  { name: 'uuid', label: '@uuid:', tooltip: 'UUID' },
];

export const botListOptions: ListItemType[] = [
  { name: 'action_type', label: '@action_type:', tooltip: 'Action Type' },
  { name: 'bot_manager_id', label: '@bot_manager_id:', tooltip: 'Bot Manager ID' },
  { name: 'bot_manager_name', label: '@bot_manager_name:', tooltip: 'Bot Manager Name' },
  { name: 'bot_rule_config_id', label: '@bot_rule_config_id:', tooltip: 'Bot Rule Config ID' },
  {
    name: 'bot_rule_config_name',
    label: '@bot_rule_config_name:',
    tooltip: 'Bot Rule Config Name',
  },
  { name: 'bot_score', label: '@bot_score:', tooltip: 'Bot Score' },
  { name: 'captcha_error_msg', label: '@captcha_error_msg:', tooltip: 'Captcha Error Message' },
  { name: 'captcha_score', label: '@captcha_score:', tooltip: 'Captcha Score' },
  { name: 'captcha_status', label: '@captcha_status:', tooltip: 'Captcha Status' },
  { name: 'challenge_status', label: '@challenge_status:', tooltip: 'Challenge Status' },
  { name: 'client_city', label: '@client_city:', tooltip: 'Client City' },
  { name: 'client_country', label: '@client_country:', tooltip: 'Client Country' },
  { name: 'client_country_code', label: '@client_country_code:', tooltip: 'Client Country Code' },
  { name: 'client_ip', label: '@client_ip:', tooltip: 'Client IP' },
  { name: 'client_tls_ja3_md5', label: '@client_tls_ja3_md5:', tooltip: 'Client TLS Ja3 MD5' },
  { name: 'created_at', label: '@created_at:', tooltip: 'Created At' },
  { name: 'host', label: '@host:', tooltip: 'Host' },
  { name: 'matched_on', label: '@matched_on:', tooltip: 'Matched On' },
  { name: 'matched_value', label: '@matched_value:', tooltip: 'Matched Value' },
  { name: 'method', label: '@method:', tooltip: 'Method' },
  { name: 'referer', label: '@referer:', tooltip: 'Referer' },
  { name: 'rule_id', label: '@rule_id:', tooltip: 'Rule ID' },
  { name: 'rule_msg', label: '@rule_msg:', tooltip: 'Rule Message' },
  { name: 'sam_id', label: '@sam_id:', tooltip: 'SAM ID' },
  { name: 'sam_name', label: '@sam_name:', tooltip: 'SAM Name' },
  { name: 'total_time', label: '@total_time:', tooltip: 'Total Time' },
  { name: 'timestamp', label: '@timestamp:', tooltip: 'Timestamp' },
  { name: 'token_validity', label: '@token_validity:', tooltip: 'Token Validity' },
  { name: 'url', label: '@url:', tooltip: 'URL' },
  { name: 'user_agent', label: '@user_agent:', tooltip: 'User Agent' },
  { name: 'uuid', label: '@uuid:', tooltip: 'UUID' },
];
