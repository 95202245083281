import { HTTP_2, HTTP_3, HTTP_4, HTTP_5, HTTP_OTHER } from 'helpers/httpStatusCodes';

export const httpResponseCodesCheckboxes = [
  {
    name: 'Total/rps',
    value: 'HITS',
    color: '3394dc',
  },
  {
    name: '2xx/rps',
    value: HTTP_2,
    color: '549568',
  },
  {
    name: '3xx/rps',
    value: HTTP_3,
    color: '0079d3',
  },
  {
    name: '304/rps',
    value: 'http_304',
    color: '055d9e',
  },
  {
    name: '4xx/rps',
    value: HTTP_4,
    color: 'ec892e',
  },
  {
    name: '403/rps',
    value: 'http_403',
    color: 'ecb22e',
  },
  {
    name: '404/rps',
    value: 'http_404',
    color: 'd8a430',
  },
  {
    name: '5xx/rps',
    value: HTTP_5,
    color: 'db5960',
  },
  {
    name: 'Other/rps',
    value: HTTP_OTHER,
    color: '9ac6a9',
  },
];

export const httpResponseCodesCheckboxesHistorical = [
  // {
  //   name: 'Hits',
  //   value: 'HITS',
  //   color: '#1a59d9',
  // },
  // {
  //   name: '1xx',
  //   value: '1xx',
  //   color: '#9ac6a9',
  // },
  {
    name: '2xx',
    value: 'status_2xx',
    color: '#549568',
  },
  {
    name: '3xx',
    value: 'status_3xx',
    color: '#ab61c5',
  },
  {
    name: '4xx',
    value: 'status_4xx',
    color: '#97b716',
  },
  {
    name: '5xx',
    value: 'status_5xx',
    color: '#57bddd',
  },
  // {
  //   name: 'Other',
  //   value: 'Other',
  //   color: '#fbc447',
  // },
  // {
  //   name: 'Unassigned',
  //   value: 'Unassigned',
  //   color: '#d8a430',
  // },
];

export const cacheStatusesCheckboxesHistorical = [
  {
    name: 'Cache Hits',
    value: 'cache_hit',
    color: '#1a59d9',
  },
  {
    name: 'Misses',
    value: 'cache_miss',
    color: '#549568',
  },
  {
    name: 'Expired Hits',
    value: 'tcp_expired_hit',
    color: '#ab61c5',
  },
  {
    name: 'Expired Miss',
    value: 'tcp_expired_miss',
    color: '#97b716',
  },
  {
    name: 'Partial Hit',
    value: 'tcp_partial_hit',
    color: '#57bddd',
  },
  {
    name: 'Partial Miss',
    value: 'tcp_partial_miss',
    color: '#9ac6a9',
  },
  {
    name: 'Mem Hit',
    value: 'tcp_mem_hit',
    color: '#fbc447',
  },
  {
    name: 'No Cache',
    value: 'config_nocache',
    color: '#d8a430',
  },
  {
    name: 'Uncacheable',
    value: 'uncacheable',
    color: '#57bddd',
  },
];

export const cacheStatusCheckboxes = [
  {
    name: 'Total/rps',
    value: 'HITS',
    color: '#0079d3',
  },
  {
    name: 'Config No Cache/rps',
    value: 'cache_cfg-nocache',
    color: '#ecb22e',
  },
  {
    name: 'Hit/rps',
    value: 'cache_hit',
    color: '#549568',
  },
  {
    name: 'None/rps',
    value: 'cache_miss',
    color: '#FFAB00',
  },
  {
    name: 'Uncacheable/rps',
    value: 'cache_not-cacheable',
    color: '#FF991F',
  },
];

export default httpResponseCodesCheckboxes;
