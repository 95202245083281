import { LogsChartData } from 'models';
import React, { FC, useMemo, useRef } from 'react';
import FusionCharts from 'fusioncharts';
import cn from 'classnames';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from 'react-fusioncharts';
import { map, range, round, size } from 'lodash';
import moment, { DurationInputArg2 } from 'moment';
import { useAppDispatch } from 'store';
import { setIsUsingCustomRange } from 'store/slices/project';
import { updateSelectedCustomLogsPeriod } from 'store/slices/logs';
import styles from '../ProjectLogsViewList.module.scss';

charts(FusionCharts);

export const ProjectLogsViewListBarChart: FC<{
  data: LogsChartData[];
  interval: string;
  isLoading: boolean;
  handleChartHistory: (preInterval: [number, number]) => void;
}> = ({ data, interval, handleChartHistory, isLoading }) => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const clickHandlerRef = useRef<(event: any, dataObject: any) => void>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clickHandlerRef.current = (event: any, dataObject: any) => {
    if (!interval) return;
    dispatch(setIsUsingCustomRange(true));
    const splitedPeriod = (interval as string).split('');
    const periodUnit = splitedPeriod.pop();
    const periodQuantity = Number(splitedPeriod.join(''));
    const newDates: [number, number] = [
      moment(dataObject.id).valueOf(),
      moment(dataObject.id)
        .add(periodQuantity, periodUnit as DurationInputArg2)
        .valueOf(),
    ];

    handleChartHistory(newDates);
    dispatch(updateSelectedCustomLogsPeriod(newDates));
  };

  // eslint-disable-next-line consistent-return
  const chartConfig = useMemo(() => {
    if (data) {
      const minColumn = 56;
      const isFillEmptyData = size(data) < minColumn;
      const mapedData = map(data, ({ doc_count, key_as_string }) => {
        return {
          label: moment(key_as_string).format('YYYY-MM-DD HH:mm:ss'),
          value: doc_count,
        };
      });
      const result = () => {
        if (isFillEmptyData) {
          const emptyValuesArray = map(range(minColumn), () => ({ label: '', value: 0 }));
          emptyValuesArray.splice(
            round(minColumn / 2) - round(mapedData.length / 2),
            mapedData.length,
            ...mapedData
          );
          return emptyValuesArray;
        }
        return mapedData;
      };
      return {
        dataFormat: 'JSON',
        dataSource: {
          chart: {
            animation: '0',
            animationDuration: 0.3,
            theme: 'fusion',
            palettecolors: '#9AC6A9',
            baseFont: 'Assistant',
            baseFontColor: '#90A0B7',
            baseFontSize: '10',
            toolTipBGColor: '#fff',
            toolTipBorderColor: '#fff',
            toolTipBorderRadius: '4',
            toolTipColor: '#445A84',
            canvasbgColor: '#cddce7,#ffffff',
            canvasbgAlpha: '15',
            canvasBgAngle: '90',
            showToolTipShadow: 1,
            numDivLines: 0,
            xAxisValuesStep: size(result()) / 20,
            plotToolText: `<div style="padding: 4px"><strong>Total: $dataValue</strong><br>${interval} @ $label</div>`,
          },
          data: result().reverse(),
        },
        events: {
          // Attach to beforeInitialize
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dataPlotClick: clickHandlerRef.current,
        },
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div
      style={{ height: 135 }}
      className={cn(styles.wrapperChart, {
        [styles.origin]: !isLoading,
        [styles.grayed]: isLoading,
      })}>
      {chartConfig && (
        <ReactFusioncharts type="column2d" width="100%" height="150" {...chartConfig} />
      )}
    </div>
  );
};

export default ProjectLogsViewListBarChart;
