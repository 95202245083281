/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Switch from 'components/common/Switch';
import { Col, InputNumberProps, Row, Slider, SliderSingleProps } from 'antd';
import { IPropertySecurityConfigurationData, IPropertySecurityProfilesUpdate } from 'models';
import cn from 'classnames';
import Button from 'components/common/Button';
import Radio from 'components/common/Radio';
import { Icon } from 'components/common/Icon';
import Modal from 'components/common/Modal';
import { Drawer } from 'components/common/Drawer';
import styles from './ProjectOriginSecurityView.module.scss';
import './ProjectOriginSecurityView.scss';

interface Props {
  isPageDisabled: boolean;
  hostname: string;
  configurationData: IPropertySecurityConfigurationData;
  getUpdatedData: (value: IPropertySecurityProfilesUpdate) => void;
}

export const SecurityItem: FC<Props> = ({
  isPageDisabled,
  hostname,
  configurationData,
  getUpdatedData,
}) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [wafValue, setWafValue] = useState<boolean>(configurationData.waf);
  const [rateLimiting, setRateLimiting] = useState<boolean>(configurationData.ddos_protection);
  const [botManagement, setBotManagement] = useState<boolean>(configurationData.bot_protection);
  const [botStrategy, setBotStrategy] = useState<string>(configurationData.bot_protection_option);
  const [rateLimStrategy, setRateLimStrategy] = useState<string>(
    configurationData.ddos_protection_option
  );
  const [rateLimitingValue, setRLValue] = useState(0);
  const [botProtectionValue, setBPValue] = useState(0);
  const [visibleDrawer, setVisibleDrawe] = useState(false);

  const handleUpdateData = useCallback(() => {
    const data: IPropertySecurityProfilesUpdate = {
      hostname,
      options: {
        waf: wafValue,
        ddos_protection: rateLimiting,
        ddos_protection_option: rateLimStrategy,
        bot_protection: botManagement,
        bot_protection_option: botStrategy,
      },
    };

    getUpdatedData(data);
    setVisibleDrawe(false);
  }, [
    botManagement,
    botStrategy,
    getUpdatedData,
    hostname,
    rateLimStrategy,
    rateLimiting,
    wafValue,
  ]);

  useEffect(() => {
    setWafValue(configurationData.waf);
    setRateLimiting(configurationData.ddos_protection);
    setBotManagement(configurationData.bot_protection);
    setRateLimStrategy(configurationData.ddos_protection_option || 'strategy_1');
    setBotStrategy(configurationData.bot_protection_option || 'bot_score_90');
  }, [
    configurationData.bot_protection,
    configurationData.bot_protection_option,
    configurationData.ddos_protection,
    configurationData.ddos_protection_option,
    configurationData.waf,
  ]);

  const onChangeWAF = (value: boolean) => {
    setWafValue(value);
  };
  const onChangeRateLim = (value: boolean) => {
    setRateLimiting(value);
  };
  const onChangeBotStrategy = (value: boolean) => {
    setBotManagement(value);
  };

  const showDrawer = useCallback(() => {
    setVisibleDrawe(true);
  }, []);

  const onClose = useCallback(() => {
    setVisibleDrawe(false);
    setWafValue(configurationData.waf);
    setRateLimiting(configurationData.ddos_protection);
    setBotManagement(configurationData.bot_protection);
    setBotStrategy(configurationData.bot_protection_option);
    setRateLimStrategy(configurationData.ddos_protection_option);
  }, [
    configurationData.bot_protection,
    configurationData.bot_protection_option,
    configurationData.ddos_protection,
    configurationData.ddos_protection_option,
    configurationData.waf,
  ]);

  const SwitchComp = useCallback((isOn: boolean) => {
    return (
      <div className="switchWrapper">
        <div className={isOn ? 'switchGreenCirle' : 'switchRedCirle'} />
        <div>
          <span className="switchText">{isOn ? 'On' : 'Off'}</span>
        </div>
      </div>
    );
  }, []);

  const rederDrawerFooter = useCallback(
    () => (
      <Col span={24} style={{ padding: '22px 16px' }}>
        <Row justify="space-between">
          <Col>
            <Button type="secondary" onClick={() => onClose()}>
              <span style={{ minWidth: 82, display: 'block' }}>Cancel</span>
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={() => handleUpdateData()}>
              <span style={{ minWidth: 82, display: 'block' }}>Save Changes</span>
            </Button>
          </Col>
        </Row>
      </Col>
    ),
    [handleUpdateData, onClose]
  );

  const getRLValue = useCallback((number: number) => {
    switch (number) {
      case 0.1:
        return 'strategy_1';

      case 1:
        return 'strategy_2';

      case 1.9:
        return 'strategy_3';

      default:
        return 'strategy_1';
    }
  }, []);

  const getRLTextBlock = useCallback((number: number) => {
    switch (number) {
      case 0.1:
        return 'strategy_1 text block strategy_1 text block strategy_1 text block strategy_1 text block strategy_1 text block strategy_1 text block ';

      case 1:
        return 'strategy_2 text block strategy_2 text block strategy_2 text block strategy_2 text block ';

      case 1.9:
        return 'strategy_3 text block strategy_3 text block strategy_3 text block strategy_3 text block strategy_3 text block ';

      default:
        return 'strategy_1 text block strategy_1 text block strategy_1 text block strategy_1 text block strategy_1 text block strategy_1 text block ';
    }
  }, []);

  const getBPValue = useCallback((number: number) => {
    switch (number) {
      case 0.1:
        return 'bot_score_30';

      case 1:
        return 'bot_score_60';

      case 1.9:
        return 'bot_score_90';

      case 3:
        return 'always_challenge';

      default:
        return 'bot_score_90';
    }
  }, []);
  const getBPVTextBlock = useCallback((number: number) => {
    switch (number) {
      case 0.1:
        return 'bot_score_30 text block bot_score_30 text block bot_score_30 text block ';

      case 1:
        return 'bot_score_60 text block bot_score_60 text block bot_score_60 text block bot_score_60 text block bot_score_60 text block ';

      case 1.9:
        return 'bot_score_90 text block bot_score_90 text block bot_score_90 text block bot_score_90 text block ';

      case 3:
        return 'bot_score_90 text block bot_score_90 text block bot_score_90 text block bot_score_90 text block ';

      default:
        return 'bot_score_90 text block bot_score_90 text block bot_score_90 text block bot_score_90 text block ';
    }
  }, []);

  const getRLNum = useMemo(() => {
    switch (rateLimStrategy) {
      case 'strategy_1':
        return 0.1;

      case 'strategy_2':
        return 1;

      case 'strategy_3':
        return 1.9;

      default:
        return 0.1;
    }
  }, [rateLimStrategy]);

  const getBPNum = useMemo(() => {
    switch (botStrategy) {
      case 'bot_score_90':
        return 1.9;

      case 'bot_score_60':
        return 1;

      case 'bot_score_30':
        return 0.1;

      case 'always_challenge':
        return 3;

      default:
        return 1.9;
    }
  }, [botStrategy]);

  const onChangeRL: InputNumberProps['onChange'] = (newValue) => {
    let value = 0.1;

    if (Number(newValue) < 0.5) {
      value = 0.1;
    } else if (Number(newValue) > 0.5 && Number(newValue) < 1.4) {
      value = 1;
    } else if (Number(newValue) > 1.4) {
      value = 1.9;
    }

    setRLValue(value);
    setRateLimStrategy(getRLValue(value));
  };
  const onChangeBP: InputNumberProps['onChange'] = (newValue) => {
    let value = 0.1;

    if (Number(newValue) < 0.5) {
      value = 0.1;
    } else if (Number(newValue) > 0.5 && Number(newValue) < 1.4) {
      value = 1;
    } else if (Number(newValue) > 1.4 && Number(newValue) < 2.1) {
      value = 1.9;
    } else if (Number(newValue) > 2.1) {
      value = 3;
    }

    setBPValue(Number(value));
    setBotStrategy(getBPValue(Number(value)));
  };

  useEffect(() => {
    setRLValue(getRLNum);
    setBPValue(getBPNum);
  }, [getBPNum, getRLNum]);

  const marksRL: SliderSingleProps['marks'] = {
    0.1: {
      style: {
        color: rateLimitingValue === 0.1 ? '#1A59D9' : '#445A84',
        left: '6px',
        fontWeight: 600,
      },
      label: <span>Low</span>,
    },
    1: {
      style: {
        color: rateLimitingValue === 1 ? '#1A59D9' : '#445A84',
        fontWeight: 600,
      },
      label: <span>Medium</span>,
    },
    1.9: {
      style: {
        color: rateLimitingValue === 1.9 ? '#1A59D9' : '#445A84',
        fontWeight: 600,
        left: '97%',
      },
      label: <span>High</span>,
    },
  };
  const marksBP: SliderSingleProps['marks'] = {
    0.1: {
      style: {
        color: botProtectionValue === 0.1 ? '#1A59D9' : '#445A84',
        fontWeight: 600,
        left: '6px',
      },
      label: <span>Low</span>,
    },
    1: {
      style: {
        color: botProtectionValue === 1 ? '#1A59D9' : '#445A84',
        fontWeight: 600,
      },
      label: <span>Medium</span>,
    },
    1.9: {
      style: {
        color: botProtectionValue === 1.9 ? '#1A59D9' : '#445A84',
        fontWeight: 600,
        left: '97%',
      },
      label: <span>High</span>,
    },
  };

  return (
    <Row key={hostname} align="middle" className="ProjectOriginSecurityView__table-row">
      <Modal
        visible={isVisibleModal}
        title="Warning"
        onCancel={() => setIsVisibleModal(false)}
        width="400px">
        <div>
          You need to deploy the changes first and after you can update the Security Profiles.
        </div>
      </Modal>
      <Drawer
        className="ProjectOriginSecurityView__drawer"
        placement="right"
        onClose={onClose}
        open={visibleDrawer}
        mask={false}
        width={300}
        style={{ top: 0, bottom: 0, height: '100vh' }}
        contentWrapperStyle={{ boxShadow: '-5px 0px 30px rgba(25, 42, 62, 0.08' }}
        headerStyle={{ padding: '14px 24px', borderBottom: '1px solid #EBEFF2' }}
        closeIcon={<Icon name="cross" className="ProjectOriginSecurityView__drawer-icon" />}
        headerTitle={<span className="ProjectOriginSecurityView__drawer-title">{hostname}</span>}
        bodyStyle={{ padding: 0 }}
        footer={rederDrawerFooter()}>
        <Col className="drawerContentWrapper" span={24}>
          <Row className="drawerContentRow" align="middle" justify="space-between">
            <Col>
              <span style={{ fontSize: '14px', fontWeight: 600, color: '#445A84' }}>WAF</span>
            </Col>

            <Col>
              <Switch checked={wafValue} onChange={onChangeWAF} />
            </Col>
          </Row>

          <Row className="drawerContentRow" align="middle" justify="space-between">
            <Col>
              <span style={{ fontSize: '14px', fontWeight: 600, color: '#445A84' }}>
                RATE LIMITING
              </span>
            </Col>
            <Col>
              <Switch checked={rateLimiting} onChange={onChangeRateLim} />
            </Col>

            <Col
              span={24}
              className={cn('ProjectOriginSecurityView__additionalRateLimiting', {
                _disabled: !rateLimiting,
              })}>
              <Col span={24}>
                <Slider
                  className="ProjectOriginSecurityView__slider"
                  marks={marksRL}
                  included={false}
                  min={0}
                  max={2}
                  step={1}
                  value={rateLimitingValue}
                  tooltip={{ open: false }}
                  handleStyle={{
                    borderColor: '#1A59D9',
                    backgroundColor: '#1A59D9',
                  }}
                  onChange={onChangeRL}
                />
              </Col>
              <div
                style={{
                  border: '1px solid #90A0B7',
                  borderRadius: 8,
                  padding: 8,
                  width: '100%',
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#90A0B7',
                }}>
                {getRLTextBlock(rateLimitingValue)}
              </div>
            </Col>
          </Row>

          <Row className="drawerContentRow" align="middle" justify="space-between">
            <Col>
              <span style={{ fontSize: '14px', fontWeight: 600, color: '#445A84' }}>
                BOT PROTECTION
              </span>
            </Col>
            <Col>
              <Switch checked={botManagement} onChange={onChangeBotStrategy} />
            </Col>
            <Col
              span={24}
              className={cn('ProjectOriginSecurityView__additionalBotManagement', {
                _disabled: !botManagement,
              })}>
              <Col span={24} style={{ marginTop: 14, opacity: botProtectionValue > 2 ? 0.9 : 1 }}>
                <Radio
                  value={1.9}
                  onChange={(v) => {
                    // onChangeBP(Number(v));
                    setBPValue(Number(v));
                    setBotStrategy(getBPValue(Number(v)));
                  }}
                  checked={botProtectionValue < 2}
                  name="Bot Score">
                  Bot Score
                </Radio>
                <div style={{ fontSize: '12px', lineHeight: '13px', color: '#445A84' }}>
                  Bot Score evaluates each visitor&apos;s likelihood of being a bot based on their
                  behavior, assigning a score. Higher scores indicate a lower bot risk. Set
                  thresholds to filter access and protect your site from automated threats.
                </div>
              </Col>
              <Col span={24}>
                <Slider
                  className="ProjectOriginSecurityView__slider"
                  disabled={botProtectionValue > 1.9}
                  marks={marksBP}
                  included={false}
                  min={0}
                  max={2}
                  // dots={false}
                  step={1}
                  value={botProtectionValue}
                  tooltip={{ open: false }}
                  // railStyle={{ display: 'none' }}
                  trackStyle={{ backgroundColor: 'red' }}
                  handleStyle={{
                    borderColor: botProtectionValue > 1.9 ? '#fff' : '#1A59D9',
                    backgroundColor: botProtectionValue > 1.9 ? '#fff' : '#1A59D9',
                    display: botProtectionValue > 1.9 ? 'none' : 'block',
                  }}
                  onChange={onChangeBP}
                />
              </Col>
              <div
                style={{
                  border: '1px solid #90A0B7',
                  borderRadius: 8,
                  padding: 8,
                  width: '100%',
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#90A0B7',
                }}>
                {getBPVTextBlock(botProtectionValue)}
              </div>
              <Col span={24} style={{ marginTop: 14 }}>
                <Radio
                  value={3}
                  onChange={(v) => {
                    // onChangeBP(Number(v));
                    setBPValue(Number(v));
                    setBotStrategy(getBPValue(Number(v)));
                  }}
                  checked={botProtectionValue > 2}
                  name="Always Challenge">
                  Always Challenge
                </Radio>
                <div style={{ fontSize: '12px', lineHeight: '13px', color: '#445A84' }}>
                  Always Challenge requires all users to verify their legitimacy, regardless of
                  their bot score. This ensures maximum protection by challenging every visitor
                  before granting access.
                </div>
              </Col>
            </Col>
          </Row>
        </Col>
      </Drawer>
      <Col span={8} className="ProjectOriginSecurityView__head-col _ta-left">
        <div className="ProjectOriginSecurityView__hostname-column">{hostname}</div>
      </Col>
      <Col span={5} className="ProjectOriginSecurityView__head-col _ta_left">
        <Row className={styles.row} align="middle" gutter={16}>
          {SwitchComp(wafValue)}
        </Row>
      </Col>
      <Col span={5} className="ProjectOriginSecurityView__head-col _ta_left">
        <Row className={styles.row} align="middle" gutter={16}>
          {SwitchComp(rateLimiting)}
        </Row>
      </Col>
      <Col span={5} className="ProjectOriginSecurityView__head-col _ta_left">
        <Row className={styles.row} align="middle" gutter={16}>
          {SwitchComp(botManagement)}
        </Row>
      </Col>
      <Col span={1} className="ProjectOriginSecurityView__head-col _ta-right">
        <Row className={styles.row} align="middle" gutter={16}>
          <Icon
            name="settings"
            onClick={() => (isPageDisabled ? setIsVisibleModal(true) : showDrawer())}
            className="ProjectOriginSecurityView__setting-icon"
          />
        </Row>
      </Col>
    </Row>
  );
};

export default SecurityItem;
