import React, { FC, useEffect, useMemo } from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { IChartData, TTimeDuration } from 'store/slices/project';
import { useParams } from 'react-router-dom';
import { chartConfigs } from '../configs';

ReactFC.fcRoot(FusionCharts, Widgets, FusionTheme);

interface IProjectChartsRealtimeCONN {
  data?: IChartData;
  update?: number;
  selectedCdn?: string | number;
  timeDuration?: TTimeDuration;
}

export const ProjectChartsRealtimeCONN: FC<IProjectChartsRealtimeCONN> = ({
  data,
  update,
  selectedCdn,
  timeDuration,
}) => {
  const { directoryName } = useParams<{ directoryName?: string }>();
  const config = useMemo(
    () =>
      chartConfigs({
        dataset: [{ data: [{ value: 0 }] }],
        id: 'realtimeCONN',
        type: 'realtimearea',
        numdisplaysets: timeDuration ? `${(timeDuration * 60) / 5}` : '60',
      }),
    [timeDuration]
  );
  useEffect(() => {
    const chartRef = FusionCharts('realtimeCONN');
    if (chartRef && selectedCdn && chartRef.clearChart && directoryName) {
      chartRef.clearChart();
    }
  }, [selectedCdn, directoryName]);
  useEffect(() => {
    if (data && update) {
      const chartRef = FusionCharts('realtimeCONN');
      if (chartRef && chartRef.feedData) {
        const strData = `&label=${data.date}&value=${Number(data.data.CONN.value)}`;
        chartRef.feedData(strData);
      }
    }
  }, [data, update]);

  return <ReactFC {...config} />;
};

export default ProjectChartsRealtimeCONN;
