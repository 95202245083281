import { SelectItemType } from 'components/common/Select';

export interface IField {
  type: 'select' | 'input' | 'switch';
  id?: number;
  fieldName: string;
  selectData?: SelectItemType[];
  regexp?: RegExp;
  tooltip?: string;
  label?: string;
}

export const selectMatchObject: IField = {
  type: 'select',
  fieldName: 'result',
  selectData: [
    {
      name: 'Match',
      value: 'match',
    },
    {
      name: 'Does not match',
      value: 'nomatch',
    },
  ],
  label: 'Result',
};

export const selectCompareObject: IField = {
  type: 'select',
  fieldName: 'compare',
  selectData: [
    {
      name: '==',
      value: '==',
    },
    {
      name: '<',
      value: '<',
    },
    {
      name: '<=',
      value: '<=',
    },
    {
      name: '>',
      value: '>',
    },
    {
      name: '>=',
      value: '>=',
    },
  ],
  label: 'Compare',
};

export const selectMethodObject: IField = {
  type: 'select',
  fieldName: 'value',
  selectData: [
    {
      name: 'GET',
      value: 'GET',
    },
    {
      name: 'HEAD',
      value: 'HEAD',
    },
    {
      name: 'POST',
      value: 'POST',
    },
    {
      name: 'OPTIONS',
      value: 'OPTIONS',
    },
    {
      name: 'PUT',
      value: 'PUT',
    },
    {
      name: 'DELETE',
      value: 'DELETE',
    },
    {
      name: 'TRACE',
      value: 'TRACE',
    },
    {
      name: 'CONNECT',
      value: 'CONNECT',
    },
  ],
  label: 'Method',
};

export const selectSchemeObject: IField = {
  type: 'select',
  fieldName: 'methods',
  selectData: [
    {
      name: 'http',
      value: 'http',
    },
    {
      name: 'https',
      value: 'https',
    },
  ],
  label: 'Scheme',
};

export const selectRelativeObject: IField = {
  type: 'select',
  fieldName: 'relative_to',
  selectData: [
    {
      name: 'Root',
      value: 'root',
    },
    {
      name: 'Origin',
      value: 'origin',
    },
  ],
  label: 'Relative To',
};

export const selectTreatmentObject: IField = {
  type: 'select',
  fieldName: 'treatment',
  selectData: [
    {
      name: 'Pass Through',
      value: 'Pass Through',
    },
    {
      name: 'Overwrite',
      value: 'Overwrite',
    },
    {
      name: 'Add If Missing',
      value: 'Add If Missing',
    },
    {
      name: 'Remove',
      value: 'Remove',
    },
  ],
  label: 'Treatment',
};

export const selectModeObject: IField = {
  type: 'select',
  fieldName: 'mode',
  selectData: [
    {
      name: 'Include',
      value: 'include',
    },
    {
      name: 'Exclude',
      value: 'exclude',
    },
    {
      name: 'Include All',
      value: 'include-all',
    },
    {
      name: 'Exclude All',
      value: 'exclude-all',
    },
  ],
  label: 'Mode',
};

export const selectUnitsObject: IField = {
  type: 'select',
  fieldName: 'units',
  selectData: [
    {
      name: 'seconds',
      value: 'seconds',
    },
    {
      name: 'minutes',
      value: 'minutes',
    },
    {
      name: 'hours',
      value: 'hours',
    },
    {
      name: 'days',
      value: 'days',
    },
    {
      name: 'weeks',
      value: 'weeks',
    },
    {
      name: 'years',
      value: 'years',
    },
  ],
  label: 'Units',
};

export const selectActionObject: IField = {
  type: 'select',
  fieldName: 'action',
  selectData: [
    {
      name: 'Overwrite',
      value: 'overwrite',
    },
    {
      name: 'Append',
      value: 'append',
    },
    {
      name: 'Delete',
      value: 'delete',
    },
  ],
  label: 'Action',
};

export const selectUnitsDataObject: IField = {
  type: 'select',
  fieldName: 'usnits',
  selectData: [
    {
      name: 'Bytes',
      value: 'bytes',
    },
    {
      name: 'Kilobytes',
      value: 'kilobytes',
    },
    {
      name: 'Megabytes',
      value: 'megabytes',
    },
    {
      name: 'Gigabytes',
      value: 'gigabytes',
    },
    {
      name: 'Terabytes',
      value: 'terabytes',
    },
  ],
  label: 'Action',
};

export const selectCodeObject: IField = {
  type: 'select',
  fieldName: 'code',
  selectData: [
    {
      name: '301',
      value: '301',
    },
    {
      name: '302',
      value: '302',
    },
    {
      name: '307',
      value: '307',
    },
  ],
  label: 'Code',
};

export const inputCodesObject: IField = {
  type: 'input',
  fieldName: 'value',
  tooltip: 'Regex Pattern: ',
  label: 'Codes',
};

export const inputValueObject: IField = {
  type: 'input',
  fieldName: 'value',
  tooltip: 'Regex Pattern: ',
  label: 'Value',
};

export const inputNameObject: IField = {
  type: 'input',
  fieldName: 'name',
  tooltip: 'Regex Pattern: ',
  label: 'Name',
};

export const switchObject: IField = {
  type: 'switch',
  fieldName: 'ignore_case',
  label: 'Ignore Case',
};

export const switchEncodedObject: IField = {
  type: 'switch',
  fieldName: 'encoded',
  label: 'Encoded',
};

export const switchEnabledObject: IField = {
  type: 'switch',
  fieldName: 'enabled',
  label: 'Enabled',
};
