import { accessDeny } from './accessFieldsDataItems';
import {
  bandwidthParameters,
  bandwidthThrottling,
  bypassCache,
  cacheControlHeaderTreatment,
  cacheKeyQueryString,
  cacheKeyRewrite,
  compressFileTypes,
  defaultInternalMaxAge,
  expiresHeaderTreatment,
  externalMaxAge,
  forceInternalMaxAge,
  honorNoCacheRequest,
  hSupport,
  hSupportVideo,
  ignoreOriginNoCache,
  ignoreUnsatisfiedRanges,
  internalMaxStale,
  partialCacheSharing,
  prevalidateCachedContent,
  refreshZeroBytesCacheFiles,
  setCachebleStatusCodes,
  staleContentDeliveryOnError,
  staleWhileRevalidate,
} from './cachingFieldsDataItems';
import { IField } from './fieldsObjects';
import { generalComment } from './generalFieldsDataItems';
import {
  ageResponseHeader,
  debugCacheResponseHeader,
  modifyClientRequestHeader,
  modifyClientResponseHeader,
  setClientIpCustomHeader,
} from './headersFieldsDataItems';
import {
  asNumber,
  cityNameLiteral,
  cityNameRegex,
  continentLiteral,
  countryLiteral,
  dmaCodeLiteral,
  latitudeLiteral,
  longitudeLiteral,
  metroCodeLiteral,
  postalCodeLiteral,
  regionCodeLiteral,
} from './locationFieldsDataItems';
import {
  clientIpAddressLiteral,
  cookieLiteral,
  cookieRegex,
  cookieWildcard,
  domainLiteral,
  domainWildcard,
  headerLiteral,
  headerRegex,
  headerWildcard,
  methodLiteral,
  schemeLiteral,
} from './requestFieldsDataItems';
import {
  urlPathDirectoryLiteral,
  urlPathDirectoryWildcard,
  urlPathExtensionLiteral,
  urlPathExtensionWildcard,
  urlPathFilenameLiteral,
  urlPathFilenameWildcard,
  urlPathLiteral,
  urlPathRegex,
  urlPathWildcard,
  urlQueryLiteral,
  urlQueryParameterLiteral,
  urlQueryParameterWildcard,
  urlQueryRegex,
  urlQueryWildcard,
} from './urlFieldsDataItems';
import { customLogField, logQueryString, maskClientSubnet } from './logsFieldsDataItems';
import { maxKeepAliveRequests, proxySpecialHeaders } from './originFieldsDataItems';
import {
  cacheableMethods,
  cacheableRequestBodySize,
  revalidateWhileStale,
  revalidateWhileStaleTimer,
  userVariable,
} from './specialityFieldsDataItems';
import { followRedirects, urlRedirect, urlRewrite } from './urlFeatureFieldsDataItems';

interface IFieldsData {
  [key: string]: IField[];
}
// MATCHES
export const locationFieldsData: IFieldsData = {
  'match.location.as-number.literal': asNumber,
  'match.location.city-name.literal': cityNameLiteral,
  'match.location.city-name.regex': cityNameRegex,
  'match.location.continent.literal': continentLiteral,
  'match.location.country.literal': countryLiteral,
  'match.location.dma-code.literal': dmaCodeLiteral,
  'match.location.latitude.literal': latitudeLiteral,
  'match.location.longitude.literal': longitudeLiteral,
  'match.location.metro-code.literal': metroCodeLiteral,
  'match.location.postal-code.literal': postalCodeLiteral,
  'match.location.region-code.literal': regionCodeLiteral,
};

export const requestFieldsData: IFieldsData = {
  'match.request.client-ip-address.literal': clientIpAddressLiteral,
  'match.request.cookie.literal': cookieLiteral,
  'match.request.cookie.regex': cookieRegex,
  'match.request.cookie.wildcard': cookieWildcard,
  'match.request.referring-domain.literal': domainLiteral,
  'match.request.referring-domain.wildcard': domainWildcard,
  'match.request.request-header.literal': headerLiteral,
  'match.request.request-header.regex': headerRegex,
  'match.request.request-header.wildcard': headerWildcard,
  'match.request.request-method.literal': methodLiteral,
  'match.request.request-scheme.literal': schemeLiteral,
};

export const urlFieldsData: IFieldsData = {
  'match.url.url-path.literal': urlPathLiteral,
  'match.url.url-path.regex': urlPathRegex,
  'match.url.url-path.wildcard': urlPathWildcard,
  'match.url.url-path-directory.literal': urlPathDirectoryLiteral,
  'match.url.url-path-directory.wildcard': urlPathDirectoryWildcard,
  'match.url.url-path-extension.literal': urlPathExtensionLiteral,
  'match.url.url-path-extension.wildcard': urlPathExtensionWildcard,
  'match.url.url-path-filename.literal': urlPathFilenameLiteral,
  'match.url.url-path-filename.wildcard': urlPathFilenameWildcard,
  'match.url.url-query.literal': urlQueryLiteral,
  'match.url.url-query.regex': urlQueryRegex,
  'match.url.url-query.wildcard': urlQueryWildcard,
  'match.url.url-query-param.literal': urlQueryParameterLiteral,
  'match.url.url-query-param.wildcard': urlQueryParameterWildcard,
};

// FEATURES
export const accessFieldsData: IFieldsData = {
  'feature.access.deny-access': accessDeny,
};

export const cachingFieldsData: IFieldsData = {
  'feature.caching.bandwidth-parameters': bandwidthParameters,
  'feature.caching.bandwidth-throttling': bandwidthThrottling,
  'feature.caching.bypass-cache': bypassCache,
  'feature.caching.cache-control-header-treatment': cacheControlHeaderTreatment,
  'feature.caching.cache-key-query-string': cacheKeyQueryString,
  'feature.caching.cache-key-rewrite': cacheKeyRewrite,
  'feature.caching.compress-file-types': compressFileTypes,
  'feature.caching.default-internal-max-age': defaultInternalMaxAge,
  'feature.caching.expires-header-treatment': expiresHeaderTreatment,
  'feature.caching.external-max-age': externalMaxAge,
  'feature.caching.force-internal-max-age': forceInternalMaxAge,
  'feature.caching.h264-support': hSupport,
  'feature.caching.h264-support-video-seek-params': hSupportVideo,
  'feature.caching.honor-no-cache-request': honorNoCacheRequest,
  'feature.caching.ignore-origin-no-cache': ignoreOriginNoCache,
  'feature.caching.ignore-unsatisfiable-ranges': ignoreUnsatisfiedRanges,
  'feature.caching.internal-max-stale': internalMaxStale,
  'feature.caching.partial-cache-sharing': partialCacheSharing,
  'feature.caching.prevalidate-cached-content': prevalidateCachedContent,
  'feature.caching.refresh-zero-byte-cache-files': refreshZeroBytesCacheFiles,
  'feature.caching.set-cacheable-status-codes': setCachebleStatusCodes,
  'feature.caching.stale-content-delivery-on-error': staleContentDeliveryOnError,
  'feature.caching.stale-while-revalidate': staleWhileRevalidate,
};

export const generalFieldsData: IFieldsData = {
  'feature.comment': generalComment,
};

export const headersFieldsData: IFieldsData = {
  'feature.headers.age-response-header': ageResponseHeader,
  'feature.headers.debug-cache-response-headers': debugCacheResponseHeader,
  'feature.headers.modify-client-request-header': modifyClientRequestHeader,
  'feature.headers.modify-client-response-header': modifyClientResponseHeader,
  'feature.headers.set-client-ip-custom-header': setClientIpCustomHeader,
};

export const logsFieldsData: IFieldsData = {
  'feature.logs.custom-log-field-1': customLogField,
  'feature.logs.log-query-string': logQueryString,
  'feature.logs.mask-client-subnet': maskClientSubnet,
};

export const originFieldsData: IFieldsData = {
  'feature.origin.max-keep-alive-requests': maxKeepAliveRequests,
  'feature.origin.proxy-special-headers': proxySpecialHeaders,
};

export const specialtyFieldsData: IFieldsData = {
  'feature.specialty.cacheable-http-methods': cacheableMethods,
  'feature.specialty.cacheable-request-body-size': cacheableRequestBodySize,
  'feature.specialty.revalidate-while-stale': revalidateWhileStale,
  'feature.specialty.revalidate-while-stale-timer': revalidateWhileStaleTimer,
  'feature.specialty.user-variable': userVariable,
};

export const urlFeatureFieldsData: IFieldsData = {
  'feature.url.follow-redirects': followRedirects,
  'feature.url.url-redirect': urlRedirect,
  'feature.url.url-rewrite': urlRewrite,
};
