import React, { FC } from 'react';
import classNames from 'classnames';
import './StatusDot.scss';

export const statusDotTestId = 'status-dot';

export type StatusDotType = 'success' | 'danger' | 'warning' | 'staging';

export interface Props {
  className?: string;
  type?: StatusDotType;
  size?: number;
}

export const StatusDot: FC<Props> = ({ type = 'success', className, size }) => {
  return (
    <span
      data-testid={statusDotTestId}
      className={classNames('StatusDot', `_${type}`, className)}
      style={size ? { width: size, height: size } : undefined}
    />
  );
};

export default StatusDot;
